import { díaSemana, soloHoraNum } from "../core/Funciones/fFecha";
import { fechaT } from "../core/Funciones/fTexto";
import { IEmpleados } from "./empleados";
import { IEmpresa } from "./empresa";
import { expandirRangosHora, IHorarioDetalle, MinifiedRangoHora, minifiedRangosHora, RangoHora } from "./horarios";
import { IRespuestaChecker } from "./resultadoActualiza";

export interface DatosIncidencias {
    id: number;
    idEmpleado: number;
    idRefEmpleado: string;
    nombre: string;
    idHorario: string;
    horario: string;
    idDepartamento: number;
    fecha: Date;
    incidenciaHorario: IncidenciaHorario[];
    horasTrabajadas: number;
    horasTrabajadasTxt: string;
    horasExtras: number;
    horasExtrasTxt: string
    retardos: number;
    retardosTxt: string
    faltas: number;
    faltasTxt: string
    descanso: number;
    trabajoEnDescanso: number;
    vacaciones: number;
    incapacidad: number;
    especialTxt: string;
    especialObservaciones: string;
    esTrabajoDescanso: boolean;
    idIncidenciaAjuste: number;
    tipoManejoIncidencia: TipoManejoIncidencia;
    diasTrabajados: number;
    detalleHorarioDia: IDetalleHOrarioDia;
    incidencias: number;
    modificada: boolean; // modificada después de exportar
}

export function nuevoDatosIncidencia(idEmpleado: number, nombre: string, id: number, idDepartamento: number, fecha: Date, idReferencia: string): DatosIncidencias {
    return {
        id: id,
        idEmpleado: idEmpleado,
        idRefEmpleado: idReferencia,
        nombre: nombre,
        idHorario: '',
        idDepartamento: idDepartamento,
        horario: '',
        fecha: fecha,
        incidenciaHorario: [],
        horasTrabajadas: 0,
        horasTrabajadasTxt: '-',
        horasExtras: 0,
        horasExtrasTxt: '-',
        retardos: 0,
        retardosTxt: '-',
        faltas: 0,
        faltasTxt: '-',
        descanso: 0,
        trabajoEnDescanso: 0,
        vacaciones: 0,
        incapacidad: 0,
        especialTxt: '-',
        especialObservaciones: '-',
        esTrabajoDescanso: false,
        idIncidenciaAjuste: 0,
        tipoManejoIncidencia: TipoManejoIncidencia.Ninguno,
        diasTrabajados: 0,
        detalleHorarioDia: new IDetalleHOrarioDia(),
        incidencias: 0,
        modificada: false
    };
}

export interface IncidenciaHorario {
    horaEntrada: Date;
    horaEntradaHorario: Date;
    horaSalida: Date;
    horaSalidaHorario: Date;
    horasHorario: number;
    salidaDiaSiguiente: boolean;
    horasTrabajadas: number;
    horasExtras: number;
    tiempoAFavor: number;
    retardo: number;
    descanso: number;
    falta: number;
    trabajoEnDescanso: number;
    tipoIncidenciaEntrada: TipoIncidencia;
    tipoIncidenciaSalida: TipoIncidencia;
    observaciones: string[];
    modificada: boolean // modificada después de exportar
}

export function nuevaIncidenciaHorario(): IncidenciaHorario {
    return {
        horaEntrada: soloHoraNum(0),
        horaSalida: soloHoraNum(0),
        horaEntradaHorario: soloHoraNum(0),
        horaSalidaHorario: soloHoraNum(0),
        horasHorario: 0,
        salidaDiaSiguiente: false,
        horasTrabajadas: 0,
        horasExtras: 0,
        tiempoAFavor: 0,
        retardo: 0,
        descanso: 0,
        falta: 0,
        trabajoEnDescanso: 0,
        tipoIncidenciaEntrada: TipoIncidencia.SinRegistro,
        tipoIncidenciaSalida: TipoIncidencia.SinRegistro,
        observaciones: [],
        modificada: false
    }
}

export interface IAccesoIncidenciasAjuste
{
  id: number,
  idEmpresa: number,
  idUsuario: number,
  idEmpleado: number,
  fecha: Date,
  fechaTxt: string,
  index: number,
  tipoManejoIncidencia: TipoManejoIncidencia;
  tipoIncidencia: TipoValorIncidencia;
  modificado: Date,
  valor: number,
  activo: EstadoIncidencias,
  observaciones: string
}

export function nuevaIncidenciaAjuste(idEmpresa: number, idEmpleado: number, fecha: Date, tipoManejoIncidencia: TipoManejoIncidencia, tipoValorIncidencia: TipoValorIncidencia, valor: number, index: number): IAccesoIncidenciasAjuste {
    return {
        id: 0,
        idUsuario: 999,
        idEmpresa: idEmpresa,
        idEmpleado: idEmpleado,
        fecha: fecha,
        fechaTxt: fechaT(fecha),
        index: index,
        tipoManejoIncidencia: tipoManejoIncidencia,
        tipoIncidencia: tipoValorIncidencia,
        modificado: new Date(),
        valor: valor,
        activo: EstadoIncidencias.Activa,
        observaciones: ''
    }
}

export class IAccesoIncidenciasUsuario
{
  id: number = 0;
  idEmpresa: number = 0;
  idUsuario: number = 0;
  idEmpleado: number = 0;
  nombreEmpleado: string = '';
  inicio: Date = new Date();
  inicioTxt: string = '';
  fin: Date = new Date();
  finTxt: string = '';
  tipoManejoIncidencia: TipoManejoIncidencia = TipoManejoIncidencia.Ninguno;
  tipoIncidencia: TipoValorIncidencia = TipoValorIncidencia.IncidenciaEspecial;
  tipoIncapacidad: TipoIncapacidad = TipoIncapacidad.Ninguna;
  modificado: Date = new Date();
  valor: number = 0;
  activo: EstadoIncidencias = EstadoIncidencias.Activa;
  observaciones: string = '';
  fechaRegistro: Date = new Date();
  fechaRegistroTxt: string = '';
  idEmpleadoRegistro: number = 0;
  nombreEmpleadoRegistro: string = '';
}

export interface IAccesoIncidenciasUsuarioCargar extends IRespuestaChecker {
    empresa: IEmpresa,
    empleados: IEmpleados[],
    incidencias: IAccesoIncidenciasUsuario[],
    fechaExportarReporte: string,
    fechaExportarFinPeriodo: string,
    tipoReporteExportado: string
}

export interface IIncidenciaWeb {
    id: number;
    idEmpleado: number;
    idDepartamento: number;
    fecha: Date;
    tipoIncidencia: TipoIncidenciaWeb;
    valor: number;
    savedTime: Date | undefined;
    tipoIncapacidad: TipoIncapacidad;
    folioIncapacidad: string;
    observaciones: string;
}

export interface MinifiedIncidenciaWeb {
    // id
    a: number;
    /** kind */
    b: number;
    /** employeeId */
    c: number;
    /** departmentID */
    d: number;
    /** miltiplier */
    e: number;
    /** date */
    f: Date;
    /** folio */
    g: string;
    // SavedTime
    h: Date | undefined;
    // TipoIncapacidad //
    i: number;
    // Fecha en texto
    j: string;

    // observaciones: string;
}

export function creaMinifiedIncidenciaWeb(incidenciaWeb: IIncidenciaWeb): MinifiedIncidenciaWeb {
    return {
        a: incidenciaWeb.id,
        b: incidenciaWeb.tipoIncidencia,
        c: incidenciaWeb.idEmpleado,
        d: incidenciaWeb.idDepartamento,
        e: incidenciaWeb.valor,
        f: incidenciaWeb.fecha,
        g: incidenciaWeb.folioIncapacidad,
        h: incidenciaWeb.savedTime,
        i: incidenciaWeb.tipoIncapacidad,
        j: fechaT(incidenciaWeb.fecha),
        // observaciones:incidenciaWeb.observaciones
    }
}

// export interface ITiempoExtraWeb {
//     id: number;
//     idEmpleado: number;
//     idDepartamento: number;
//     fecha: Date;
//     valor: number;
//     savedTime: Date | undefined;
// }

export interface MinifiedTiempoExtra {
    // id
    a: number;
    /** employeeId */
    b: number;
    /** departmentId */
    c: number;
    /** date */
    d: Date;
    /** value */
    e: number;
    // SavedTime
    f: Date | undefined;
    /** value2 */
    g: number;
    // Fecha en texto
    h: string;

    // observaciones: string;
}

export class IncidencesReport {
    reportId: string = '';
    absences: MinifiedIncidenciaWeb[] = [];
    extras: MinifiedTiempoExtra[] = [];
    from: string = '';
    to: string = '';
}

export interface IAccesoPoliticas
{
    id: number,
    companyId: number,
    deparmentId: number,
    toleranciaRetardos: number,
    salidasComoRetardos: boolean,
    toleranciaHorasExtra: number,
    toleranciaFaltas: number,
    penalizarNoEntrada: number,
    penalizarNoSalida: number,
    limiteEntrada: number,
    limiteSalida: number,
    mostrarHoraReal: boolean,
    cubrirRetardo: boolean,
    horaMaxCambioJornada: number;
    ultimaAct: Date;
    ignorarPenalizarNoEntrada: boolean;
    diasFestivosEmpresa: string;
    horasOficialPDF: boolean;
    numeroRetardosComoFaltaBool: boolean;
    numeroRetardosComoFaltaInput: number;
}

export function creaMinifiedTiempoExtra(tiempoExtraWeb: IIncidenciaWeb): MinifiedTiempoExtra {
    return {
        a: tiempoExtraWeb.id,
        b: tiempoExtraWeb.idEmpleado,
        c: tiempoExtraWeb.idDepartamento,
        d: tiempoExtraWeb.fecha,
        e: tiempoExtraWeb.valor,
        f: tiempoExtraWeb.savedTime,
        g: tiempoExtraWeb.valor,
        h: fechaT(tiempoExtraWeb.fecha),
        // observaciones:tiempoExtraWeb.observaciones
    }
}

// export enum TipoManejoIncidencia
// {
//     Ingreso = 0,
//     Salida = 1,
//     ComidaSalida1 = 2,
//     ComidaRegrso1 = 3,
//     ComidaSalida2 = 4,
//     ComidaRegrso2 = 5,
//     ComidaSalida3 = 6,
//     ComidaRegrso4 = 7
// }

export enum TipoManejoIncidencia {
    Incidencia = 0,
    Asistencia = 1,
    Ausentismo = 2,
    Incapacidad = 3,
    Vacaciones = 4,
    Permiso = 5,
    PermisoSinPago = 6,
    Comision = 7,
    ComisionSinPago = 8,
    Viaje = 9,
    ReEstablecer = 98,
    Eliminar = 99,
    Ninguno = 100
}

export enum TipoIncidencia {
    ATiempo = 0,
    Tolerancia = 1,
    Retardo = 2,
    TiempoAFavor = 3,
    TiempoExtra = 4,
    Compensando = 5,
    Descanso = 30,
    RetardoSinRegistro = 60,
    ConError = 66,
    Pendiente = 97,
    SinHorario = 98,
    SinRegistro = 99
 }

export const enum TipoValorIncidencia {
    IncidenciaEspecial = 0,
    ReEstablecer = 1,
    Eliminar = 2,
    Falta = 3,
    Asistencia = 4,
    Retardo = 5,
    HoraExtra = 6,
    QuitarRetardo = 7,
    QuitarHorasExtra = 8,
    AsistenciaEspecial = 9,
    FaltaJustificada = 10
}

export const enum TipoIncidenciaWeb {
    ninguna = -1,
    ausencia,
    incapacidad,
    vacaciones,
    descanso,
    trabajoEnDescanso,
    tiempoExtra
}

export const enum TipoIncapacidad {
    Ninguna,
    RiesgoDeTrabajo,
    EnfermedadGeneral,
    Maternidad,
    LicenciaPorHijosConCancer
}

export const  enum EstadoIncidencias {
    Activa = 0,
    Baja = 1,
    Solicitud = 2
}

export const $tipoManejoIncidencia: { [key: number]: string } = {
    0: 'Incidencia',
    1: 'Asistencia',
    2: 'Ausentismo',
    3: 'Incapacidad',
    4: 'Vacaciones',
    5: 'Permiso',
    6: 'Permiso sin pago',
    7: 'Comisión',
    8: 'Comisión sin pago',
    9: 'Viaje',
    98: 'ReEstablecer',
    99: 'Eliminados',
    100: 'Ninguno'
  };

  export const $tipoManejoIncidenciaUsuario: { [key: number]: string } = {
    1: 'Asistencia',
    2: 'Ausentismo',
    3: 'Incapacidad',
    4: 'Vacaciones',
    5: 'Permiso',
    6: 'Permiso sin pago',
    7: 'Comisión',
    8: 'Comisión sin pago',
    9: 'Viaje',
    100: '- sin especificar-'
  };

  export const $tipoValorIncidencia: { [key: number]: string } = {
    0: 'Incidencia Especial',
    1: 'ReEstablecer',
    2: 'Eliminar',
    3: 'Falta',
    4: 'Asistencia',
    5: 'Retardo',
    6: 'Hora Extra',
    7: 'Quitar Retardo',
    8: 'Quitar Horas Extra',
    9: 'Asistencia Especial',
    10: 'Falta especial'
}

export const $tipoEmpleadosManejoAccesoExpress: { [key: number]: string } = {
    0: 'Normal',
    1: 'Empleados de Confianza sin registro',
    2: 'Empleados de Confianza sin exportación'
}

export const $tipoIncapacidad: { [key: number]: string } = {
    0: '- Sin tipo de incapacidad seleccionada -',
    1: 'Riesgo de Trabajo',
    2: 'Enfermedad general',
    3: 'Maternidad',
    4: 'Licencia por hijos con cancer'
}

export class DatosCrearIncidencia {
    constructor (idEmpleado: number, nombre: string, id: number, dia: Date, esDescanso: boolean,esHoyOMayor: boolean) {
        this.idEmpleado = idEmpleado;
        this.nombreEmpleado = nombre;
        this.idIncidencia = id;
        this.dia = dia;
        this.esDescanso = esDescanso;
        this.esHoyOMayor = esHoyOMayor;
    }
    idEmpleado: number = 0;
    nombreEmpleado: string = '';
    idIncidencia: number = 0;
    dia: Date = new Date();
    esDescanso: boolean = false;
    esHoyOMayor: boolean = false;
}

export class IDetalleHOrarioDia {
    esDescanso: boolean = false;
    esNocturno: boolean = false;
    horarioDia: RangoHora[] = [];
}

export class MinifiedDetalleHOrarioDia {
    de: boolean = false;
    no: boolean = false;
    ho: MinifiedRangoHora[] = [];
}

export function minifiedDetalleHorarioDia(horarioDia: IDetalleHOrarioDia): MinifiedDetalleHOrarioDia {
    return {
        de: horarioDia.esDescanso,
        no: horarioDia.esNocturno,
        ho: minifiedRangosHora(horarioDia.horarioDia)
    }
}

export function expandirDetalleHorarioDia(horarioDia: MinifiedDetalleHOrarioDia): IDetalleHOrarioDia {
    return {
        esDescanso: horarioDia.de,
        esNocturno: horarioDia.no,
        horarioDia: expandirRangosHora(horarioDia.ho)
    }
}


export function convertirHorarioDiaADetalle(horarioDia: IDetalleHOrarioDia, dia: Date): IHorarioDetalle[] {
    let diaSemana: string = díaSemana(dia);
    let horarioSemana: { [key: string]: RangoHora[] } = {};
    horarioSemana[diaSemana] = horarioDia.horarioDia;
    let horarioDetalle: IHorarioDetalle = {
        diasDescanso: horarioDia.esDescanso ? [ diaSemana ] : [],
        horariosSemana: horarioSemana
    };
    return [ horarioDetalle ];
}

export interface DatosIncidenciasDia {
  incidenciasDelDia: DatosIncidencias[],
  incidencia: DatosIncidencias,
}
