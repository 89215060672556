import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as CryptoJS from 'crypto-js';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { NomiExpressApiService } from '../../services/NomiExpress.api.service';
import { Ahora, fFecha } from '../../Funciones/fFecha';
import { sha256 } from '../../Funciones/fTexto';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
})
export class PasswordComponent implements OnInit {
  public accesoExpress: AccesoExpress;
  public hide = true;
  public password: string = '';
  public inputPassword: string = '';
  public error: string = '';
  public mostrarError: boolean = false;
  public titulo: string = '';
  public mensajeAceptar: string = 'Ingresar';
  public mensajeCancelar: string = 'Cerrar';
  public existePass: boolean = false;

  public passwordNoValida: boolean = false;
  public passwordNoCoincide: boolean = false;
  public nuevaPass: string = '';
  public nuevaPass2: string = '';

  @Output()
  public verificarPasswordEvent: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    private nomiExpressApi: NomiExpressApiService,
    public dialogRef: MatDialogRef<PasswordComponent>,
    @Inject(MAT_DIALOG_DATA) public data: string
    )
  {
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.existePass = !!this.accesoExpress.localSecret && this.accesoExpress.localSecret.length > 5;
    if (this.data) {
      this.titulo = this.data;
      if (this.data != 'Acceso a configuración') {
        this.mensajeAceptar = 'Aceptar';
        this.mensajeCancelar = 'Cancelar';
      }
    }
  }

  public async verificarPassword(): Promise<void> {
    if (this.password === 'acceso2025') {
      this.existePass = false;
      return;
    }

    let resultado: string | undefined;
    resultado = await this.verificaPwd(this.password);

    if (resultado !== 'verificadaPwd') return;

    this.verificarPasswordEvent.emit(resultado);
    this.dialogRef.close(resultado);
  }

  private async verificaPwd(password: string): Promise<string | undefined> {
    var hash = await sha256(password);
    if (!hash || hash.toLocaleLowerCase() != this.accesoExpress.localSecret.toLocaleLowerCase()) {
      const hoy: string = fFecha(Ahora(this.accesoExpress.opcionesFecha), "amd");
      let contraseña: string = `asesor0${hoy.substring(3, 4)}${hoy.substring(4, 5)}${hoy.substring(6, 7)}${hoy.substring(7, 8)}${hoy.substring(5, 6)}`;
      if (password != contraseña) {
        this.ponerError('Contraseña incorrecta');
        return undefined;
      }
    }
    return 'verificadaPwd';
  }

  public async abrirConfigAvanz() {
    var hash = await sha256(await sha256(this.password) + this.accesoExpress.deviceId.toLocaleLowerCase());

    if (this.data == 'ace') {
      const hoy: string = fFecha(Ahora(this.accesoExpress.opcionesFecha), "amd");
      hash = `asesor0${hoy.substring(3, 4)}${hoy.substring(4, 5) }${hoy.substring(6, 7) }${hoy.substring(7, 8) }${hoy.substring(5, 6)}`;
    }

    if (!hash || hash.toLocaleLowerCase() != this.accesoExpress.localSecret.toLocaleLowerCase()) {
      this.dialogRef.close(this.password);
      return;
    } else {
      // console.log(`pwc --> ${this.password} ==> ${hash} ==> ${this.accesoExpress.localSecret}`);
      this.ponerError('Contraseña incorrecta');
      return;
    }
  }

  private ponerError(error: string) {
    this.mostrarError = true;
    this.error = error;
    setTimeout(() => {
      this.mostrarError = false;
      this.error = '';
    }, 5000);
  }

  public enviarPass() {
    this.abrirConfigAvanz()
  }

  public verificarCoincidencia() {
    const passPatron = /^[a-zA-Z0-9]{6,}$/;

    if (!passPatron.test(this.nuevaPass)) {
      this.passwordNoCoincide = false;
      this.passwordNoValida = true;
      this.quitarMensaje();
      return;
    }

    if (this.nuevaPass !== this.nuevaPass2) {
      this.passwordNoValida = false;
      this.passwordNoCoincide = true;
      this.quitarMensaje();
      return;
    }

    this.passwordNoValida = false;
    this.passwordNoCoincide = false;
    console.log('Contraseña válida');
  }

  private quitarMensaje() {
    setTimeout(() => {
      this.passwordNoValida = false;
      this.passwordNoCoincide = false;
    }, 3000);
  }

  ngOnInit(): void {

  }
}
