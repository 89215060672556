import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IEmpleados, ManejoEmpleados, TiposEmpleado, nombreEmpleado, nuevoEmpleado } from 'src/app/models/empleados';
import { fFecha, soloDia } from '../../../Funciones/fFecha';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import Swal from 'sweetalert2';
import { IRespuestaCheckerEmpleado } from 'src/app/models/resultadoActualiza';
import { mostrarSwalConfirm, mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { IEmpresa } from 'src/app/models/empresa';
import { fechaT } from 'src/app/core/Funciones/fTexto';
import { MatDatepicker } from '@angular/material/datepicker';
import { MatDialog } from '@angular/material/dialog';
import { EmpleadosValidarIncidenciasComponent } from 'src/app/empleados/empleados-validar-incidencias/empleados-validar-incidencias.component';

@Component({
  selector: 'app-datos-empleado',
  templateUrl: './datos-empleado.component.html',
  styleUrls: ['./datos-empleado.component.scss']
})
export class DatosEmpleadoComponent {

  public empleado: IEmpleados = nuevoEmpleado();

  public esDebugger: boolean = false;
  public tipoEmpleadoSeleccionado: string =' ';
  public tiposEmpleado = TiposEmpleado;
  public manejoEmpelado = ManejoEmpleados;
  public grupoEmpleados: string[] = [];
  public cargando: boolean = false;
  public esNuevoEmpleado: boolean = false;
  public estaGuardando: boolean = false;
  public etiquetaAgrupar: string = 'Agrupador';
  public tipoEmpleado: number = 0;

  @Output()
  public mostrarReconocimientoFacial = new EventEmitter<boolean>();

  @Output()
  public mostrarCodgioQr = new EventEmitter<boolean>();

  @Output()
  public mostrarRegistroIncidencias = new EventEmitter<boolean> ();

  @Input()
  public mostrarElementos: boolean = false;

  @Input()
  public isModificando: boolean = false;

  @Output()
  public empleadoNuevo = new EventEmitter<boolean>();

  @Output()
  public verificarMenuEmpleadosEmitter = new EventEmitter<boolean>();

  constructor(
    private _snackBar: MatSnackBar,
    private accesoDatosService: AccesoDatosService,
    public dialog: MatDialog,
  )
  {
    this.cargarEmpleado();
    this.tipoEmpleado = this.accesoDatosService.getUsuario().tipoEmpleado;

    if (!this.esDebugger) {
      delete this.tiposEmpleado[3];
    }
  }

   public formatoFecha(fecha: Date | undefined){
    if (!fecha) {
      return '-';
    }
    // console.log(`fecha: ${fecha}`);
    return fFecha(fecha, "FSL")
  }

   public nombreCompleto(){
    return nombreEmpleado(this.empleado);
  }

  public modificarDatos(): void {
    this.isModificando = true;
  }

  public async eliminarEmpleado() {
    const { value: formValues } = await Swal.fire({
      title: `Seleccione la fecha con la que se dará de baja a el empleado ${nombreEmpleado(this.empleado)}?`,
      html: `<input type="date" class="form-control" id="inputDate">`,
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Dar de baja",
      preConfirm: () => {
        const inputDate = (document.getElementById('inputDate') as HTMLInputElement).value;

        if (!inputDate) {
          Swal.showValidationMessage("Necesitas seleccionar una fecha");
          setTimeout(() => {
            Swal.resetValidationMessage()
          }, 3000);
          return null;
        }

        return { inputDate };
      }
    });
    if (formValues) {
      const { inputDate } = formValues;

      console.log('Baja empleado', inputDate);
      this.empleado.bajaActualTxt = inputDate + 'T00:00:00';
      this.accesoDatosService.empleadoBaja(this.empleado).subscribe(
        (respuesta: IRespuestaCheckerEmpleado) => {
          // console.log('Respuesta guardar empleado', respuesta);
          if (respuesta.code == 100){
            mostrarSwalConfirm('Empleado dado de baja correctamente', 'success');
            this.cargarEmpleado();
          } else {;
            mostrarSwalError(`Respuesta del servidor. ${respuesta.code}, ${respuesta.mensaje}`, 'Dar de baja empleados')
            this.estaGuardando = false;
          }
        }, (error) => {
          let errorTxt: string = error.error;
          mostrarSwalError(`Error al guardar los datos de los empleados. ${errorTxt}`, 'Dar de baja empleados');
          console.error(error);
          this.estaGuardando = false;
        }
      );
    }
  }

  public deshacerDatos() {
    this.isModificando = false;
    this.cargarEmpleado();
  }

  public guardarDatos() {
    this.estaGuardando = true;
    if (+this.empleado.id == 0) this.empleado.id = '-1';
    this.empleado.altaActualTxt = fechaT(soloDia(this.empleado.alta));
    this.accesoDatosService.empleadoModificar(this.empleado).subscribe(
      (respuesta: IRespuestaCheckerEmpleado) => {
        // console.log('Respuesta guardar empleado', respuesta);
        if (respuesta.code == 100){
          mostrarSwalToast('Datos guardados correctamente', 'success');
          if (this.esNuevoEmpleado) {
            if (!respuesta.empleado) {
              this.verificarMenuEmpleadosEmitter.emit(false);
              return;
            }
            this.empleado = respuesta.empleado;
            this.accesoDatosService.empleadoActual(+this.empleado.id);
            this.empleadoNuevo.emit(false);
            this.estaGuardando = false;
            return;
          }
          this.cargarEmpleado();
        } else {
          mostrarSwalError(`Respuesta del servidor. ${respuesta.code}, ${respuesta.mensaje}`, 'Guardar datos de empleados');
          this.estaGuardando = false;
        }
      }, (error) => {
        let errorTxt: string = error.error;
        mostrarSwalError(`Error al guardar los datos de los empleados. ${errorTxt}`, 'Guardar datos de empleados');
        console.error(error);
        this.estaGuardando = false;
      }
    );
  }

  public cargarEmpleado() {
    this.isModificando = false;
    this.estaGuardando = false;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empresaObtener().subscribe(
      (empresa: IEmpresa) => {
        this.etiquetaAgrupar = !empresa.etiquetaGrupo ? 'Agrupador' : empresa.etiquetaGrupo;
      },
      (error) => {
        let errorTxt: string = error.error;
        mostrarSwalToast(`Error al cargar los datos de los grupos de empleados. ${errorTxt}`, 'error');
        console.error(error);
      }
    );
    this.accesoDatosService.empleadosGrupos().subscribe(
      (grupos: string[]) => {
        this.grupoEmpleados = grupos;
      },
      (error) => {
        let errorTxt: string = error.error;
        mostrarSwalToast(`Error al cargar los datos de los grupos de empleados. ${errorTxt}`, 'error');
        console.error(error);
      }
    );
    this.accesoDatosService.empleadoObtener().subscribe(
      (empleado: IEmpleados) => {
        this.empleado = empleado;
        if (+empleado.id == 0){
          this.isModificando = true;
          this.esNuevoEmpleado = true;
        }
        this.cargando = false;
      },
      (error) => {
        let errorTxt: string = error.error;
        mostrarSwalError(`Error al cargar los datos de los empleados. ${errorTxt}`, 'Guardar datos de empleados')
        console.error(error);
      }
    );
  }

  public emitirMostrarReconocimientoFacial() {
    this.mostrarReconocimientoFacial.emit(true);
  }

  public emitirMostrarCodigo() {
    this.mostrarCodgioQr.emit(true);
  }

  public emitirMostrarRegistroIncidencias() {
    this.mostrarRegistroIncidencias.emit(true);
  }

  public validarIncidenciaDialog() {
    const validarIncidencia = this.dialog.open(EmpleadosValidarIncidenciasComponent, {
      data: this.empleado,
      height: '90vh'
    });

    validarIncidencia.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit(): void {}

}
