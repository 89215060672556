import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { fFecha } from 'src/app/core/Funciones/fFecha';
import { fNumero } from 'src/app/core/Funciones/fTexto';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { IAccesoEmpleadoConRFData } from 'src/app/models/accesoCF';
import { IEmpleados, nombreEmpleado } from 'src/app/models/empleados';
import { $tipoPeriodoReporte, $tipoPeriodoReporteOrden } from 'src/app/models/periodos';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-empleados-registro-incidencias',
  templateUrl: './empleados-registro-incidencias.component.html',
  styleUrls: ['./empleados-registro-incidencias.component.scss']
})
export class EmpleadosRegistroIncidenciasComponent {
  public datosRF: IAccesoEmpleadoConRFData[] =[];
  public datosRFFiltrados: IAccesoEmpleadoConRFData[] = [];
  public cargando: boolean = true;
  public seleccionEmpleados: string = '6';
  public esDebugger: boolean = false;
  private mostrarComparativa: boolean = false;
  private comparativa: string = '';
  public myControl = new FormControl('');
  public filteredOptions!: Observable<IEmpleados[]>;

  public dataSource: MatTableDataSource<IAccesoEmpleadoConRFData> = new MatTableDataSource<IAccesoEmpleadoConRFData>(this.datosRFFiltrados);
  public datosRFObs!: Observable<any>;

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @Input()
  public empleados: IEmpleados[] = [];

  public inicioPeriodo: Date = new Date();
  public finPeriodo: Date = new Date();
  public empleadoSeleccionado: IEmpleados | undefined = undefined;

  // public idEmpleado: string | undefined = undefined;

  public vista2Cards: boolean = false;
  public vista3Cards: boolean = false;
  public vista4Cards: boolean = false;
  public vista6Cards: boolean = true;
  public vista12Cards: boolean = false;

  public opcionesVistaEmpleado = {
    '2': '2 Tarjetas',
    '3': '3 Tarjetas',
    '4': '4 Tarjetas',
    '6': '6 Tarjetas',
    '12': '12 Tarjetas',
  }

  @Output()
  public cerrarRegistroIncidencias = new EventEmitter<void>();

  public error: string = '';
  public mensaje: string = '';

  public periodo: string = 'semana0';
  public tipoPeriodoReporte = $tipoPeriodoReporte;
  public tipoPeriodoReporteOrden = $tipoPeriodoReporteOrden;
  public rangoPersonalizado = new FormGroup({
    desde: new FormControl<Date | null>(null),
    hasta: new FormControl<Date | null>(null),
  });

  public selectedAcomodo: string = 'fecha';
  public opcionesFiltrosAcomodo = {
    'nombre': 'Ordenar por nombre',
    'clave': 'Ordenar por clave',
    'fecha': 'Ordenar por fecha',
  }

  constructor (
    private accesoDatosServicio: AccesoDatosService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {
    let datos = this.accesoDatosServicio.obtenerDatosRegistro();
    this.inicioPeriodo = datos.inicio;
    this.finPeriodo = datos.fin;
    this.empleadoSeleccionado = datos.empleadoSeleccionado;
    this.cargarRegistroIncidencias();
  }

  private cargarRegistroIncidencias() {
    console.log(`cargando datos: incidencias. ${fFecha(this.inicioPeriodo, 'fc')} al ${fFecha(this.finPeriodo, 'fc')}`);

    this.error = '';
    this.mensaje = '';
    this.accesoDatosServicio.empleadoObtenerRegistroIncidenciasListado(this.inicioPeriodo, this.finPeriodo).subscribe(
      (datosRF: IAccesoEmpleadoConRFData[]) => {
        this.datosRF = datosRF;
        this.dataSource.data = datosRF;
        this.ordenarDatos();
        this.aplicarFiltros();
        this.cargando = false;
        console.log(`Datos cargados: incidencias datosRF: ${this.datosRF.length}`);
      },
      (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosServicio.logAgrega2("Error al cargar el registro de incidencias. " + err);
        Swal.fire({
          html: `Error al cargar el registro de incidencias`,
          icon: 'error',
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener('mouseenter', Swal.stopTimer);
            toast.addEventListener('mouseleave', Swal.resumeTimer);
          },
        });
      }
    );
  }

  public formatoFecha(fechaTxt: string | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fsl");
  }

  public formatoFechaHora(fechaTxt: string | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fmh");
  }

  public verDetalles(datos: IAccesoEmpleadoConRFData) {
    this.comparativa = '';
    this.accesoDatosServicio.obtenerNombrePuntoAccesoDeviceId(datos.datosRF.deviceId).subscribe(
      (respuesta: any) => {
        let nombrePuntoAcceso: string = datos.datosRF.deviceId;
        if (respuesta.code == 100) nombrePuntoAcceso = respuesta.mensaje;
        this.verDetallesModal(datos, nombrePuntoAcceso);
      }, (error) => {
        this.verDetallesModal(datos, datos.datosRF.deviceId);
      }
    );
  }

  private verDetallesModal(datos: IAccesoEmpleadoConRFData, nombrePuntoAcceso: string) {
    Swal.close();
    if (!this.esDebugger && this.mostrarComparativa) this.mostrarComparativa = false;
    let html: string = `
        <p style="margin: 5px 0; font-weight: bold;">${this.nombreCompleto(datos.empleado)}</p>
        <div class="d-flex justify-content-left">
          <p class="mb-0">
            <b class="fw-semibold">Tomada el: </b>${fFecha(datos.datosRF.fecha, 'fmh')}
          </p>
        </div>
        <div class="d-flex justify-content-left">
          <p class="mb-0">
            <b class="fw-semibold">Similitud: </b>${fNumero(datos.datosRF.similarity, '%')}
          </p>
        </div>
        <div class="d-flex justify-content-left">
          <p class="mb-0">
            <b class="fw-semibold">Punto de acceso: </b>${nombrePuntoAcceso}
          </p>
        </div>
      `;
    if (this.esDebugger) {
      let box: string = '';
      let resultado: string = '';
      if (!!datos.datosRF && !!datos.datosRF.box) {
        box = datos.datosRF.box.replace(/,/g, ", ");
      }
      if (!!datos.datosRF && !!datos.datosRF.resultado) {
        resultado = datos.datosRF.resultado.replace(/,/g, ", ");
      }
      html += `
        <div class="text-start">
          <p class="mb-0">
            <b class="fw-semibold">Box: </b>${box}
          </p>
        </div>
        <div class="text-start">
          <p class="mb-0">
            <b class="fw-semibold">Resultado: </b>${resultado}
          </p>
        </div>
      `;
      if (this.mostrarComparativa) {
        html += `
        <div class="text-start">
          <p class="mb-0">
            <b class="fw-semibold">Comparativa: </b>${this.comparativa}
          </p>
        </div>
        `;
      }
    }
    Swal.fire({
      html: html,
      imageUrl: `data:image/jpg;base64,${datos.datosRF.datos}`,
      imageWidth: 350,
      imageHeight: 'auto',
      showCancelButton: true,
      cancelButtonText: 'Cerrar',
      cancelButtonColor: 'red',
      confirmButtonText: 'Detalles',
      showConfirmButton: this.esDebugger && !this.mostrarComparativa,
    }).then((result) => {
      if (result.isConfirmed) {
        this.accesoDatosServicio.empleadoObtenerRegistroIncidenciaDetalle(datos.datosRF.id).subscribe(
          (respuesta: IRespuestaChecker) => {
            this.comparativa = '';
            if (respuesta.code == 100) {
              this.comparativa = respuesta.mensaje;
            }
          }, (error) => {
            this.comparativa = '';
          }
        );
        this.verDetallesModal(datos, nombrePuntoAcceso);
      }
    });
  }

  public limpiarFiltro() {
    this.empleadoSeleccionado = undefined;
    this.myControl.reset();
    this.datosRFFiltrados = this.datosRF;
    this.dataSource.data = this.datosRFFiltrados;
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.accesoDatosServicio.logAgrega(txt);
  }

  public nombreCompleto(empleado: IEmpleados){
    return nombreEmpleado(empleado);
  }

  public ordenarDatos() {
    // this.datosRF.datos.sort((a, b) => new Date(a.fechaTxt).getTime() - new Date(b.fechaTxt).getTime());
  }

  public aplicarFiltros(event?: MatAutocompleteSelectedEvent) {
    this.empleadoSeleccionado = event?.option.value;

    if (this.selectedAcomodo === 'nombre') {
      this.datosRFFiltrados = this.datosRF.sort((a, b) => a.empleado.nombre.toLowerCase().localeCompare(b.empleado.nombre.toLowerCase()));
    } else if (this.selectedAcomodo === 'clave') {
      this.datosRFFiltrados = this.datosRF.sort((a, b) => +a.empleado.numero - +b.empleado.numero);
    } else if (this.selectedAcomodo === 'fecha') {
      this.datosRFFiltrados = this.datosRF.sort((a, b) => b.datosRF.fecha.getTime() - a.datosRF.fecha.getTime())
    }

    if (this.empleadoSeleccionado) {
      this.datosRFFiltrados = this.datosRF.filter(dato => dato.empleado.id === this.empleadoSeleccionado?.id);
    }

    this.dataSource.data = this.datosRFFiltrados;
  }

  // public valueDescOrder = (a: KeyValue<string, IAccesoEmpleadoRFData>, b: KeyValue<string, IAccesoEmpleadoRFData>): number => {
  //   const fechaA = new Date(a.value.fechaTxt);
  //   const fechaB = new Date(b.value.fechaTxt);
  //   return fechaB.getTime() - fechaA.getTime();
  // };

  public toggleMostrarRegistroIncidencias() {
    this.cerrarRegistroIncidencias.emit();
  }

  public onChangeVistaEmpleado() {
    this.vista2Cards = false;
    this.vista3Cards = false;
    this.vista4Cards = false;
    this.vista6Cards = false;
    this.vista12Cards = false;

    switch (this.seleccionEmpleados) {
      case '2':
        this.vista2Cards = true;
        break;
      case '3':
        this.vista3Cards = true;
        break;
      case '4':
        this.vista4Cards = true;
        break;
      case '6':
        this.vista6Cards = true;
      break;
      case '12':
        this.vista12Cards = true;
      break;
      default:
        console.log('Opción no válida');
    }
  }

  private _filter(value: string): IEmpleados[] {
    const filterValue = this._normalizeValue(value);
    return this.empleados.filter(empleado => this._normalizeValue(this.nombreCompleto(empleado)).includes(filterValue));
  }

  private _normalizeValue(value: string): string {
    return value.toLowerCase().replace(/\s/g, '');
  }

  public cambiarPeriodo() {
    // if (this.periodo != 'personalizado') console.log(`cambiarPeriodo. periodo: ${this.periodo}`);

    // let inicio: Date = this.inicioPeriodo;
    // let fin: Date = soloHoy();

    // switch (this.periodo) {
    //   case 'hoy':
    //     inicio = soloHoy();
    //     break;
    //   case 'semana0':
    //     inicio = inicioSemanaActual();
    //     break;
    //   case 'semana1a':
    //     inicio = addDays(inicioSemanaActual(), -7);
    //     fin = addDays(inicio, 6);
    //     break;
    //   case 'quincena0':
    //     inicio = inicioQuincenaActual();
    //     break;
    //   case 'quincena1a':
    //     fin = addDays(inicioQuincenaActual(), -1);
    //     inicio = inicioQuincena(fin);
    //     break;
    //   case 'mes0':
    //     inicio = inicioMesActual();
    //     break;
    //   case 'mes1a':
    //     fin = addDays(inicioMesActual(), -1);
    //     inicio = inicioMes(fin);
    //     break;
    //   case 'personalizado':
    //     inicio = this.rangoPersonalizado.value.desde ? this.rangoPersonalizado.value.desde : this.inicioPeriodo;
    //     fin = this.rangoPersonalizado.value.hasta ? this.rangoPersonalizado.value.hasta : this.finPeriodo;
    //     console.log(`cambiarPeriodo. periodo: ${this.periodo}, ${inicio}, ${fin}`);
    //     break;
    //   default:

    //     return;
    // }

    // if (inicio.getTime() > fin.getTime()) {
    //   fin = inicio;
    // }

    // if (this.inicioPeriodo.getTime() != inicio.getTime() || this.finPeriodo.getTime() != fin.getTime()) {
    //   console.log(`===========>>> Nuevo periodo: ${fFecha(inicio, 'fm')}, ${fFecha(fin, 'fm')} <<<<===========`);
    //   this.inicioPeriodo = inicio;
    //   this.finPeriodo = fin;
    //   this.accesoDatosService.guardarDatosRegistro(this.inicioPeriodo, this.finPeriodo, this.empleadoSeleccionado);
    //   if (this.inicioPeriodo.getTime() < this.fechaBajaMax.getTime()) {
    //     this.cargarDatosInicial();
    //   } else {
    //     this.cargarDatos();
    //   }
    //   return;
    // }
  }

  public valuePeriodoAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return this.tipoPeriodoReporteOrden[a.key] - this.tipoPeriodoReporteOrden[b.key];
  };

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.datosRFObs = this.dataSource.connect();
    this.esDebugger = !environment.production;
    this.filteredOptions = this.myControl.valueChanges.pipe(
      startWith(''),
      map(value => value ? (typeof value === 'string' ? value : this.nombreCompleto(value)) : ''),
      map(name => name ? this._filter(name) : this.empleados.slice())
    );
  }

}
