<div class="d-flex justify-content-between align-items-center px-3 border-bottom border-light shadow-sm mb-3 modal-header-fixed">
  <h2 class="mb-0 ms-2 fw-bold">{{ titleIncidencia }}</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon fontIcon="close"></mat-icon>
  </button>
</div>

<div *ngIf="estaCargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<!-- ================================== agregar incidencia ================================== -->
<ng-container *ngIf="!estaCargando && agregarNuevaIncidencia && !agregarIncidenciaMasiva">
  <div class="row g-3 mx-auto pb-3 card-body mt-1">

    <div class="col-12">
      <label class="mb-0 text-start">Selecciona a un empleado</label>
      <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" (ngModelChange)="aplicarFiltrosIncidencias()" (clear)="limpiarFiltro()" [compareWith]="compareFn" notFoundText="Empleado no encontrado" placeholder="Selecciona a un empleado">
        <ng-template ng-option-tmp let-item="item">
          {{ nombreCompleto(item) }}
        </ng-template>
      </ng-select>
    </div>

    <div class="col-4">
      <div class="example-form">
        <label class="form-label mb-0">Inicio del periodo</label>
        <input type="date" [(ngModel)]="inicioPersonalizado" (change)="calcularDias()" class="form-control form-select-sm">
      </div>
    </div>

    <div class="col-4">
      <div class="example-form">
        <label class="form-label mb-0">Terminación del periodo</label>
        <input type="date" [(ngModel)]="finPersonalizado" (change)="calcularDias()" class="form-control form-select-sm">
      </div>
    </div>

    <div class="col-4">
      <div class="example-form">
        <label class="form-label mb-0">Días</label>
        <input type="number" [(ngModel)]="diasPeriodo" class="form-control form-select-sm bg-light" readonly>
      </div>
    </div>

    <div [ngClass]="{'col-12': nuevaIncidencia.tipoManejoIncidencia != 3, 'col-4': nuevaIncidencia.tipoManejoIncidencia == 3}">
      <label class="form-label mb-0">Tipo de movimiento</label>
      <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="nuevaIncidencia.tipoManejoIncidencia">
        <option *ngFor="let movimiento of tipoManejoIncidencia | keyvalue: valueTipoIncidenciaAscOrder" [value]="movimiento.key">
          {{ movimiento.value }}
        </option>
      </select>
    </div>

    <div class="col-4" *ngIf="nuevaIncidencia.tipoManejoIncidencia == 3">
      <label class="form-label mb-0">Tipo de incapacidad</label>
      <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="nuevaIncidencia.tipoIncapacidad">
        <option *ngFor="let incapacidad of tipoIncapacidad | keyvalue" [value]="incapacidad.key">
          {{ incapacidad.value }}
        </option>
      </select>
    </div>

    <div class="col-4" *ngIf="nuevaIncidencia.tipoManejoIncidencia == 3">
      <label class="form-label mb-0">Folio incapacidad</label>
      <input type="text" placeholder="Folio incapacidad" [(ngModel)]="nuevaIncidencia.observaciones" class="form-control form-select-sm">
    </div>

    <div class="col-12" *ngIf="nuevaIncidencia.tipoManejoIncidencia != 3">
      <label class="form-label mb-0">Observaciones</label>
      <textarea placeholder="Observaciones de la incidencia" [(ngModel)]="nuevaIncidencia.observaciones" class="form-control" style="min-height: 2lh;"></textarea>
    </div>
  </div>

  <div class="d-flex mx-1 pb-2 justify-content-end">
    <button mat-raised-button class="mx-1 bg-success text-white" (click)="guardarIncidencia()">Validar incidencia</button>
    <button class="bg-danger text-white mx-1" mat-raised-button (click)="toggleAgregarNuevaIncidencia()">Cancelar</button>
  </div>
</ng-container>

<!-- ================================== incidencias ================================== -->
<ng-container *ngIf="!estaCargando && !agregarNuevaIncidencia && !agregarIncidenciaMasiva">
  <!-- Títulos de incidencia, filtros -->
  <div class="row g-3 mb-3 align-items-end mx-2">
    <div class="col-12 d-flex">
      <div [ngClass]="{'col-6': periodo !== 'personalizado', 'col-3': periodo === 'personalizado'}">
        <label for="fecha" class="form-label mb-0">Periodo</label>
        <select class="form-select" aria-label="Default select example" [(ngModel)]="periodo" (change)="cambiarPeriodo()">
          <option *ngFor="let tipoPeriodo of tipoPeriodoReporte | keyvalue: valuePeriodoAscOrder" [value]="tipoPeriodo.key">
            {{ tipoPeriodo.value }}
          </option>
        </select>
      </div>
      <!-- ================================== periodo personalizado ================================== -->
      <div class="col-3 ps-3" *ngIf="periodo == 'personalizado'">
        <div class="example-form">
          <label class="form-label mb-0">Periodo personalizado</label>
          <div class="d-flex align-items-center form-control" style="height: 31px;" (click)="picker.open()">
            <mat-date-range-input [formGroup]="rangoPersonalizado" [rangePicker]="picker">
              <input matStartDate formControlName="desde" placeholder="Desde" readonly>
              <input matEndDate formControlName="hasta" placeholder="Hasta" readonly>
            </mat-date-range-input>
            <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </div>
        </div>
      </div>

      <!-- ================================== filtro empleado ================================== -->
      <div class="col-6 mx-2">
        <label class="mb-0 text-start">Filtrar por empleado</label>
        <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" (ngModelChange)="aplicarFiltrosIncidencias()" (clear)="limpiarFiltro()" [compareWith]="compareFn" notFoundText="Empleado no encontrado"  placeholder="Selecciona un empleado">
          <ng-template ng-option-tmp let-item="item">
            {{ nombreCompleto(item) }}
          </ng-template>
        </ng-select>
      </div>
    </div>

    <div class="col-12 d-flex justify-content-end">
      <div>
        <button mat-raised-button matTooltip="Ver en Listado" class="mx-1 text-info" (click)="esListado = !esListado">
          <mat-icon class="m-0">list</mat-icon>
        </button>
        <button class="text-success mx-1" mat-raised-button (click)="toggleAgregarNuevaIncidencia()" *ngIf="!agregarNuevaIncidencia">
          Agregar incidencia
        </button>
        <button class="text-primary-emphasis mx-1" mat-raised-button (click)="toggleIncidenciaMasiva()" *ngIf="!agregarNuevaIncidencia">
          Agregar incidencia masiva
        </button>
        <button class="text-danger-emphasis mx-2" mat-raised-button matTooltip="Imprimir" (click)="exportarPDF()">
          <mat-icon class="m-0">picture_as_pdf</mat-icon>
        </button>
        <!-- <button class="text-success" mat-raised-button matTooltip="Exportar a Excel" (click)="exportarExcel()">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 48 48">
            <g fill="none" stroke="#026917" stroke-linecap="round" stroke-width="4"><path stroke-linejoin="round" d="M8 15V6a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-9"/><path d="M31 15h3m-6 8h6m-6 8h6"/><path stroke-linejoin="round" d="M4 15h18v18H4zm6 6l6 6m0-6l-6 6"/></g>
          </svg>
        </button> -->
      </div>
    </div>
  </div>

  <!-- Cards Incidencias -->
  <ng-container *ngIf="!esListado">
    <div class="row row-cols-1 row-cols-md-4 g-3 mx-auto pb-3">
      <div *ngFor="let incidencia of incidenciasObs | async">
        <mat-card class="bg-body-tertiary">
          <mat-card-content class="text-start p-2">
            <div class="row">
              <div class="mb-2">
                <div class="col-11 text-truncate h6 mb-0" [matTooltip]="incidencia.nombreEmpleado">{{ incidencia.nombreEmpleado }}</div>
                <small>Fecha de registro: {{ formatoFechaCorto(incidencia.fechaRegistro) }}</small>
                <hr class="border border-secondary m-0">
              </div>
              <div class="col-6">
                <label class="form-label mb-0">Inicio</label>
                <input type="text" class="form-control" [value]="formatoFechaCorto(incidencia.inicio)" onClick="this.select();" readonly>
              </div>
              <div class="col-6">
                <label class="form-label mb-0">Terminación</label>
                <input type="text" class="form-control" [value]="formatoFechaCorto(incidencia.fin)" onClick="this.select();" readonly>
              </div>
              <div class="col-12">
                <label class="form-label mb-0">Tipo de movimiento</label>
                <input type="text" class="form-control" [value]="tipoManejoIncidencia[incidencia.tipoManejoIncidencia]" onClick="this.select();" readonly>
              </div>
              <div class="col-12">
                <label class="form-label mb-0">Tipo de incapacidad</label>
                <input type="text" class="form-control" [value]="incidencia.tipoIncapacidad == 0 ? '-' : tipoIncapacidad[incidencia.tipoIncapacidad]" onClick="this.select();" readonly>
              </div>
              <div class="col-12">
                <label class="form-label mb-0">Observaciones</label>
                <textarea placeholder="Observaciones de la incidencia" [value]="incidencia.observaciones" class="form-control" style="min-height: 2lh;" readonly></textarea>
              </div>
            </div>
          </mat-card-content>
          <mat-card-actions align="end">
            <button mat-raised-button color="warn" (click)="eliminarIncidencia(incidencia)">Eliminar incidencia</button>
          </mat-card-actions>
        </mat-card>
      </div>
    </div>
  </ng-container>

  <!-- Listado Incidencias -->
  <ng-container *ngIf="esListado">
    <div class="mat-elevation-z8 mt-3 col-11 ms-5 mb-3">
      <table mat-table [dataSource]="dataSource">

        <ng-container matColumnDef="nombre">
          <th mat-header-cell *matHeaderCellDef> Nombre </th>
          <td mat-cell *matCellDef="let incidencia"> {{incidencia.nombreEmpleado}} </td>
        </ng-container>

        <ng-container matColumnDef="inicio">
          <th mat-header-cell *matHeaderCellDef> Inicio </th>
          <td mat-cell *matCellDef="let incidencia"> {{formatoFechaCorto(incidencia.inicio)}} </td>
        </ng-container>

        <ng-container matColumnDef="fin">
          <th mat-header-cell *matHeaderCellDef> Terminación </th>
          <td mat-cell *matCellDef="let incidencia"> {{formatoFechaCorto(incidencia.fin)}} </td>
        </ng-container>

        <ng-container matColumnDef="tipoMovimiento">
          <th mat-header-cell *matHeaderCellDef> Tipo de movimiento</th>
          <td mat-cell *matCellDef="let incidencia"> {{tipoManejoIncidencia[incidencia.tipoManejoIncidencia]}} </td>
        </ng-container>

        <ng-container matColumnDef="tipoIncapacidad">
          <th mat-header-cell *matHeaderCellDef> Tipo de incapacidad</th>
          <td mat-cell *matCellDef="let incidencia"> {{incidencia.tipoIncapacidad == 0 ? '-' : tipoIncapacidad[incidencia.tipoIncapacidad]}} </td>
        </ng-container>

        <ng-container matColumnDef="observaciones">
          <th mat-header-cell *matHeaderCellDef> Observaciones </th>
          <td mat-cell *matCellDef="let incidencia"> {{incidencia.observaciones}} </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

        <tr class="mat-row" *matNoDataRow>
          <td class="mat-cell text-center h5 text-danger p-3" colspan="7">Aún no existen incidencias para ningún empleado en el periodo seleccionado.</td>
        </tr>
      </table>
    </div>
  </ng-container>
</ng-container>

<div [hidden]="estaCargando || agregarNuevaIncidencia || agregarIncidenciaMasiva || dataSource.data.length <= 0">
  <div class="d-flex justify-content-between mx-3">
    <div class="form-check align-self-end">
      <input class="form-check-input" type="checkbox" id="mostrarBajasIncidenciasCheck" [(ngModel)]="mostrarBajas" (change)="aplicarFiltrosEmpleados()">
      <label class="form-check-label fw-bold ms-1" for="mostrarBajasIncidenciasCheck">Mostrar empleados dados de baja</label>
    </div>

    <mat-paginator
      [showFirstLastButtons]="true"
      [length]="dataSource.data.length"
      [pageSizeOptions]="[20, 50, 80, 100, dataSource.data.length]">
    </mat-paginator>
  </div>
</div>

<ng-container *ngIf="!estaCargando && !agregarNuevaIncidencia && agregarIncidenciaMasiva">
  <app-empleados-incidencias-masivo (cerrarIncidenciasMasivas)="handleCerrarIncidencias()" [dialogRef]="dialogRef"></app-empleados-incidencias-masivo>
</ng-container>
