<div *ngIf="cargando">
  <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>{{guardando ? 'Guardando' : 'Cargando'}} datos...</span>
  </div>
</div>

<div *ngIf="!cargando" [ngClass]="{'m-0': diaIncidencia, 'm-3': !diaIncidencia}">
  <div *ngIf="!horario.idHorario || horario.idHorario == '-'">
    <div class="text-center" *ngIf="!soloConsulta && !diaIncidencia">
      <h1 class="fw-medium">Agregar Nuevo Horario</h1>
    </div>
  </div>

  <div class="row mb-3 text-start" *ngIf="editandoHorario && !diaIncidencia">
    <div class="col-6">
      <label class="form-label mb-0">Nombre del horario</label>
      <input type="text" class="form-control" [(ngModel)]="horario.nombreHorario" onClick="this.select();" placeholder="Escribe el nombre del horario">
    </div>

    <div class="col-6" *ngIf="horario.detalles.length == 1">
      <label class="mb-0 text-start">Días de descanso</label>
      <ng-select [(ngModel)]="horario.detalles[0].diasDescanso" [items]="semanaDias" [multiple]="true" [closeOnSelect]="false" [virtualScroll]="true">
        <ng-template ng-option-tmp let-item="item">
          <div class="d-flex justify-content-start align-items-center">
            <input class="me-2" type="checkbox" [checked]="horario.detalles[0].diasDescanso.includes(item)" /> {{ item }}
          </div>
        </ng-template>
      </ng-select>
    </div>
  </div>

  <div *ngFor="let detalle of horario.detalles; index as indexSemana">
    <div class="shadow rounded" [ngClass]="{'mb-5': !diaIncidencia, 'mb-3': diaIncidencia}">
      <mat-card-header class="d-block text-start pt-1">
        <mat-card-title>
          <div class="d-flex justify-content-between" *ngIf="horario.detalles.length > 1">
            <div class="fw-semibold d-flex justify-content-between">
              <p>Semana {{ indexSemana + 1 }}</p>
            </div>
            <div *ngIf="editandoHorario" class="col-6">
              <mat-form-field appearance="outline" class="col-12">
                <mat-label>Días de descanso</mat-label>
                <mat-select [(ngModel)]="horario.detalles[indexSemana].diasDescanso" multiple>
                  <mat-option *ngFor="let dia of semanaDias" [value]="dia">{{ dia }}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div class="d-flex justify-content-between mx-4 my-2">
            <div class="fw-semibold d-flex">
              <mat-icon inline color="warn" fontIcon="calendar_month" style="font-size: 30px; width: 30px; height: 30px;"></mat-icon>
              <p class="mb-0 ms-2">{{ horario.nombreHorario }}</p>
            </div>
            <div *ngIf="!soloConsulta && (horario.inicioTxt != '-' || horario.finTxt != '-') && !editandoHorario" class="d-block">
              <small class="fs-6 fw-normal opacity-75 mx-4" *ngIf="horario.inicioTxt != '-'">Activo desde: {{ formatoFecha(horario.inicio) }}</small>
              <small class="fs-6 fw-normal opacity-75" *ngIf="horario.finTxt != '-'">Fecha terminación: {{ formatoFecha(horario.fin) }}</small>
            </div>
          </div>
        </mat-card-title>
      </mat-card-header>

      <mat-card-content [ngClass]="{'pb-0': diaIncidencia}">
        <div class="d-flex justify-content-end align-items-center mb-3" *ngIf="editandoHorario && !diaIncidencia">
          <div class="form-check mx-2">
            <input class="form-check-input" type="checkbox" id="aplicarTodosChk" [(ngModel)]="aplicarTodos">
            <label class="form-check-label fw-bold" for="aplicarTodosChk">Aplicar a todos</label>
          </div>
          <div class="form-check mx-2">
            <label class="form-check-label fw-bold" (click)="esHorarioNocturno()">Horario Nocturno: {{horarioNocturno ? 'Activado' : 'Desactivado'}}</label>
          </div>
        </div>

        <div class="row align-items-center my-2 border-bottom border-light-subtle" *ngFor="let dia of semanaDias">
          <div class="col-1 text-center">
            <h3 class="mt-2 fw-medium">{{ dia }}</h3>
          </div>
          <div [ngClass]="{'col-8': diaIncidencia, 'col-9': !diaIncidencia}">
            <div *ngIf="isDiaDescanso(detalle, dia)">
              <!-- <div class="d-flex justify-content-between">
                <div id="descanso1" class="fw-medium ms-2 fs-7">
                  <span class="opacity-50">  Descanso  </span>
                </div>
                <div id="horaSalida" class="d-flex align-content-center fw-medium me-5 fs-7">
                  <span class="opacity-50">  Descanso  </span>
                </div>
              </div> -->
              <mat-slider min="0" [max]="24" [step]="0.25" style="width: 50%" showTickMarks [color]="'success'" [disabled]="true">
                <input matSliderThumb [(ngModel)]="valorCero" (ngModelChange)="onInputChangeValorCero($event)">
              </mat-slider>
            </div>

            <div class="d-flex justify-content-between w-100">
              <div *ngFor="let horarioDia of detalle.horariosSemana[dia]" class="d-flex justify-content-between align-items-center w-100 px-3">
                <div id="horaEntrada" class="fw-medium fs-7" [ngClass]="{ 'primary': horarioDia.nocturno, 'warn': !horarioDia.nocturno }">
                  {{ formatearHora(horarioDia.horaEntrada) }}
                </div>
                <div id="horaSalida" class="fw-medium fs-7" [ngClass]="{ 'primary': horarioDia.nocturno, 'warn': !horarioDia.nocturno }">
                  {{ formatearHora(horarioDia.horaSalida) }}
                  <!-- <div *ngIf="!!horarioDia.horaSalida && horarioDia.nocturno">
                    <mat-icon class="text-danger mt-1 me-2">start</mat-icon>
                  </div>
                  <div *ngIf="detalle.horariosSemana[dia].length > 1 && !horarioDia.nocturno">
                    <mat-icon class="text-info mx-3">call_split</mat-icon>
                  </div> -->
                </div>
              </div>
            </div>

            <mat-slider *ngFor="let horarioDia of detalle.horariosSemana[dia]; let indexRango = index"
                        [min]="horarioDia.nocturno ? 0 : indexRango === 0 ? 0 : detalle.horariosSemana[dia][indexRango - 1].horaSalida"
                        [max]="horarioDia.nocturno ? 24 :indexRango < detalle.horariosSemana[dia].length - 1 ? detalle.horariosSemana[dia][indexRango + 1].horaEntrada : 24"
                        [step]="0.25"
                        [ngStyle]="{'width': (92 / detalle.horariosSemana[dia].length) + '%'}"
                        showTickMarks
                        [color]="horarioDia.nocturno ? 'warn' : detalle.horariosSemana[dia].length > 1 ? 'info' : 'primary'"
                        [disabled]="!editandoHorario"
                        (input)="actualizarSliders(dia, indexSemana, indexRango, aplicarTodos)">
              <input matSliderStartThumb [(ngModel)]="horarioDia.horaEntrada" (ngModelChange)="onInputChangeHoraEntrada($event)">
              <input matSliderEndThumb [(ngModel)]="horarioDia.horaSalida">
            </mat-slider>
          </div>
          <div class="d-flex justify-content-center" *ngIf="editandoHorario" [ngClass]="{'col-3': diaIncidencia, 'col-2': !diaIncidencia}">
            <div class="mt-auto me-auto" *ngIf="isDiaDescanso(detalle, dia) && !editandoHorario">
              <mat-icon class="opacity-50">bed</mat-icon>
            </div>
            <div class="mt-auto me-auto" *ngIf="!!detalle.horariosSemana[dia] && detalle.horariosSemana[dia].length > 0 && !editandoHorario">
              <mat-icon
                [ngClass]="{'text-danger' : detalle.horariosSemana[dia][detalle.horariosSemana[dia].length - 1].nocturno, 'text-primary': !detalle.horariosSemana[dia][detalle.horariosSemana[dia].length - 1].nocturno }">
                {{detalle.horariosSemana[dia][detalle.horariosSemana[dia].length - 1].nocturno ? 'dark_mode' : 'sunny' }}
              </mat-icon>
            </div>
            <button mat-raised-button matTooltip="Agregar hora" (click)="agregarRangoHora(detalle, dia, indexSemana)" [disabled]="!editandoHorario || isDiaDescanso(detalle, dia) || detalle.horariosSemana[dia].length >= 4"
              *ngIf="!soloConsulta" >
              <mat-icon class="me-0">add</mat-icon>
            </button>
            <button class="mx-1" mat-raised-button matTooltip="Remover hora" (click)="removerRangoHora(detalle, dia, indexSemana)" [disabled]="!editandoHorario || isDiaDescanso(detalle, dia) || detalle.horariosSemana[dia].length == 1"
              *ngIf="!soloConsulta">
              <mat-icon class="me-0">remove</mat-icon>
            </button>
            <button *ngIf="!!detalle.horariosSemana[dia] && detalle.horariosSemana[dia].length > 0 && editandoHorario" mat-raised-button
              (click)="cambiarHorarioNocturno(detalle, dia, indexSemana)" [disabled]="!editandoHorario || isDiaDescanso(detalle, dia)">
              <mat-icon class="m-0" [ngClass]="{'text-danger' : detalle.horariosSemana[dia][detalle.horariosSemana[dia].length - 1].nocturno, 'text-primary': !detalle.horariosSemana[dia][detalle.horariosSemana[dia].length - 1].nocturno }">
                {{detalle.horariosSemana[dia][detalle.horariosSemana[dia].length - 1].nocturno ? 'dark_mode' : 'sunny' }}
              </mat-icon>
            </button>
          </div>
        </div>
      </mat-card-content>

      <div class="d-flex justify-content-end align-items-center p-2" *ngIf="!diaIncidencia">
        <div class="text-end" class="mx-1" *ngIf="editandoHorario">
          <button class="bg-success text-white" (click)="guardarHorario()" mat-raised-button>Guardar</button>
        </div>
        <div class="text-end" class="mx-1" *ngIf="editandoHorario">
          <button class="text-white bg-danger" (click)="deshacerCambios()" mat-raised-button>Deshacer</button>
        </div>
        <div class="text-end" class="mx-1" *ngIf="editandoHorario && horario.finTxt == '-'">
          <button class="bg-danger text-white" (click)="eliminarHorario()" mat-raised-button>Eliminar</button>
        </div>
        <div class="text-end" class="mx-1" *ngIf="!editandoHorario && !soloConsulta">
          <button class="bg-primary text-white" (click)="editarHorario()" mat-raised-button>Modificar</button>
        </div>
        <div class="text-end" class="mx-1" *ngIf="editandoHorario && indexSemana == 0">
          <button class="bg-info text-white" (click)="agregarSemana()" [disabled]="horario.detalles.length > 1" mat-raised-button>Añadir semana</button>
        </div>
        <div class="text-end" class="mx-1" *ngIf="editandoHorario && indexSemana == 1">
          <button class="bg-danger text-white" (click)="eliminarSemana()" [disabled]="horario.detalles.length == 1" mat-raised-button>Eliminar semana</button>
        </div>
      </div>
    </div>
  </div>
</div>
