<div *ngIf="cargando">
  <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div class="m-3" *ngIf="!cargando">
  <div class="px-3 text-start pb-3 shadow mb-5 bg-body-tertiary rounded tracking-in-expand">

    <div class="py-3 d-flex justify-content-end" *ngIf="enEdicion">
      <button mat-raised-button class="me-2 text-danger bg-white" (click)="deshacerCambios()"><mat-icon>undo</mat-icon>Deshacer</button>
      <button mat-raised-button class="text-success bg-white" (click)="aplicarCambios()"><mat-icon>save</mat-icon>Aplicar cambios</button>
    </div>

    <div class="py-3 d-flex justify-content-end" *ngIf="!enEdicion">
      <button mat-raised-button class="text-primary bg-white" (click)="modificarDatos()">Modificar</button>
    </div>

    <div>
      <hr>
      <div class="d-flex align-items-center">
        <h2 class="opacity-75 mb-2 col-8">Tolerancia en retardos</h2>
        <div class="col-3 ms-4 mb-3" *ngIf="esDebugger">
          <label class="mb-0 text-start">Aplicar para</label>
          <ng-select class="small-select" [items]="grupoEmpleados" [(ngModel)]="gruposSeleccionados" [multiple]="true" [closeOnSelect]="false" [virtualScroll]="true" notFoundText="Grupo no encontrado" [disabled]="!enEdicion">
            <ng-template ng-option-tmp let-item="item">
              <div class="d-flex justify-content-start align-items-center">
                <input class="me-2" type="checkbox" [checked]="gruposSeleccionados.includes(item)" (change)="cambiarSelect(item, gruposSeleccionados, $event)"/>
                {{ item }}
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>Tiempo de tolerancia para llegar tarde sin que se marque como retardo</p>
        </div>
        <div class="col-3 ms-4">
          <select [disabled]="!enEdicion" class="form-select form-select-sm" aria-label="Small select example"  [(ngModel)]="toleranciaRetardos">
            <option [value]="+0">No hay tolerancia</option>
            <option [value]="+0.0833333">5 minutos</option>
            <option [value]="+0.1333333">8 minutos</option>
            <option [value]="+0.1666666">10 minutos</option>
            <option [value]="+0.2500000">15 minutos</option>
            <option [value]="+0.5000000">30 minutos</option>
            <option [value]="+1">1 hora</option>
            <option [value]="+2">2 horas</option>
            <option [value]="+24">Nunca marcar retardos</option>
          </select>
        </div>
      </div>
      <hr>
    </div>

    <div>
      <h2 class="opacity-75 mb-2">Marcar salidas tempranas como retardo</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>Si está activado, el retardo será todo el tiempo que el empleado no cubrió:
            <b class="text-navy">horas programadas menos horas trabajadas</b>
          </p>
        </div>
        <div class="col-3 ms-4 text-center">
          <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="salidasComoRetardos"></mat-slide-toggle>
        </div>
      </div>
      <hr>
    </div>

    <div>
      <h2 class="opacity-75 mb-2">Tolerancia en pago de horas extras</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>Indica a partir de cuanto tiempo trabajado extra, iniciará el pago de horas extras</p>
        </div>
        <div class="col-3 ms-4">
          <select [disabled]="!enEdicion" class="form-select form-select-sm" aria-label="Small select example" [(ngModel)]="toleranciaHorasExtra">
            <option [value]="+0">Desde el primer segundo</option>
            <option [value]="+0.0833333">5 minutos</option>
            <option [value]="+0.1666666">10 minutos</option>
            <option [value]="+0.2500000">15 minutos</option>
            <option [value]="+0.5000000">30 minutos</option>
            <option [value]="+0.7500000">45 minutos</option>
            <option [value]="+1">1 hora</option>
            <option [value]="+2">2 horas</option>
            <option [value]="+24">Nunca pagar tiempo extra</option>
          </select>
        </div>
      </div>
      <hr>
    </div>

    <div class="d-flex" *ngIf="esDebugger">
      <h2 class="opacity-75 mb-2 col-8">Número de retardos equivalentes a falta en el periodo</h2>
      <div class="col-3 ms-4 text-center">
        <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="manejaNumeroRetardosComoFalta"></mat-slide-toggle>
      </div>
    </div>
    <div class="mb-3 d-flex" *ngIf="esDebugger">
      <div class="col-8">
        <p>Seleccione el número de retardos que se considerarán como faltas</p>
      </div>

      <div class="col-3 ms-4 text-center">
        <div>
          <select [disabled]="!enEdicion" class="form-select form-select-sm" [(ngModel)]="numeroRetardosComoFalta">
            <option [value]="+0.15">4</option>
            <option [value]="+0.25">5</option>
            <option [value]="+0.50">6</option>
            <option [value]="+0.75">7</option>
            <option [value]="+0.95">8</option>
            <option [value]="+0.95">9</option>
            <option [value]="+0.95">10</option>
          </select>
        </div>
      </div>
    </div>
    <hr>

    <div>
      <h2 class="opacity-75 mb-2">Tolerancia en faltas</h2>
      <p>Indica a partir de que momento un retardo se convierte en falta</p>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>
            Si los retardos del empleado son mayores al <b class="text-navy">{{ toleranciaFaltas }}</b> del horario a cubrir, entonces marcar el retardo como falta
          </p>
        </div>
        <div class="col-3 ms-4">
          <select [disabled]="!enEdicion" class="form-select form-select-sm" [(ngModel)]="toleranciaFaltas">
            <option [value]="+0">No aplica</option>
            <option [value]="+0.15">15%</option>
            <option [value]="+0.25">25%</option>
            <option [value]="+0.50">50%</option>
            <option [value]="+0.75">75%</option>
            <option [value]="+0.95">95%</option>
          </select>
        </div>
      </div>
      <hr>
    </div>

    <div>
      <h2 class="opacity-75 mb-2">Penalización por no marcar la entrada al turno</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>
            Indica el retardo que se le dará a un empleado en caso de que no se tenga el registro de su entrad al turno
          </p>
        </div>
        <div class="col-3 ms-4">
          <select [disabled]="!enEdicion" class="form-select form-select-sm" aria-label="Small select example"  [(ngModel)]="penalizarNoEntrada">
            <option [value]="+0">Ignorar</option>
            <option [value]="+0.0833333">5 minutos</option>
            <option [value]="+0.1333333">8 minutos</option>
            <option [value]="+0.1666666">10 minutos</option>
            <option [value]="+0.2500000">15 minutos</option>
            <option [value]="+0.5000000">30 minutos</option>
            <option [value]="+0.7500000">45 minutos</option>
            <option [value]="+1">1 hora</option>
            <option [value]="+2">2 horas</option>
            <option [value]="+4">4 horas</option>
            <option [value]="+24">Marcar como falta</option>
          </select>
        </div>
      </div>
      <hr>
    </div>

    <div>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <h2 class="opacity-75 mb-2">
            Ignorar penalización de retardo en hora de comida
          </h2>
        </div>
        <div class="col-3 ms-4 text-center">
          <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="ignorarPenalizarNoEntrada"></mat-slide-toggle>
        </div>
      </div>
      <hr>
    </div>

    <h2 class="opacity-75 mb-2">Penalización por no marcar la salida del turno</h2>
    <div class="mb-3 d-flex">
      <div class="col-8">
        <p>
          Indica la penalización en minutos que se le dará a un empleado en caso de que no se tenga el registro de su salida
          del turno
        </p>
      </div>
      <div class="col-3 ms-4">
        <select [(ngModel)]="penalizarNoSalida" [disabled]="!enEdicion" class="form-select form-select-sm">
          <option [value]="+0">Ignorar</option>
          <option [value]="+0.0833333">5 minutos</option>
          <option [value]="+0.1333333">8 minutos</option>
          <option [value]="+0.1666666">10 minutos</option>
          <option [value]="+0.2500000">15 minutos</option>
          <option [value]="+0.5000000">30 minutos</option>
          <option [value]="+0.7500000">45 minutos</option>
          <option [value]="+1">1 hora</option>
          <option [value]="+2">2 horas</option>
          <option [value]="+4">4 horas</option>
          <option [value]="+24">Marcar como falta</option>
        </select>
      </div>
    </div>
    <hr>

    <div>
      <h2 class="opacity-75 mb-2">Limite de llegada temprana</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>
            Indica el tiempo que tiene permitido un empleado para entrar antes de su turno. Si el empleado llega antes de este límite,
            entonces el tiempo que llego antes será ignorado para el cálculo de horas extra o alguna otra incidencia
          </p>
        </div>
        <div class="col-3 ms-4">
          <select [disabled]="!enEdicion" class="form-select form-select-sm" aria-label="Small select example" [(ngModel)]="limiteEntrada">
            <option [value]="+0">No puede llegar antes</option>
            <option [value]="+0.0833333">5 minutos</option>
            <option [value]="+0.1333333">8 minutos</option>
            <option [value]="+0.1666666">10 minutos</option>
            <option [value]="+0.2500000">15 minutos</option>
            <option [value]="+0.5000000">30 minutos</option>
            <option [value]="+0.7500000">45 minutos</option>
            <option [value]="+1">1 hora</option>
            <option [value]="+1.5">1.5 horas</option>
            <option [value]="+2">2 horas</option>
            <option [value]="+2.5">2.5 horas</option>
            <option [value]="+3">3 horas</option>
            <option [value]="+24">No hay limite</option>
          </select>
        </div>
      </div>
      <hr>
    </div>

    <div>
      <h2 class="opacity-75 mb-2">Limite máximo de salida del turno</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>
            Indica el tiempo en minutos, que tiene permitido un empleado para salir después de su turno.
            Si el empleado sale después de este límite, entonces el tiempo que salió después será ignorado
            para el cálculo de horas extra o alguna otra incidencia
          </p>
        </div>
        <div class="col-3 ms-4">
          <select [disabled]="!enEdicion" class="form-select form-select-sm" aria-label="Small select example"  [(ngModel)]="limiteSalida">
            <option [value]="+0">No puede salir después</option>
            <option [value]="+0.0833333">5 minutos</option>
            <option [value]="+0.1333333">8 minutos</option>
            <option [value]="+0.1666666">10 minutos</option>
            <option [value]="+0.2500000">15 minutos</option>
            <option [value]="+0.5000000">30 minutos</option>
            <option [value]="+0.7500000">45 minutos</option>
            <option [value]="+1">1 hora</option>
            <option [value]="+1.5">1.5 horas</option>
            <option [value]="+2">2 horas</option>
            <option [value]="+2.5">2.5 horas</option>
            <option [value]="+3">3 horas</option>
            <option [value]="+24">No hay limite</option>
          </select>
        </div>
      </div>
      <hr>
    </div>

    <div *ngIf="false">
      <h2 class="opacity-75 mb-2">Mostrar hora de entrada y salida real siempre</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>
            Indica la hora a mostrar cuando la hora de salida o de entrada exceden los límites<b class="text-navy">*</b>, si está apagado
            entonces mostrará la hora límite, si prendido mostrara la hora real de entrada o salida. Aunque se muestre la hora real, el cálculo del
            tiempo trabajado respetara siempre los límites<b class="text-navy">*</b> establecidos
          </p>
          <p>
            <b class="text-navy">* Limite de llegada temprana y limite máximo de salida del turno</b>
          </p>
        </div>
        <div class="col-3 ms-4 text-center">
          <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="mostrarHoraReal"></mat-slide-toggle>
        </div>
      </div>
      <hr>
    </div>

    <!-- <div>
      <h2 class="opacity-75 mb-2">Contar días trabajados en descanso obligatorio</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>
           Indica si el sistema contara los días que se trabajaron y debieron de haberse descansado de acuerdo al horario establecido
          </p>
        </div>
        <div class="col-3 ms-4 text-center">
          <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="trabajoDescansoObligatorio"></mat-slide-toggle>
        </div>
      </div>
      <hr>
    </div> -->

    <div>
      <h2 class="opacity-75 mb-2">Cubrir retardo con tiempo extra</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>El empleado podrá cubrir el tiempo de llegada tarde con tiempo extra un a vez terminada su jornada</p>
        </div>
        <div class="col-3 ms-4 text-center">
          <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="cubrirRetardo"></mat-slide-toggle>
        </div>
      </div>
      <hr>
    </div>

    <div>
      <h2 class="opacity-75 mb-2">Hora máxima para cambio de turno</h2>
      <div class="mb-3 d-flex">
        <div class="col-8">
          <p>Hasta que hora de la noche se considera aun como salida de la jornada del día anterior</p>
        </div>
        <div class="col-3 ms-4">
          <select [disabled]="!enEdicion" class="form-select form-select-sm" aria-label="Small select example" [(ngModel)]="toleranciaHorasExtra">
            <option [value]="0">Sin valor</option>
            <option [value]="1">1 a.m.</option>
            <option [value]="2">2 a.m.</option>
            <option [value]="3">3 a.m.</option>
            <option [value]="4">4 a.m.</option>
            <option [value]="5">5 a.m.</option>
            <option [value]="6">6 a.m.</option>
          </select>
        </div>
      </div>
      <hr>
    </div>

    <div *ngIf="esDebugger">
      <h2 class="opacity-75 mb-2">Exportar Incidencias</h2>
      <div class="mb-3">
        <p>Seleccione los tipos de incidencias que desea incluir en la nómina:</p>
        <div class="d-flex justify-content-around">
          <div class="text-center">
            <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="exportarFaltas">Faltas</mat-slide-toggle>
          </div>
          <div class="text-center">
            <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="exportarRetardos">Retardos</mat-slide-toggle>
          </div>
          <div class="text-center">
            <mat-slide-toggle [disabled]="!enEdicion" color="primary" [(ngModel)]="exportarHorasExtra">Horas extras</mat-slide-toggle>
          </div>
        </div>
      </div>
      <hr>
    </div>
  </div>
</div>
