import { environment } from "src/environments/environment";
import { soloHoraNum } from "../core/Funciones/fFecha";

export interface AccesoExpress  {    
    isConnected: boolean;
    lastUpdate: string;
    ownerId: string;
    companyId: number;
    companyName: string;
    imagenUrl: string;

    domicilio: string;
    telefono: string;
    correo: string;
    rfc: string;
    ciudad: string;
    estado: string;
    cp: string;
    apiKeyCF: string;
    vigenteHasta: Date;
    vigenteHastaTxt: string;
    díasVigenciaAdicional: number;

    clientId: string; // Id del punto de acceso (CheckerAccess.Id)
    deviceId: string; // Es del dispositivo en el que está corriendo la instancia, es un Uuid generado la primera vez que corre el sistema.
    deviceKey: string;
    localSecret: string; // (contraseña de los puntos de acceso + device id) * sha256

    escucharPorMovimientos: boolean;
    faceDetectionEnabled: boolean;
    faceDetectionActive: boolean;
    faceRecognitionDebugMode: boolean;
    habilitarSonido: boolean;
    habilitarCodigoManual: boolean;
    infoReconocimientoFacial: boolean;

    // configuración del modelo
    distanceThreshold: number; // is threshold used when comparing two different faces.
    ssdMinConfidence: number; // determines if face is face at all during detection - if detected face is below that level, it will not be analyzed at all and you don't get things like descriptor as a result.
    tinyFaceScoreThreshold: number; // determines if face is face at all during detection - if detected face is below that level, it will not be analyzed at all and you don't get things like descriptor as a result.
    faceApiModel: string;
    showActualCheckTime: boolean;
    camHighlight1: string;
    camHighlight2: string;
    faceAndCamAreaTolerance: number;
    hasTimeError: boolean;
    scanCountToCheck: number;
    showCamIndicators: boolean;
    canvasFps: number;
    facingMode: string;

    faceBoxX: number;
    faceBoxY: number;
    faceBoxH: number;
    faceBoxW: number;
    faceBoxTolerance : number;
    webCamScale: number;
    drawingLoopInicial: number;
    mostrarInfoAdd: boolean;
    camaraEmpleados: number;

    accesoNombre: string; // nombre del acceso
    isActive: boolean; // empresa activa

    debug: boolean;
    checkServerTime: boolean;
    offSetFromServer: number;

    opcionesFecha: OpcionesFecha

    version: string,
    ultimaActualizacion: string;

    validarDirectoOnline: boolean;
    usarReconocimientoCompleto: boolean;

    servidorCF: string;
    tipoAcceso: string;

    validarReconocimiento: number;
    validarPorcentajeImagen: number;

    vecesPorVerificar: number;
    minutosParaRecargar: number;

    manejaBajarFrecuenciaRF: boolean;
    bajarFrecuenciaInicio: Date;
    bajarFrecuenciaFin: Date;
    bajarFrecuenciaCada: number;

    manejaCentradoEnAcceso: boolean;
    imagenesParaValidarRegistro: number;    
}

// nomiexpressapi

export const versionAccesoExpress = '0.55.00';
export const validarReconocimiento = 0.97;
export const verificarReconocimientoFacial = 0.985;

export function nuevoAccesoExpress(): AccesoExpress {
    return  {
        isConnected: false,
        lastUpdate: '1900-01-01T12:12:12',
        ownerId: '',
        companyId: 0,
        companyName: '',
        imagenUrl: '',
        clientId: '',
        deviceId: '',
        deviceKey: '',
        localSecret: '',
        escucharPorMovimientos: true,
        faceDetectionEnabled: true,
        faceDetectionActive: true,
        faceRecognitionDebugMode: false,
        habilitarSonido: true,
        habilitarCodigoManual: true,
        showActualCheckTime: false,
        distanceThreshold: 0.9,
        ssdMinConfidence: 0.9,
        tinyFaceScoreThreshold: 0.9,
        faceAndCamAreaTolerance: 0.1,
        faceApiModel: 'ssdMobileNetV1',
        camHighlight1: "#fafafa",
        camHighlight2: "#004c8c",
        hasTimeError: false,
        showCamIndicators: false,
        scanCountToCheck: 3,
        canvasFps: 60,
        facingMode: 'user',
        faceBoxX: 0,
        faceBoxY: 0,
        faceBoxH: 0,
        faceBoxW: 0,
        webCamScale: 0.85,
        faceBoxTolerance: 0.08,
        drawingLoopInicial: 60,
        mostrarInfoAdd: false,
        accesoNombre: '',
        isActive: false,
        debug: false,
        checkServerTime: false,
        offSetFromServer: 0,
        version: '',
        ultimaActualizacion: '1900-01-01T00:00:00',
        infoReconocimientoFacial: true,
        opcionesFecha: OpcionesFecha.Normal,
        domicilio: '',
        telefono: '',
        correo: '',
        rfc: '',
        ciudad: '',
        estado: '',
        cp: '',
        apiKeyCF: '',
        vigenteHasta: new Date(1999, 0, 1),
        vigenteHastaTxt: '1900-01-01T00:00:00',
        validarDirectoOnline: true,
        usarReconocimientoCompleto: false,
        camaraEmpleados: 400,
        servidorCF: 'CF1',
        tipoAcceso: 'CF',
        validarReconocimiento: 0.985,
        vecesPorVerificar: 2,
        validarPorcentajeImagen: 0.3,
        manejaBajarFrecuenciaRF: false,
        bajarFrecuenciaInicio: soloHoraNum(0),
        bajarFrecuenciaFin: soloHoraNum(0),
        bajarFrecuenciaCada: 0,
        minutosParaRecargar: 14400,
        manejaCentradoEnAcceso: false,
        imagenesParaValidarRegistro: 2,
        díasVigenciaAdicional: 0
      };
}


export function restaurarAccesoExpress(accesoExpress: AccesoExpress): AccesoExpress {
    accesoExpress.isConnected = true;
    accesoExpress.escucharPorMovimientos= true;
    accesoExpress.faceDetectionEnabled= true;
    accesoExpress.faceDetectionActive= true;
    accesoExpress.faceRecognitionDebugMode= false;
    accesoExpress.habilitarSonido= true;
    accesoExpress.habilitarCodigoManual= true;
    accesoExpress.showActualCheckTime= false;
    accesoExpress.distanceThreshold=  0.9;
    accesoExpress.ssdMinConfidence=  0.9;
    accesoExpress.tinyFaceScoreThreshold=  0.9;
    accesoExpress.faceAndCamAreaTolerance=  0.1;
    accesoExpress.faceApiModel=  'ssdMobileNetV1';
    accesoExpress.camHighlight1=  "#fafafa";
    accesoExpress.camHighlight2=  "#004c8c";
    accesoExpress.hasTimeError= false;
    accesoExpress.showCamIndicators= false;
    accesoExpress.scanCountToCheck=  3;
    accesoExpress.canvasFps=  60;
    accesoExpress.facingMode = 'user';
    accesoExpress.faceBoxX=  0;
    accesoExpress.faceBoxY=  0;
    accesoExpress.faceBoxH=  0;
    accesoExpress.faceBoxW=  0;
    accesoExpress.webCamScale = 0.85;
    accesoExpress.faceBoxTolerance=  0.08;
    accesoExpress.drawingLoopInicial = 60;
    accesoExpress.infoReconocimientoFacial=  true;
    accesoExpress.mostrarInfoAdd = false;
    accesoExpress.vecesPorVerificar = 2;
    return accesoExpress;
}


export enum OpcionesFecha {
    Normal = 0,
    HorarioVerano = 1,
    PorSO = 2, // por el horario del dispositivo
    Mas1 = 3,
    Mas2 = 4,
    Menos1 = 5,
    Menos2 = 6
}

export interface AccesoExpressConfig  {
    ownerId: string;
    companyId: number;
    companyName: string;

    clientId: string; // Id del punto de acceso (CheckerAccess.Id)
    deviceId: string; // Es del dispositivo en el que está corriendo la instancia, es un Uuid generado la primera vez que corre el sistema.
    deviceKey: string;
    localSecret: string; // (contraseña de los puntos de acceso + device id) * sha256

    escucharPorMovimientos: boolean;
    faceDetectionEnabled: boolean;
    faceDetectionActive: boolean;
    faceRecognitionDebugMode: boolean;
    habilitarSonido: boolean;
    habilitarCodigoManual: boolean;
    infoReconocimientoFacial: boolean;

    // configuración del modelo
    distanceThreshold: number; // is threshold used when comparing two different faces.
    ssdMinConfidence: number; // determines if face is face at all during detection - if detected face is below that level, it will not be analyzed at all and you don't get things like descriptor as a result.
    tinyFaceScoreThreshold: number; // determines if face is face at all during detection - if detected face is below that level, it will not be analyzed at all and you don't get things like descriptor as a result.
    faceApiModel: string;
    showActualCheckTime: boolean;
    camHighlight1: string;
    camHighlight2: string;
    faceAndCamAreaTolerance: number;
    scanCountToCheck: number;
    showCamIndicators: boolean;
    canvasFps: number;
    facingMode: string;

    faceBoxX: number;
    faceBoxY: number;
    faceBoxH: number;
    faceBoxW: number;
    faceBoxTolerance : number;
    webCamScale: number;
    drawingLoopInicial: number;
    mostrarInfoAdd: boolean;

    debug: boolean;
    checkServerTime: boolean;
    offSetFromServer: number;

    opcionesFecha: OpcionesFecha
}

export function AccesoExpressToConfig(accesoExpress: AccesoExpress): AccesoExpressConfig  {
    return  {
        ownerId: accesoExpress.ownerId,
        companyId: accesoExpress.companyId,
        companyName: accesoExpress.companyName,

        clientId: accesoExpress.clientId,
        deviceId: accesoExpress.deviceId,
        deviceKey: accesoExpress.deviceKey,
        localSecret: accesoExpress.localSecret,

        escucharPorMovimientos: accesoExpress.escucharPorMovimientos,
        faceDetectionEnabled: accesoExpress.faceDetectionEnabled,
        faceDetectionActive: accesoExpress.faceDetectionActive,
        faceRecognitionDebugMode: accesoExpress.faceRecognitionDebugMode,
        habilitarSonido: accesoExpress.habilitarSonido,
        habilitarCodigoManual: accesoExpress.habilitarCodigoManual,
        infoReconocimientoFacial: accesoExpress.infoReconocimientoFacial,

        // configuración del modelo
        distanceThreshold: accesoExpress.distanceThreshold,
        ssdMinConfidence: accesoExpress.ssdMinConfidence,
        tinyFaceScoreThreshold: accesoExpress.tinyFaceScoreThreshold,
        faceApiModel: accesoExpress.faceApiModel,
        showActualCheckTime: accesoExpress.showActualCheckTime,
        camHighlight1: accesoExpress.camHighlight1,
        camHighlight2: accesoExpress.camHighlight2,
        faceAndCamAreaTolerance: accesoExpress.faceAndCamAreaTolerance,
        scanCountToCheck: accesoExpress.scanCountToCheck,
        showCamIndicators: accesoExpress.showCamIndicators,
        canvasFps: accesoExpress.canvasFps,
        facingMode: accesoExpress.facingMode,

        faceBoxX: accesoExpress.faceBoxX,
        faceBoxY: accesoExpress.faceBoxY,
        faceBoxH: accesoExpress.faceBoxH,
        faceBoxW: accesoExpress.faceBoxW,
        faceBoxTolerance : accesoExpress.faceBoxTolerance,
        webCamScale: accesoExpress.webCamScale,
        drawingLoopInicial: accesoExpress.drawingLoopInicial,
        mostrarInfoAdd: accesoExpress.mostrarInfoAdd,

        debug: accesoExpress.debug,
        checkServerTime: accesoExpress.checkServerTime,
        offSetFromServer: accesoExpress.offSetFromServer,

        opcionesFecha: accesoExpress.opcionesFecha,
    }
}

export function ConfigToAccesoExpress(config: AccesoExpressConfig, acceso: AccesoExpress): AccesoExpress {
    acceso.escucharPorMovimientos = config.escucharPorMovimientos;
    acceso.faceDetectionEnabled = config.faceDetectionEnabled;
    acceso.faceDetectionActive = config.faceDetectionActive;
    acceso.faceRecognitionDebugMode = config.faceRecognitionDebugMode;
    acceso.habilitarSonido = config.habilitarSonido;
    acceso.habilitarCodigoManual = config.habilitarCodigoManual;
    acceso.infoReconocimientoFacial = config.infoReconocimientoFacial;

    // configuración del modelo
    acceso.distanceThreshold = config.distanceThreshold;
    acceso.ssdMinConfidence = config.ssdMinConfidence;
    acceso.tinyFaceScoreThreshold = config.tinyFaceScoreThreshold;
    acceso.faceApiModel = config.faceApiModel;
    acceso.showActualCheckTime = config.showActualCheckTime;
    acceso.camHighlight1 = config.camHighlight1;
    acceso.camHighlight2 = config.camHighlight2;
    acceso.faceAndCamAreaTolerance = config.faceAndCamAreaTolerance;
    acceso.scanCountToCheck = config.scanCountToCheck;
    acceso.showCamIndicators = config.showCamIndicators;
    acceso.canvasFps = config.canvasFps;
    acceso.facingMode = config.facingMode;

    acceso.faceBoxX = config.faceBoxX;
    acceso.faceBoxY = config.faceBoxY;
    acceso.faceBoxH = config.faceBoxH;
    acceso.faceBoxW = config.faceBoxW;
    acceso.faceBoxTolerance = config.faceBoxTolerance;
    acceso.webCamScale = config.webCamScale;
    acceso.drawingLoopInicial = config.drawingLoopInicial;
    acceso.mostrarInfoAdd = config.mostrarInfoAdd;
    acceso.debug = config.debug;
    acceso.checkServerTime = config.checkServerTime;
    acceso.offSetFromServer = config.offSetFromServer;

    acceso.opcionesFecha = config.opcionesFecha;
    return  acceso;
}

export function validarAccesoExpress(_accesoExpress: AccesoExpress) {
    _accesoExpress.version = versionAccesoExpress;
    _accesoExpress.isConnected = false;
    if (_accesoExpress.distanceThreshold || _accesoExpress.distanceThreshold < 0.5) _accesoExpress.distanceThreshold = 0.9;
    if (_accesoExpress.distanceThreshold < 0.75) _accesoExpress.distanceThreshold = 0.75;
    if (_accesoExpress.ssdMinConfidence || _accesoExpress.ssdMinConfidence < 0.5) _accesoExpress.distanceThreshold = 0.9;
    if (_accesoExpress.ssdMinConfidence < 0.75) _accesoExpress.ssdMinConfidence = 0.9;
    if (_accesoExpress.tinyFaceScoreThreshold || _accesoExpress.tinyFaceScoreThreshold < 0.5) _accesoExpress.distanceThreshold = 0.9;
    if (_accesoExpress.tinyFaceScoreThreshold < 0.75) _accesoExpress.tinyFaceScoreThreshold = 0.9;
    if (!_accesoExpress.faceApiModel) _accesoExpress.faceApiModel = 'ssdMobileNetV1';
    if (!_accesoExpress.camHighlight1 || _accesoExpress.camHighlight1.length < 3) _accesoExpress.camHighlight1 = "#fafafa";
    if (!_accesoExpress.camHighlight2 || _accesoExpress.camHighlight2.length < 3) _accesoExpress.camHighlight2 = "#004c8c";
    if (!_accesoExpress.faceAndCamAreaTolerance || _accesoExpress.faceAndCamAreaTolerance < 0.06) _accesoExpress.faceAndCamAreaTolerance = 0.07;
    if (_accesoExpress.faceAndCamAreaTolerance > 0.5) _accesoExpress.faceAndCamAreaTolerance = 0.5;
    // _accesoExpress.hasTimeError = false;
    if (_accesoExpress.scanCountToCheck < 2) _accesoExpress.scanCountToCheck = 3;
    if (!_accesoExpress.canvasFps || _accesoExpress.canvasFps < 50) _accesoExpress.canvasFps = 60;
    if (_accesoExpress.canvasFps > 120) _accesoExpress.canvasFps = 120;
    _accesoExpress.debug = !environment.production;
    _accesoExpress.showCamIndicators = _accesoExpress.showCamIndicators && _accesoExpress.debug;
    // _accesoExpress.infoReconocimientoFacial = true;

    if (_accesoExpress.faceBoxX == undefined || _accesoExpress.faceBoxX == null) _accesoExpress.faceBoxX = 0;
    if (_accesoExpress.faceBoxY == undefined || _accesoExpress.faceBoxY == null) _accesoExpress.faceBoxY = 0;
    if (_accesoExpress.faceBoxW == undefined || _accesoExpress.faceBoxW == null) _accesoExpress.faceBoxW = 0;
    if (_accesoExpress.faceBoxH == undefined || _accesoExpress.faceBoxH == null) _accesoExpress.faceBoxH = 0;
    if (_accesoExpress.webCamScale == undefined || _accesoExpress.webCamScale == null) _accesoExpress.webCamScale = 0.85;
    if (_accesoExpress.faceBoxTolerance == undefined || _accesoExpress.faceBoxTolerance == null) _accesoExpress.faceBoxTolerance = 0;
    if (_accesoExpress.drawingLoopInicial == undefined || _accesoExpress.drawingLoopInicial == null) _accesoExpress.drawingLoopInicial = 60;

    if (_accesoExpress.faceBoxX < -200) _accesoExpress.faceBoxX = -200;
    if (_accesoExpress.faceBoxX > 200) _accesoExpress.faceBoxX = 200;
    if (_accesoExpress.faceBoxY < -200) _accesoExpress.faceBoxY = -200;
    if (_accesoExpress.faceBoxY > 200) _accesoExpress.faceBoxY = 200;
    if (_accesoExpress.faceBoxH < -200) _accesoExpress.faceBoxH = -200;
    if (_accesoExpress.faceBoxH > 200) _accesoExpress.faceBoxH = 200;
    if (_accesoExpress.faceBoxW < -200) _accesoExpress.faceBoxW = -200;
    if (_accesoExpress.faceBoxW > 200) _accesoExpress.faceBoxW = 200;
    if (_accesoExpress.webCamScale < 0.3) _accesoExpress.webCamScale = 0.3;
    if (_accesoExpress.webCamScale > 1) _accesoExpress.webCamScale = 1;
    if (_accesoExpress.faceBoxTolerance < 0.05) _accesoExpress.faceBoxTolerance = 0.05;
    if (_accesoExpress.faceBoxTolerance > 0.20) _accesoExpress.faceBoxTolerance = 0.20;
    if (_accesoExpress.drawingLoopInicial < 20) _accesoExpress.drawingLoopInicial  = 20;
    if (_accesoExpress.drawingLoopInicial > 120) _accesoExpress.drawingLoopInicial  = 120;
    _accesoExpress.mostrarInfoAdd = _accesoExpress.mostrarInfoAdd && (!environment.production || environment.url == 'https://pruebas.nomiexpress.com');

    if (_accesoExpress.validarDirectoOnline == undefined) _accesoExpress.validarDirectoOnline = true;
    if (!_accesoExpress.servidorCF) _accesoExpress.servidorCF = "CF1";    
    if (!_accesoExpress.validarReconocimiento) _accesoExpress.validarReconocimiento = validarReconocimiento;
    if (_accesoExpress.validarReconocimiento < validarReconocimiento) _accesoExpress.validarReconocimiento = validarReconocimiento;
    if (_accesoExpress.validarReconocimiento > 1) _accesoExpress.validarReconocimiento = 1;

    if (!_accesoExpress.vecesPorVerificar) _accesoExpress.vecesPorVerificar = 2;
    if (_accesoExpress.vecesPorVerificar < 1) _accesoExpress.vecesPorVerificar = 1;
    if (_accesoExpress.vecesPorVerificar > 5) _accesoExpress.vecesPorVerificar = 5;

    if (!_accesoExpress.validarPorcentajeImagen) _accesoExpress.validarPorcentajeImagen = 0.3;
    if (_accesoExpress.validarPorcentajeImagen < 0.2) _accesoExpress.validarPorcentajeImagen = 0.2;
    if (_accesoExpress.validarPorcentajeImagen > 0.7) _accesoExpress.validarPorcentajeImagen = 0.7;

    if (!_accesoExpress.bajarFrecuenciaInicio) _accesoExpress.bajarFrecuenciaInicio = soloHoraNum(0);
    if (!_accesoExpress.bajarFrecuenciaFin) _accesoExpress.bajarFrecuenciaFin = soloHoraNum(0);
    if (!_accesoExpress.bajarFrecuenciaCada) _accesoExpress.bajarFrecuenciaCada = 0;

    if (_accesoExpress.minutosParaRecargar == undefined) _accesoExpress.minutosParaRecargar = 0;
    if (_accesoExpress.manejaCentradoEnAcceso === undefined) _accesoExpress.manejaCentradoEnAcceso = false;
    if (!_accesoExpress.imagenesParaValidarRegistro || _accesoExpress.imagenesParaValidarRegistro < 2)_accesoExpress.imagenesParaValidarRegistro = 2;
    if (_accesoExpress.díasVigenciaAdicional == undefined) _accesoExpress.díasVigenciaAdicional = 0;
    if (_accesoExpress.díasVigenciaAdicional < 0) _accesoExpress.díasVigenciaAdicional = 0;
    if (_accesoExpress.díasVigenciaAdicional > 15) _accesoExpress.díasVigenciaAdicional = 15;

  }
