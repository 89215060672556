import { getFechaT } from "../core/Funciones/fFecha";
import { nuevoEmpleadoRF } from "./accesoCF";
import { ICodigoQr } from "./codigoQr";
import { TipoOrigen } from "./datosEmpleadosAcceso";

export interface IEmpleados {
    id: string;
    numero: string,
    nombre: string;
    paterno: string;
    materno: string;
    alta: Date;
    altaActualTxt: string;
    baja: Date | undefined;
    bajaActualTxt: string | undefined;
    rfc: string;
    curp: string;
    correo: string;
    departamento: string;
    horario: string;
    idDepartamento: number;
    idHorario: string;
    // incidencias: DatosIncidencias;
    // incidenciasRegistradas: DatosIncidenciasRegistrados;
    imss: string;
    puesto: string;
    area: string;
    selected: boolean;
    tipoEmpleado: any;
    ultimaCargaCF: Date;
    qrCode: ICodigoQr;
    datosRF: IAccesoEmpleadoRFData;
    tieneDatosRF: boolean;
    idReferencia: string;
    manejoAccesoExpress: EmpleadosManejoAccesoExpress;
    grupo: string | undefined;
}

// export interface IEmpleadosSeleccionado extends IEmpleados {
//   seleccionado: boolean;
// }
// let nuevoEmpleado: IEmpleadosSeleccionado = {...x, seleccionado: true};


export interface DatosIncidenciasRegistrados {
  horaEntradaRegistrada: Date;
  horaSalidaRegistrada: Date;
}

export interface EmployeeTransfer {
  id: string;
  nombre: string;
  rfc: string;
  departamento: string;
  horario: string;
  selected: boolean;
}

export interface IMinifiedEmpleados { //     public class MinifiedEmployeeChecker
    a: string;
    b: string;
    c: string;
    d: string;
    e: string;
    f: string | undefined;
    g: string;
    h: number;

    // i: string;
    // j: string;
    // k: string;
    // l: number;
    // m: string;
    // n: any;
    // o: string;
    // p: string;
    // q: string;
    // r: number;
    // s: string;
    // t: any;
    // u: any;
    // v: string;
}

export function empleadosExpandir(empleadosMini: IMinifiedEmpleados): IEmpleados {
    return {
        id: empleadosMini.a,
        numero: '', // empleadosMini.v,
        nombre: empleadosMini.b,
        paterno: empleadosMini.c,
        materno: empleadosMini.d,
        alta: getFechaT(empleadosMini.e),
        altaActualTxt: '',
        baja: getFechaT(empleadosMini.f || ''),
        bajaActualTxt: undefined,
        rfc: empleadosMini.g,
        curp: '', // empleadosMini.h,
        correo: '', // empleadosMini.i,
        departamento: '', // empleadosMini.j,
        horario: '', // empleadosMini.k,
        idDepartamento: 0, // empleadosMini.l,
        idHorario: '', // empleadosMini.m,
        // incidencias: undefined, // empleadosMini.n,
        imss: '', // empleadosMini.o,
        puesto: '', // empleadosMini.p,
        area: '', // empleadosMini.q,
        tipoEmpleado: empleadosMini.h, // empleadosMini.r,
        ultimaCargaCF: new Date(1900, 1, 1), //  CVFechaT(empleadosMini.s),
        selected: false,
        qrCode: {
          id: '',
          idCorto: 0,
          llave: 0,
        },
        datosRF: {
          id: 0,
          datos: '',
          fecha: new Date(1900, 1, 1),
          fechaTxt: '',
          deviceId: '',
          tipo: 0,
          idCf: '',
          origen: 0,
          similarity: 0,
          box: '',
          resultado: '',
          idPuntoAcceso: ''
        },
        tieneDatosRF: false,
        //  incidenciasRegistradas: '', // empleadosMini.u
        idReferencia: '',
        manejoAccesoExpress: EmpleadosManejoAccesoExpress.Normal,
        grupo: ''
    };
}

export function nombreEmpleado(empleado: IEmpleados): string {
  if (!empleado) return '';
  if (!!empleado.paterno && !!empleado.materno) return empleado.nombre + ' ' + empleado.paterno + ' ' + empleado.materno;
  if (!empleado.materno) return empleado.nombre + ' ' + empleado.paterno;
  return empleado.nombre + ' ' + empleado.materno;
}

export function nombreEmpleadoCorto(empleado: IEmpleados | undefined): string {
  if (!empleado) return '';
  if (!!empleado.paterno) return empleado.nombre + ' ' + empleado.paterno;
  return empleado.nombre + ' ' + empleado.materno;
}

export function nuevoEmpleado(): IEmpleados {
  return {
    id: '0',
    numero: '',
    nombre: '',
    paterno: '',
    materno: '',
    alta: new Date(),
    altaActualTxt: '',
    baja: undefined,
    bajaActualTxt: undefined,
    rfc: '',
    curp: '',
    correo: '',
    departamento: '',
    horario: '',
    idDepartamento: 0,
    idHorario: '',
    // incidencias: {
    //   fecha: new Date(),
    //   horaEntrada: undefined,
    //   horaSalida: undefined,
    //   horasTrabajadas: null,
    //   horasExtras: null,
    //   retardos: null,
    //   faltas: 0,
    // },
    imss: '',
    puesto: '',
    area: '',
    tipoEmpleado: 0,
    selected: false,
    ultimaCargaCF: new Date(1900, 0, 1),
    qrCode: {
      id: '',
      idCorto: 0,
      llave: 0,
    },
    datosRF: {
      id: 0,
      datos: '',
      fecha: new Date(1900, 1,1 ),
      fechaTxt: '',
      deviceId: '',
      tipo: 0,
      idCf: '',
      origen: 0,
      similarity: 0,
      box: '',
      resultado: '',
      idPuntoAcceso: ''
    },
    tieneDatosRF: false,
    // incidenciasRegistradas: {
    //   horaEntradaRegistrada: new Date(),
    //   horaSalidaRegistrada: new Date(),
    // }
    idReferencia: '',
    manejoAccesoExpress: EmpleadosManejoAccesoExpress.Normal,
    grupo: ''
  };
}

export enum TipoEmpleado {
  Normal = 0,
  Supervisor = 1,
  Administrador = 2,
  Sistemas = 3
}

export const TiposEmpleado: { [key:number]: string} = {
  0: 'Normal',
  1: 'Supervisor',
  2: 'Administrador',
  3: 'Sistemas'
}

export const ManejoEmpleados: { [key:number]: string} = {
  0: 'Normal',
  1: 'Empleado de confianza sin registro',
  2: 'Empleado de confianza sin exportar',
}

export class EmpleadoMigracion {
  clave: string = '';
  nombre: string = '';
  apellidoPaterno: string = '';
  apellidoMaterno: string = '';
  rfc: string = '';
  curp: string = '';
  imss: string = '';
  departamento: string = '';
  correo: string = '';
  area: string = '';
  puesto: string = '';
  metodoDePago: string = '';
  banco: string = '';
  cuentaBanco: string = '';
  numTarjetaBanco: string = '';
  clabe: string = '';
  cuentaVales: string = '';
  // dpscCfdi: number;
  padre: string = '';
  madre: string = '';
  nacimientoFecha: string = '';
  nacimientoLugar: string = '';
  nacimientoEstado: string = '';
  sexo: string = '';
  estadoCivil: string = '';
  umf: string = '';
  domicilioCalle: string = '';
  domicilioNumeroExterior: string = '';
  domicilioNumeroInterior: string = '';
  domicilioColonia: string = '';
  domicilioMunicipio: string = '';
  domicilioPoblacion: string = '';
  domicilioEstado: string = '';
  domicilioCodigoPostal: string = '';
  domicilioTelefono: string = '';
  tipoSueldo: number = 0;
  duracionJornada: string = '';
  tipoTrabajador: number = 0;
  tipoJornada: number = 0;
  sindicalizado: boolean = false;
  manejoDiasCotizadosAlImss: number = 0;
  chofer: string = '';
  campo: string = '';
  aplicarSubsidio: boolean = true;
  manejoDosDepartamentos: boolean = false;
  diasDescanso: string = '';
  primaDominical: number = 0;
  tieneInfonavit: boolean = false;
  reciboCampoTitulo: string = '';
  reciboCampoConcepto: string = '';
  horasPorNomina: number = 0;
  sueldoVariable: number = 0;
  horasPorDia: number = 0;
  asimiladoSalario: boolean = false;
  nominaPorHora: string = '';
  factorDiasHoras: number = 0;
  cfdiRegimen: number = 0;
  cfdiContrato: number = 0;
  cfdiJornada: number = 0;
  cfdiRiesgo: number = 0;
  cfdiBanco: string = '';
  cfdiClabe: string = '';
  cfdiPago: string = '';
  cfdiOrigen: string = '';
  movimientos: string = '';
  prestacionLeyAguinaldo: number = 0;
  prestacionLeyPrimaVacacional: number = 0;
  prestacionLeyVacaciones: string = '';
  regimenFiscal: number = 0;
  cfdiCodigoPostal: string = '';
  jornadaReducida: string = '';
  altaTxt: string = '';
  bajaTxt: string = '';
  sueldoDiario: number = 0;
  SDI: number = 0;
}

export interface IEmpleadoQr {
  empleado: IEmpleados,
  qrCode: ICodigoQr
}

export interface IAccesoEmpleadoRFData
{
    id: number;
    deviceId: string;
    fecha: Date;
    fechaTxt: string;
    datos: string;
    tipo: TipoFoto;
    idCf: string;
    origen: TipoOrigen;
    similarity: number;
    box: string;
    resultado: string;
    idPuntoAcceso: string;
}

export enum TipoFoto
{
  Identificacion,
  Ingreso,
  RegistroEP,
  RegistroCF
}

export enum EmpleadosManejoAccesoExpress {
  Normal,
  ConfianzaSinRegistro,
  ConfianzaSinExportar,
}

export class Contrato {
  leyenda: string = '';
}


export const estadosCiviles: { [key:string]: number} = {
  '': 0,
  'soltero': 1,
  'casado': 2,
  'divorciado': 3,
  'viudo': 4
}

export const sexos: { [key:string]: number} = {
  '': 0,
  'masculino': 1,
  'femenino': 2
}

export const tiposSueldo: { [key: string]: number } = {
  'fijo': 0,
  'variable': 1,
  'mixto': 2
};

export const tiposTrabajadores: { [key: string]: number } = {
  'permanente': 0,
  'eventual': 1,
  'construcción': 2,
  'eventual del campo': 3,
  'campo': 3
}

export const tiposJornadas: { [key: string]: number } = {
  'Semana completa': 0,
  'Labora 1 día a la semana': 1,
  'Labora 2 días a la semana': 2,
  'Labora 3 días a la semana': 3,
  'Labora 4 días a la semana': 4,
  'Labora 5 días a la semana': 5,
  'Labora 1 hora por día': 6,
  'Labora 2 horas por día': 7,
  'Labora 3 horas por día': 9,
  'Labora 4 horas por día': 10,
  'Labora 5 horas por día': 11,
  'Labora 6 horas por día': 12,
  'Jornada reducida': 13,
};

export const tiposCFDIRegimen: { [key: string]: number } = {
  '02': 2,
  'sueldos': 2,
  'sueldos y salarios': 2,
  '03': 3,
  'jubilados': 3,
  '04': 4,
  'pensionados': 4,
  '05': 5,
  'asimilados miembros sociedades cooperativas produccion': 5,
  'asimilados miembros sociedades cooperativas producción': 5,
  'miembros sociedades cooperativas producción': 5,
  '06': 6,
  'asimilados integrantes sociedades asociaciones civiles': 6,
  'integrantes sociedades asociaciones civiles': 6,
  '07': 7,
  'asimilados miembros consejos': 7,
  'miembros consejos': 7,
  '08': 8,
  'asimilados comisionistas': 8,
  'comisionistas': 8,
  '09': 9,
  'asimilados honorarios': 9,
  'honorarios': 9,
  '10': 10,
  'asimilados acciones': 10,
  'acciones': 10,
  'accionistas': 10,
  '11': 11,
  'asimilados otros': 11,
  'otros asimilados': 11,
  'otros': 11,
  '12': 12,
  'jubilados o pensionados': 12,
  'pensionados o jubilados': 12,
  '13': 13,
  'indemnización o separación': 13,
  'indemnizacion o separacion': 13,
  'separación o indemnización': 13,
  'separacion o indemnizacion': 13,
  'separación': 13,
  'separacion': 13,
  'indemnización': 13,
  'indemnizacion': 13,
  '99': 99,
  'otro regimen': 99,
  'otro régimen': 99,
  'otro': 99
}

export const tiposCFDIContrato: { [key: string]: number } = {
  '01': 1,
  'contrato de trabajo por tiempo indeterminado': 1,
  'trabajo por tiempo indeterminado': 1,
  'por tiempo indeterminado': 1,
  'tiempo indeterminado': 1,
  'indeterminado': 1,
  'base': 1,
  '02': 2,
  'contrato de trabajo para obra determinada': 2,
  'trabajo para obra determinada': 2,
  'para obra determinada': 2,
  'obra determinada': 2,
  '03': 3,
  'contrato de trabajo por tiempo determinado': 3,
  'trabajo por tiempo determinado': 3,
  'por tiempo determinado': 3,
  'tiempo determinado': 3,
  '04': 4,
  'contrato de trabajo por temporada': 4,
  'trabajo por temporada': 4,
  'por temporada': 4,
  'temporada': 4,
  '05': 5,
  'contrato de trabajo sujeto a prueba': 5,
  'trabajo sujeto a prueba': 5,
  'sujeto a prueba': 5,
  'prueba': 5,
  '06': 6,
  'contrato de trabajo con capacitación inicial': 6,
  'trabajo con capacitación inicial': 6,
  'con capacitación inicial': 6,
  'capacitación inicial': 6,
  'capacitación': 6,
  '07': 7,
  'modalidad de contratación por pago de hora laborada': 7,
  'contratación por pago de hora laborada': 7,
  'por pago de hora laborada': 7,
  'pago de hora laborada': 7,
  'pago de hora': 7,
  'hora laborada': 7,
  '08': 8,
  'modalidad de trabajo por comisión laboral': 8,
  'trabajo por comisión laboral': 8,
  'por comisión laboral': 8,
  'comisión laboral': 8,
  'comisión': 8,
  '09': 9,
  'modalidades de contratación donde no existe relación de trabajo': 9,
  'contratación donde no existe relación de trabajo': 9,
  'donde no existe relación de trabajo': 9,
  'sin relación de trabajo': 9,
  '10': 10,
  'jubilación, pensión, retiro': 10,
  'pensión, retiro': 10,
  'retiro': 10,
  '99': 99,
  'otro contrato': 99,
  'otro': 99
}

export const tiposCFDIJornada: { [key: string]: number } = {
  '01': 1,
  'diurna': 1,
  '02': 2,
  'nocturna': 2,
  '03': 3,
  'mixta': 3,
  '04': 4,
  'por hora': 4,
  'hora': 4,
  '05': 5,
  'reducida': 5,
  '06': 6,
  'continuada': 6,
  '07': 7,
  'partida': 7,
  'por partida': 7,
  '08': 8,
  'por turnos': 8,
  'turnos': 8,
  '99': 99,
  'otra jornada': 99,
  'otro': 99,
  'otra': 99
}

export const tiposCFDIRiesgoPuesto: { [key: string]: number } = {
  '00': 0,
  'no': 0,
  'no aplica': 0,
  '01': 1,
  'i': 1,
  'clase i': 1,
  '02': 2,
  'ii': 2,
  'clase ii': 2,
  '03': 3,
  'iii': 3,
  'clase iii': 3,
  '04': 4,
  'iv': 4,
  'clase iv': 4,
  '05': 5,
  'v': 5,
  'clase v': 5,
  '99': 99,
  'otro': 99,
  'otra': 99
}

export const tiposCFDIRegimenFiscal: { [key: string]: number } = {
  'no especificado': 0,
  'no': 0,
  'sin especificar': 0,
  'sueldos y Salarios e ingresos asimilados a salarios': 605,
  'sueldos y Salarios': 605,
  'ingresos asimilados a salarios': 605,
  'ingresos asimilados': 605,
  'asimilados': 605,
  'arrendamiento': 606,
  'régimen de enajenación o adquisición de bienes': 607,
  'regimen de enajenación o adquisición de bienes': 607,
  'enajenación o adquisición de bienes': 607,
  'enajenación': 607,
  'adquisición de bienes': 607,
  'demás ingresos"': 608,
  'demas ingresos"': 608,
  'ingresos"': 608,
  'residentes en el extranjero sin establecimiento permanente en méxico': 610,
  'residentes en el extranjero': 610,
  'extranjero': 610,
  'ingresos por dividendos (socios y accionistas)': 611,
  'por dividendos (socios y accionistas)': 611,
  'dividendos (socios y accionistas)': 611,
  'dividendos': 611,
  'socios y accionistas': 611,
  'socios': 611,
  'accionistas': 611,
  'personas físicas con actividades empresariales y profesionales': 612,
  'personas físicas': 612,
  'actividades empresariales y profesionales': 612,
  'actividades empresariales': 612,
  'actividades profesionales': 612,
  'empresariales': 612,
  'profesionales': 612,
  'ingresos por intereses': 614,
  'por intereses': 614,
  'intereses': 614,
  'régimen de los ingresos por obtención de premios': 615,
  'regimen de los ingresos por obtención de premios': 615,
  'ingresos por obtención de premios': 615,
  'obtención de premios': 615,
  'premios': 615,
  'sin obligaciones fiscales': 616,
  'incorporación fiscal': 621,
  'incorporacion fiscal': 621,
  'incorporación': 621,
  'incorporacion': 621,
  'régimen de las actividades empresariales con ingresos a través de plataformas tecnológica': 625,
  'regimen de las actividades empresariales con ingresos a través de plataformas tecnológica': 625,
  'con ingresos a través de plataformas tecnológica': 625,
  'ingresos a través de plataformas tecnológica': 625,
  'plataformas tecnológica': 625,
  'plataformas tecnologica': 625,
  'régimen simplificado de confianza': 626,
  'simplificado de confianza': 626,
  'simplificado': 626,
  'confianza': 626,
}

export const $tipoFoto: { [key: number]: string } = {
  0: 'Identificación',
  1: 'Ingreso',
  2: 'Registro Empleado',
  3: 'Registro Reconocimiento Facial'
}

export interface Vacaciones {
  aniosTrabajados: number;
  diasLey: number;
  diasExtra: number;
  diasTomados: number;
  saldoTotal: number;
  saldoRestante: number;
}

export interface VacacionesEmpleado {
  empleado: IEmpleados;
  vacaciones: Vacaciones[];
}
