import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IAccesoPoliticas } from 'src/app/models/incidencias';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-empresa-politicas-incidencias',
  templateUrl: './empresa-politicas-incidencias.component.html',
  styleUrls: ['./empresa-politicas-incidencias.component.scss']
})
export class EmpresaPoliticasIncidenciasComponent implements OnInit {
  public accesoPoliticas!: IAccesoPoliticas;

  public enEdicion: boolean = false;
  public cargando: boolean = environment.production;

  // ngModels
  public toleranciaRetardos: number = 0;
  public salidasComoRetardos: boolean = false;
  public toleranciaHorasExtra: number = 0;
  public toleranciaFaltas: number = 0;
  public penalizarNoEntrada: number = 0;
  public penalizarNoSalida : number = 0;
  public limiteEntrada: number = 0;
  public limiteSalida: number = 0;
  public mostrarHoraReal: boolean = false;
  public cubrirRetardo: boolean = false;
  public ignorarPenalizarNoEntrada: boolean = false;
  public horasOficialPDF: boolean = false;
  public manejaNumeroRetardosComoFalta: boolean = false;
  public numeroRetardosComoFalta: number = 0;
  public exportarFaltas: boolean = false;
  public exportarRetardos: boolean = false;
  public exportarHorasExtra: boolean = false;
  public esDebugger: boolean = false;
  public grupoEmpleados: string[] = [];
  public gruposSeleccionados: string[] = [];

  @Output()
  public aplicarCambiosEvent = new EventEmitter<void>();

  constructor(
    private accesoDatosService: AccesoDatosService
  ) {
    this.cargando = true;
    this.cargarDatos();
  }

  public cargarDatos() {
    this.cargando = true;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.incidenciasPolitica().subscribe(
      (accesoPoliticas: IAccesoPoliticas) => {
        this.accesoPoliticas = accesoPoliticas;
        if (!this.accesoPoliticas.horaMaxCambioJornada) this.accesoPoliticas.horaMaxCambioJornada = 5;
        this.cargando = false;
        this.enEdicion = false;
        this.toleranciaRetardos = accesoPoliticas.toleranciaRetardos;
        this.salidasComoRetardos = accesoPoliticas.salidasComoRetardos;
        this.toleranciaHorasExtra = accesoPoliticas.toleranciaHorasExtra;
        this.toleranciaFaltas = accesoPoliticas.toleranciaFaltas;
        this.penalizarNoEntrada = accesoPoliticas.penalizarNoEntrada
        this.penalizarNoSalida = accesoPoliticas.penalizarNoSalida;
        this.limiteEntrada = accesoPoliticas.limiteEntrada;
        this.limiteSalida = accesoPoliticas.limiteSalida
        this.mostrarHoraReal = accesoPoliticas.mostrarHoraReal;
        this.cubrirRetardo = accesoPoliticas.cubrirRetardo;
        this.ignorarPenalizarNoEntrada = accesoPoliticas.ignorarPenalizarNoEntrada;
        this.horasOficialPDF = accesoPoliticas.horasOficialPDF;
        this.manejaNumeroRetardosComoFalta = accesoPoliticas.manejaNumeroRetardosComoFalta;
        this.numeroRetardosComoFalta = accesoPoliticas.numeroRetardosComoFalta;
        this.exportarFaltas = accesoPoliticas.exportarFaltas;
        this.exportarRetardos = accesoPoliticas.exportarRetardos;
        this.exportarHorasExtra = accesoPoliticas.exportarHorasExtra;
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al cargar la política de incidencias. " + err);
        mostrarSwalError('Error', 'Error al cargar la política de incidencias');
      }
    );

    this.accesoDatosService.empleadosGrupos().subscribe(
      (grupos: string[]) => {
        this.grupoEmpleados = grupos;
      },
      (error) => {
        let errorTxt: string = error.error;
        console.log(`Error al cargar los datos de los grupos de empleados. ${errorTxt}`);
        console.error(error);
      }
    );
  }

  public aplicarCambios() {
    this.cargando = true;
    this.accesoPoliticas.toleranciaRetardos = this.toleranciaRetardos;
    this.accesoPoliticas.salidasComoRetardos = this.salidasComoRetardos;
    this.accesoPoliticas.toleranciaHorasExtra = this.toleranciaHorasExtra;
    this.accesoPoliticas.toleranciaFaltas = this.toleranciaFaltas;
    this.accesoPoliticas.penalizarNoEntrada = this.penalizarNoEntrada;
    this.accesoPoliticas.penalizarNoSalida = this.penalizarNoSalida;
    this.accesoPoliticas.limiteEntrada = this.limiteEntrada;
    this.accesoPoliticas.limiteSalida = this.limiteSalida;
    this.accesoPoliticas.mostrarHoraReal = this.mostrarHoraReal;
    this.accesoPoliticas.cubrirRetardo = this.cubrirRetardo;
    this.accesoPoliticas.ignorarPenalizarNoEntrada = this.ignorarPenalizarNoEntrada;
    this.accesoPoliticas.horasOficialPDF = this.horasOficialPDF;
    this.accesoPoliticas.manejaNumeroRetardosComoFalta = this.manejaNumeroRetardosComoFalta;
    this.accesoPoliticas.numeroRetardosComoFalta = this.numeroRetardosComoFalta;
    this.accesoPoliticas.exportarFaltas = this.exportarFaltas;
    this.accesoPoliticas.exportarRetardos = this.exportarRetardos;
    this.accesoPoliticas.exportarHorasExtra = this.exportarHorasExtra;
    this.accesoDatosService.incidenciasPoliticaGuardar(this.accesoPoliticas).subscribe(
      (respuesta: IRespuestaChecker) => {
        if (respuesta.code == 100) {
          mostrarSwalToast(`Datos guardados correctamente`, 'success');
          this.aplicarCambiosEvent.emit();
          this.cargarDatos();
        } else {
          mostrarSwalToast(`Respuesta del servidor ${respuesta.code}, ${respuesta.mensaje}`, 'error');
        }
      }, (error) => {
        console.error(error);
        let err: string = error.error;
        this.accesoDatosService.logAgrega2("Error al guardar la política de incidencias. " + err);
        mostrarSwalError('Error', 'Error al guardar la política de incidencias');
      }
    );
  }

  public modificarDatos() {
    this.enEdicion = true;
  }

  public deshacerCambios() {
    this.cargarDatos();
  }

  public cambiarSelect(item: string, seleccionados: string[], event: Event) {
    const inputElement = event.target as HTMLInputElement;
    const isChecked = inputElement?.checked;

    if (isChecked) {
      if (!seleccionados.includes(item)) {
        seleccionados.push(item);
      }
    } else {
      const index = seleccionados.indexOf(item);
      if (index > -1) {
        seleccionados.splice(index, 1);
      }
    }
  }

  ngOnInit(): void {}
}
