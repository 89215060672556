<div *ngIf="!existePass" class="row g-3 mx-3 my-2">
  <div class="col-12">
    <p>Por favor, establece una contraseña. Debe tener al menos 6 caracteres y contener solo letras y números.</p>
  </div>

  <div class="col-12">
    <label class="form-label fw-medium">Contraseña</label>
    <input class="form-control" autocomplete="new-password" type="password" placeholder="Ingresa la contraseña" [(ngModel)]="nuevaPass" minlength="6" pattern="[a-zA-Z0-9]*" required>
  </div>

  <div class="col-12">
    <label class="form-label fw-medium">Vuelva a escribir la contraseña</label>
    <input class="form-control" autocomplete="new-password" type="password" placeholder="Reingresa la contraseña" [(ngModel)]="nuevaPass2" (keydown.enter)="verificarCoincidencia()" minlength="6" pattern="[a-zA-Z0-9]*" required>
  </div>

  <div class="col-12">
    <button class="btn btn-primary w-100" (click)="verificarCoincidencia()">Establecer Contraseña</button>
  </div>

  <div *ngIf="passwordNoCoincide" class="col-12 text-danger text-center flicker">
    <p>Las contraseñas no coinciden. Por favor, inténtelo de nuevo.</p>
  </div>

  <div *ngIf="passwordNoValida" class="col-12 text-danger text-center flicker">
    <p>Las contraseñas no cumplen con los caracteres establecidos</p>
  </div>
</div>


<div *ngIf="existePass">
  <div class="my-2 text-center">
    <p class="fs-6 fw-bolder">{{titulo}}</p>
  </div>
  <div class="col-11 mx-3 mb-3">
    <label for="contraseña" class="form-label fw-medium">Contraseña</label>
    <input id="contraseña" class="form-control" autocomplete="new-password" type="password" placeholder="Ingresa la Contraseña" [(ngModel)]="password" (keydown.enter)="verificarPassword()">
  </div>
  <div class="text-center flicker mt-1" *ngIf="mostrarError">
    <b class="text-danger">{{ error }}</b>
  </div>
  <mat-dialog-actions align="end">
    <button mat-stroked-button color="primary" (click)="verificarPassword()">{{mensajeAceptar}}</button>
    <button mat-dialog-close mat-raised-button color="warn">{{mensajeCancelar}}</button>
  </mat-dialog-actions>
</div>
