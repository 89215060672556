<!-- toolbar.component.html -->
<mat-toolbar class="pt-3 shadow-sm p-3 mb-3 bg-body-tertiary rounded d-flex">
  <div>
    <a href="">
      <img class="px-2 img-fluid centro" src="assets/images/ACE_Acceso-Express.png" alt="Empresa" routerLink="" width="150" height="150"/>
    </a>
  </div>
  <div class="d-flex align-items-center text-secondary fs-6 me-auto ms-2 mt-auto">
    <ng-container *ngFor="let crumb of breadcrumbService.breadcrumb$ | async; let last = last">
      <span>{{ crumb }}</span>
      <mat-icon class="text-primary-emphasis mx-2" *ngIf="!last">chevron_right</mat-icon>
    </ng-container>
  </div>
  <div>
    <button class="btn-usuario col-auto ms-auto border border-secondary-subtle centro" mat-button [matMenuTriggerFor]="menu">
      <mat-icon color="navy">account_circle</mat-icon>
      <span class="px-1 d-none d-lg-block blue">{{ empresa?.nombre }}</span>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item routerLink="">
        <mat-icon class="blue">home</mat-icon>
        <span class="px-1 blue">Inicio AccesoExpress</span>
      </button>
      <button mat-menu-item (click)="enviarPerfil()">
        <mat-icon class="blue">person</mat-icon>
        <span class="px-1 blue">Perfil</span>
      </button>
      <button mat-menu-item (click)="ayudaWhats()">
        <mat-icon class="blue">help</mat-icon>
        <span class="px-1 blue">Ayuda</span>
      </button>
      <button mat-menu-item (click)="cerrarSesion()">
        <mat-icon class="text-danger">logout</mat-icon>
        <span class="px-1 blue">Cerrar sesión</span>
      </button>
      <div class="border-bottom border-secondary-subtle"></div>
      <div class="d-flex justify-content-center" mat-menu-item *ngIf="esDebugger" style="min-height: 25px;">
        <small class="text-muted">Tipo de empleado: {{ tipoEmpleado }} - {{ obtenerNombreTipoEmpleado() }}</small>
      </div>
      <div class="d-flex justify-content-center" mat-menu-item style="min-height: 25px;">
        <small class="px-5 text-muted">v. {{ accesoExpress.version }}</small>
      </div>
    </mat-menu>
  </div>
</mat-toolbar>
