<div *ngIf="cargando">
  <div class="text-center py-5">
      <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div class="m-3" *ngIf="!cargando">
  <div class="col-12">
    <div class="mb-4">
      <i class="material-icons mr-1">  date_range </i>
      Horarios por día
    </div>
  </div>
  <div class="row mx-auto mb-3">
    <div class="col-md-3">
      <button class="button btn-perso" (click)="periodoAnterior()">{{ textoFiltro }} anterior</button>
    </div>
    <div class="col-md-6 text-center">
      <h4 id="current-week">{{ semanaActualTitulo }}</h4>
    </div>
    <div class="col-md-3 d-flex justify-content-end">
      <button class="button btn-perso" (click)="periodoSiguiente()">{{ textoFiltro }} siguiente</button>
    </div>
  </div>
  <div class="d-flex row mx-auto">
    <div class="radio-inputs col-lg-3 d-flex justify-content-around">
      <label class="radio">
        <input type="radio" name="radio" checked="">
        <span class="name" (click)="filtrarPorSemana()">Semana</span>
      </label>
      <label class="radio">
        <input type="radio" name="radio">
        <span class="name" (click)="filtrarPorQuincena()">Quincena</span>
      </label>
      <label class="radio">
        <input type="radio" name="radio">
        <span class="name" (click)="filtrarPorMes()">Mes</span>
      </label>
    </div>
    <div class="col-lg-4 d-flex justify-content-center">
      <button class="button" (click)="periodoActualBtn()">{{ textoFiltro }} actual</button>
    </div>
    <div class="col-lg-2 d-flex justify-content-center">
     <button class="button" (click)="cambiarFormatoHoras()">{{ formatoHoraBtn }}</button>
    </div>
    <div class="d-flex justify-content-end col-lg-3">
      <button class="button" (click)="habilitarEdicion()">{{ textoBoton }}</button>
    </div>
  </div>
  <hr />
  <div id="calendar" class="table-responsive tabla-scroll">
    <table class="table table-striped table-hover text-center">
      <thead class="thead-light">
        <tr>
          <th colspan="3"></th>
          <th *ngIf="campoDeEntrada !== '' && habilitar" class="border-left"></th>
          <th scope="col" class="border-right border-left" colspan="2" *ngFor="let dia of diasSemana">{{ dia.nombre }}</th>
        </tr>
        <tr>
          <th colspan="3"></th>
          <th *ngIf="campoDeEntrada !== '' && habilitar" class="border-left"></th>
          <th scope="col" class="border-right border-left" colspan="2" *ngFor="let dia of diasSemana">{{ dia.numero }}</th>
        </tr>
        <tr>
          <th colspan="3"></th>
          <th *ngIf="campoDeEntrada !== '' && habilitar" class="border-left"></th>
          <ng-container *ngFor="let entrada of entradas">
            <th scope="col" class="border-right border-left">Entrada</th>
            <th scope="col" class="border-right">Salida</th>
          </ng-container>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let empleado of empleados; let empleadoIndex = index">
          <td scope="row" colspan="3" class="min-vw-20 border-right"> <!-- [ngClass]="{ 'min-vw-0': this.filtro === 'semana'}"-->
            <small>{{ empleado.nombre }}</small>
          </td>
          <td *ngIf="campoDeEntrada !== '' && habilitar" class="border-right">
            <button class="btn btn-default" (click)="openModal(template)">
              <i class="material-icons mr-1" *ngIf="empleadoActual == empleadoIndex">navigate_next</i>
            </button>
            <!-- ==================================== Modal ==================================== -->
            <ng-template #template>
              <div class="modal-header">
                <h3>Aplicar horarios</h3>
              </div>
              <div class="modal-body">
                <form>
                  <div>
                    <p>El horario se aplicara según la opción que seleccione</p>
                  </div>
                  <div class="input-group mb-3">
                    <div class="input-group-prepend">
                      <label class="input-group-text" for="opcion">Selecciona una opción:</label>
                    </div>
                    <select class="custom-select" name="opcion" id="opcion" [(ngModel)]="opcionSeleccionadaValue">
                      <option *ngFor="let opcion of opciones" [value]="opcion">{{ opcion }}</option>
                    </select>
                  </div>
                  <div class="pt-2 d-flex justify-content-end">
                    <button class="button btn-perso mr-3" type="submit" (click)="enviarOpcion(opcionSeleccionadaValue)">Guardar</button>
                    <button class="button btn-cancelar" (click)="modalRef?.hide()">Cancelar</button>
                  </div>
                </form>
              </div>
            </ng-template>
          </td>
          <!-- <td class="border-right border-left" *ngFor="let dato of filaDatos; let entradaIndex = index" [attr.colspan]="esDescanso ? 2 : 1">
            <ng-container *ngIf="!esDescanso; else descansoInput">
              <timepicker
                [showSpinners]="false"
                [showMeridian]="formatoHoras"
                (input)="actualizarCampoDeEntrada(empleadoIndex, $event.target.value)"
                [disabled]="!habilitar"
                (change)="habilitar ? guardarValor(empleadoIndex, entradaIndex, filaDatos[entradaIndex].valor) : null"
                (keydown)="verificarDescanso($event)">
              </timepicker>
            </ng-container>
            <ng-template #descansoInput>
              <input id="inputDescanso" type="text" (input)="verificarDescanso($event)" [disabled]="!habilitar" [(ngModel)]="inputDescanso">
            </ng-template>
          </td> -->

          <!-- <ng-container>
            <td class="border-right border-left" *ngFor="let dato of filaDatos; let entradaIndex = index">
              <timepicker
                [showSpinners]="false"
                [showMeridian]="formatoHoras"
                (input)="actualizarCampoDeEntrada(empleadoIndex, $event.target.value)"
                [disabled]="!habilitar"
                (change)="habilitar ? guardarValor(empleadoIndex, entradaIndex, filaDatos[entradaIndex].valor) : null">
              </timepicker>
            </td>
          </ng-container> -->
        </tr>
      </tbody>
    </table>
  </div>
</div>
