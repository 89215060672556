import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit } from '@angular/core';
import { Ahora, CVFechaT, TipoDateDiff, ValidarHora, addDays, addHours, addSeconds, dateDiff, dateDiffAhora, fFecha, getFechaT, obtenerValorFecha } from '../../Funciones/fFecha';
import { AccessHubService, IConnectionState, SecretValidation, TimeToken } from '../../services/accessHubService';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PasswordComponent } from '../password/password.component';
import { AccesoExpress, OpcionesFecha, validarReconocimiento, verificarReconocimientoFacial } from 'src/app/models/accesoExpress';
import { ManejoDescarga, NomiExpressApiService } from '../../services/NomiExpress.api.service';
import { ConfiguraBasicaComponent } from '../configura-basica/configura-basica.component';
import { fechaT, fechaUTC, sha256 } from '../../Funciones/fTexto';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { forkJoin, Observable, of, Subject, Subscription } from 'rxjs';
import { ITokenAccess } from 'src/app/models/tokenAccess';
import { ICodigoQr } from 'src/app/models/codigoQr';
import { IRespuestaChecker, IRespuestaCheckerEmpleado, IRespuestaEmpleadoListadoImagenesCF, IRespuestaEmpleadosListadoImagenesCF, IResultadoActualiza } from 'src/app/models/resultadoActualiza';
import { IEmpleados, TipoEmpleado, nombreEmpleado, nombreEmpleadoCorto, nuevoEmpleado } from 'src/app/models/empleados';
import { IAcceso, ITokenValidation } from 'src/app/models/checks';
import { getToken, getTokenCodeTimeComponents, getUuid } from '../../Funciones/funciones';
import { ActivatedRoute, Router } from '@angular/router';
import { ConfiguraReconocimientoComponent } from '../configura-reconocimiento/configura-reconocimiento.component';
import { WebcamImage } from 'ngx-webcam';
import { ConfigAvanzadaComponent } from '../config-avanzada/config-avanzada.component';
import { ReconocimientoCFService } from '../../services/reconocimiento-cf.service';
import { AccesoCF_RespuestaRecognize_Respuesta, AccesoCF_RespuestaRecognize_Subjects } from 'src/app/models/accesoCF';
import { AccesoDatosService } from '../../services/acceso-datos.service';
import { HttpError } from '@microsoft/signalr';
import { TipoOrigen, TipoRevisarImagenContraRegistro } from 'src/app/models/datosEmpleadosAcceso';
import { Dimensiones, getImageDimensions } from '../../Funciones/imagen';
import { fork } from 'child_process';
import { mergeMap, retry } from 'rxjs/operators';
import { IEmpresa } from 'src/app/models/empresa';
import { now } from 'moment';

@Component({
  selector: 'app-acceso',
  templateUrl: './acceso.component.html',
  styleUrls: ['./acceso.component.scss']
})
export class AccesoComponent implements OnInit, AfterViewInit, OnDestroy {
  public accesoExpress: AccesoExpress;
  public $offset: number = 0;
  public idConexionPuntoAcceso: string = '';
  public estaEscuchando: boolean = false;
  public buscandoConexion: boolean = false;
  public terminalTime: string = '';
  public connectionState: IConnectionState;
  public subscriptions = new Subscription();
  private _codeLoop: any = undefined;
  public mensaje: string = '';
  public error: string = '';
  public renovar: string = '';
  public datosActualizados: Date = new Date(1900, 0, 0);
  public inicioApp: Date = new Date();
  public mensajeAddInicioApp: string = '';

  // Datos de la empresa
  public empleados: IEmpleados[] = [];
  public codigosQR: ICodigoQr[] = [];
  private empleadosListadoImágenesCF: { [empleado: number]: string[] } = {};

  // Datos de la cámara para reconocimiento facial
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  public facingMode: string = '-'; // 'user'; // 'environment'
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  private _buscarRostro: any = undefined;
  private tipoChecada: string = '-';

  public camaraAncho: number = 100;
  public camaraAlto: number = 100;

  public infoAdd: string | undefined = undefined;
  public infoCamara: string = 'No se reconocen rostros en los datos';
  private _inicioReconocimientoFacial: Date = new Date(1900, 1, 1);
  private _ultimoAcceso: Date = new Date(1900, 1, 1);
  private _ultimaFoto: Date = new Date();
  private ultimaFoto: string | undefined = undefined;
  private datosReconocimiento: AccesoCF_RespuestaRecognize_Respuesta | undefined = undefined;

  public idEmpleadoActivo: string | undefined = undefined;
  public idEmpleadoRegistrado: string | undefined = undefined;
  public empleadoActivo: IEmpleados | undefined = undefined;
  public vecesRegistrado: number = 0;

  // Datos del qr
  public qrAccessLeft: number = 0;
  public qrAncho: number = 300;
  public codigoQR: string = '-';
  public valorFecha: Date = new Date();

  public mostrarFaceID: boolean = false;
  public volumenOn: boolean = true;
  public audio = new Audio('assets/images/notificacion.mp3');
  public proceso: string = 'Cargando código QR...';
  public esPantallaCompleta: boolean = false;
  public iconoCamara: string = 'no_photography';
  public iconoVolumen: string = 'volume_up';
  public modoAdmin: number = 0;
  public modoFullDebug: boolean = !environment.production;
  public revisarOrdenAscendente: boolean = true;

  constructor(
    private el: ElementRef,
    private _accessHubService: AccessHubService,
    private _httpClient: HttpClient,
    private nomiExpressApi: NomiExpressApiService,
    private accesoDatosServicio: AccesoDatosService,
    private reconocimientoCFApi: ReconocimientoCFService,
    private _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private dialog: MatDialog
  ) {
    this.proceso = 'Inicializando aplicación...'
    this.accesoDatosServicio.cerrarServicioDatosEmpresa();
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.accesoExpress.debug = this.accesoExpress.debug || !environment.production;
    if (!this.accesoExpress.opcionesFecha) this.accesoExpress.opcionesFecha = 0;

    this._accessHubService.connect();
    this.connectionState = this._accessHubService.connectionState;
  }

   public pantallaCompleta() {
     this.esPantallaCompleta = !this.esPantallaCompleta;
     const elem = this.el.nativeElement;

     if (elem.requestFullscreen) {
       elem.requestFullscreen();
     } else if (elem.mozRequestFullScreen) { // Firefox
       elem.mozRequestFullScreen();
     } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
       elem.webkitRequestFullscreen();
     } else if (elem.msRequestFullscreen) { // Internet Explorer and Edge
       elem.msRequestFullscreen();
     }
   }

  public salirPantallaCompleta() {
    this.esPantallaCompleta = !this.esPantallaCompleta;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  public abrirConfig() {
    const password = this.dialog.open(PasswordComponent, {
      data: 'Acceso a configuración',
      width: '33rem',
      height: 'auto'
    });

    password.componentInstance.verificarPasswordEvent.subscribe((resultado: string) => {
      if (!resultado) return;
      if (resultado !== 'verificadaPwd') return;

      this.abrirConfig2(resultado);
    });

    password.afterClosed().subscribe((resultado) => {
      if (!resultado) return;
    });
  }

  private async abrirConfig2(password: string | undefined) {
    if (!password) return;

    if (password === 'modoAdmin') {
      if (!this.idEmpleadoActivo) {
        console.log(`modoAdmin --> idEmpleadoActivo nulo`);
        return;
      }
      if (this.modoAdmin != 1) {
        console.log(`modoAdmin --> no está en modo admin ${this.modoAdmin}`);
        return;
      }

      let empleado: IEmpleados | undefined = this.empleados.find(x => x.id == this.idEmpleadoActivo);
      if (!empleado) {
        console.log(`modoAdmin --> Empleado nulo`);
        return;
      }
      if (empleado.tipoEmpleado != TipoEmpleado.Supervisor && empleado.tipoEmpleado != TipoEmpleado.Administrador) {
        console.log(`modoAdmin --> el empleado no es administrador`);
        return;
      }
      this.idEmpleadoActivo = undefined;
      this.modoAdmin = 0;
      password = 'verificadaPwd';
    }

    const config = this.dialog.open(ConfiguraBasicaComponent, {
      data: undefined,
      width: '45rem',
      height: '21rem',
    });

    config.afterClosed().subscribe((resultado) => {
      this.accesoExpress = this.nomiExpressApi.accesoExpress();
      switch (resultado) {
        case 'inicio-empresa':
          if (environment.production) return;
          this.desactivarReconocimientoFacial();
          let empleado: IEmpleados = nuevoEmpleado();
          empleado.id = '999';
          empleado.tipoEmpleado = TipoEmpleado.Sistemas;
          this.accesoDatosServicio.setEmpleadoActual(empleado);
          break;
        case 'avanzada':
          this.abrirConfigAvanzada();
          break;
        case 'ConfiguraReconocimientoFacial':
          this.abrirConfiguraReconocimientoFacial();
          break;
        case 'ConfigAvanzada':
          this.abrirConfigAvanzada();
          break;
      }
    });
  }

  private abrirConfigAvanzada() {
    const config = this.dialog.open(ConfigAvanzadaComponent, {
      data: undefined,
      width: '50rem',
      height: '37rem',
    });

    config.afterClosed().subscribe((resultado) => {
      if (resultado && resultado == 'Guardado') {
        this.accesoExpress = this.nomiExpressApi.accesoExpress();
        this.reiniciar('Guardando configuración');
      }
      if (resultado && resultado == 'log') {
        this.ponerTxtEnConsola('Abriendo Log');
        this._router.navigate(['log']);
      }
    });
  }

  private reiniciar(logTxt: string) {
    this.nomiExpressApi.logAgrega2(logTxt);
    setTimeout(() => {
      this.nomiExpressApi.logAgrega2(logTxt);
      window.location.reload();
    }, 2000);
  }

  private abrirConfiguraReconocimientoFacial() {
    const config = this.dialog.open(ConfiguraReconocimientoComponent, {
      data: undefined,
      width: 'auto',
      height: 'auto',
    });

    config.afterClosed().subscribe((resultado) => {
      if (resultado && resultado == 'Guardado') {
        this.accesoExpress = this.nomiExpressApi.accesoExpress();
        this.reiniciar('Guardando configuración');
      }
      if (resultado && resultado == 'log') {
        this.ponerTxtEnConsola('Abriendo Log');
        this._router.navigate(['log']);
      }
    });
  }

  public quitarRenovacion() {
    const passwordDialog = this.dialog.open(PasswordComponent, {
      data: 'Quitar aviso de renovación por este día',
      width: '33rem',
      height: 'auto'
    });

    passwordDialog.componentInstance.verificarPasswordEvent.subscribe(async (resultado: string) => {
      if (!resultado) return;
      // resultado = await this.verificaPwd(resultado);
      if (resultado !== 'verificadaPwd') return;
      this.renovar = '';
    });

    passwordDialog.afterClosed().subscribe(async (resultado) => {
      if (!resultado) return;
      // resultado = await this.verificaPwd(resultado);
      if (resultado !== 'verificadaPwd') return;
      this.renovar = '';
    });
  }

  public mostrarFaceIdClick() {
    if (!this.accesoExpress.isActive || !this.accesoExpress.faceDetectionEnabled) {
      this.mostrarFaceID = false;
    } else {
      this.mostrarFaceID = !this.mostrarFaceID;
      this.accesoExpress.faceDetectionActive = this.mostrarFaceID;
      this.nomiExpressApi.guardarAccesoExpress(this.accesoExpress);
    }

    this.qrAncho = this.mostrarFaceID ? 200 : 300;
    if (this.mostrarFaceID) {
      this.iconoCamara = 'photo_camera';
      this.ponerTxtEnConsola('Reinicializando la cámara');
      this.infoCamara = 'Reinicializando la cámara';
      this.onResize();
      this.desactivarReconocimientoFacial();
      this.ponerTxtEnConsola('Reinicializando video elemento');
      this.reiniciar('Reinicializando la cámara');
      return;
    }
    this.iconoCamara = 'no_photography';
    this.infoCamara = 'Desactivando la cámara';
    this.desactivarReconocimientoFacial();
    this.reiniciar('Desactivando la cámara');
  }

  public onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    const esVertical: boolean = win.innerHeight > win.innerWidth;

    if (this.mostrarFaceID) {
      var codigoQrDiv = document.getElementById('codigoQrDiv') as HTMLElement;
      var rectCodigoQrDiv = codigoQrDiv.getBoundingClientRect();
      if (rectCodigoQrDiv) {
        let codigoQrDivIzq = rectCodigoQrDiv.left;
        let codigoQrDivAlto = rectCodigoQrDiv.height;

        var cámaraDiv = document.getElementById('codigoQrDiv') as HTMLElement;
        var rectCámaraDiv = cámaraDiv.getBoundingClientRect();
        let cámaraDivAncho = rectCámaraDiv.width || 0;

        // console.log(`codigoQrDivIzq: ${codigoQrDivIzq}, codigoQrDivAlto: ${codigoQrDivAlto}, cámaraDivAncho: ${cámaraDivAncho}, cámaraDivAlto: ${cámaraDivAlto}, win.innerWidth: ${win.innerWidth}, win.innerHeight: ${win.innerHeight}`);
        if (!this.accesoExpress.webCamScale || this.accesoExpress.webCamScale == null || this.accesoExpress.webCamScale < 0 || this.accesoExpress.webCamScale > 1) this.accesoExpress.webCamScale = 0.85;
        let maxAncho: number = cámaraDivAncho > codigoQrDivAlto ? cámaraDivAncho : codigoQrDivAlto;
        maxAncho = maxAncho > (codigoQrDivIzq * this.accesoExpress.webCamScale) ? maxAncho : (codigoQrDivIzq * this.accesoExpress.webCamScale);
        if (esVertical) maxAncho *= 0.9;

        this.camaraAncho = maxAncho; // win.innerWidth -
        this.camaraAlto = maxAncho; // win.innerHeight > codigoQrDivAlto ? codigoQrDivAlto : win.innerHeight;
        this.qrAncho = this.camaraAncho * 0.4;
        // console.log(`camaraAncho: ${this.camaraAncho}, camaraAlto: ${this.camaraAlto}, esVertical: ${esVertical}, qrAncho: ${this.qrAncho}`);
      }

      // reinicia la posición del canvas
      this._inicioReconocimientoFacial = this.Now();
  }

    var qrAccessElement = document.getElementById('qr-access') as HTMLElement;
    this.qrAccessLeft = qrAccessElement.getBoundingClientRect().left;
  }

  private async processScanToken(checadaServidor: TimeToken) {
    if (!checadaServidor.token) return;

    let now: Date = this.Now();
    this._ultimoAcceso = now;
    let tokenId = checadaServidor.token.substring(0, checadaServidor.token.length - 3);
    var shortId = parseInt(tokenId, 16);
    if (this.accesoExpress.debug) this.ponerTxtEnConsola(`processScanToken ==> shortId (${tokenId}) ${shortId} + ${this.$offset}}`);

    if (this.codigosQR.length == 0 || this.codigosQR.length < 1) {
      this.ponerTxtEnConsola('Parece que no ha sido posible descargar la información de los empleados desde el servidor.');
      return;
    }

    let codigoQr: ICodigoQr | undefined = this.codigosQR.find(x => x.idCorto == shortId);
    if (!codigoQr || !codigoQr.id) {
      // algo anda mal, se repitieron los códigos, o el empleado no está registrado...
      let error =  'Parece que tenemos un problema con el teléfono registrado, por favor intenta de nuevo, si ' +
        'el problema persiste, pide un nuevo código de acceso en la empresa a la que estas accediendo.';
      this.nomiExpressApi.logAgrega(error);
      this.ponerError(error);
      return;
    }

    let empleado: IEmpleados | undefined = this.empleados.find(x => x.id == codigoQr?.id);
    if (!empleado) {
      this.ponerTxtEnConsola(`processScanToken ==> getMany ==> _api.employees.getOne (${codigoQr.id}) - error: No fue posible localizar el empleado seleccionado`);
      this.ponerError(`No fue posible localizar el empleado seleccionado`);
      return;
    }

    this.idEmpleadoActivo = empleado.id;
    this.vecesRegistrado = 1;
    this.tipoChecada = 'qr';
    this.tomarFoto();

    if (this.$offset === 0) {
      let tokenValidation = this.validarChecadaEmpleado(codigoQr, checadaServidor);
      if (tokenValidation.type == 'error') {
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`processScanToken ==> getMany ==> _api.employees.getOne (${codigoQr.id}) ==> validateEmployeeTimeToken - error`);
        if (!checadaServidor.replyTo)
          this.ponerError(tokenValidation.message);
        else
          this._httpClient.post(`${environment.hubUrl}/access/reply`, { error: tokenValidation.message }).subscribe();
        return;
      }
    }

    // validamos que el qr esté autorizado por el usuario.
    if (checadaServidor.replyTo) {
      let c = checadaServidor.connectionId.split('{remote}');
      let k = await sha256(checadaServidor.replyTo.toLocaleLowerCase() + this.accesoExpress.localSecret.toLocaleLowerCase());
      let u = c[1];
      if (k != u) {
        // firma incorrecta
        if (this.accesoExpress.debug) {
          this.ponerTxtEnConsola(`processScanToken ==> getMany ==> _api.employees.getOne (${codigoQr.id}) ==> connectionId + remote`);
          // console.log(txt, k, u);
        }
        return;
      }
    } else {
      let c = checadaServidor.connectionId.split('{local}');
      let k = await sha256(this.connectionState.id.toLocaleLowerCase() + this.accesoExpress.localSecret.toLocaleLowerCase());
      let u = c[1];
      if (k != u) {
        // firma incorrecta
        if (this.accesoExpress.debug) {
          this.ponerTxtEnConsola(`processScanToken ==> getMany ==> _api.employees.getOne (${codigoQr.id}) ==> connectionId + local`);
          // console.log(txt, k, u);
        }
        return;
      }
    }

    this.checadaEmpleado(empleado, now, !!checadaServidor.replyTo, TipoOrigen.codigoQr, 0);

    var replay = {
      name: nombreEmpleadoCorto(empleado),
      date: now,
      replyTo: checadaServidor.replyTo
    };
    if (this.accesoExpress.debug) {
      console.dir(`serverCheck.replyTo`, replay);
    }

    if (checadaServidor.replyTo) {
      this._httpClient.post(`${environment.hubUrl}/access/reply`, replay).subscribe();
    }
  }

  private validarChecadaEmpleado(codigoQr: ICodigoQr, checadaServidor: TimeToken): ITokenValidation {
    this.ponerTxtEnConsola(`ValidarChecadaEmpleado en Acceso.Component`);
    let now = this.Now();
    let serverTime: Date = ValidarHora(new Date(checadaServidor.serverTime), this.accesoExpress.opcionesFecha);
    const token = getToken(checadaServidor.code, codigoQr.idCorto || 0, codigoQr.llave || 0);

    let secondsDif = Math.abs(serverTime.getTime() - now.getTime()) / 1000;
    if (secondsDif >  (this.accesoExpress.debug ? 120 : 60) && this.accesoExpress.checkServerTime && this.accesoExpress.offSetFromServer == 0 && Math.abs(secondsDif) < 200) {
      this.accesoExpress.offSetFromServer = secondsDif;
      now = this.Now();
      secondsDif = Math.abs(serverTime.getTime() - now.getTime()) / 1000;
    }
    if (secondsDif >  (this.accesoExpress.debug ? 120 : 60)) {
      // el envío del servidor al cliente duró más de 30 segundos???
      // es posible que la hora de la computadora local esté mal
      // rechazar y volver a intentar

      if (this.accesoExpress.debug) {
        this.ponerTxtEnConsola(`validateEmployeeTimeToken ==> Parece que la hora de la terminal es incorrecta, por favor verifica que la hora en la terminal sea correcta e intenta de nuevo. ${secondsDif}`);
      }

      return {
        message:
          'Parece que la hora de la terminal es incorrecta, por favor verifica que la hora en la terminal sea correcta e intenta de nuevo.',
        type: 'error'
      };
    }

    const codeComponents = getTokenCodeTimeComponents(checadaServidor.code);
    if (!codeComponents.isValid) {
      // código no válido
      if (this.accesoExpress.debug) {
        this.ponerTxtEnConsola(`validateEmployeeTimeToken ==> Código inválido o caducado, intenta de nuevo`);
        // console.log(txt, codeComponents);
      }
      return {
        message: 'Código inválido o caducado, intenta de nuevo.',
        type: 'error'
      };
    }

    var nowInMinutes = now.getHours() * 60 + now.getMinutes() + now.getSeconds() / 60;
    var codeInMinutes = codeComponents.h * 60 + codeComponents.m + codeComponents.s / 60;
    if (Math.abs(nowInMinutes - codeInMinutes) > (checadaServidor.replyTo ? 1 : 0.5) ) {
      // el código qr ya caducó, fue generado hace mas de medio minuto (30 secs)
      if (this.accesoExpress.debug) {
        this.ponerTxtEnConsola(`validateEmployeeTimeToken ==> Código inválido o caducado, intenta de nuevo (2), ${nowInMinutes}, ${codeInMinutes}`);
      }
      return {
        message: 'Código inválido o caducado, intenta de nuevo.',
        type: 'error'
      };
    }

    if (token.toUpperCase() != checadaServidor.token.toUpperCase()) {
      // no pasó la validación por seguridad.
      if (this.accesoExpress.debug) this.ponerTxtEnConsola(`validateEmployeeTimeToken ==> Código inválido o caducado, intenta de nuevo (3), ${token}, ${checadaServidor.token}`);
      return {
        message: 'Código inválido o caducado, intenta de nuevo.',
        type: 'error'
      };
    }

    return { type: "checkIn", message: 'ok' };
  }

  public fFecha(fecha: Date): string {
    return fFecha(fecha, 'fmh');
  }

  private Now(): Date {
    // $ offset es una variable global para mover la hora del reloj checador.
    // this.ponerTxtEnConsola(`Now en Acceso.Component`);
    let opcionesFecha: OpcionesFecha = OpcionesFecha.Normal;
    if (!this.accesoExpress || !this.accesoExpress.opcionesFecha) {
      // this.ponerTxtEnConsola(`Now en Acceso.Component. sin opcionesFecha`);
      this.accesoExpress = this.nomiExpressApi.accesoExpress();
    }
    if (!!this.accesoExpress) {
      // this.ponerTxtEnConsola(`Now en Acceso.Component. sin opcionesFecha (2)`);
      opcionesFecha = this.accesoExpress.opcionesFecha;
    }
    // this.ponerTxtEnConsola(`Now en Acceso.Component. Op Hora: ${opcionesFecha}`);
    let now: Date = Ahora(opcionesFecha);
    if (this.accesoExpress && this.accesoExpress.offSetFromServer && this.accesoExpress.offSetFromServer != 0) {
      if (this.accesoExpress.offSetFromServer >= -200 && this.accesoExpress.offSetFromServer <= 200)
        now = addSeconds(Ahora(this.accesoExpress.opcionesFecha), this.accesoExpress.offSetFromServer);
    }
    // this.ponerTxtEnConsola(`Now en Acceso.Component (2)`);
    return addHours(now, this.$offset || 0);
  }

  private async creaCodigo() {
    // currentTimeStamp
    this.valorFecha = this.Now();
    if (!this.connectionState.id || this.connectionState.id == ':)') {
      this.codigoQR = '-';
      return;
    }
    this.validarMinutosParaRecargar();
    this.codigoQR = `${this.connectionState.id}{local}${await sha256(this.connectionState.id.toLocaleLowerCase()
        + this.accesoExpress.localSecret.toLocaleLowerCase())}.`
        + `${this.accesoExpress.companyId}.${obtenerValorFecha(this.valorFecha)}`;
  }

  private validarMinutosParaRecargar() {
    if (!this.accesoExpress.minutosParaRecargar || this.accesoExpress.minutosParaRecargar < 5) {
      this.mensajeAddInicioApp = '+';
      return;
    }
    let minutosActivo: number = dateDiffAhora(this.inicioApp, TipoDateDiff.minutos);
    if (minutosActivo < 5) {
      if (minutosActivo < 1) return;
      this.mensajeAddInicioApp = ' (1+)';
      return;
    }
    if (Math.floor(minutosActivo) % 5 == 0) {
      this.mensajeAddInicioApp = ` (${Math.floor(minutosActivo / 5) * 5}+)`;
    }

    if (minutosActivo > this.accesoExpress.minutosParaRecargar) {
      this.reiniciar('Reiniciando la aplicación por la configuración de recarga de la aplicación');
    }

  }

  private siguienteCodigo = () => {
    this.creaCodigo();
    let nextCodeCount = 4000 + Math.random() * 6000;
    setTimeout(this.siguienteCodigo, nextCodeCount);
  };

  private _inicializarReconocimientoFacial = () => {
    this._inicioReconocimientoFacial = new Date();
    this._ultimaFoto = new Date(1900, 1, 1);
    this.error = '';
    this.mensaje = '';
    this.ponerTxtEnConsola(`>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> inicializarReconocimientoFacial. ${fFecha(this._inicioReconocimientoFacial, 'fmhs')}`);
    this.mostrarFaceID = true;
    this.idEmpleadoActivo = undefined;
    this.idEmpleadoRegistrado = undefined;
    this.vecesRegistrado = 0;
    this._buscarRostro = setTimeout(() => { this.tomarFoto(); }, 2000);
  }

  private ponerError(error: string) {
    this.error = error;
    this.mensaje = '';
    setTimeout(() => { this.error = '' }, 2000);
  }

  private ponerAdvertencia(advertencia: string) {
    this.error = advertencia;
    this.mensaje = '';
  }

  public cambiarSonido() {
    this.volumenOn = !this.volumenOn;
    if (this.volumenOn) {
      this.iconoVolumen = 'volume_up';
    } else {
      this.iconoVolumen = 'volume_off';
    }
  }

  public ponerMensaje(mensaje: string) {
    this.mensaje = mensaje;
    this.error = '';
    if (this.volumenOn) {
      this.audio.play();
    } else {
      this.audio.pause();
    }
    setTimeout(() => { this.mensaje = '' }, 5000);
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.nomiExpressApi.logAgrega(txt);
  }

  private tomarFoto() {
    this.ultimaFoto = undefined;
    this.datosReconocimiento = undefined;
    this.validarMinutosParaRecargar();
    if (this.infoCamara == 'Sin rostro' || this.infoCamara.includes('Imagen demasiado pequeña')) this.infoCamara = '';
    if (!this.mostrarFaceID) {
      this.desactivarReconocimientoFacial();
      return;
    }
    if (this.tipoChecada == 'qr' && !this.idEmpleadoActivo) this.tipoChecada = '-';
    if (this.tipoChecada != 'br') this.tipoChecada = 'br'; // buscar rostro
    if (this.accesoExpress.debug) {
      if (this.tipoChecada == 'qr') {
        this.ponerTxtEnConsola(`tomarFoto. trigger. IdEmpleado: ${this.idEmpleadoActivo} - ${this.tipoChecada}`);
      }
    }
    if (dateDiffAhora(this._ultimaFoto, TipoDateDiff.segundos) >= 1.2 || (!!this.idEmpleadoActivo && this.vecesRegistrado < this.accesoExpress.vecesPorVerificar)) {
      this.trigger.next(void 0);
    }
    this._buscarRostro = setTimeout(() => { this.tomarFoto(); }, 1250);
  }

  private validarFotoDesconocida(foto64: string) {
    this.infoCamara = 'Reconociendo empleado';
    let idEmpleadoDesconocido = 0;
    // this.infoAdd += `, validando online`;
    this.reconocimientoCFApi.empleadoReconocer(foto64).subscribe(
      async (respuesta: AccesoCF_RespuestaRecognize_Respuesta) => {
        idEmpleadoDesconocido = +respuesta.subjects.subject;
        let similitud: number = respuesta.subjects.similarity;
        if (similitud < this.accesoExpress.validarReconocimiento || idEmpleadoDesconocido <= 0) {
          return;
        }

        let dimensiones: Dimensiones = new Dimensiones();
        try {
          dimensiones = await getImageDimensions('data:image/jpg;base64,' + foto64);
        } catch {
          console.log(`No se pudieron obtener datos de la imagen seleccionada`);
        }
        let imageWidth: number =  dimensiones.ancho == 0 ? this.camaraAncho : dimensiones.ancho;
        let imageHeight: number = dimensiones.alto == 0 ? this.camaraAlto : dimensiones.alto;

        let box_x: number = 0;
        let box_y: number = 0;
        if (!!respuesta.box) {
          box_x = respuesta.box.x_max - respuesta.box.x_min;
          box_y = respuesta.box.y_max - respuesta.box.y_min;
        }
        // if (!environment.production) console.log(`empleadoReconocer --> similarity`, respuesta.subjects, respuesta.box);

        if (box_x > 0) {
          let prop: number = box_x / imageWidth;
          if (prop < this.accesoExpress.validarPorcentajeImagen || box_x < 100) {
            let txt: string = `Demasiado lejos.`;
            if (!environment.production || this.accesoExpress.debug) {
              prop *= 100;
              txt += ` x --> ${prop.toFixed(2)}%`;
            }
            this.infoCamara = txt;
            return;
          }
        }
        if (box_y > 0) {
          let prop: number = box_y / imageHeight;
          if (prop < this.accesoExpress.validarPorcentajeImagen || box_y < 100) {
            let txt: string = `Demasiado lejos.`;
            if (!environment.production || this.accesoExpress.debug) {
              prop *= 100;
              txt += ` y --> ${prop.toFixed(2)}%`;
            }
            this.infoCamara = txt;
            return;
          }
        }

        if (respuesta.box.x_max > 0 && this.accesoExpress.manejaCentradoEnAcceso) {
          let x1: number = respuesta.box.x_min;
          let x2: number = imageWidth - respuesta.box.x_max;
          let diferenciaActualX: number = Math.abs(x1 - x2);
          let diferenciaMaxX: number = (imageWidth - box_x)  * 0.5;

          let y1: number = respuesta.box.y_min;
          let y2: number = imageHeight - respuesta.box.y_max;
          let diferenciaActualY: number = Math.abs(y1 - y2);
          let diferenciaMaxY: number = (imageHeight - box_y) * 0.6;

          if ((diferenciaActualX > diferenciaMaxX) || (diferenciaActualY > diferenciaMaxY)) {
            console.log(`diferenciaActualX: ${diferenciaActualX}, diferenciaMaxX: ${diferenciaMaxX}, x1: ${x1}, x2 ${x2}, imageWidth: ${imageWidth}, box.x_max: ${respuesta.box.x_max}`);
            console.log(`diferenciaActualY: ${diferenciaActualY}, diferenciaMaxY: ${diferenciaMaxY}, y1: ${y1}, y2 ${y2}, imageHeight: ${imageHeight}, box.y_max: ${respuesta.box.y_max}`);
            let txt: string = `La imagen no esta centrada.`;
            if (!environment.production) {
              txt += `        x1: ${x1}, x2: ${x2}, w: ${box_x}, dm: ${diferenciaMaxX}, d: ${diferenciaActualX} ---`;
              txt += `        y1: ${y1}, y2: ${y2}, a: ${box_y}, dm: ${diferenciaMaxY}, d: ${diferenciaActualY}`;
              console.log(txt);
            }
            this.infoCamara = txt;
            return;
          }
        }

        let ahora: Date = this.Now();
        let enviarFoto: boolean = true;
        if ((!!this.idEmpleadoActivo && +this.idEmpleadoActivo == idEmpleadoDesconocido) || (!!this.idEmpleadoRegistrado && +this.idEmpleadoRegistrado == idEmpleadoDesconocido)) {
          if (dateDiff(this._ultimoAcceso, ahora, TipoDateDiff.minutos) > 1) {
            this.vecesRegistrado = 0;
          }
          if (this.vecesRegistrado > this.accesoExpress.vecesPorVerificar) {
            let txt: string = `Empleado ya registrado`
            if (!!this.empleadoActivo && (this.empleadoActivo.id == this.idEmpleadoActivo || this.empleadoActivo.id == this.idEmpleadoRegistrado)) {
              txt = `El empleado ${nombreEmpleado(this.empleadoActivo)} ya ha sido registrado`;
            }

            this.infoCamara = txt;
            if (dateDiff(this._ultimoAcceso, ahora, TipoDateDiff.minutos) < 1) {
              if (this.accesoExpress.debug) this.ponerTxtEnConsola(`Empleado ya registrado, transcurrido: ${dateDiff(this._ultimoAcceso, ahora, TipoDateDiff.segundos)} segundos`);
              this._ultimoAcceso = ahora;
              if (this.modoAdmin > 0 && !!this.empleadoActivo) {
                this.abrirModoAdmin(this.empleadoActivo);
              }
              return;
            }
          }
          this.vecesRegistrado++;
          enviarFoto = this.vecesRegistrado == this.accesoExpress.vecesPorVerificar;
        } else {
          this.vecesRegistrado = 1;
        }

        this.infoCamara = 'Registrando nuevo ingreso';
        let txt: string = `, sujeto: ${respuesta.subjects.subject}, validez: ${respuesta.subjects.similarity}, Box: ${box_x} - ${box_y}`;
        this.ponerTxtEnConsola(`===================== Validado empleado OnLine. IdEmpleado: ${idEmpleadoDesconocido} - ${this.tipoChecada} ${txt}`);
        // this.infoAdd += `, validado online`;

        this.idEmpleadoActivo = idEmpleadoDesconocido.toString();
        this.idEmpleadoRegistrado = this.idEmpleadoActivo;
        this._ultimoAcceso = ahora;

        let empleado: IEmpleados | undefined = this.nomiExpressApi.obtenerEmpleado(this.idEmpleadoActivo);
        this.empleadoActivo = empleado;
        if (!empleado) {
          this.infoCamara = 'Empleado no registrando';
          // this.infoAdd += `No se puede localizar al empleado seleccionado. ${this.idEmpleadoActivo}`;
          this.ponerTxtEnConsola(`checkEmployeeById. No se localizó al empleado ${this.idEmpleadoActivo}`);
          this.ponerError('Ha ocurrido un error inesperado, por favor intenta de nuevo, si el problema persiste contacta a soporte técnico.');
          return;
        }

        if (this.vecesRegistrado < this.accesoExpress.vecesPorVerificar) {
          this.infoCamara = `Validando ingreso (${this.vecesRegistrado}) del empleado: ${nombreEmpleado(empleado)}`;
          return;
        }

        // REVISAR IMAGEN ANTES DE GUARDAR
        if (false) {
          // let respuestaRevisarImagenContraRegistro: TipoRevisarImagenContraRegistro = await this.revisarImagenValidaContraRegistro(empleado, foto64);
          // if (respuestaRevisarImagenContraRegistro == TipoRevisarImagenContraRegistro.NoValidado) {
          //   this.idEmpleadoActivo = undefined;
          //   this.idEmpleadoRegistrado = undefined;
          //   this.infoCamara = `Validando ingreso (${this.vecesRegistrado}) del empleado: ${nombreEmpleado(empleado)}, NO VALIDADO`;
          //   return;
          // }
        }

        this.infoCamara = `Registrando ingreso del empleado: ${nombreEmpleado(empleado)}`;
        // this.infoAdd += `, Empleado: ${nombreEmpleado(empleado)}`;
        this.checadaEmpleado(empleado, this._ultimoAcceso, false, TipoOrigen.reconocimientoFacialCF, similitud);

        if (enviarFoto) {
          this.nomiExpressApi.enviarDatosEmpleado(idEmpleadoDesconocido, 'rf', foto64, similitud, respuesta).subscribe( (x: IRespuestaChecker) => {
            this.ponerTxtEnConsola(`=========================================  validarFotoDesconocida. enviarDatosEmpleado. Código: ${x.code}, Mensaje: ${x.mensaje}, IdEmpleadoFoto ${idEmpleadoDesconocido} - ${'rf'}`);
          }, (err: Error) => {
            let txt: string = err.message;
            this.ponerTxtEnConsola(`=========================================  validarFotoDesconocida. enviarDatosEmpleado. Error: ${txt}`);
          });
        }
      }, (err: Error) => {
        this.infoCamara = 'Error al cargando información del empleado';
        let txt: string = err.message;
        this.ponerTxtEnConsola(`=========================================  validarFotoDesconocida. empleadoReconocer. Error: ${txt}`);
      }
    );
  }

  private async revisarImagenValidaContraRegistro(empleado: IEmpleados, foto64: string): Promise<TipoRevisarImagenContraRegistro> {
    // si en el listado hay una imagen o menos, regresamos verdadero
    if (!(empleado.id in this.empleadosListadoImágenesCF)) return TipoRevisarImagenContraRegistro.SinRegistro;
    let listadoImágenes: string[] = this.empleadosListadoImágenesCF[+empleado.id];

    if (!listadoImágenes || listadoImágenes.length <= 1) return TipoRevisarImagenContraRegistro.SinImágenesSuficientes;

    if (!this.accesoExpress.imagenesParaValidarRegistro || this.accesoExpress.imagenesParaValidarRegistro < 2) this.accesoExpress.imagenesParaValidarRegistro = 2;

    let imágenesParaValidar: string[] = listadoImágenes;
    if (listadoImágenes.length > this.accesoExpress.imagenesParaValidarRegistro) {
      if (this.revisarOrdenAscendente) {
        imágenesParaValidar = imágenesParaValidar.slice(0, this.accesoExpress.imagenesParaValidarRegistro);
      } else {
        let i = imágenesParaValidar.length - this.accesoExpress.imagenesParaValidarRegistro - 1;
        imágenesParaValidar = imágenesParaValidar.slice(i, this.accesoExpress.imagenesParaValidarRegistro);
      }
      this.revisarOrdenAscendente = !this.revisarOrdenAscendente;
    }

    if (this.accesoExpress.servidorCF.startsWith('NM')) {
      let validarReconocimiento: { code: number, mensaje: string, respuestaReconocimiento: TipoRevisarImagenContraRegistro } = await this.reconocimientoCFApi.empleadoValidarReconocimientoFacial(+empleado.id, imágenesParaValidar, foto64);
      if (validarReconocimiento.code != 100) {
        return TipoRevisarImagenContraRegistro.NoRevisado;
      }
      return validarReconocimiento.respuestaReconocimiento;
    }

    let mensaje: string = '';
    let respuestaReconocimiento: TipoRevisarImagenContraRegistro = TipoRevisarImagenContraRegistro.NoRevisado;
    let procesados: number = 0;
    let sumaProcesados: number = 0;
    let respuestaImagenes: { [imagen: string]: number } = { };

    for (let index = 0; index < imágenesParaValidar.length; index++) {
      const imagen = imágenesParaValidar[index];
      let resultadoVerificar = await this.reconocimientoCFApi.empleadoValidarImagenReconocimientoFacial(imagen, foto64);

      respuestaImagenes[imagen] = resultadoVerificar.subjects.similarity;
      if (resultadoVerificar.subjects.similarity == 0) {
        mensaje = `Imagen no verificada ${imagen}`;
        respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoRevisado;
        continue;
      }

      if (resultadoVerificar.subjects.similarity < validarReconocimiento) {
        mensaje = `Imagen no validada ${imagen}`;
        respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoValidado;
        break;
      }

      procesados++;
      sumaProcesados += resultadoVerificar.subjects.similarity;
    }

    let guardarValidación: boolean = new Date() <= new Date(2024, 9, 1) || respuestaReconocimiento == TipoRevisarImagenContraRegistro.NoValidado;

    if (respuestaReconocimiento != TipoRevisarImagenContraRegistro.NoValidado) {
      if (procesados > 0 && sumaProcesados > 0) {
        let promedio: number = sumaProcesados / procesados;
        if (promedio >= verificarReconocimientoFacial) {
          respuestaReconocimiento = TipoRevisarImagenContraRegistro.Validado;
          mensaje = `Imagen validada`;
        } else {
          respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoValidado;
          mensaje = `Imagen no validada`;
          guardarValidación = true;
        }
      } else {
        respuestaReconocimiento = TipoRevisarImagenContraRegistro.NoRevisado;
        mensaje = `Imagen no revisada`;
      }
    }

    if (guardarValidación) {
      await this.reconocimientoCFApi.empleadosReconocimientoVerificarGuardar(+empleado.id, imágenesParaValidar, foto64, respuestaImagenes )
    }

    return respuestaReconocimiento;
  }

  public obtenerDatos() {
    if (this.accesoExpress.debug) {
      this.ponerTxtEnConsola('Cargando datos dese el servidor...');
    } else {
      this.nomiExpressApi.logAgrega('Cargando datos dese el servidor...');
    }
    this.proceso = 'Cargando datos dese el servidor...'
    this.nomiExpressApi.logAgrega2(`Cargando datos desde el Servidor`);

    this.datosActualizados = new Date(1900, 0, 0);
    setTimeout(() => {
      // this.nomiExpressApi.logAgrega2(`Verificando fecha de actualización de datos ${fFecha(this.datosActualizados, 'fmhs')}`);
      if (dateDiff(this.datosActualizados, new Date(), TipoDateDiff.días) >= 10) {
        this.nomiExpressApi.logAgrega2(`Reiniciando (a2)`);
        this.ponerError('Reiniciando datos sin actualizar 10 días');
        this.reiniciar('Reiniciando datos sin actualizar 10 días (a2)');
      }
    }, 15000);

    this.renovar = '';
    this.nomiExpressApi.getUpdate(ManejoDescarga.TodoSinAccesos).subscribe(
      async (datosApi: IResultadoActualiza | undefined) => {
        this.datosActualizados = new Date();
        this.nomiExpressApi.logAgrega2(`Procesando datos del Servidor.  ${fFecha(this.datosActualizados, 'fmhs')}`);
        if (!datosApi) {
          this.nomiExpressApi.logAgrega2('Error al cargar datos. Sin información.');
          this.proceso = 'Error al cargar datos. Sin información.'
          this.renovar = 'Tu suscripción esta por expirar';
          return;
        }
        if (!datosApi.checkServerTime) {
          this._router.navigate(['fecha-no-sincronizada']);
          return;
        }
        if (this.accesoExpress.isActive) {
          this.nomiExpressApi.logAgrega2('Datos cargados.');
          let ahora: Date = Ahora(this.accesoExpress.opcionesFecha);

          // Vigencia del sistema
          let estaVigente: boolean = false;
          let empresa: IEmpresa = new IEmpresa();
          empresa.vigenteHastaFecha = new Date(2024, 11, 31);
          if (!!datosApi && !!datosApi.empresa) {
            empresa = datosApi.empresa;
            if (!!empresa.vigenteHasta) {
              empresa.vigenteHastaFecha = getFechaT(empresa.vigenteHasta);
              if (empresa.vigenteHastaFecha < new Date(2024, 8, 31)) {
                empresa.vigenteHastaFecha = new Date(2024, 11, 31);
              }
            } else {
              empresa.vigenteHastaFecha = new Date(2024, 11, 31);
            }
            if (empresa.vigenteHastaFecha.getTime() != this.accesoExpress.vigenteHasta.getTime()) {
              this.accesoExpress.vigenteHasta = empresa.vigenteHastaFecha;
              this.accesoExpress.vigenteHastaTxt = fechaT(empresa.vigenteHastaFecha);
            }
          }
          if (this.accesoExpress.díasVigenciaAdicional > 15) this.accesoExpress.díasVigenciaAdicional = 15;

          estaVigente = ahora.getTime() < addDays(empresa.vigenteHastaFecha, 15).getTime();
          if (!estaVigente) { // completamente fuera de uso
            this.nomiExpressApi.logAgrega2(`No esta vigente. Vigencia: ${fFecha(empresa.vigenteHastaFecha, 'fsl')}`);
            this.renovar = 'Tu suscripción ha expirado';
            this._router.navigate(['no-vigente']);
            return;
          }

          estaVigente = ahora.getTime() < addDays(empresa.vigenteHastaFecha, 1).getTime();
          if (!estaVigente) {
            this.renovar = 'Tu suscripción ha expirado';
            this.nomiExpressApi.logAgrega2(`Tu suscripción ha expirado. Vigencia: ${fFecha(empresa.vigenteHastaFecha, 'fsl')}`);
          } else {
            estaVigente = ahora.getTime() < addDays(empresa.vigenteHastaFecha, -31).getTime();
            if (!estaVigente) { // por expirar
              this.renovar = 'Tu suscripción esta por expirar';
              this.nomiExpressApi.logAgrega2(`Tu suscripción esta por expirar. Vigencia: ${fFecha(empresa.vigenteHastaFecha, 'fsl')}`);
            }
          }
        } else {
          this.nomiExpressApi.logAgrega('Tu suscripción esta por expirar');
          this.renovar = 'Tu suscripción esta por expirar';
        }
        this.proceso = 'Guardando datos localmente...'
        this.empleados = datosApi.empleados;
        this.codigosQR = datosApi.codigosQr;
        this.proceso = 'Guardando datos al servidor...'
        try {
          this.nomiExpressApi.guardarDatosTerminalDelServidor().subscribe(
            (respuesta: IRespuestaChecker) => {
              console.log(`Guardando configuración desde el servidor. Codigo: ${respuesta.code}, Mensaje: ${respuesta.mensaje}`);
              this._router.navigate(['']);
            }, (error) => {
              console.error(error);
            }
          );
        } catch(ex) {
          console.error(ex);
        }
        this.empleadosListadoImágenesCF = { };
        if (!!this.accesoExpress.apiKeyCF) {
          let respuesta: IRespuestaEmpleadosListadoImagenesCF = await this.reconocimientoCFApi.empleadosImagenesCF();
          if (respuesta.code == 100) {
            this.empleadosListadoImágenesCF = respuesta.imágenes;
          }
        }
        this.proceso = 'Proceso terminado, guardando datos localmente, en espera de respuesta del servidor...';
      }, (err: Error) => {
        this.proceso = 'Error al cargar datos';
        this.renovar = 'Tu suscripción esta por expirar';
        this.nomiExpressApi.logAgrega(`Error al cargar datos ${err.message}`);
        console.error(err);
        this.ponerError(err.message);
      }
    );
  }

  private desactivarReconocimientoFacial(){
    this.mostrarFaceID = false;
    // this.ponerTxtEnConsola('Desactivando reconocimiento facial');
    let loopMax: number = 0;
    if (!!this._codeLoop) {
      loopMax = +this._codeLoop;
      clearInterval(this._codeLoop);
    } else {
      this.ponerTxtEnConsola('Desactivando reconocimiento facial (2)');
    }

    if (!this._buscarRostro) {
      this.ponerTxtEnConsola('Desactivando reconocimiento facial (3)');
    } else if (loopMax > +this._buscarRostro) {
      loopMax = +this._buscarRostro;
    }
    this.ponerTxtEnConsola('Desactivando reconocimiento facial (4)');
    for (let index = 0; index < +this._buscarRostro; index++) {
      clearInterval(index);
    }
  }

  public urlBase(): string {
    return environment.hubUrl;
  }

  public captureImg(webcamImage: WebcamImage): void {
    // this.infoAdd += `, ft`;
    this.webcamImage = webcamImage;
    let sysImage: string = webcamImage!.imageAsDataUrl;
    this.ultimaFoto = webcamImage!.imageAsBase64;
    let txt: string = this.tipoChecada == 'qr' ? `IdEmpleadoFoto: ${this.idEmpleadoActivo} - ${this.tipoChecada}` : ' reconocer de rostro';

    if (!this.ultimaFoto) {
      this.ponerTxtEnConsola(`captureImg. foto: null... sysImage: ${sysImage.substring(0, 50)}..., ${txt}`);
      return;
    }

    let foto: string = this.ultimaFoto;

    if (this.tipoChecada == 'qr') {
      this.infoCamara = 'Registrando ingreso de empleado con QR (2)';
      if (!this.idEmpleadoActivo) {
        this.ponerTxtEnConsola(`captureImg - empleado. Toma de foto al empleado: NULO - ${this.tipoChecada}, imagen: ${this.ultimaFoto.substring(0, 30)}...`);
        return;
      }
      this.ponerTxtEnConsola(`captureImg - empleado. Toma de foto al empleado: ${this.idEmpleadoActivo} - ${this.tipoChecada}, imagen: ${this.ultimaFoto.substring(0, 30)}...`);

      this.nomiExpressApi.enviarDatosEmpleado(+this.idEmpleadoActivo, this.tipoChecada, this.ultimaFoto, 0, undefined).subscribe( (x: IRespuestaChecker) => {
        this.ponerTxtEnConsola(`=========================================  captureImg. enviarDatosEmpleado. Código: ${x.code}, Mensaje: ${x.mensaje}, IdEmpleadoFoto ${this.idEmpleadoActivo} - ${this.tipoChecada}`);
        this.tipoChecada = '-';
      }, (err: Error) => {
        let txt: string = err.message;
        this.tipoChecada = '-';
        this.ponerTxtEnConsola(`=========================================  captureImg. enviarDatosEmpleado. Error: ${txt}`);
      }, () => {
        this.infoCamara = '';
      });
      return;
    }

    this._ultimaFoto = new Date();
    this.reconocimientoCFApi.empleadoReconocerRostro(this.ultimaFoto).subscribe(
      async (respuesta: AccesoCF_RespuestaRecognize_Respuesta) => {
        this.datosReconocimiento = respuesta;
        let box_x: number = 0;
        let box_y: number = 0;
        if (!!respuesta.box) {
          box_x = respuesta.box.x_max - respuesta.box.x_min;
          box_y = respuesta.box.y_max - respuesta.box.y_min;
        }
        if (this.accesoExpress.debug && respuesta.subjects.similarity > 0.1) {
          this.ponerTxtEnConsola(`=========================================  captureImg. empleadoReconocerRostro. Semejanza: ${respuesta.subjects.similarity}, Box: ${box_x} - ${box_y}`);
        }
        // if (!environment.production) console.log(`similarity`, x.subjects, x.box);
        let dimensiones: Dimensiones = new Dimensiones();
        try {
          dimensiones = await getImageDimensions('data:image/jpg;base64,' + this.ultimaFoto);
        } catch {
          console.log(`No se pudieron obtener datos de la imagen seleccionada`);
        }
        let imageWidth: number =  dimensiones.ancho == 0 ? this.camaraAncho : dimensiones.ancho;
        let imageHeight: number = dimensiones.alto == 0 ? this.camaraAlto : dimensiones.alto;

        if (respuesta.subjects.similarity > this.accesoExpress.validarReconocimiento) { // existe rostro en la imagen
          if (box_x > 0) {
            let prop: number = box_x / imageWidth;
            if (prop < this.accesoExpress.validarPorcentajeImagen) {
              let txt: string = `Imagen demasiado pequeña.`;
              if (!environment.production || this.accesoExpress.debug) {
                prop *= 100;
                txt += ` x --> ${prop.toFixed(2)}%`;
              }
              this.infoCamara = txt;
              this.idEmpleadoActivo = undefined;
              return;
            }
          }
          if (box_y > 0) {
            let prop: number = box_y / imageHeight;
            if (prop < this.accesoExpress.validarPorcentajeImagen) {
              let txt: string = `Imagen demasiado pequeña.`;
              if (!environment.production || this.accesoExpress.debug) {
                prop *= 100;
                txt += ` y --> ${prop.toFixed(2)}%`;
              }
              this.infoCamara = txt;
              this.idEmpleadoActivo = undefined;
              return;
            }
          }
          if (respuesta.box.x_max > 0 && this.accesoExpress.manejaCentradoEnAcceso) {
            let x1: number = respuesta.box.x_min;
            let x2: number = imageWidth - respuesta.box.x_max;
            let diferenciaActualX: number = Math.abs(x1 - x2);
            let diferenciaMaxX: number = (imageWidth - box_x)  * 0.5;

            let y1: number = respuesta.box.y_min;
            let y2: number = imageHeight - respuesta.box.y_max;
            let diferenciaActualY: number = Math.abs(y1 - y2);
            let diferenciaMaxY: number = (imageHeight - box_y) * 0.6;

            if ((diferenciaActualX > diferenciaMaxX) || (diferenciaActualY > diferenciaMaxY)) {
              console.log(`diferenciaActualX: ${diferenciaActualX}, diferenciaMaxX: ${diferenciaMaxX}, x1: ${x1}, x2 ${x2}, imageWidth: ${imageWidth}, box.x_max: ${respuesta.box.x_max}`);
              console.log(`diferenciaActualY: ${diferenciaActualY}, diferenciaMaxY: ${diferenciaMaxY}, y1: ${y1}, y2 ${y2}, imageHeight: ${imageHeight}, box.y_max: ${respuesta.box.y_max}`);
              let txt: string = `La imagen no esta centrada.`;
              if (!environment.production) {
                txt += `        x1: ${x1}, x2: ${x2}, w: ${box_x}, dm: ${diferenciaMaxX}, d: ${diferenciaActualX} ---`;
                txt += `        y1: ${y1}, y2: ${y2}, a: ${box_y}, dm: ${diferenciaMaxY}, d: ${diferenciaActualY}`;
                console.log(txt);
              }
              this.infoCamara = txt;
              return;
            }
          }
          if (!this.ultimaFoto) this.ultimaFoto = foto;
          this.validarFotoDesconocida(this.ultimaFoto);
        } else if (respuesta.subjects.similarity < 0.5) {
          this.infoCamara = 'Sin rostro';
          this.idEmpleadoActivo = undefined;
        } else {
          this.infoCamara = 'No se detecta rostro';
          this.idEmpleadoActivo = undefined;
        }
      }, (err: HttpError) => {
        this.idEmpleadoActivo = undefined;
        let txt: string = err.message;
        this.ponerTxtEnConsola(`=========================================  captureImg. empleadoReconocerRostro. Error: ${txt}`);
      }
    );
  }

  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.accesoExpress.facingMode && this.accesoExpress.facingMode !== '') {
        if (this.facingMode != this.accesoExpress.facingMode) {
          this.ponerTxtEnConsola(`>>>>>>>>>>>>>>>>>>>>>>>>> facingMode: ${this.accesoExpress.facingMode}`)
          this.facingMode = this.accesoExpress.facingMode;
        }
        result.facingMode = { ideal: this.accesoExpress.facingMode };
    }
    return result;
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public mandarPagina() {
    window.open('https://asesorcontable.mx', '_blank');
  }

  public abrirModoAdmin(empleado: IEmpleados){
    if (!empleado) return;
    if (empleado.id != this.idEmpleadoActivo && empleado.id != this.idEmpleadoRegistrado) return;
    if (empleado.tipoEmpleado == TipoEmpleado.Supervisor || empleado.tipoEmpleado == TipoEmpleado.Administrador) {
      if (this.modoAdmin == 1) {
        this.abrirConfig2('modoAdmin');
        return;
      }
      if (this.modoAdmin == 2) {
        this.modoAdmin = 0;
        this.accesoDatosServicio.setEmpleadoActual(empleado);
        this.desactivarReconocimientoFacial();
        this._router.navigate(['inicio-empresa']);
        return;
      }
    }
  }

  // no mover debe ser la ultima función antes del NgOnInit
  private checadaEmpleado(empleado: IEmpleados, now: Date, ocultar: boolean, origen: TipoOrigen, similitud: number) {
    // =================================================================================================== registro de checada del empleado    <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<<============================================================
    let acceso: IAcceso = {
      localId: getUuid(),
      companyId: this.accesoExpress.companyId,
      employeeId: empleado.id,
      fecha: fechaUTC(now),
      checkerAccessId: this.accesoExpress.clientId,
      origen: origen,
      similitud: similitud
    };

    this.datosActualizados = new Date(1900, 0, 0);
    setTimeout(() => {
      // this.nomiExpressApi.logAgrega2(`Verificando fecha de actualización updateCheck ${fFecha(this.datosActualizados, 'fmhs')}`);
      if (dateDiff(this.datosActualizados, new Date(), TipoDateDiff.días) >= 10) {
        this.nomiExpressApi.logAgrega2(`Reiniciando (a)`);
        this.ponerError('Reiniciando datos sin actualizar 10 días');
        this.reiniciar('Reiniciando datos sin actualizar 10 días');
      }
    }, 10000);
    this.nomiExpressApi.updateCheck(acceso).subscribe(
      (respuesta: IRespuestaChecker) =>  {
        if (respuesta.code >= 100) {
          if (!ocultar) this.ponerMensaje(`Hola ${nombreEmpleadoCorto(empleado)}`);
        } else {
          this.ponerError(respuesta.mensaje);
        }
        this.idEmpleadoActivo = empleado.id;
        this.idEmpleadoRegistrado = empleado.id;
        this.datosActualizados = new Date();
        if (this.modoAdmin == 1 || this.modoAdmin == 2) {
          console.log(`Validando modo admin qr => id: ${this.idEmpleadoActivo}, ma: ${this.modoAdmin}, te: ${empleado.tipoEmpleado}`); // ${JSON.stringify(empleado)}
          this.abrirModoAdmin(empleado);
        }
      }, (err: any) => {
        let error = 'Ha ocurrido un error inesperado, por favor intenta de nuevo, si el problema persiste contacta a soporte técnico.';
        this.ponerError(error);
        this.ponerTxtEnConsola(`Acceso Empleado ==> ${error}, ${err.message}`);
    });
  }

  ngAfterViewInit(): void {
    this.onResize();
  }

  ngOnInit(): void {
    if (environment.production) {
      if (location.protocol === 'http:') {
        this.proceso = 'Redirigiendo a https...';
        this.nomiExpressApi.logAgrega('Redirigiendo a https...');
        window.location.href = location.href.replace('http', 'https');
      }
    }

    this.obtenerDatos();
    this._codeLoop = setInterval(() => {
      const now = this.Now();
      this.terminalTime = `${now.getHours().toString().padStart(2, '0')} : ${now.getMinutes().toString().padStart(2, '0')} : ${now.getSeconds().toString().padStart(2, '0')}`;
    }, 1000);

    if (!this._accessHubService.isListeningToUserAccess) {
      this._accessHubService.onUserAccess.subscribe(token => {
        this.nomiExpressApi.logAgrega('Conectando con el servidor. Acceso de usuario.');
        this.proceso = 'Conectando con el servidor. Acceso de usuario.';
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`_accessHubService ==> onUserAccess token.code: ${token.code}`);
        this.processScanToken(token);
       });
      this._accessHubService.onTokenValidationRequested.subscribe((secretoValidar: SecretValidation) => {
        this.nomiExpressApi.logAgrega('Conectando con el servidor. Validando');
        this.proceso = 'Conectando con el servidor. Validando';
        secretoValidar.state = secretoValidar.secret.toLocaleLowerCase() == this.accesoExpress.localSecret.toLocaleLowerCase() ? 'ok' : '??';
        this._httpClient.post(`${environment.hubUrl}/access/validateSecret`, secretoValidar).subscribe();
      });
      this._accessHubService.isListeningToUserAccess = true;
    }

    this.subscriptions.add(
      this._accessHubService.connectionStateSubject.subscribe(async state => {
        this.connectionState = state;
        this.nomiExpressApi.logAgrega('Access Hub - Connecting');
        if (this.idConexionPuntoAcceso != this.connectionState.id && (!this.connectionState.id || this.connectionState.id == ':)'))  {
          this.idConexionPuntoAcceso = this.connectionState.id;
        }

        if (!this.accesoExpress.escucharPorMovimientos || this.connectionState.state != 'connected' || this.buscandoConexion) {
          this.nomiExpressApi.logAgrega('Conectando con el servidor. No conectado.');
          this.proceso = 'Conectando con el servidor. No conectado.';
          if (!this.idConexionPuntoAcceso) {
            this.ponerError('No se ha conectado al servidor');
          }
          this.estaEscuchando = false;
          return;
        }
        if (this.error == 'No se ha conectado al servidor') this.error = '';
        this.nomiExpressApi.logAgrega('Conectando con el servidor. Conectado.');
        this.proceso = 'Conectando con el servidor. Conectado.';
        setTimeout(this.siguienteCodigo, 100);

        let listenForScreen = () => {
          this.buscandoConexion = true;
          this.nomiExpressApi.accessHub_EscucharPorMovimiento(this.connectionState.id).subscribe(
            success => {
              // verificar la versión y ver si no hay datos nuevos
              this.nomiExpressApi.logAgrega('Conectando con el servidor. AccessHub_EscucharPorMovimiento.');
              this.estaEscuchando = true;
              this.buscandoConexion = false;
              setTimeout(() => {
                listenForScreen();
              }, 1000 * 60 * 60);
            }, error => {
              this.estaEscuchando = false;
              this.buscandoConexion = false;
              this.nomiExpressApi.logAgrega(`Conectando con el servidor. AccessHub_EscucharPorMovimiento. Error.`);
              this.nomiExpressApi.validarPuntoDeAcceso().subscribe(
                (response: ITokenAccess) => {
                  const acceso = response.accessPoint;
                  this.accesoExpress.isActive = acceso.isActive;
                  setTimeout(() => {
                    listenForScreen();
                  }, 1000 * 10);
                }, (error: any) => {
                  setTimeout(() => {
                    listenForScreen();
                  }, 1000 * 20);
                });
            }
          );
        };

        listenForScreen();
      })
    );

    let empleado = this._activatedRoute.snapshot.params['employee'];
    if (empleado) {
      // this._api.employees.getOne(employee).subscribe(e => this.setBiometricsFor = e);
      // this._appService.sideBarSubject.next(false);
    }

    this.mostrarFaceID = this.accesoExpress.isActive && this.accesoExpress.faceDetectionEnabled && this.accesoExpress.faceDetectionActive;
    if (this.mostrarFaceID) this._inicializarReconocimientoFacial();

    // this._lockTimeout = setTimeout(() => {
    //   this._appService.isLocked = true;
    // }, 5000);
  }

  ngOnDestroy(): void {
    // console.log('acceso, ngOnDestroy');
    this.subscriptions.unsubscribe();
    // console.log('acceso, ngOnDestroy 2');
    this.desactivarReconocimientoFacial();
    // console.log('acceso, ngOnDestroy 3');
    if (!!this.inicioApp) this.inicioApp = new Date();
    console.log('acceso, ngOnDestroy 4');
  }

}

