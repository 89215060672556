import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { IEmpleados, nombreEmpleado, nuevoEmpleado } from 'src/app/models/empleados';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent {
  public agregarHorario: boolean = true;
  public menuPuntosAcceso: boolean = false;
  public menuEmpleados: boolean = false;
  public menuHorarios: boolean = false;
  public agregandoEmpleado: boolean = false;
  public modificandoEmpleado: boolean = false;
  public mostrarRegistroIncidencias: boolean = false;
  public esDebugger: boolean = !environment.production;
  public tipoEmpleado: number = 0;
  public componenteActivo: number = 0;
  public componenteActivoEmpleados: number = 0;
  public componenteActivoHorarios: number = 0;
  public componenteActivoPuntos: number = 0;

  public idEmpleado: number = 0;
  public empleado: IEmpleados | undefined = undefined;

  @Output()
  public verificarMenuEmpleadosEvent = new EventEmitter<void>();

  @Output()
  public recargarIncidenciasEvent = new EventEmitter<void>();

  @Output()
  public recargarPoliticasEvent = new EventEmitter<void>();

  constructor(
    private breadcrumbService: BreadcrumbService,
    private accesoDatosService: AccesoDatosService
  ) {
    let empleado = this.accesoDatosService.getUsuario();
    this.tipoEmpleado = empleado.tipoEmpleado;
    console.log(`Tipo de empleado: ${this.tipoEmpleado}`);
   }

  public mostrarReconocimientoFacial(nada: boolean) {
    this.componenteActivoEmpleados = 3;
  }

  public onMostrarRegistroIncidencias(event: boolean) {
    this.componenteActivoEmpleados = 3;
    this.mostrarRegistroIncidencias = event;
  }

  public mostrarCodigoQr(dommy: boolean) {
    this.componenteActivoEmpleados = 2;
  }

  public cambiarMenuPuntosAcceso(cambiar: boolean) {
    this.menuPuntosAcceso = cambiar;
    if (cambiar) {
      this.onTabChangePuntos({ index: 0 })
    }
  }

  public verificarMenuEmpleados(_: boolean) {
    if (this.componenteActivo != 1) return;
    this.menuEmpleados = false;
    this.onTabChange({ index: 1 });
    this.verificarMenuEmpleadosEvent.emit();
  }

  public verificarMenuPuntosAcceso() {
    if (this.componenteActivo != 0) return;
    this.menuPuntosAcceso = false;
    this.onTabChange({ index: 0 });
  }


  public nombreCompleto(empleado: IEmpleados){
    return nombreEmpleado(empleado);
  }

  public cambiarMenuEmpleados(cambiar: boolean) {
    this.menuEmpleados = cambiar;
    if (this.menuEmpleados) {
      this.onTabChangeEmpleados({ index: 0 });
      return;
    }
    this.onTabChange({ index: 1 });
  }

  public empleadoNuevo(agregando: boolean) {
    this.agregandoEmpleado = agregando;
    this.modificandoEmpleado = agregando;

    if (this.menuEmpleados) {
      this.componenteActivoEmpleados = 0;
    }
  }

  public actualizarBreadcrumbs(index: number): string[] {
    switch (index) {
      case 0:
        return ['Datos generales'];
      case 1:
        return ['Empleados'];
      case 2:
        return ['Horarios'];
      case 3:
        return ['Incidencias'];
      case 4:
      return ['Política de incidencias'];
      default:
        return [''];
    }
  }

  private actualizarBreadcrumbsEmpleado(index: number, empleado?: IEmpleados): string[] {
    const empleadoNombre = empleado ? this.nombreCompleto(empleado) : '';

    switch (index) {
      case 0:
        return [empleadoNombre, 'Datos personales'];
      case 1:
        return [empleadoNombre, 'Horario'];
      case 2:
        return [empleadoNombre, 'Código de acceso'];
      case 3:
        return [empleadoNombre, 'Reconocimiento facial'];
      default:
        return ['Empleados', empleadoNombre];
    }
  }

  private actualizarBreadcrumbsHorarios(index: number): string[] {
    switch (index) {
      case 0:
        return ['Listado de horarios'];
      case 1:
        return ['Asignar horarios'];
      case 2:
        return ['Horarios por día'];
      case 3:
        return ['Horarios rotativos'];
      case 4:
        return ['Manejo de grupos'];
      default:
        return ['Horarios'];
    }
  }

  private actualizarBreadcrumbsPuntos(index: number): string[] {
    switch (index) {
      case 0:
        return ['Puntos de acceso'];
      case 1:
        return ['Checador en vivo'];
      default:
        return ['Puntos de acceso'];
    }
  }

  public onTabChangeEmpleados(event: any) {
    this.componenteActivoEmpleados = event.index;
    if (this.componenteActivoEmpleados !== 3) {
      this.mostrarRegistroIncidencias = false;
    }
    let idEmpleadoService: number = this.accesoDatosService.empleadoActualObtener();
    if (!this.empleado || this.idEmpleado != idEmpleadoService) {
      this.accesoDatosService.empleadoObtener().subscribe(
        (empleado: IEmpleados) => {
          this.empleado = empleado;
          this.idEmpleado = +empleado.id;
          const breadcrumbs = this.actualizarBreadcrumbsEmpleado(this.componenteActivoEmpleados, this.empleado);
          this.breadcrumbService.actualizarBreadcrumbsEmpleado(breadcrumbs);
        },
        (error: HttpErrorResponse) => {
          let err: string = JSON.stringify(error);
          mostrarSwalError('Cargando datos de los empleados', err);
          console.log(`Cargando datos de los empleados. ${err}`);
        }
      );
      return;
    }
    const breadcrumbs = this.actualizarBreadcrumbsEmpleado(this.componenteActivoEmpleados, this.empleado);
    this.breadcrumbService.actualizarBreadcrumbsEmpleado(breadcrumbs);
  }

  public onTabChangeHorarios(event: any) {
    this.componenteActivoHorarios = event.index;
    const breadcrumbs = this.actualizarBreadcrumbsHorarios(this.componenteActivoHorarios);
    this.breadcrumbService.actualizarBreadcrumbsHorarios(breadcrumbs);
  }

  public onTabChangePuntos(event: any) {
    this.componenteActivoPuntos = event.index;
    const breadcrumbs = this.actualizarBreadcrumbsPuntos(this.componenteActivoPuntos);
    this.breadcrumbService.actualizarBreadcrumbsPuntosAcceso(breadcrumbs);
  }

  public onTabChange(event: any) {
    const tabIndex = event.index;

    this.menuEmpleados = false;
    this.menuHorarios = false;
    this.menuPuntosAcceso = false;

    if (tabIndex === 2) {
      this.menuHorarios = true;
      this.componenteActivoHorarios = 0;
    }

    let breadcrumbs: string[];

    if (tabIndex == 1) {
      breadcrumbs = ['Empleados'];
    } else if (this.menuHorarios) {
      breadcrumbs = this.actualizarBreadcrumbsHorarios(0);
    } else if (this.menuPuntosAcceso) {
      breadcrumbs = this.actualizarBreadcrumbsPuntos(0);
    } else {
      breadcrumbs = this.actualizarBreadcrumbs(tabIndex);
    }
    this.breadcrumbService.actualizarBreadcrumbs(breadcrumbs);
  }

  public recargarIncidencias() {
    this.recargarIncidenciasEvent.emit();
  }

  public recargarPoliticas() {
    this.recargarPoliticasEvent.emit();
  }
}
