import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { QRCodeModule } from 'angularx-qrcode';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginComponent } from './core/components/login/login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InicioComponent } from './core/components/inicio/inicio.component';
import { ErrorEnDatosComponent } from './core/components/error-en-datos/error-en-datos.component';
import { MaterialModule } from './core/material.module';
import { HttpClientModule } from '@angular/common/http';
import { ConfiguraAvanzadaComponent } from './core/components/configura-avanzada/configura-avanzada.component';
import { PasswordComponent } from './core/components/password/password.component';
import { ConfiguraBasicaComponent } from './core/components/configura-basica/configura-basica.component';
import { WebcamModule } from 'ngx-webcam';
import { DatosEmpresaComponent } from './core/components/empresa/datos-empresa/datos-empresa.component';
import { MenuComponent } from './core/components/empresa/menu/menu.component';
import { EmpleadosComponent } from './core/components/empresa/empleados/empleados.component';
import { EmpleadosDialogComponent } from './core/components/empresa/empleados-dialog/empleados-dialog.component';
import { ConfiguraReconocimientoComponent } from './core/components/configura-reconocimiento/configura-reconocimiento.component';
import { AccesoComponent } from './core/components/acceso/acceso.component';
import { LogComponent } from './core/components/log/log.component';
import { RecargarComponent } from './core/components/recargar/recargar.component';
import { InicioEmpresaComponent } from './core/components/empresa/inicio-empresa/inicio-empresa.component';
import { NavbarEmpresaComponent } from './core/components/empresa/navbar-empresa/navbar-empresa.component';
import { IncidenciasComponent } from './core/components/empresa/incidencias/incidencias.component';
import { HorariosComponent } from './core/components/empresa/horario/horarios/horarios.component';
import { PaginaNoEncontradaComponent } from './core/components/pagina-no-encontrada/pagina-no-encontrada.component';
import { CamaraModalComponent } from './core/components/camara-modal/camara-modal.component';
import { NgxScannerQrcodeModule, LOAD_WASM } from 'ngx-scanner-qrcode';
import { DatosEmpleadoComponent } from './core/components/empresa/datos-empleado/datos-empleado.component';
import { ConfigEmpresaComponent } from './core/components/empresa/config-empresa/config-empresa.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { CodigoAccesoEmpleadoComponent } from './core/components/empresa/codigo-acceso-empleado/codigo-acceso-empleado.component';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSliderModule } from '@angular/material/slider';
import { AgregarHorarioComponent } from './core/components/empresa/agregar-horario/agregar-horario.component';
import { NgxDaterangepickerMd } from 'ngx-daterangepicker-material';
import { EmpleadoComponent } from './core/components/empresa/empleado/empleado.component';
import { AsignarHorariosComponent } from './core/components/empresa/horario/asignar-horarios/asignar-horarios.component';
import { ManejoGrupoComponent } from './core/components/empresa/horario/manejo-grupo/manejo-grupo.component';
import { HorariosDiaComponent } from './core/components/empresa/horario/horarios-dia/horarios-dia.component';
import { HorariosRotativosComponent } from './core/components/empresa/horario/horarios-rotativos/horarios-rotativos.component';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { TransferirDialogComponent } from './core/components/empresa/horario/transferir-dialog/transferir-dialog.component';
import { ConfigAvanzadaComponent } from './core/components/config-avanzada/config-avanzada.component';
import { EmpleadoReconocimientoFacialComponent } from './core/components/empresa/empleado-reconocimiento-facial/empleado-reconocimiento-facial.component';
import { HorarioComponent } from './core/components/empresa/horario/horario/horario.component';
import { PuntosAccesoComponent } from './puntosDeAcceso/puntos-acceso/puntos-acceso.component';
import { ChecadorTiempoRealComponent } from './puntosDeAcceso/checador-tiempo-real/checador-tiempo-real.component';
import { EmpleadoReconocimientoFacialCfComponent } from './empleados/empleado-reconocimiento-facial-cf/empleado-reconocimiento-facial-cf.component';
import { AccesoBiComponent } from './acceso/components/acceso-bi/acceso-bi.component';
import { EmpleadoHorariosComponent } from './empleados/empleado-horarios/empleado-horarios.component';
import { HorarioDetalleComponent } from './horarios/horario-detalle/horario-detalle.component';
import { RegistroIncidenciasComponent } from './empleados/registro-incidencias/registro-incidencias.component';
import { EmpleadosRegistroIncidenciasComponent } from './empleados/empleados-registro-incidencias/empleados-registro-incidencias.component';
import { EmpresaPoliticasIncidenciasComponent } from './empresa/empresa-politicas-incidencias/empresa-politicas-incidencias.component';
import { PuntosDeAccesoEstadoComponent } from './puntosDeAcceso/puntos-de-acceso-estado/puntos-de-acceso-estado.component';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HorarioDialogComponent } from './horarios/horario-dialog/horario-dialog.component';
import { EmpleadosValidarIncidenciasComponent } from './empleados/empleados-validar-incidencias/empleados-validar-incidencias.component';
import { EmpleadosDocumentosComponent } from './empleados/empleados-documentos/empleados-documentos.component';
import { EmpleadosContratoComponent } from './empleados/empleados-contrato/empleados-contrato.component';
import { FormatoFechaPipe } from './pipes/formato-fecha.pipe';
import { NoVigenteComponent } from './empresa/no-vigente/no-vigente.component';
import { EmpleadosIncidenciasMasivoComponent } from './empleados/empleados-incidencias-masivo/empleados-incidencias-masivo.component';
import { EmpleadosImagenesRegistroRFComponent } from './empleados/empleados-imagenes-registro-rf/empleados-imagenes-registro-rf.component';
import { CambiarHorarioComponent } from './empleados/cambiar-horario/cambiar-horario.component';
import { FechaNoSincronizadaComponent } from './empresa/fecha-no-sincronizada/fecha-no-sincronizada.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { EmpleadosControlVacacionesComponent } from './empleados/empleados-control-vacaciones/empleados-control-vacaciones.component';


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    InicioComponent,
    ErrorEnDatosComponent,
    ConfiguraBasicaComponent,
    ConfiguraAvanzadaComponent,
    PasswordComponent,
    DatosEmpresaComponent,
    MenuComponent,
    EmpleadosComponent,
    EmpleadosDialogComponent,
    ConfiguraReconocimientoComponent,
    AccesoComponent,
    LogComponent,
    RecargarComponent,
    InicioEmpresaComponent,
    NavbarEmpresaComponent,
    IncidenciasComponent,
    HorariosComponent,
    PaginaNoEncontradaComponent,
    CamaraModalComponent,
    DatosEmpleadoComponent,
    ConfigEmpresaComponent,
    CodigoAccesoEmpleadoComponent,
    AgregarHorarioComponent,
    EmpleadoComponent,
    AsignarHorariosComponent,
    ManejoGrupoComponent,
    HorariosDiaComponent,
    HorariosRotativosComponent,
    TransferirDialogComponent,
    ConfigAvanzadaComponent,
    EmpleadoReconocimientoFacialComponent,
    HorarioComponent,
    PuntosAccesoComponent,
    ChecadorTiempoRealComponent,
    EmpleadoReconocimientoFacialCfComponent,
    AccesoBiComponent,
    EmpleadoHorariosComponent,
    HorarioDetalleComponent,
    RegistroIncidenciasComponent,
    EmpleadosRegistroIncidenciasComponent,
    EmpresaPoliticasIncidenciasComponent,
    PuntosDeAccesoEstadoComponent,
    HorarioDialogComponent,
    EmpleadosValidarIncidenciasComponent,
    EmpleadosDocumentosComponent,
    EmpleadosContratoComponent,
    FormatoFechaPipe,
    NoVigenteComponent,
    EmpleadosIncidenciasMasivoComponent,
    EmpleadosImagenesRegistroRFComponent,
    CambiarHorarioComponent,
    FechaNoSincronizadaComponent,
    EmpleadosControlVacacionesComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    MaterialModule,
    QRCodeModule,
    WebcamModule,
    NgxScannerQrcodeModule,
    ModalModule.forRoot(),
    MatTabsModule,
    MatSliderModule,
    NgxDaterangepickerMd,
    TimepickerModule.forRoot(),
    ReactiveFormsModule,
    BsDatepickerModule.forRoot(),
    NgSelectModule
  ],
  exports: [
    FormsModule
  ],
  providers: [
 //    { provide: HTTP_INTERCEPTORS, useClass: AppInterceptor, multi: true },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
