export const $verPor: { [key:string]: string} = {
  'faltas': 'faltas',
  'todo': 'ver todo',
  'incidencia': 'cualquier incidencia',
  'horasExtras': 'horas extras',
  'retardos': 'retardos',
  'trabajoDescanso': 'trabajo en descanso',
}

export const $tipoReporte: { [key:string]: string} = {
  'fecha': 'Por fecha',
  'empleado': 'Por empleado',
  'checks': 'Por registro de acceso',
  'registroFoto': 'Por registro de foto',
  'ejecutivo': 'Resumen ejecutivo',
  'incidencias': 'Resumen de incidencias',
}

export const $timerActulizacion: { [key: string]: string } = {
  '5': 'Cada 5 minutos',
  '10': 'Cada 10 minutos',
  '15': 'Cada 15 minutos',
  '30': 'Cada 30 minutos',
  '1hora': 'Cada hora',
  'nunca': 'Nunca actualizar'
}
