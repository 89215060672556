import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AccesoExpress, nuevoAccesoExpress, validarAccesoExpress } from 'src/app/models/accesoExpress';
import { environment } from 'src/environments/environment';
import { LocalService } from './local.service';
import { getHeaders } from '../Funciones/funciones';
import { CS_ConDatos, fechaT } from '../Funciones/fTexto';
import { IEmpresa } from 'src/app/models/empresa';
import { Observable, of } from 'rxjs';
import { EmpleadoMigracion, IEmpleadoQr, IEmpleados, TipoEmpleado, nombreEmpleado, nuevoEmpleado } from 'src/app/models/empleados';
import { map } from 'rxjs/operators';
import { CVFechaT, PeriodoTxt, TipoDateDiff, addDays, dateDiff, dateDiffAhora, fFecha, fechaCV } from '../Funciones/fFecha';
import { nuevoCodigoQr } from 'src/app/models/codigoQr';
import { IAccesoEmpleadoConRFData, IEmpleadoRF, nuevoEmpleadoRF } from 'src/app/models/accesoCF';
import { IHorarios, IPeriodo, IHorarioEmpleado, IHorariosEmpleado, IHorariosTotal, IHorariosIncidencias } from 'src/app/models/horarios';
import { IRespuesta, IRespuestaChecker, IRespuestaCheckerEmpleado } from 'src/app/models/resultadoActualiza';
import { creaMinifiedIncidenciaWeb, creaMinifiedTiempoExtra, DatosIncidencias, IAccesoIncidenciasAjuste, IAccesoIncidenciasUsuario, IAccesoIncidenciasUsuarioCargar, IAccesoPoliticas, IIncidenciaWeb, IncidencesReport, MinifiedIncidenciaWeb, MinifiedTiempoExtra, TipoIncapacidad, TipoIncidenciaWeb } from 'src/app/models/incidencias';
import { ICheckReporte, IMinifiedCheck, checksReporteExpandir } from 'src/app/models/checks';
import { EmpleadosContratos, EmpleadosContratosDatos, EmpleadosDocumentos, EmpleadosDocumentosDatos, IRespuestaContratos, IRespuestaDocumento, IRespuestaDocumentos, IRespuestaEmpresaEmpleados, IRespuestaImagenesRF } from 'src/app/models/documentos';

@Injectable({
  providedIn: 'root'
})
export class AccesoDatosService {  

  private urlBase = `${environment.api}/acceso`;

  private _accesoExpress: AccesoExpress;
  private _log: string[] = [];

  public inicioApp: Date = new Date();
  
  private idEmpleado: number = 0;
  private tipoEmpleado: TipoEmpleado = TipoEmpleado.Normal;
  private inicioServicioDatosEmpresa: Date = new Date(1900, 1, 1);
  private modoDebug: boolean = false;

  private datosRegistroEmpleados: { inicio: Date, fin: Date, empleadoSeleccionado: IEmpleados | undefined} = { inicio: new Date(1900, 1, 1), fin: new Date(2500, 1, 1), empleadoSeleccionado: undefined };

  constructor(
    private readonly _httpClient: HttpClient,
    private datosLocales: LocalService
  ) {
    let datosAcceso: string = this.datosLocales.obtenerDatos('acceso');
    if (CS_ConDatos(datosAcceso)) {
      this._accesoExpress = JSON.parse(datosAcceso);
      if (this._accesoExpress.imagenUrl && this._accesoExpress.imagenUrl.substring(0, 1) == '$') {
        this._accesoExpress.imagenUrl = '';
      }
      this._accesoExpress.faceRecognitionDebugMode = false;
    } else {
      this._accesoExpress = nuevoAccesoExpress();
    }
    validarAccesoExpress(this._accesoExpress);

  }

  public iniciarServicioDatosEmpresa() {
    if (this.idEmpleado <= 0 || this.tipoEmpleado <= TipoEmpleado.Normal) return;
    this.inicioServicioDatosEmpresa = new Date();
  }

  public cerrarServicioDatosEmpresa() {
    this.idEmpleado = 0;
    this.tipoEmpleado = TipoEmpleado.Normal;
    this.inicioServicioDatosEmpresa = new Date(1900, 1, 1);
  }

  public setModoDebug(modoDebug: boolean) {
    if (modoDebug && (this.idEmpleado <= 0 || this.tipoEmpleado <= TipoEmpleado.Normal)) {
      // console.log(`setModoDebug => modoDebug: ${this.modoDebug}`);
      return;
    }
    this.modoDebug = modoDebug;
    // console.log(`setModoDebug => modoDebug: ${this.modoDebug}`);
  }

  public getModoDebug(): boolean {
    if (this.idEmpleado <= 0 || this.tipoEmpleado <= TipoEmpleado.Normal) {
      // console.log('Usuario no asignado');      
      this.modoDebug = false;      
    }
    // console.log(`getModoDebug => modoDebug: ${this.modoDebug}`);
    return this.modoDebug;
  }

  public getUsuario(): IEmpleados {
    let empleado: IEmpleados = nuevoEmpleado();
    if (!environment.production && this.idEmpleado == 0) {
      this.modoDebug = true;
      this.idEmpleado = 999;
      this.tipoEmpleado = TipoEmpleado.Sistemas;
    }
    empleado.id = this.idEmpleado.toString();
    empleado.tipoEmpleado = this.tipoEmpleado;
    return empleado;
  }

  public setEmpleadoActual(empleado: IEmpleados) {
    this.idEmpleado = +empleado.id;
    this.tipoEmpleado = empleado.tipoEmpleado;
    this.inicioServicioDatosEmpresa = new Date();
  }

  public servicioActivo(): boolean {
    // console.log(`servicioActivo: ${fFecha(this.inicioServicioDatosEmpresa, 'fmhs')}`);
    if (!environment.production) {
      // console.log('!environment.production');
      let empleado: IEmpleados = nuevoEmpleado();
      empleado.id = '999';
      empleado.tipoEmpleado = TipoEmpleado.Sistemas;
      this.setEmpleadoActual(empleado);
      this.modoDebug = true;
      this.idEmpleado = 999;
      this.tipoEmpleado = TipoEmpleado.Sistemas;
    };
    // console.log(`servicioActivo => modoDebug: ${this.modoDebug}`);
    return dateDiffAhora(this.inicioServicioDatosEmpresa, TipoDateDiff.horas) < 2;
  }

  public testConnection() {
    this.logAgrega2("====================================================================== ad-tc");
    let url = `${this.urlBase}/empresa/testConnection`;
    return this._httpClient.get<{ name: string }>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public empresaObtener(): Observable<IEmpresa> {
    let url = `${this.urlBase}/empresa`;
    return this._httpClient.get<IEmpresa>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public empresaModificar(empresa: IEmpresa): Observable<IEmpresa> {
    let url = `${this.urlBase}/empresa`;
    return this._httpClient.post<IEmpresa>(url, empresa, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public empresaCambiarLogo(file: File): Observable<any> {
    const formData = new FormData();
    formData.append(`EmpresaCambiarLogo|${this._accesoExpress.ownerId}|${this._accesoExpress.companyId}`, file, file.name);

    let url = `${this.urlBase}/empresa/cambiarLogo/${this._accesoExpress.ownerId}/${this._accesoExpress.companyId}`;

    return this._httpClient.post(url, formData, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public empresaEmpleados() {
    let url = `${this.urlBase}/empleados/empresa`;
    return this._httpClient.post<IRespuestaEmpresaEmpleados>(url, 0, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }


  public empleadosListado(): Observable<IEmpleados[]> {
    let url = `${this.urlBase}/empleados/listadoEmpleados`;
    return this._httpClient.get<IEmpleados[]>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (empleados: IEmpleados[]) => {
          empleados.forEach(
            (empleado: IEmpleados) => {
              empleado.alta = CVFechaT(empleado.altaActualTxt);
              empleado.baja = CVFechaT(empleado.bajaActualTxt);
            }
          )
          return empleados;
        }
      )
    );
  }

  public empleadoObtener(): Observable<IEmpleados> {
    if (!this.idEmpleado) return of(nuevoEmpleado());
    let url = `${this.urlBase}/empleados`;
    return this._httpClient.post<IEmpleados>(url, this.idEmpleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (empleado: IEmpleados) => {
          empleado.alta = CVFechaT(empleado.altaActualTxt);
          empleado.baja = CVFechaT(empleado.bajaActualTxt);
          empleado.ultimaCargaCF = CVFechaT(`${empleado.ultimaCargaCF}`);
          return empleado;
        }
      )
    );
  }

  public empleadosGrupos(): Observable<string[]> {
    let url = `${this.urlBase}/empleados/grupos`;
    return this._httpClient.get<string[]>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public empleadoObtenerQR(): Observable<IEmpleadoQr> {
    if (!this.idEmpleado) return of({empleado: nuevoEmpleado(), qrCode: nuevoCodigoQr()});
    let url = `${this.urlBase}/empleados/qrCode`;
    return this._httpClient.post<IEmpleadoQr>(url, this.idEmpleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public empleadoObtenerRF(): Observable<IEmpleadoRF> {
    if (!this.idEmpleado) return of({empleado: nuevoEmpleado(), empleadoRF: nuevoEmpleadoRF() });
    let url = `${this.urlBase}/empleados/rf`;
    return this._httpClient.post<IEmpleadoRF>(url, this.idEmpleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (empleado: IEmpleadoRF) => {
          empleado.empleado.alta = CVFechaT(empleado.empleado.altaActualTxt);
          empleado.empleado.baja = CVFechaT(empleado.empleado.bajaActualTxt);
          empleado.empleado.ultimaCargaCF = CVFechaT(`${empleado.empleado.ultimaCargaCF}`);
          if (!!empleado.empleadoRF.datos) {
            empleado.empleadoRF.datos.forEach(x => {
              x.fecha = CVFechaT(x.fechaTxt);
            });
          }
          return empleado;
        }
      )
    );
  }

  public empleadoObtenerRegistroIncidencias(): Observable<IEmpleadoRF> {
    if (!this.idEmpleado) return of({empleado: nuevoEmpleado(), empleadoRF: nuevoEmpleadoRF() });
    let url = `${this.urlBase}/empleados/registroIncidencias`;
    return this._httpClient.post<IEmpleadoRF>(url, this.idEmpleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (empleado: IEmpleadoRF) => {
          empleado.empleado.alta = CVFechaT(empleado.empleado.altaActualTxt);
          empleado.empleado.baja = CVFechaT(empleado.empleado.bajaActualTxt);
          empleado.empleado.ultimaCargaCF = CVFechaT(`${empleado.empleado.ultimaCargaCF}`);
          if (!!empleado.empleadoRF.datos) {
            empleado.empleadoRF.datos.forEach(x => {
              x.fecha = CVFechaT(x.fechaTxt);
            });
          }
          return empleado;
        }
      )
    );
  }

  public empleadoObtenerRegistroIncidenciasListado(inicioPeriodo: Date, finPeriodo: Date): Observable<IAccesoEmpleadoConRFData[]> {    
    let url = `${this.urlBase}/empleados/registroIncidenciasListado`;
    let periodo: PeriodoTxt = {
      // inicio: fechaT(new Date(1900, 1, 1)),
      // final: fechaT(new Date(2500, 1, 1))
      inicio: fechaT(inicioPeriodo),
      final: fechaT(addDays(finPeriodo, 1))
    }
    return this._httpClient.post<IAccesoEmpleadoConRFData[]>(url, periodo, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (registro: IAccesoEmpleadoConRFData[]) => {
          registro.forEach(x => {
            x.datosRF.fecha = CVFechaT(x.datosRF.fechaTxt);
          });
          return registro;
        }
      )
    );
  }

  public empleadoObtenerRegistroIncidenciaDetalle(id: number): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/empleados/registroIncidenciaDetalle`;
    return this._httpClient.post<IRespuestaChecker>(url, id, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }



  public empleadoModificar(empleado: IEmpleados): Observable<IRespuestaCheckerEmpleado> {
    let url = `${this.urlBase}/empleados/datos`;
    return this._httpClient.post<IRespuestaCheckerEmpleado>(url, empleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public empleadoBaja(empleado: IEmpleados): Observable<IRespuestaCheckerEmpleado> {
    let url = `${this.urlBase}/empleados/baja`;
    return this._httpClient.post<IRespuestaCheckerEmpleado>(url, empleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public empleadoActual(idEmpleado: number) {
    this.idEmpleado = idEmpleado;
  }

  public empleadoActualObtener(): number {
    return this.idEmpleado;
  }

  public importarEmpleados(nuevosEmpleados: EmpleadoMigracion[]): Observable<IRespuesta> {
    let url = `${this.urlBase}/empleados/importar`;
    return this._httpClient.post<IRespuesta>(url, nuevosEmpleados, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }




  public horarios(): Observable<IHorarios[]> {    
    let url = `${this.urlBase}/Horarios/listado`;
    
    return this._httpClient.get<IHorarios[]>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (horarios: IHorarios[]) => {
          horarios.forEach(x => {
            x.inicio = CVFechaT(x.inicioTxt);
            if (x.inicio >= new Date(2500, 0, 1) || x.inicio <= new Date(1900, 0, 1)) x.inicioTxt = "-";
            x.fin = CVFechaT(x.finTxt);
            if (x.fin >= new Date(2500, 0, 1) || x.fin <= new Date(1900, 0, 1)) x.finTxt = "-";
          });
          return horarios;
        }
      )
    );
  }

  public horarioGuardar(horario: IHorarios): Observable<IRespuestaChecker> {    
    let url = `${this.urlBase}/horarios/guardar`;

    return this._httpClient.post<IRespuestaChecker>(url, horario, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });    
  }

  public horarioAsignar(idHorario: string, fechaAsignacion: Date): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/horarios/asignar`;
    let datos: IHorarioEmpleado = {
      idEmpleado: this.idEmpleado,
      idHorario: idHorario,
      fecha: fechaAsignacion
    };
    
    return this._httpClient.post<IRespuestaChecker>(url, datos, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });    
  }

  public horarioEmpleadoAsignar(idEmpleado: number, idHorario: string, fechaAsignacion: Date): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/horarios/asignar`;
    let datos: IHorarioEmpleado = {
      idEmpleado: idEmpleado,
      idHorario: idHorario,
      fecha: fechaAsignacion
    };
    
    return this._httpClient.post<IRespuestaChecker>(url, datos, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });    
  }

  public horarioEliminarAsignacion(idHorario: number): Observable<IRespuestaChecker> {    
    let url = `${this.urlBase}/horarios/eliminarAsignacion`;
    let datos: IHorarioEmpleado = {
      idEmpleado: this.idEmpleado,
      idHorario: idHorario.toString(),
      fecha: new Date()
    };
    
    return this._httpClient.post<IRespuestaChecker>(url, datos, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });    
  }

  public horarioEliminar(idHorario: string): Observable<IRespuestaChecker> {    
    let url = `${this.urlBase}/horarios/eliminar`;
    let datos: IHorarioEmpleado = {
      idEmpleado: this.idEmpleado,
      idHorario: idHorario,
      fecha: new Date()
    };
    
    return this._httpClient.post<IRespuestaChecker>(url, datos, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });    
  }

  public horariosEmpleado(): Observable<IHorarios[]> {
    let url = `${this.urlBase}/Horarios/empleadoListado`;

    return this._httpClient.post<IHorarios[]>(url, this.idEmpleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (horarios: IHorarios[]) => {
          horarios.forEach(x => {
            x.inicio = CVFechaT(x.inicioTxt);
            if (x.inicio >= new Date(2500, 0, 1) || x.inicio <= new Date(1900, 0, 1)) x.inicioTxt = "-";
            x.fin = CVFechaT(x.finTxt);
            if (x.fin >= new Date(2500, 0, 1) || x.fin <= new Date(1900, 0, 1)) x.finTxt = "-";
          });
          return horarios;
        }
      )
    );
  }

  public horariosEmpleados(): Observable<IHorariosEmpleado[]> {
    let url = `${this.urlBase}/Horarios/empleadosListado`;

    return this._httpClient.get<IHorariosEmpleado[]>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (horariosEmpleado: IHorariosEmpleado[]) => {
          horariosEmpleado.forEach(
            horarioEmpleado => {
              horarioEmpleado.horarios.forEach(x => {
                x.inicio = CVFechaT(x.inicioTxt);
                if (x.inicio >= new Date(2500, 0, 1) || x.inicio <= new Date(1900, 0, 1)) x.inicioTxt = "-";
                x.fin = CVFechaT(x.finTxt);
                if (x.fin >= new Date(2500, 0, 1) || x.fin <= new Date(1900, 0, 1)) x.finTxt = "-";
              });
            }
          );
          
          return horariosEmpleado;
        }
      )
    );
  }

  public horariosEmpleadosConIncidencias(): Observable<IHorariosTotal> {
    let url = `${this.urlBase}/Horarios/empleadosConIncidencias`;

    return this._httpClient.get<IHorariosTotal>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (horariosTotales: IHorariosTotal) => {
          let horariosEmpleado: IHorariosEmpleado[] = horariosTotales.horariosEmpleados;
          if (!!horariosEmpleado && horariosEmpleado.length > 0) {
            horariosEmpleado.forEach(
              horarioEmpleado => {
                horarioEmpleado.horarios.forEach(x => {
                  x.inicio = CVFechaT(x.inicioTxt);
                  if (x.inicio >= new Date(2500, 0, 1) || x.inicio <= new Date(1900, 0, 1)) x.inicioTxt = "-";
                  x.fin = CVFechaT(x.finTxt);
                  if (x.fin >= new Date(2500, 0, 1) || x.fin <= new Date(1900, 0, 1)) x.finTxt = "-";
                });
              }             
            );
          }

          let horariosIncidencias: IHorariosIncidencias[] = horariosTotales.horariosIncidencias;
          if (!!horariosIncidencias && horariosIncidencias.length > 0) {
            horariosIncidencias.forEach(
              horarioIncidencias => {
                horarioIncidencias.inicio = CVFechaT(horarioIncidencias.inicioTxt);
                horarioIncidencias.fin = CVFechaT(horarioIncidencias.finTxt);
                if (!!horarioIncidencias.horario) {

                };
              }
            );
          }

          
          return horariosTotales;
        }
      )
    );
  }

  public horarioEmpleado(idHorario: number): Observable<IHorarios> {    
    let url = `${this.urlBase}/Horarios/empleado`;
    let datos: IHorarioEmpleado = {
      idEmpleado: this.idEmpleado,
      idHorario: idHorario.toString(),
      fecha: new Date()
    };

    return this._httpClient.post<IHorarios>(url, datos, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (horario: IHorarios) => {          
          horario.inicio = CVFechaT(horario.inicioTxt);
          if (horario.inicio >= new Date(2500, 0, 1) || horario.inicio <= new Date(1900, 0, 1)) horario.inicioTxt = "-";
          horario.fin = CVFechaT(horario.finTxt);
          if (horario.fin >= new Date(2500, 0, 1) || horario.fin <= new Date(1900, 0, 1)) horario.inicioTxt = "-";          
          return horario;
        }
      )
    );    
  }

  public incidenciasPolitica(): Observable<IAccesoPoliticas> {
    let url = `${this.urlBase}/Incidencias/AccesoPolitica`;
    return this._httpClient.get<IAccesoPoliticas>(url, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public incidenciasPoliticaGuardar(politicas: IAccesoPoliticas): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Incidencias/AccesoPoliticaGuardar`;
    return this._httpClient.post<IRespuestaChecker>(url, politicas, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public incidenciasAjuste(periodo: IPeriodo): Observable<IAccesoIncidenciasAjuste[]> {
    let url = `${this.urlBase}/Incidencias/incidenciasAjustes`;
    return this._httpClient.post<IAccesoIncidenciasAjuste[]>(url, periodo, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (incidenciasAjusteEmpleados: IAccesoIncidenciasAjuste[]) => {
          incidenciasAjusteEmpleados.forEach(
            x => {
              x.fecha = fechaCV(x.fechaTxt);
            }
          );
          return incidenciasAjusteEmpleados;
        }
      )
    );
  }

  public incidenciasAjusteConEspeciales(periodo: IPeriodo): Observable<IAccesoIncidenciasAjuste[]> {
    let url = `${this.urlBase}/Incidencias/incidenciasAjustesConEspeciales`;
    return this._httpClient.post<IAccesoIncidenciasAjuste[]>(url, periodo, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (incidenciasAjusteEmpleados: IAccesoIncidenciasAjuste[]) => {
          incidenciasAjusteEmpleados.forEach(
            x => {
              x.fecha = fechaCV(x.fechaTxt);
            }
          );
          return incidenciasAjusteEmpleados;
        }
      )
    );
  }

  public incidenciasAjusteGuardar(incidencia: IAccesoIncidenciasAjuste): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Incidencias/incidenciasAjustesGuardar`;
    return this._httpClient.post<IRespuestaChecker>(url, incidencia, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public incidenciasAjusteReEstablecer(incidencia: IAccesoIncidenciasAjuste): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Incidencias/incidenciasAjusteReEstablecer`;
    return this._httpClient.post<IRespuestaChecker>(url, incidencia, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public incidenciasAjusteEliminar(incidencia: IAccesoIncidenciasAjuste): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Incidencias/incidenciasAjusteEliminar`;
    return this._httpClient.post<IRespuestaChecker>(url, incidencia, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public incidenciasUsuario(periodo: IPeriodo): Observable<IAccesoIncidenciasUsuario[]> {
    let url = `${this.urlBase}/Incidencias/incidenciasUsuarios`;
    return this._httpClient.post<IAccesoIncidenciasUsuario[]>(url, periodo, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (incidenciasAjusteEmpleados: IAccesoIncidenciasUsuario[]) => {
          incidenciasAjusteEmpleados.forEach(
            x => {
              x.inicio = fechaCV(x.inicioTxt);
              x.fin = fechaCV(x.finTxt);
            }
          );
          return incidenciasAjusteEmpleados;
        }
      )
    );
  }

  public incidenciasUsuarioCargar(periodo: IPeriodo): Observable<IAccesoIncidenciasUsuarioCargar> {
    let url = `${this.urlBase}/Incidencias/incidenciasUsuariosCargar`;
    return this._httpClient.post<IAccesoIncidenciasUsuarioCargar>(url, periodo, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (respuesta: IAccesoIncidenciasUsuarioCargar) => {
          let empleados: { [key: string]: string}  = {};
          respuesta.empleados.forEach(
            empleado => {
              empleados[empleado.id] = nombreEmpleado(empleado);
            }
          );
          respuesta.incidencias.forEach(
            x => {
              if (x.idEmpleado in empleados) {
                x.nombreEmpleado = empleados[x.idEmpleado];
              } else {
                x.nombreEmpleado = x.idEmpleado.toString();
              }
              x.inicio = fechaCV(x.inicioTxt);
              x.fin = fechaCV(x.finTxt);
            }
          );
          return respuesta;
        }
      )
    );
  }

  public incidenciasUsuarioGuardar(incidencia: IAccesoIncidenciasUsuario): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Incidencias/incidenciasUsuarioGuardar`;
    return this._httpClient.post<IRespuestaChecker>(url, incidencia, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public incidenciasUsuarioMasivoGuardar(empleados: IEmpleados[], incidencia: IAccesoIncidenciasUsuario): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Incidencias/incidenciasUsuarioMasivoGuardar`;
    return this._httpClient.post<IRespuestaChecker>(url, { empleados, incidencia }, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public incidenciasUsuarioEliminar(incidencia: IAccesoIncidenciasUsuario): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Incidencias/incidenciasUsuarioEliminar`;
    return this._httpClient.post<IRespuestaChecker>(url, incidencia, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public horarioIncidenciaCambio(horarioIncidencia1: IHorariosIncidencias, horarioIncidencia2: IHorariosIncidencias): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Horarios/incidenciasHorarioCambio`;
    return this._httpClient.post<IRespuestaChecker>(url, { horarioIncidencia1, horarioIncidencia2 }, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public horarioIncidenciaModificar(horarioIncidencia: IHorariosIncidencias): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Horarios/incidenciasHorarioModificar`;
    return this._httpClient.post<IRespuestaChecker>(url, horarioIncidencia, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public horarioIncidenciaEliminar(horarioIncidencia: IHorariosIncidencias): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/Horarios/incidenciasHorarioModificar`;
    return this._httpClient.post<IRespuestaChecker>(url, horarioIncidencia, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }




  
  public registros(periodo: IPeriodo, empleados: { [key: string]: {nombre: string, idDepartamento: number}}): Observable<ICheckReporte[]> {
    let url = `${this.urlBase}/Incidencias/registros`;
    return this._httpClient.post<IMinifiedCheck[]>(url, periodo, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) }).pipe(
      map(
        (miniChecks: IMinifiedCheck[]) => {
          let checks: ICheckReporte[] = [];
          miniChecks.forEach(
            x => {
              // let check: ICheckReporte = checksReporteExpandir(x, empleados[x.c]);
              // check.nombre = empleados[x.c];
              // console.log(`empelados: ${empleados[x.c]} ${x.c}`, check );
              checks.push(checksReporteExpandir(x, empleados[x.c].nombre, empleados[x.c].idDepartamento));
            }
          )
          
          return checks;
        }
    ));
  }

  public crearIncidenciasExportar(incidencias: DatosIncidencias[]): {incidenciasWeb: IIncidenciaWeb[], incidenciasLog: string[] } {
    let incidenciasWeb: IIncidenciaWeb[] =  [];
    let incidenciasLog: string[] = [];

    for (let i = 0; i < incidencias.length; i++) {
      const incidencia = incidencias[i];

      if (incidencia.horasExtras > 0) {
        let extra: IIncidenciaWeb = {
          id: 0,
          idEmpleado: incidencia.idEmpleado,
          idDepartamento: incidencia.idDepartamento,
          fecha: incidencia.fecha,
          tipoIncidencia: TipoIncidenciaWeb.tiempoExtra,
          valor: incidencia.horasExtras,
          tipoIncapacidad: TipoIncapacidad.Ninguna,
          folioIncapacidad: '',
          savedTime: undefined,
          observaciones: `${incidencia.idEmpleado}, nombre: ${incidencia.nombre}, Tiempo Extra: ${incidencia.horasExtras}`
        }
        incidenciasLog.push(`${extra.observaciones}, Fecha: ${fFecha(extra.fecha, 'fc')}`);
        incidenciasWeb.push(extra);
      }
      if (incidencia.retardos > 0) {
        let tiempoTotal: number = 0;
        incidencia.incidenciaHorario.forEach(
          x => {
            let horasTrabajadas: number = dateDiff(x.horaEntradaHorario, x.horaSalidaHorario, TipoDateDiff.horas);
            if (horasTrabajadas > 0) tiempoTotal += horasTrabajadas;
          }
        );
        if (tiempoTotal == 0) tiempoTotal = 24;
        if (incidencia.retardos > tiempoTotal) tiempoTotal = incidencia.retardos;
        let retardo: IIncidenciaWeb = {
          id: 0,
          idEmpleado: incidencia.idEmpleado,
          idDepartamento: incidencia.idDepartamento,
          fecha: incidencia.fecha,
          tipoIncidencia: TipoIncidenciaWeb.ausencia,
          valor: incidencia.retardos / tiempoTotal,
          tipoIncapacidad: TipoIncapacidad.Ninguna,
          folioIncapacidad: '',
          savedTime: undefined,
          observaciones: `${incidencia.idEmpleado}, nombre: ${incidencia.nombre}, Retardo: ${incidencia.retardos}, TiempoTotal: ${tiempoTotal}`
        }
        incidenciasLog.push(`${retardo.observaciones}, Fecha: ${fFecha(retardo.fecha, 'fc')}`);
        incidenciasWeb.push(retardo);
      }
      
      if (incidencia.faltas > 0 || incidencia.descanso > 0 || incidencia.incapacidad > 0 || incidencia.vacaciones > 0) {
        let incidenciaWeb: IIncidenciaWeb = {
          id: 0,
          idEmpleado: incidencia.idEmpleado,
          idDepartamento: incidencia.idDepartamento,
          fecha: incidencia.fecha,
          tipoIncidencia: incidencia.faltas > 0 ? TipoIncidenciaWeb.ausencia : incidencia.descanso > 0 ? TipoIncidenciaWeb.descanso : incidencia.incapacidad > 0 ? TipoIncidenciaWeb.incapacidad : incidencia.vacaciones > 0 ? TipoIncidenciaWeb.vacaciones : TipoIncidenciaWeb.ninguna,
          valor: 1,
          tipoIncapacidad: TipoIncapacidad.Ninguna,
          folioIncapacidad: '',
          savedTime: undefined,
          observaciones: `${incidencia.idEmpleado}, nombre: ${incidencia.nombre}, Fecha: ${fFecha(incidencia.fecha, 'fc')}`
        }

        switch (incidenciaWeb.tipoIncidencia) {
          case TipoIncidenciaWeb.ausencia:
            incidenciaWeb.observaciones += `, Ausentismo`;
            break;
          case TipoIncidenciaWeb.descanso:
            if (incidencia.trabajoEnDescanso > 0.001) {
              incidenciaWeb.tipoIncidencia = TipoIncidenciaWeb.trabajoEnDescanso;
              incidenciaWeb.valor = incidencia.trabajoEnDescanso;
              incidenciaWeb.observaciones += `, Trabajo en descanso: ${incidenciaWeb.valor} horas`;
            } else {
              incidenciaWeb.observaciones += `, Descanso`;
            }            
            break;
          case TipoIncidenciaWeb.incapacidad:
            // incidenciaWeb.folioIncapacidad = incidencia.ob
            incidenciaWeb.observaciones += `, Incapacidad`;
            break;
          case TipoIncidenciaWeb.vacaciones:
            incidenciaWeb.observaciones += `, Vacaciones`;
            break;
          default:
            incidenciaWeb.observaciones += `, descocido: ${incidenciaWeb.tipoIncidencia}`;
            continue;              
        }

        if (incidenciaWeb.tipoIncidencia >= 0) {          
          incidenciasWeb.push(incidenciaWeb);
        }
        incidenciasLog.push(incidenciaWeb.observaciones);
      }
    }

    return { incidenciasWeb: incidenciasWeb, incidenciasLog: incidenciasLog };

  }

  public incidenciasExportar(inicio: Date, fin: Date, incidencias: DatosIncidencias[]): Observable<IRespuestaChecker> {    
    console.log(`Cargando Incidencias para exportar. Movimientos: ${incidencias.length}`);
    
    let incidenciasExportar = this.crearIncidenciasExportar(incidencias);
    let incidenciasWeb: IIncidenciaWeb[] = incidenciasExportar.incidenciasWeb;
    let incidenciasLog: string[] = incidenciasExportar.incidenciasLog;

    let empleadosConDepartamento: IIncidenciaWeb[] = incidenciasWeb.filter(x => x.idDepartamento > 0);
    if (!!empleadosConDepartamento && empleadosConDepartamento.length > 0) {
      let empleadosSinDepartamento: IIncidenciaWeb[] = incidenciasWeb.filter(x => x.idDepartamento == 0);
      if (!!empleadosSinDepartamento && empleadosSinDepartamento.length > 0) {
        return of({code: 99, mensaje: 'Existen empelados (${empleadosSinDepartamento[0].nombre}) que no tienen registrado un departamento, es necesiario que esten asignados a un departamento por políticas de la empresa' });
      }
    }

    if (this.modoDebug) console.log('incidenciasWeb:', incidenciasWeb);

    let url = `${environment.api}/checker/incidences`;
    console.log(`Incidencias cargadas, listas para exportar. Movimientos: ${incidencias.length}, Incidencias: ${incidenciasWeb.length} ==> `, incidenciasWeb, `log ==> `, incidenciasLog);

    let extras: MinifiedTiempoExtra[] =  [];
    let incidenciasMiniWeb: MinifiedIncidenciaWeb[] = [];

    incidenciasWeb.forEach( x => {      
      if (x.tipoIncidencia == TipoIncidenciaWeb.tiempoExtra) {
        extras.push(creaMinifiedTiempoExtra(x));
      } else {
        incidenciasMiniWeb.push(creaMinifiedIncidenciaWeb(x));
      }
    });

    var reporte = new IncidencesReport();
    reporte.extras = extras;
    reporte.absences = incidenciasMiniWeb;
    reporte.from = fechaT(inicio);
    reporte.to = fechaT(fin);

    return this._httpClient.post<IRespuestaChecker>(url, reporte, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public obtenerNombrePuntoAccesoDeviceId(deviceId: string): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/PuntosDeAcceso/deviceId`;
    return this._httpClient.post<IRespuestaChecker>(url, deviceId, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public contratosObtener(contrato: EmpleadosContratos): Observable<IRespuestaContratos> {
    let url = `${this.urlBase}/empresa/ObtenerContrato`;
    return this._httpClient.post<IRespuestaContratos>(url, contrato, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public contratosGuardar(contrato: EmpleadosContratosDatos): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/empresa/GuardarContrato`;
    return this._httpClient.post<IRespuestaChecker>(url, contrato, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public contratosLeyendaPredeterminada(): Observable<string> {
    return this._httpClient.get('assets/contratos/privacidad.txt', {responseType: 'text'});
  }

  public documentoObtener(documento: EmpleadosDocumentos): Observable<IRespuestaDocumento> {
    let url = `${this.urlBase}/empresa/ObtenerDocumento`;
    return this._httpClient.post<IRespuestaDocumento>(url, documento, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public documentoEliminar(documento: EmpleadosDocumentos): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/empresa/EliminarDocumento`;
    return this._httpClient.post<IRespuestaChecker>(url, documento, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public documentosObtener(documento: EmpleadosDocumentos): Observable<IRespuestaDocumentos> {
    let url = `${this.urlBase}/empresa/ObtenerDocumentos`;
    return this._httpClient.post<IRespuestaDocumentos>(url, documento, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public documentosGuardar(documentosGuardar: EmpleadosDocumentosDatos): Observable<IRespuestaChecker> {
    let url = `${this.urlBase}/empresa/GuardarDocumentos`;
    return this._httpClient.post<IRespuestaChecker>(url, documentosGuardar, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }


  // ========================================================================  IMAGENES RF
  public imagenesRegistroRF(idEmpleado: number) {
    let url = `${this.urlBase}/empleados/imagenesRegistroRF`;
    return this._httpClient.post<IRespuestaImagenesRF>(url, idEmpleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }

  public depurarImagenesRegistroRF(idEmpleado: number) {
    let url = `${this.urlBase}/empresa/depurarImagenesRegistroRF`;
    return this._httpClient.post<IRespuestaChecker>(url, idEmpleado, { headers: getHeaders(this._accesoExpress.deviceId, this._accesoExpress.clientId, this._accesoExpress.deviceKey) });
  }






  public obtenerDatosRegistro(): { inicio: Date, fin: Date, empleadoSeleccionado: IEmpleados | undefined} {
    return this.datosRegistroEmpleados;
  }

  public guardarDatosRegistro(inicio: Date, fin: Date, empleadoSeleccionado: IEmpleados | undefined) {
    this.datosRegistroEmpleados.inicio = inicio;
    this.datosRegistroEmpleados.fin = fin;
    this.datosRegistroEmpleados.empleadoSeleccionado = empleadoSeleccionado;
  }



  public logAgrega(log: string) {
    this._log.push(log);
  }

  public logAgrega2(log: string) {
    this._log.push(log);
    console.log(log);
  }

  public log(): string[] {
    return this._log;
  }
}
