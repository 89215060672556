import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { EmpleadoMigracion, estadosCiviles, IEmpleados, nombreEmpleado, sexos, TipoEmpleado, tiposCFDIContrato, tiposCFDIJornada, tiposCFDIRegimen, tiposCFDIRegimenFiscal, tiposCFDIRiesgoPuesto, tiposJornadas, tiposSueldo, tiposTrabajadores } from 'src/app/models/empleados';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { addDays, fFecha, fFechaCV, soloDia } from '../../../Funciones/fFecha';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import * as ExcelJS from 'exceljs';
import { MatSnackBar } from '@angular/material/snack-bar';
import Swal from 'sweetalert2';
import { ArchivoExcel, ColumnaExcel, RenglonesExcel, TítulosExcel, creaArchivoExcel } from 'src/app/core/Funciones/excel';
import { IEmpresa, empresaDesdeAcceso } from 'src/app/models/empresa';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { eliminarDiacriticosEs, fechaT } from 'src/app/core/Funciones/fTexto';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { BreadcrumbService } from 'src/app/core/services/breadcrumb.service';
import { mostrarSwalConfirm, mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { MatDialog } from '@angular/material/dialog';
import { EmpleadosValidarIncidenciasComponent } from 'src/app/empleados/empleados-validar-incidencias/empleados-validar-incidencias.component';
import { IRespuesta } from 'src/app/models/resultadoActualiza';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator, MatPaginatorIntl } from '@angular/material/paginator';
import { EmpleadosContratoComponent } from 'src/app/empleados/empleados-contrato/empleados-contrato.component';
import { EmpleadosControlVacacionesComponent } from 'src/app/empleados/empleados-control-vacaciones/empleados-control-vacaciones.component';

@Component({
  selector: 'app-empleados',
  templateUrl: './empleados.component.html',
  styleUrls: ['./empleados.component.scss'],
})
export class EmpleadosComponent implements OnInit {
  public empleados: IEmpleados[] = [];
  public empleadoSeleccionado: IEmpleados | undefined = undefined;
  public empleadosTotales: IEmpleados[] = [];
  public esListado: boolean = false;
  public cardConFoto: boolean = true;
  public esDebugger: boolean = false;
  public iconoMostrar = 'view_agenda';
  public busqueda: string = '';
  public empleadoNoEncontrado: boolean = false;
  public selectedFile: File | undefined = undefined;
  public nuevosEmpleados: EmpleadoMigracion[] = [];
  public empresa: IEmpresa | undefined = undefined;
  public accesoExpress: AccesoExpress;
  public mostrarBajas: boolean = false;
  public mostrarSoloAdmin: boolean = false;
  public hoy = new Date();
  public registroIncidencias: boolean = false;
  public imagenesRegistradasRF: boolean = false;
  public mostrarDocumentos: boolean = false;

  public selectedFiltro: string = 'sinFiltro';
  public selectedAcomodo: string = 'clave';

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  public empleadosObs!: Observable<any>;
  public dataSource: MatTableDataSource<IEmpleados> = new MatTableDataSource<IEmpleados>(this.empleados);

  public displayedColumns: string[] = ['clave', 'nombre', 'rfc', 'alta', 'detalles'];
  public cargando: boolean = false;

  public opcionesFiltros = {
    'qr': 'Mostrar empleados activos que no tienen QR',
    'RF': 'Mostrar empleados activos que no tienen Reconocimiento Facial',
    'sinFiltro': 'Mostrar todos los empleados activos',
  }

  public opcionesFiltrosAcomodo = {
    'nombre': 'Ordenar por nombre',
    'fechaAlta': 'Ordenar por fecha de alta',
    'clave': 'Ordenar por clave',
    'puesto': 'Ordenar por puesto',
    'area': 'Ordenar por area',
    'sinAcomodo': 'Mostrar todos',
  }

  @Output()
  public cambiarMenuEmpleados = new EventEmitter<boolean>();

  @Output()
  public empleadoNuevo = new EventEmitter<boolean>();

  @Output()
  public datosEmpleado = new EventEmitter<IEmpleados>();

  @Input()
  public verificarMenuEmpleadosEvent = new EventEmitter<void>();

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private paginadoLabel: MatPaginatorIntl,
    private breadcrumbService: BreadcrumbService,
    private _snackBar: MatSnackBar,
    private nomiExpressApi: NomiExpressApiService,
    private accesoDatosService: AccesoDatosService,
    public dialog: MatDialog,
  ) {
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.empresa = empresaDesdeAcceso(this.accesoExpress);
    this.cargarEmpleados();
    this.paginadoLabel.itemsPerPageLabel = 'Empleados por página';
  }

  public cargarEmpleados() {
    this.cargando = true;

    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.empleadosTotales = empleados;
        this.dataSource.data = empleados;
        this.aplicarFiltros();
        this.empleados.forEach(empleado => {
          (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
        });
        this.cargando = false;
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
        this.empleadosTotales = [];
        this.dataSource.data = [];
        this.cargando = false;
      }
    );
  }

  public aplicarFiltros() {
    this.cargando = true;
    let empleadosFiltrados = this.empleadosTotales;

    if (this.mostrarBajas) {
      this.empleados = empleadosFiltrados;
    } else if (this.mostrarSoloAdmin) {
      empleadosFiltrados = empleadosFiltrados.filter(x => x.tipoEmpleado !== TipoEmpleado.Normal);
    }  else {
      empleadosFiltrados = empleadosFiltrados.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime() || x.baja.getTime() >= new Date().getTime());
    }

    this.empleados = empleadosFiltrados.sort((a, b) =>(a.nombre + a.paterno).toLowerCase().localeCompare((b.nombre + b.paterno).toLowerCase()));

    if (this.selectedFiltro === 'qr') {
      this.empleados = this.empleados.filter(x => !x.qrCode || !Object.keys(x.qrCode).length);
    } else if (this.selectedFiltro === 'RF') {
      this.empleados = this.empleados.filter(x => !x.datosRF || !x.tieneDatosRF);
    }

    if (this.selectedAcomodo === 'nombre') {
      this.empleados = this.empleados.sort((a, b) => a.nombre.toLowerCase().localeCompare(b.nombre.toLowerCase()));
    } else if (this.selectedAcomodo === 'fechaAlta') {
      this.empleados = this.empleados.sort((a, b) => a.alta.getTime() - b.alta.getTime());
    } else if (this.selectedAcomodo === 'clave') {
      this.empleados = this.empleados.sort((a, b) => +a.numero - +b.numero);
    } else if (this.selectedAcomodo === 'area') {
      this.empleados = this.empleados.sort((a, b) => {
        const areaA = a.area ? a.area.toLowerCase() : '';
        const areaB = b.area ? b.area.toLowerCase() : '';
        if (areaA && !areaB) return -1;
        if (!areaA && areaB) return 1;
        return areaA.localeCompare(areaB);
      });
    } else if (this.selectedAcomodo === 'puesto') {
      this.empleados = this.empleados.sort((a, b) => {
        const puestoA = a.puesto ? a.puesto.toLowerCase() : '';
        const puestoB = b.puesto ? b.puesto.toLowerCase() : '';
        if (puestoA && !puestoB) return -1;
        if (!puestoA && puestoB) return 1;
        return puestoA.localeCompare(puestoB);
      });
    }

    this.dataSource.data = this.empleados;
    this.cargando = false;
  }

  public buscarEmpleado(value: IEmpleados | string) {
    let filterValue: string = '';

    if (typeof value === 'string') {
      filterValue = value.toLowerCase().trim();
    } else if (value && value.nombre) {
      filterValue = value.nombre.toLowerCase().trim();
    }

    if (filterValue) {
      this.empleados = this.empleadosTotales.filter(empleado =>
        empleado.nombre.toLowerCase().includes(filterValue)
      );
      this.dataSource.data = this.empleados;

      this.empleadoNoEncontrado = this.empleados.length === 0;
    } else {
      this.aplicarFiltros();
      this.empleadoNoEncontrado = false;
    }
  }

  public limpiarFiltro() {
    this.empleadoNoEncontrado = false;
    this.empleadoSeleccionado = undefined;
    this.cargarEmpleados();
  }

  public verListado() {
    this.esListado = !this.esListado;
    this.aplicarFiltros();
    if (this.iconoMostrar === 'view_agenda') {
      this.iconoMostrar = 'dataset';
    } else {
      this.iconoMostrar = 'view_agenda';
    }
  }

  public nombreCompleto(empleado: IEmpleados){
    return nombreEmpleado(empleado);
  }

  public formatoFecha(fecha: any) {
    return fFecha(fecha, "FSL");
  }

  public formatoFechaCorto(fechaTxt: Date | undefined): string {
    if (!fechaTxt) {
      return '-';
    }
    const fecha = new Date(fechaTxt);
    return fFecha(fecha, "fc");
  }

  public abrirEmpleado(empleado?: IEmpleados) {
    let idEmpleado: number = !empleado || !empleado.id ? 0 : +empleado.id;
    console.log(`abrirEmpleado`, idEmpleado);
    this.empleadoNuevo.emit(idEmpleado == 0);
    this.accesoDatosService.empleadoActual(idEmpleado);
    this.cambiarMenuEmpleados.emit(true);
  }

  // private actualizarEmpleado(empleadoActualizado: IEmpleados) {
  //   this.empleados.push(empleadoActualizado);
  //   Swal.fire({
  //     html: 'Empleado agregado correctamente',
  //     icon: "success"
  //   });
  // }

  public async importarArchivo() {
    type ImportType = 'nomiExpress' | 'contpaq' | 'sua';

    const acceptTypes: Record<ImportType, string> = {
      nomiExpress: '.xlsx, .xls',
      contpaq: '.xlsx',
      sua: '.txt',
    };

    const { value: formValues } = await Swal.fire({
      title: "Seleccione el tipo de archivo y el archivo a importar",
      html: `
        <select id="importType" class="form-select">
          <option value="" disabled selected>Seleccione el tipo de archivo</option>
          <option value="nomiExpress">Archivo NomiExpress</option>
          <option value="contpaq">Archivo Contapq</option>
          <option value="sua">Importar del SUA</option>
        </select>
        <input type="file" id="fileInput" class="form-control mt-3">
      `,
      width: 'auto',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      cancelButtonColor: 'red',
      confirmButtonText: 'Importar archivo',
      confirmButtonColor: 'green',
      preConfirm: () => {
        const importType = (document.getElementById('importType') as HTMLSelectElement).value;
        const fileInput = (document.getElementById('fileInput') as HTMLInputElement);
        const file = fileInput.files ? fileInput.files[0] : null;

        if (!importType) {
          Swal.showValidationMessage("Necesitas seleccionar un tipo de importación.");
          setTimeout(() => {
            Swal.resetValidationMessage()
          }, 3000);
          return null;
        }

        if (!file) {
          Swal.showValidationMessage("Necesitas seleccionar un archivo.");
          setTimeout(() => {
            Swal.resetValidationMessage()
          }, 3000);
          return null;
        }

        return { importType, file };
      },
      didOpen: () => {
        // Swal.disableButtons();
        const importTypeElement = document.getElementById('importType') as HTMLSelectElement;
        const fileInputElement = document.getElementById('fileInput') as HTMLInputElement;
        const fileInput = document.getElementById('fileInput') as HTMLInputElement;

        // fileInput.addEventListener('change', () => {
        //   if (fileInput.value) {
        //     Swal.enableButtons();
        //   } else {
        //     Swal.disableButtons();
        //   }
        // });

        importTypeElement.addEventListener('change', (event) => {
          const selectedImportType = (event.target as HTMLSelectElement).value as ImportType;
          fileInputElement.setAttribute('accept', acceptTypes[selectedImportType]);
        });
      }
    });

    if (formValues) {
      const { importType, file } = formValues;

      const fileBuffer = await this.readFileAsArrayBuffer(file);
      if (importType === 'nomiExpress') {
        await this.procesarArchivoNomiExpress(fileBuffer);
      }
      if (importType === 'contpaq') {
        await this.procesarArchivoContpaq(fileBuffer);
      }
      if (importType === 'sua') {
        await this.procesarArchivoSua(fileBuffer);
      }
    }
  }

  private async readFileAsArrayBuffer(file: File): Promise<ArrayBuffer> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result instanceof ArrayBuffer) {
          resolve(reader.result);
        } else {
          reject(new Error("No se pudo leer el archivo como un ArrayBuffer."));
        }
      };
      reader.onerror = () => {
        reject(new Error("Error al leer el archivo."));
      };
      reader.readAsArrayBuffer(file);
    });
  }

  public async procesarArchivoNomiExpress(file: ArrayBuffer) {
    this.nuevosEmpleados = [];

    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);

    const worksheet = workbook.getWorksheet(1);

    if (!worksheet || worksheet.actualRowCount <= 1) {
      mostrarSwalError("Error al cargar el archivo", 'La hoja de cálculo no contiene datos. Por favor, asegúrate de que el archivo tenga datos a partir de la segunda fila.');
      return;
    }

    let rowIndex = 0;
    let columnMap: { [key: string]: number } = {};  // nos sirve para tener el nombre de la columna, pero no nos da de manera sencilla el numero de columna
    let columnMapNum: { [key: number]: string } = {}; // este se usa en la carga de datos, ya que tenemos el numero de la columna obtenemos el nombre de la columna YA formateado

    for (let index = 1; index <= 10; index++) {
      rowIndex = index;
      let headerRow = worksheet.getRow(index);
      headerRow.eachCell((cell, colNumber) => {
        const columnName = this.nombreColumna(cell.text);
        columnMap[columnName] = colNumber;
        columnMapNum[colNumber] = columnName;
      });

      if (this.tieneColumnasParaImportar(columnMap)) break;
      columnMap = {};
      columnMapNum = {};
    }

    if (!this.tieneColumnasParaImportar(columnMap)) {
      mostrarSwalError("Error al cargar el archivo", 'La hoja de cálculo no contiene las columnas necesarias para cargar la información, estas son: nombre, apellido paterno y/o materno, RFC y alta');
      return;
    };

    worksheet.eachRow({ includeEmpty: false }, (row) => {
      if (row.number <= rowIndex) return;

      const nuevoEmpleado: EmpleadoMigracion = new EmpleadoMigracion();
      nuevoEmpleado.aplicarSubsidio = true;

      row.eachCell((cell, colNumber) => {
        const valor = cell.value as string;
        if (!valor || valor === null || valor == '-') return;

        const colName = columnMapNum[colNumber];
        if (!colName) return;

        if (this.esColumnaFecha(colName)) {
          let valorFecha: Date = cell.value as Date;
          if (cell.type == 3) {
            valorFecha = fFechaCV(valor);
          }
          if (valorFecha.getHours() >= 15) {
            valorFecha = addDays(soloDia(valorFecha), 1);
          } else if (valorFecha.getHours() > 0) {
            valorFecha = soloDia(valorFecha);
          }
          this.cargarCampoFecha(nuevoEmpleado, colName, valorFecha);
          return;
        }

        this.cargarCampo(nuevoEmpleado, colName, valor);
      });

      if (!!nuevoEmpleado.nombre && (!!nuevoEmpleado.apellidoPaterno || !!nuevoEmpleado.apellidoMaterno)) {
        this.nuevosEmpleados.push(nuevoEmpleado);
      }
    });

    this.importarEmpleados();
  }

  private importarEmpleados() {
    if(!this.nuevosEmpleados || this.nuevosEmpleados.length < 1) {
      mostrarSwalError("Error al importar los empleados", 'No existen empleados a importar en el archivo seleccionado.');
      return;
    }

    this.accesoDatosService.importarEmpleados(this.nuevosEmpleados).subscribe(
      (respuesta: IRespuesta) => {
        console.log(`Importar empleados`, respuesta);
        if (respuesta.codigo == 100) {
          mostrarSwalConfirm(`${this.nuevosEmpleados.length} ${this.nuevosEmpleados.length > 1 ? 'empleados' : 'empleado'} ${this.nuevosEmpleados.length > 1 ? 'cargados' : 'cargado'} correctamente`, 'success');
          this.cargarEmpleados();
        } else {
          mostrarSwalError("Error al importar los empleados", `No fue posible realizar la importación. Respuesta del servidor: ${respuesta.codigo}, ${respuesta.mensaje}`);
        }
      },
      (error) => {
        console.log(`Error al importar empleados`, error);
        let errTxt: string = error.error;
        if (!errTxt) {
          errTxt = error.message;
        }
        mostrarSwalError("Error al importar los empleados", `No fue posible realizar la importación. Error: ${errTxt}`);
      }
    );

  }

  private tieneColumnasParaImportar(columnMap: { [key: string]: number }): boolean {
    // if (!('clave' in columnMap || 'numero' in columnMap)) return false;
    if (!('nombre' in columnMap || 'nombres' in columnMap || 'nombress' in columnMap)) {
      // console.log(`sin la columna de nombres`);
      return false;
    }
    if (!('apellidopaterno' in columnMap || 'appaterno' in columnMap || 'paterno' in columnMap || 'apellidomaterno' in columnMap || 'apmaterno' in columnMap || 'materno' in columnMap)) {
      console.log(`sin la columna de apellidos`);
      return false;
    }
    if (!('rfc' in columnMap || 'imss' in columnMap)) {
      console.log(`sin la columna de RFC`);
      return false;
    }
    if (!('alta' in columnMap || 'alta/reingreso' in columnMap || 'fechaingresomasreciente' in columnMap || 'ingreso' in columnMap || 'fechaingreso' in columnMap)) {
      console.log(`sin la columna de fecha de ingreso`);
      return false;
    }
    // console.log(`Columnas completas`);
    return true;
  }

  private esColumnaFecha(colName: string): boolean {
    if (colName == 'alta' || colName == 'alta/reingreso' || colName == 'fechaingresomasreciente' || colName == 'ingreso') return true;
    if (colName == 'baja' || colName == 'fechabaja') return true;
    if (colName == 'fechanac' || colName == 'nacimiento' || colName == 'fechanacimiento') return true;
    return false;
  }

  private nombreColumna(nombreColumna: string): string {
    if (!nombreColumna) return '';
    let col = eliminarDiacriticosEs(nombreColumna.toLowerCase()).replace(/\./g, "").replace(/\,/g, "").replace(/\-/g, "").replace(/\(/g, "").replace(/\)/g, "");
    col = col.replace(/ de /g, " ");
    col = col.replace(/ del /g, " ");
    col = col.replace(/ la /g, " ");
    col = col.replace(/ el /g, " ");
    col = col.replace(/ los /g, " ");
    col = col.replace(/ al /g, " ");
    col = col.replace(/ actual /g, " ");
    col = col.replace(/ actual/g, " ");
    col = col.replace(/ /g, "");
    return col;
  }

  private cargarCampo(empleado: EmpleadoMigracion, col: string, valor: string | undefined) {
    if (!valor) return;
    // const colNormalized = eliminarDiacriticosEs(col.toLowerCase()).replace(/\./g, "").replace(/\,/g, "").replace(/\-/g, "");

    // let tablas: TablasNominaDataSource = new TablasNominaDataSource(undefined);

    switch (col) {
      case "clave":
      case "numero":
          empleado.clave = valor;
          break;
      case "nombre":
      case "nombres":
      case "nombress":
          empleado.nombre = valor.toString().trim();
          break;
      case "apellidopaterno":
      case "appaterno":
      case "paterno":
          empleado.apellidoPaterno = valor.toString().trim();
          break;
      case "apellidomaterno":
      case "apmaterno":
      case "materno":
          empleado.apellidoMaterno = valor.toString().trim();
          break;
      case "rfc":
          empleado.rfc = valor.replace(/\./g, "").replace(/\,/g, "").replace(/\-/g, "").replace(/\(/g, "").replace(/\)/g, "").trim();
          break;
      case "curp":
          empleado.curp = valor;
          break;
      case "imss":
      case "numeroregistrosegurosocial":
      case "segurosocial":
          empleado.imss = valor;
          break;
      case "departamento":
      case "nombredepartamento":
          empleado.departamento = valor.toString().trim();
          break;
      case "correo":
      case "correoelectronico":
      case "email":
      case "mail":
          empleado.correo = valor;
          break;
      case "area":
          empleado.area = valor.toString().trim();
          if (empleado.area.length > 32) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Area, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}, el texto es demasiado grande para este campo (32)`);
          }
          break;
      case "puesto":
          empleado.puesto = valor.toString().trim();
          if (empleado.puesto.length > 32) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Puesto, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}, el texto es demasiado grande para este campo (32)`);
          }
          break;
      case "metodopago":
          empleado.metodoDePago = valor;
          break;
      case "banco":
      case "bancodeposito":
      case "banconomina":
      case "bancodepositonomina":
          empleado.banco = valor;
          break;
      case "cuentabancaria":
      case "numerocuenta":
      case "numerobancario":
      case "numerocuentabancaria":
          empleado.cuentaBanco = valor;
          break;
      case "tarjetabancaria":
      case "tarjeta":
      case "numerotarjetabancaria":
          empleado.numTarjetaBanco = valor;
          break;
      case "clabe":
      case "clabebancaria":
      case 'clabeinterbancaria':
      case "clabecuentabancaria":
          empleado.clabe = valor;
          break;
      case "vales":
      case "cuentavales":
          empleado.cuentaVales = valor;
          break;
      case "padre":
      case "nombrepadre":
          empleado.padre = valor;
          break;
      case "madre":
      case "nombremadre":
          empleado.madre = valor;
          break;
      case "fechanac":
      case "nacimiento":
      case "fechanacimiento":
          empleado.nacimientoFecha = valor;
          break;
      case "ciudadnacimiento":
          empleado.nacimientoLugar = valor;
          break;
      case "estadonacimiento":
          empleado.nacimientoEstado = valor;
          break;
      case "sexo":
          if (isNaN(+valor)) {
            let sexo: number | undefined = sexos[valor.toLowerCase()];
            if (!sexo) {
              throw new Error(`El valor: '${valor}', no es válido para el campo: Sexo, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
            }
            empleado.sexo = sexo.toString();
          } else {
            empleado.sexo = valor;
          }
          break;
      case "estadocivil":
        if (isNaN(+valor)) {
          let estadoCivil: number | undefined = estadosCiviles[valor.toLowerCase()];
          if (!estadoCivil) {
              throw new Error(`El valor: '${valor}', no es válido para el campo: Estado Civil, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
            }
          empleado.estadoCivil = estadoCivil.toString();
        } else {
          empleado.estadoCivil = valor;
        }
          break;
      case "umf":
      case "unidadmedicafamiliar":
          empleado.umf = valor;
          break;
      case "domicilio":
          empleado.domicilioCalle = valor;
          break;
      case "noexterior":
      case "numexterior":
      case "numeroexterior":
      case "numeroexteriordomicilio":
          empleado.domicilioNumeroExterior = valor;
          break;
      case "nointerior":
      case "numinterior":
      case "numerointerior":
      case "numerointeriordomicilio":
          empleado.domicilioNumeroInterior = valor;
          break;
      case "col":
      case "colonia":
      case "coloniadomicilio":
          empleado.domicilioColonia = valor;
          break;
      case "mun":
      case "municipio":
      case "municipiodomicilio":
          empleado.domicilioMunicipio = valor;
          break;
      case "poblacio":
      case "poblaciondomicilio":
          empleado.domicilioPoblacion = valor;
          break;
      case "edo":
      case "estado":
      case "estadodomicilio":
          empleado.domicilioEstado = valor;
          break;
      case "cp":
      case "codigopostal":
          empleado.domicilioCodigoPostal = valor;
          break;
      case "tel":
      case "telefono":
          empleado.domicilioTelefono = valor;
          break;
      case "tiposueldo":
        if (isNaN(+valor)){
          let tipoSueldo: number | undefined = tiposSueldo[valor.toLowerCase()];
          if (!tipoSueldo) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Tipo de Sueldo, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
          }
          empleado.tipoSueldo = tipoSueldo;
        } else {
          empleado.tipoSueldo = +valor;
        }
        break;
      case "jornada":
      case "duracionjornada":
        empleado.duracionJornada = valor;
        break;
      case "tipotrabajador":
        if (isNaN(+valor)){
          let tipoTrabajador: number | undefined = tiposTrabajadores[valor.toLowerCase()];
          if (!tipoTrabajador) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Tipo de Trabajador, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
          }
          empleado.tipoTrabajador = tipoTrabajador;
        } else {
          empleado.tipoTrabajador = +valor;
        }
        break;
      case "tipojornada":
        if (isNaN(+valor)){
          let tipoJornada: number | undefined = tiposJornadas[valor.toLowerCase()];
          if (!tipoJornada) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Tipo de Jornada, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
          }
          empleado.tipoJornada = tipoJornada;
        } else {
          empleado.tipoJornada = +valor;
        }
        break;
      case "sindicalizado":
          empleado.sindicalizado = (valor === '-1' || valor === 'Si' || valor === 'True');
          break;
      case "diascotizados":
      case "diascotizadosimss":
      case "manejodiascotizadosimss":
        if (isNaN(+valor)){
          throw new Error(`El valor: '${valor}', no es válido para el campo: Días cotizados al IMSS, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        }
        empleado.manejoDiasCotizadosAlImss = +valor;
        break;
      case "chofer":
          empleado.chofer = valor;
          break;
      case "campo":
          empleado.campo = valor;
          break;
      case "aplicarsubsidio":
          empleado.aplicarSubsidio = (valor === '-1' || valor === 'Si' || valor === 'True');
          break;
      case "manejodosdepartamentos":
          empleado.manejoDosDepartamentos = (valor === '-1' || valor === 'Si' || valor === 'True');
          break;
      case 'diadescanso':
      case "diasdescanso":
        let diasDescanso: string[] = [];
        let dias: string[] = valor.split(',');
        for (let index = 0; index < dias.length; index++) {
          let dia: string = dias[index];
          if (dia.length > 2) dia = dia.substring(0, 2);
          switch (dia.toLowerCase()) {
            case '1':
            case 'l':
            case 'lu':
              diasDescanso.push('1');
              break;
            case '2':
            case 'm':
            case 'ma':
              diasDescanso.push('2');
              break;
            case '3':
            case 'mi':
              diasDescanso.push('3');
              break;
            case '4':
            case 'j':
            case 'ju':
              diasDescanso.push('4');
              break;
            case '5':
            case 'v':
            case 'vi':
              diasDescanso.push('5');
              break;
            case '6':
            case 's':
            case 'sa':
              diasDescanso.push('6');
              break;
            case '0':
            case 'd':
            case 'do':
              diasDescanso.push('0');
              break;
          }
        }
        empleado.diasDescanso = diasDescanso.join(',');
        break;
      case "primadominical":
        if (isNaN(+valor)){
          throw new Error(`El valor: '${valor}', no es válido para el campo: Prima dominical, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        }
        empleado.primaDominical = +valor;
        break;
      case "tieneinfonavit":
          empleado.tieneInfonavit = (valor === '-1' || valor === 'Si' || valor === 'True');
          break;
      case "recibocampotitulo":
          empleado.reciboCampoTitulo = valor;
          break;
      case "recibocampoconcepto":
          empleado.reciboCampoConcepto = valor;
          break;
      case "horaspornomina":
        if (!isNaN(+valor)) {
          empleado.horasPorNomina = +valor;
        }
        break;
      case "sueldovariable":
        if (isNaN(+valor)){
          throw new Error(`El valor: '${valor}', no es válido para el campo: Sueldo Variable, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        }
        empleado.sueldoVariable = +valor;
        break;
      case "horaspordia":
        if (isNaN(+valor)){
          throw new Error(`El valor: '${valor}', no es válido para el campo: Horas por día, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        }
        empleado.horasPorDia = +valor;
        break;
      case "asimiladoasalario":
          empleado.asimiladoSalario = (valor === '-1' || valor === 'Si' || valor === 'True');
          break;
      case "nominaporhora":
          empleado.nominaPorHora = valor;
          break;
      case "factordiashoras":
        if (isNaN(+valor)){
          throw new Error(`El valor: '${valor}', no es válido para el campo: Factor día / hora, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        }
        empleado.factorDiasHoras = +valor;
        break;
      case "tiporegimen":
      case "tiporegimenfiscal":
        if (isNaN(+valor)){
          let tipoCFDIRegimen: number | undefined = tiposCFDIRegimen[valor.toLowerCase()];
          if (!tipoCFDIRegimen) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Tipo Régimen Fiscal, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
          }
          empleado.cfdiRegimen = tipoCFDIRegimen;
        } else {
          empleado.cfdiRegimen = +valor;
        }
        break;
      case "tipocontrato":
        if (isNaN(+valor)){
          let tipoCFDIContrato: number | undefined = tiposCFDIContrato[valor.toLowerCase()];
          if (!tipoCFDIContrato) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Tipo de Contrato, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
          }
          empleado.cfdiContrato = tipoCFDIContrato;
        } else {
          empleado.cfdiContrato = +valor;
        }
        break;
      case "tipojornada":
      case 'tipojornadatrabajo':
        if (isNaN(+valor)){
          let tipoCFDIJornada: number | undefined = tiposCFDIJornada[valor.toLowerCase()];
          if (!tipoCFDIJornada) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Tipo de Jornada, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
          }
          empleado.cfdiJornada = tipoCFDIJornada;
        } else {
          empleado.cfdiJornada = +valor;
        }
        break;
      case "riesgopuestoanteimss":
        if (isNaN(+valor)){
          let tipoCFDIRiesgoPuesto: number | undefined = tiposCFDIRiesgoPuesto[valor.toLowerCase()];
          if (!tipoCFDIRiesgoPuesto) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Riesgo de Puesto IMSS, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
          }
          empleado.cfdiRiesgo = tipoCFDIRiesgoPuesto;
        } else {
          empleado.cfdiRiesgo = +valor;
        }
        break;
      case "bancodeposito":
          empleado.cfdiBanco = valor;
          break;
      case "clabebancaria":
          empleado.cfdiClabe = valor;
          break;
      case "tipopago":
          empleado.cfdiPago = valor;
          break;
      case "rfcpatronorigen":
          empleado.cfdiOrigen = valor;
          break;
      case "movimientos":
          empleado.movimientos = valor;
          break;
      case "prestacionaguinaldo":
        if (isNaN(+valor)){
          throw new Error(`El valor: '${valor}', no es válido para el campo: Prestación Aguinaldo, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        }
        empleado.prestacionLeyAguinaldo = +valor;
        break;
      case "prestacionprimavacacional":
        if (isNaN(+valor)){
          throw new Error(`El valor: '${valor}', no es válido para el campo: Prestación Prima Vacacional, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        }
        empleado.prestacionLeyPrimaVacacional = +valor;
        break;
      case "prestacionvacaciones":
          empleado.prestacionLeyVacaciones = valor;
          break;
      case "regimenfiscal":
        if (isNaN(+valor)){
          if (!isNaN(+valor.substring(0, 3))) {
            valor = valor.substring(0, 3);
          }
        }
        if (isNaN(+valor)){
          let tipoCFDIRegimenFiscal: number | undefined = tiposCFDIRegimenFiscal[valor.toLowerCase()];
          if (!tipoCFDIRegimenFiscal) {
            throw new Error(`El valor: '${valor}', no es válido para el campo: Régimen Fiscal, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
          }
          empleado.regimenFiscal = tipoCFDIRegimenFiscal;
        } else {
          empleado.regimenFiscal = +valor;
        }
        break;
      case "cfdicodigopostal":
          empleado.cfdiCodigoPostal = valor;
          break;
      case "jornadareducida":
          empleado.jornadaReducida = valor;
          break;
      case "alta":
      case "ingreso":
      case "fechaingreso":
      case "alta/reingreso":
      case "fechaingresomasreciente":
        empleado.altaTxt = valor;
        break;
      case "baja":
      case "fechabaja":
          empleado.bajaTxt = valor;
          break;
      case "sdo":
      case "sueldo":
      case "sueldodiario":
        if (isNaN(+valor)) {
          throw new Error(`El valor: '${valor}', no es válido para el campo: Sueldo Diario, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        } else {
          empleado.sueldoDiario = +valor;
        }
        break;
      case "sdi":
      case "salariodiario":
      case "sueldodiariointegrado":
      case "salariodiariointegrado":
        if (isNaN(+valor)){
          throw new Error(`El valor: '${valor}', no es válido para el campo: Salario Diario Integrado, del empleado: ${empleado.nombre + ' ' + empleado.apellidoPaterno + ' ' + empleado.apellidoMaterno}`);
        } else {
          empleado.SDI = +valor;
        }
        break;
      case 'nombrecompleto':
        if (valor == 'dfvdfv sdcsdcdvc vdfvdf') {
          valor = '';
        }
        break;
      case 'tipodescuentocreditoinfonavit':
        break;
      default:
        console.log(`Columna desconocida: '${col}', valor: ${valor}`);
        break;
    }
  }

  private cargarCampoFecha(empleado: EmpleadoMigracion, col: string, valor: Date) {
    if (!valor) return;
    switch (col) {
      case "fechanac":
      case "nacimiento":
      case "fechanacimiento":
          empleado.nacimientoFecha = fechaT(valor);
          break;
      case "fechaingresomasreciente":
      case "alta":
      case "alta/reingreso":
      case "ingreso":
          empleado.altaTxt = fechaT(valor);
          break;
      case "baja":
      case "fechabaja":
          empleado.bajaTxt = fechaT(valor);
          break;
    }
  }

  public async procesarArchivoContpaq(file: ArrayBuffer) {
    this.nuevosEmpleados = [];

    const workbook = new ExcelJS.Workbook();
    await workbook.xlsx.load(file);

    const worksheet = workbook.getWorksheet(1);

    if (!worksheet || worksheet.actualRowCount <= 1) {
      mostrarSwalError("Error al cargar el archivo", 'La hoja de cálculo no contiene datos. Por favor, asegúrate de que el archivo tenga datos a partir de la segunda fila.');
      return;
    }

    const todasLasCoincidencias: string[] = [];
    const headerRow = worksheet.getRow(1);
    const columnMap: { [key: string]: number } = {};

    headerRow.eachCell((cell, colNumber) => {
      const normalizedColumnName = eliminarDiacriticosEs(cell.value as string).toLowerCase();
      columnMap[normalizedColumnName] = colNumber;
    });

    const columnasObligatorias = ['codigo', 'fecha de alta', 'apellido paterno', 'apellido materno', 'nombre', 'fecha de alta'];

    for (const col of columnasObligatorias) {
      if (!(col in columnMap)) {
        mostrarSwalError("Error al cargar el archivo", `La columna obligatoria ${col} no se encontró en el encabezado.`);
        return;
      }
    }

    worksheet.eachRow({ includeEmpty: false }, (row, rowIndex) => {
      if (rowIndex === 1) return;

      const getCellValue = (row: ExcelJS.Row, colName: string) => {
        const colIndex = columnMap[colName];
        if (!colIndex) return null;
        const cell = row.getCell(colIndex);
        return cell ? cell.value : null;
      };

      let nuevoEmpleado: EmpleadoMigracion = new EmpleadoMigracion();
      nuevoEmpleado.clave = getCellValue(row, 'codigo') as string;
      nuevoEmpleado.nombre = getCellValue(row, 'nombre') as string;
      nuevoEmpleado.apellidoPaterno = getCellValue(row, 'apellido paterno') as string;
      nuevoEmpleado.apellidoMaterno = getCellValue(row, 'apellido materno') as string;
      nuevoEmpleado.rfc = getCellValue(row, 'rfc') as string;
      nuevoEmpleado.curp = getCellValue(row, 'curp') as string;
      nuevoEmpleado.imss = getCellValue(row, 'num seguridad social') as string;
      nuevoEmpleado.departamento = getCellValue(row, 'departamento') as string;
      nuevoEmpleado.correo = getCellValue(row, 'correo electrónico') as string;
      nuevoEmpleado.puesto = getCellValue(row, 'puesto') as string;
      nuevoEmpleado.metodoDePago = getCellValue(row, 'método de pago') as string;
      nuevoEmpleado.banco = getCellValue(row, 'banco para pago electrónico') as string;
      nuevoEmpleado.cuentaBanco = getCellValue(row, 'numero de cuenta para pago electrónico') as string;
      nuevoEmpleado.clabe = getCellValue(row, 'clabe interbancaria') as string;
      nuevoEmpleado.padre = getCellValue(row, 'nombre del padre') as string;
      nuevoEmpleado.madre = getCellValue(row, 'nombre de la madre') as string;
      nuevoEmpleado.nacimientoFecha = getCellValue(row, 'fecha de nacimiento') as string;
      nuevoEmpleado.nacimientoLugar = getCellValue(row, 'ciudad de nacimiento') as string;
      nuevoEmpleado.nacimientoEstado = getCellValue(row, 'entidad federativa de nacimiento') as string;
      nuevoEmpleado.sexo = getCellValue(row, 'sexo') as string;
      nuevoEmpleado.estadoCivil = getCellValue(row, 'estado civil') as string;
      nuevoEmpleado.umf = getCellValue(row, 'umf') as string;
      nuevoEmpleado.domicilioCalle = getCellValue(row, 'direccion') as string;
      nuevoEmpleado.domicilioPoblacion = getCellValue(row, 'poblacion') as string;
      nuevoEmpleado.domicilioCodigoPostal = getCellValue(row, 'cp') as string;
      nuevoEmpleado.domicilioTelefono = getCellValue(row, 'telefono') as string;
      nuevoEmpleado.tipoSueldo = getCellValue(row, 'turno de trabajo') as number;
      nuevoEmpleado.tipoJornada = getCellValue(row, 'tipo de periodo') as number;
      nuevoEmpleado.sindicalizado = getCellValue(row, 'sindicalizado') as boolean;
      nuevoEmpleado.cfdiRegimen = getCellValue(row, 'tipo de regimen') as number;
      nuevoEmpleado.cfdiContrato = getCellValue(row, 'tipo de contrato') as number;
      nuevoEmpleado.altaTxt = getCellValue(row, 'fecha de alta') as string;
      nuevoEmpleado.bajaTxt = getCellValue(row, 'fecha de baja') as string;
      nuevoEmpleado.sueldoDiario = getCellValue(row, 'salario diario') as number;
      nuevoEmpleado.SDI = getCellValue(row, 'sbc parte fija') as number;


      let datosFaltantes = columnasObligatorias.filter(col => !getCellValue(row, col));
      if (datosFaltantes.length > 0) {
        mostrarSwalError("Error al cargar el archivo", `La fila ${rowIndex} tiene las siguientes columnas obligatorias sin datos: ${datosFaltantes.join(', ')}.`);
        return;
      }

      let coincidencias: string[] = [];

      for (const empleadoExistente of this.empleados) {
        if (nuevoEmpleado.rfc && nuevoEmpleado.rfc === empleadoExistente.rfc) {
          coincidencias.push(`El empleado ${this.nombreCompleto(empleadoExistente)} con RFC ${nuevoEmpleado.rfc} ya está registrado.`);
        }
        if (nuevoEmpleado.clave && nuevoEmpleado.clave === empleadoExistente.id) {
          coincidencias.push(`El empleado ${this.nombreCompleto(empleadoExistente)} con ID ${nuevoEmpleado.clave} ya está registrado.`);
        }
      }

      if (coincidencias.length > 0) {
        todasLasCoincidencias.push(...coincidencias);
      } else {
        this.nuevosEmpleados.push(nuevoEmpleado);
      }
    });

    if (todasLasCoincidencias.length > 0) {
      const coincidenciasNumeradas = todasLasCoincidencias.map((mensaje, index) => `${index + 1}. ${mensaje}`);
      const mensajeCompleto = coincidenciasNumeradas.join('<br>');
      Swal.fire({
        title: "Coincidencias encontradas",
        html: mensajeCompleto,
        icon: "warning"
      });
    }

    this.importarEmpleados();
}

  public async procesarArchivoNomilinea(file: ArrayBuffer) {}

  public async procesarArchivoSua(file: ArrayBuffer) {
    const text = new TextDecoder('utf-8').decode(file);
    const lines = text.split('\n');
    this.nuevosEmpleados = [];

    for (const line of lines) {
      let lineaAncho: number = line.trim().length;
      if (lineaAncho < 164) {
        continue;
      }

      let empleado: EmpleadoMigracion = new EmpleadoMigracion();
      const em_imss = line.substring(0, 10).trim(); // 0
      // if (em_imss != this.empresa.imss) {
      //   mostrarSwalError("Error al cargar el archivo SUA", `El numero del Seguro Social de la empresa, no corresponde con la información a importar`);
      //   return;
      // }

      empleado.imss = line.substring(11, 12).trim(); // 1
      empleado.rfc = line.substring(22, 13).trim(); // 2
      empleado.curp = line.substring(35, 18).trim(); // 3
      const nombre = line.substring(53, 50).trim();  // 4
      const tipo = line.substring(103, 1).trim(); // 5
      const jornada = line.substring(104, 1).trim(); // 6
      const alta = line.substring(105, 8).trim(); // 7
      const sbc = +line.substring(113, 7).trim(); // 8
      empleado.clave = line.substring(120, 17).trim(); // 9
      const numeroCréditoINFONAVIT = line.substring(137, 10).trim(); // 10
      const fechaCréditoINFONAVIT = line.substring(147, 8).trim(); // 11
      const tipoCréditoINFONAVIT = line.substring(155, 81).trim(); // 12
      const valorCréditoINFONAVIT = line.substring(155, 81).trim(); // 13

      let nombreEmpleado: string[] = nombre.split('$');
      if (nombreEmpleado.length < 2) {
        mostrarSwalError("Error al cargar el archivo SUA", `El numero del Seguro Social de la empresa, no corresponde con la información a importar`);
        return;
      }
      if (nombreEmpleado.length < 3) {
        empleado.nombre = nombreEmpleado[1];
      } else {
        empleado.nombre = nombreEmpleado[2];
        empleado.apellidoMaterno = nombreEmpleado[1];
      }
      empleado.apellidoPaterno = nombreEmpleado[0];
      if (!isNaN(+tipo) && +tipo >= 1) {
        empleado.tipoTrabajador = +tipo - 1;
      }
      if (!isNaN(+jornada) && +jornada >= 0) {
        empleado.tipoJornada = +jornada;
        if (empleado.tipoJornada == 6) empleado.tipoJornada = 12;
      }
      const fAlta = new Date(+alta.substring(4, 4), +alta.substring(2, 2), +alta.substring(0, 2));
      empleado.altaTxt = fechaT(fAlta);
      if (!isNaN(+sbc) && +sbc > 0) {
        empleado.tipoJornada = +sbc;
      }

      this.nuevosEmpleados.push(empleado);
    }

    this.importarEmpleados();
  }

  public async procesarArchivoIdse(file: ArrayBuffer) {}

  public exportarEmpleados() {
    let títulos: TítulosExcel = {
      titulo1: 'Listado de empleados',
      titulo2: `Empresa: ${this.empresa?.nombre}`,
      titulo3: `Total de empleados: ${this.empleados.length}`,
      titulo4: undefined
    };

    let columnas: ColumnaExcel[] = [
      { header: 'Clave', key: 'clave', width: 9, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Nombre(s)', key: 'nombres', width: 30, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Apellido Paterno', key: 'paterno', width: 17, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Apellido Materno', key: 'materno', width: 17, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Fecha de Ingreso', key: 'ingreso', width: 27, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'RFC', key: 'rfc', width: 18, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'CURP', key: 'curp', width: 24, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Correo', key: 'correo', width: 37, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'IMSS', key: 'imss', width: 13, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Puesto', key: 'puesto', width: 35, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Area', key: 'area', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
    ];

    if (this.esDebugger) {
      columnas.unshift({ header: 'ID', key: 'id', width: 9, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } });
    }

    let títuloColumnas: string[] = columnas.map(columna => columna.header);

    let renglones: RenglonesExcel[] = this.empleados.map(empleado => {
      let datosRenglon: any[] = [
        empleado.numero,
        empleado.nombre,
        empleado.paterno,
        empleado.materno,
        this.formatoFecha(empleado.alta),
        empleado.rfc,
        empleado.curp,
        empleado.correo,
        empleado.imss,
        empleado.puesto,
        empleado.area
      ];

      if (this.esDebugger) {
        datosRenglon.unshift(empleado.id);
      }

      return { datos: datosRenglon };
    });

    let totales: string[] = [];

    let datos: ArchivoExcel = {
      nombreArchivo: `${títulos.titulo1}_${this.empresa?.nombre}.xlsx`,
      títulos: títulos,
      columnas: columnas,
      títuloColumnas: títuloColumnas,
      renglones: renglones,
      totales: totales,
      fuenteTítulo: undefined,
      fuenteRenglones: undefined,
      fuenteTotales: undefined,
    };

    creaArchivoExcel(datos, 'xxx');
  }

  public empleadosBaja(index: any): boolean {
    const empleado = this.empleados[index];
    const baja = empleado.baja ? new Date(empleado.baja) : empleado.bajaActualTxt;
    const fechaPorDefecto = new Date('1900-01-01');
    const hoy = new Date();

    if (!baja || baja > fechaPorDefecto) return true;

    if (baja < hoy) return false;

    return true;
  }

  public mostrarRegistroIncidencias(empleado: IEmpleados | undefined) {
    if (!empleado) {
      mostrarSwalError('Registro de incidencias', 'No se puede localizar al empleado seleccionado');
      return;
    }
    const nombreEmpleado = this.nombreCompleto(empleado);
     this.accesoDatosService.guardarDatosRegistro(new Date(1900, 1, 1), new Date(2500, 1, 1), empleado);
     this.actualizarBreadcrumbs(nombreEmpleado);

    this.registroIncidencias = true;
    this.mostrarDocumentos = false;
    this.imagenesRegistradasRF = false;
    this.empleadoSeleccionado = empleado;
  }

  public mostrarListadoImagenesRegistradasRF() {
    this.imagenesRegistradasRF = true;
    this.registroIncidencias = false;
    this.mostrarDocumentos = false;
    console.log(`Mostrar registro de incidencias ${this.imagenesRegistradasRF}, ${this.registroIncidencias}, ${this.mostrarDocumentos}`);
  }

  public abrirContratos() {
    this.mostrarDocumentos = true;
    this.imagenesRegistradasRF = false;
    this.registroIncidencias = false;
  }

  private actualizarBreadcrumbs(nombreEmpleado: string) {
    const breadcrumbs = this.construirBreadcrumbs(nombreEmpleado);
    this.breadcrumbService.actualizarBreadcrumbsEmpleado(breadcrumbs);
  }

  private construirBreadcrumbs(nombreEmpleado: string): string[] {
      return [nombreEmpleado, 'Registro de incidencias'];
  }

  public handleCerrarRegistroIncidencias() {
    console.log(`EMPLEADOS ==> handleCerrarRegistroIncidencias`);
    this.registroIncidencias = false;
    this.imagenesRegistradasRF = false;
    this.cambiarMenuEmpleados.emit(false);
  }

  public handleCerrarDocumentos() {
    console.log(`EMPLEADOS ==> handleCerrarRegistroIncidencias`);
    this.mostrarDocumentos = false;
    this.cambiarMenuEmpleados.emit(false);
  }

  public handleCerrarRegistroRF() {
    console.log(`EMPLEADOS ==> handleCerrarRegistroRF`);
    this.imagenesRegistradasRF = false;
    this.cambiarMenuEmpleados.emit(false);
  }

  public validarIncidenciaDialog() {
    const validarIncidencia = this.dialog.open(EmpleadosValidarIncidenciasComponent, {
      data: undefined,
      height: '90vh'
    });

    validarIncidencia.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  public controlVacacionesDialog() {
    const controlVacaciones = this.dialog.open(EmpleadosControlVacacionesComponent, {
      data: undefined,
      width: '90vw',
      maxWidth: '90vw',
      height: 'auto',
      maxHeight: '90vh',
    });

    controlVacaciones.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  public abrirCOntratoBiometrico() {
    const contrato = this.dialog.open(EmpleadosContratoComponent, {
      data: { enEdicon: true, idEmpleado: 0 },
      width: '80vw',
      height: 'auto'
    });

    contrato.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  ngOnInit() {
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.empleadosObs = this.dataSource.connect();
    this.aplicarFiltros();

    this.verificarMenuEmpleadosEvent.subscribe(
      () => {
        console.log('verificarMenuEmpleadosEvent');
        this.registroIncidencias = false;
        this.imagenesRegistradasRF = false;
      }
    );
  }
}
