import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-recargar',
  templateUrl: './recargar.component.html',
  styleUrls: ['./recargar.component.scss']
})
export class RecargarComponent implements OnInit {

  constructor(
    private readonly _router: Router
  ) {  }

  ngOnInit() {
    setTimeout(() => {
      this._router.navigate(['']);
    }, 5000);
  }

}
