<div *ngIf="cargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div class="mt-4" *ngIf="!cargando">
  <div class="mb-3" *ngIf="!estaVigente">
    <h3 class="text-danger">La vigencia del sistema termina el {{formatoFecha(empresa.vigenteHastaFecha)}}, solo se muestran registros anteriores a esta fecha</h3>
  </div>
  <!-- ================================== filtros ================================== -->
  <div class="row g-3 text-start mb-2 px-2 mx-auto">
    <!-- ================================== filtro periodo ================================== -->
    <div [ngClass]="{'col-6': periodo !== 'personalizado', 'col-3': periodo === 'personalizado'}">
      <label for="fecha" class="form-label mb-0">Periodo</label>
      <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="periodo" (change)="cambiarPeriodo()">
        <option *ngFor="let tipoPeriodo of tipoPeriodoReporte | keyvalue: valuePeriodoAscOrder" [value]="tipoPeriodo.key">
          {{ tipoPeriodo.value }}
        </option>
      </select>
    </div>
    <!-- ================================== periodo personalizado ================================== -->
    <div class="col-3" *ngIf="periodo === 'personalizado'">
      <div class="example-form">
        <label for="filtroEmpleado" class="form-label mb-0">Periodo personalizado</label>
        <div class="d-flex align-items-center form-control form-control-sm" style="height: 31px;" (click)="picker.open()">
          <mat-date-range-input [formGroup]="rangoPersonalizado" [rangePicker]="picker">
            <input matStartDate formControlName="desde" placeholder="Desde" readonly>
            <input matEndDate formControlName="hasta" placeholder="Hasta" readonly>
          </mat-date-range-input>
          <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker>
            <!-- <mat-date-range-picker-actions>
              <button mat-raised-button class="text-danger" (click)="limpiarFecha(picker)">
                <mat-icon>delete</mat-icon>
              </button>
              <button mat-raised-button color="primary" (click)="dateRangeChange(dateRangeStart, dateRangeEnd)">Aplicar</button>
            </mat-date-range-picker-actions> -->
          </mat-date-range-picker>
        </div>
      </div>
    </div>

    <!-- ================================== filtro reporte ================================== -->
    <div class="col-4" [ngClass]="{'col-6': reporteSeleccionado === 'registroFoto'}">
      <label class="form-label mb-0">Tipo de reporte</label>
      <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="reporteSeleccionado" (change)="cambioReporte()">
        <option *ngFor="let opcion of tipoReporte | keyvalue" [value]="opcion.key">
          {{ opcion.value }}
        </option>
      </select>
    </div>

    <!-- ================================== filtro grupo ================================== -->
    <div class="col-2" *ngIf="reporteSeleccionado !== 'registroFoto'">
      <label class="form-label mb-0">{{etiquetaAgrupar}}</label>
      <div class="input-group">
        <select class="form-select form-select-sm" [(ngModel)]="agrupadorSeleccionado" (ngModelChange)="cambiarAgrupador()">
          <option value="" selected disabled hidden>Etiqueta</option>
          <option *ngFor="let grupo of grupoEmpleados" [value]="grupo">
            {{ grupo }}
          </option>
        </select>
        <button *ngIf="agrupadorSeleccionado" mat-raised-button type="button" class="text-primary" (click)="limpiarFiltroAgrupador()">
          <mat-icon fontIcon="cancel" class="m-0"></mat-icon>
        </button>
      </div>
    </div>

    <!-- ================================== ver por tipo de incidencia ================================== -->
    <div class="col-2" *ngIf="reporteSeleccionado !== 'registroFoto' && false">
      <div *ngIf="reporteSeleccionado != 'checks'">
        <label class="form-label mb-0">Ver por</label>
        <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="verPorSeleccionado">
          <option *ngFor="let opcion of verPor | keyvalue" [value]="opcion.key">
            {{ opcion.value }}
          </option>
        </select>
      </div>
    </div>

    <!-- ================================== filtro empleado ================================== -->
    <div class="col-6" *ngIf="reporteSeleccionado !== 'registroFoto'">
      <label class="mb-0 text-start">Filtrar por empleado</label>
      <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" (ngModelChange)="filtrarDatos(true)" (clear)="limpiarFiltro()" bindLabel="nombreCompleto" notFoundText="Empleado no encontrado" placeholder="Selecciona un empleado">
        <ng-template ng-option-tmp let-item="item">
          {{ nombreCompleto(item) }}
        </ng-template>
      </ng-select>
    </div>

    <!-- ================================== botón sincronizar ================================== -->
    <div *ngIf="reporteSeleccionado != 'checks' && reporteSeleccionado != 'registroFoto'" class="col-6 d-flex align-items-end justify-content-end">
      <button matTooltip="Mostrar opciones a exportar" mat-raised-button type="button" class="btn text-primary-emphasis fw-medium" (click)="exportaArchivo()">
        Exportar a {{ programas[programaSeleccionado] }}
      </button>
      <button mat-raised-button type="button" class="btn" [matMenuTriggerFor]="menu">
        <mat-icon>arrow_drop_down</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="programaSeleccionado = 'NomiExpressEscritorio'">
          <span>NomiExpress Escritorio</span>
        </button>
        <button mat-menu-item (click)="programaSeleccionado = 'NomiExpressWeb'">
          <span>NomiExpress Web</span>
        </button>
        <button mat-menu-item (click)="programaSeleccionado = 'contpaq'">
          <span>Contpaq Nomi</span>
        </button>
        <button mat-menu-item (click)="programaSeleccionado = 'aspel'">
          <span>Aspel</span>
        </button>
        <button mat-menu-item (click)="programaSeleccionado = 'runa'">
          <span>Runa</span>
        </button>
        <button mat-menu-item (click)="programaSeleccionado = 'nomilinea'">
          <span>Nomilinea</span>
        </button>
      </mat-menu>
      <!-- ================================== botones adicionales ================================== -->
      <div *ngIf="reporteSeleccionado !== 'checks'">
        <button mat-raised-button class="text-success mx-2" matTooltip="Actualizar incidencias" (click)="cargarDatosInicial()">
          <mat-icon>sync</mat-icon>
        </button>
        <button mat-raised-button class="text-danger-emphasis mx-2" matTooltip="Imprimir" (click)="exportarReporte()">
          <mat-icon>picture_as_pdf</mat-icon>
        </button>
        <button mat-raised-button type="button" class="btn mx-2" matTooltip="Exportar a Excel" (click)="exportarExcel()" *ngIf="reporteSeleccionado !== 'ejecutivo'">
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="100" viewBox="0 0 48 48">
            <g fill="none" stroke="#026917" stroke-linecap="round" stroke-width="4"><path stroke-linejoin="round" d="M8 15V6a2 2 0 0 1 2-2h28a2 2 0 0 1 2 2v36a2 2 0 0 1-2 2H10a2 2 0 0 1-2-2v-9"/><path d="M31 15h3m-6 8h6m-6 8h6"/><path stroke-linejoin="round" d="M4 15h18v18H4zm6 6l6 6m0-6l-6 6"/></g>
          </svg>
        </button>
      </div>
    </div>

    <!-- ================================== filtro departamento ================================== -->
    <div class="col-3 scale-in-ver-top" *ngIf="false">
      <label class="form-label mb-0 opacity-75">Filtro por departamento</label>
      <select class="form-select form-select-sm" aria-label="Default select example">
        <option selected>Sin filtro</option>
      </select>
    </div>
  </div>
  <!-- ================================== diccionario ================================== -->
  <div class="row g-3 mx-auto" *ngIf="reporteSeleccionado != 'registroFoto'">
    <button mat-raised-button class="text-primary bg-white me-1" (click)="abrirDialogPuntos()" *ngIf="false">
      Historial puntos de acceso
    </button>
    <div class="col-lg-12 d-lg-flex justify-content-between" *ngIf="reporteSeleccionado != 'checks'">
      <ul class="list-group list-group-horizontal">
        <li class="border border-0 list-group-item d-flex align-items-center p-1">
          <mat-icon class="text-success me-1">check</mat-icon>Checo a la hora
        </li>
        <li class="border border-0 list-group-item d-flex align-items-center p-1">
          <mat-icon class="text-danger me-1">cancel</mat-icon>No checo
        </li>
        <li class="border border-0 list-group-item d-flex align-items-center p-1">
          <mat-icon class="text-warning me-1">arrow_back</mat-icon>Retardo
        </li>
        <li class="border border-0 list-group-item d-flex align-items-center p-1">
          <mat-icon class="text-primary me-1">alarm</mat-icon>Horas extras
        </li>
      </ul>
      <!-- ================================== ver detalles ================================== -->
      <div class="d-flex align-items-center" *ngIf="reporteSeleccionado === 'fecha' || reporteSeleccionado === 'empleado' || reporteSeleccionado === 'ejecutivo' || reporteSeleccionado === 'incidencias'">
        <mat-slide-toggle color="primary" class="opacity-75 mx-2 mt-2" [(ngModel)]="agregarFirma">Agregar firma</mat-slide-toggle>
        <mat-slide-toggle color="primary" class="opacity-75 me-2 mt-2" [(ngModel)]="verDetalles">Ver detalles</mat-slide-toggle>
        <mat-slide-toggle color="primary" class="opacity-75 me-3 mx-2 mt-2" [(ngModel)]="formato12Horas">Mostrar en formato de 12 horas</mat-slide-toggle>
      </div>
    </div>
    <div class="d-flex justify-content-between px-3" [ngClass]="{'justify-content-end': esDebugger}">

      <!-- ================================== botones exportar ================================== -->

      <div class="col-lg-12 d-lg-flex justify-content-end" *ngIf="reporteSeleccionado == 'checks'">
        <!-- ================================== ver detalles ================================== -->
        <div *ngIf="esDebugger">
          <button mat-raised-button class="text-primary mx-2" matTooltip="Imprimir">
            <mat-icon>print</mat-icon>
          </button>
        </div>
        <div class="d-flex align-items-center mx-1">
          <mat-slide-toggle color="primary" class="opacity-75 me-2" [(ngModel)]="verDetalles">Ver detalles</mat-slide-toggle>
        </div>
      </div>
    </div>
  </div>

  <!-- ================================== tabla registro de acceso ================================== -->
  <div class="my-3" *ngIf="reporteSeleccionado === 'checks'">
    <table class="table table-hover centro">
      <thead class="bg-body-secondary">
        <!-- Cabecera por asistencia -->
        <tr *ngIf="reporteSeleccionado === 'checks'">
          <th class="border border-2 border-light" scope="col" colspan="2">Empleado</th>
          <th class="border border-2 border-light" scope="col">Fecha</th>
          <th class="border border-2 border-light" scope="col">Hora</th>
          <th class="border border-2 border-light" scope="col">Tipo de ingreso</th>
        </tr>
      </thead>
      <tbody>
        <!-- ================================== Contenido por asistencia  ==================================-->
        <tr *ngFor="let check of checks">
          <td scope="col" colspan="2" class="text-start">
            <p class="d-flex justify-content-center mt-2 mb-0">{{ check.nombre }}</p>
            <!-- {{ nombreCompletoConId(check.idEmpleado) }} -->
          </td>
          <td>
            <p class="d-flex justify-content-center mt-2 mb-0">{{ check.dia }}</p>
            <!-- {{ formatoFecha(check.fecha) }} -->
          </td>
          <td>
            <p class="d-flex justify-content-center mt-2 mb-0">{{ check.hora }}</p>
            <!-- {{ getFormatoHoras(check.fecha) }} -->
          </td>
          <td>
            <div class="d-flex justify-content-center align-items-center">
              <mat-icon matTooltip="Registro con QR" *ngIf="check.tipoIngreso == TipoIngreso.codigoQr">qr_code_scanner</mat-icon>
              <mat-icon matTooltip="Registro con Reconocimiento facial" *ngIf="check.tipoIngreso == TipoIngreso.reconocimientoFacial">switch_account</mat-icon>
              <mat-icon *ngIf="check.RFnoLocal">account_box</mat-icon>
              <span class="mx-3 align-self-end" *ngIf="check.similitud" [class.text-danger]="check.similitud > 0 && check.similitud < 0.99">{{ check.similitud | percent:'1.2-2' }}</span>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <div class="d-flex justify-content-end">
      <p>
        <span class="me-1">TOTAL DE MOVIMIENTOS:</span>
        <span class="fw-bold">{{ checks.length }}</span>
      </p>
    </div>
  </div>

  <!-- ================================== tabla incidencias ================================== -->
  <div class="my-3 scroll" *ngIf="reporteSeleccionado != 'registroFoto' && reporteSeleccionado != 'checks' && reporteSeleccionado != 'ejecutivo' && reporteSeleccionado != 'incidencias'" style="max-height: 600px; overflow-y: auto;">
    <table class="table table-borderless table-striped table-hover centro">
      <thead class="bg-body-secondary" style="position: sticky; top: 0; z-index: 100;">
        <tr>
          <!-- Cabecera común -->
          <th class="border border-2 border-light" scope="col" colspan="2">
            {{ reporteSeleccionado == "empleado" ? "Fecha" : "Empleado" }}
          </th>
          <th class="border border-2 border-light" scope="col" *ngIf="verDetalles && esDebugger">ID</th>
          <th class="border border-2 border-light" scope="col" *ngIf="verDetalles">Horario</th>
          <th class="border border-2 border-light" scope="col">Entrada</th>
          <th class="border border-2 border-light" scope="col">Salida</th>
          <th class="border border-2 border-light" scope="col">Horas trabajadas</th>
          <th class="border border-2 border-light" scope="col">Retardos</th>
          <th class="border border-2 border-light" scope="col">Horas Extras</th>
          <th class="border border-2 border-light" scope="col">Falta</th>
          <th class="border border-2 border-light" scope="col">Especial</th>
          <th class="border border-2 border-light" scope="col">Detalles</th>
        </tr>
      </thead>
      <ng-container *ngFor="let incidenciaGrupo of incidenciasReporte | keyvalue: valueIncidenciasAscOrder">
        <tr class="bg-body-secondary" style="position: sticky; top: 35px; z-index: 99;">
          <!-- Cabecera por fecha -->
          <th scope="col" colspan="2" class="border border-2 border-light text-start" *ngIf="reporteSeleccionado == 'fecha'">
            {{ formatoFechaKey( incidenciaGrupo.key) }}
          </th>
          <!-- Cabecera por nombre -->
          <th scope="col" colspan="2" class="border border-2 border-light text-start" *ngIf="reporteSeleccionado != 'fecha'">
            {{ incidenciaGrupo.key }}
          </th>
          <th class="border border-2 border-light" *ngIf="verDetalles && esDebugger"></th>
          <th class="border border-2 border-light" *ngIf="verDetalles"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
        </tr>
        <tbody>
          <tr *ngFor="let incidencia of incidenciasReporte[incidenciaGrupo.key]">
            <th class="border border-2 border-light" *ngIf="incidencia.esTrabajoDescanso">
              <small>Trabajo en descanso</small>
            </th>
            <!-- Contenido -->
            <ng-container>
                <td class="text-center" colspan="2" *ngIf="reporteSeleccionado == 'fecha'">
                  <p class="mt-2 mb-0 text-truncate">{{ validarEntradaSalida(incidencia) ? '--' : incidencia.nombre }}</p>
                </td>
                <td class="text-center" colspan="2" *ngIf="reporteSeleccionado != 'fecha'">
                  <p class="mt-2 mb-0 text-truncate">{{ validarEntradaSalida(incidencia) ? '--' : formatoFecha(incidencia.fecha) }}</p>
                </td>
                <td class="text-center" *ngIf="verDetalles && esDebugger">
                  <p class="mt-2 mb-0 text-truncate">{{ incidencia.idEmpleado }}</p>
                </td>
                <td *ngIf="verDetalles">
                  <button mat-button (click)="abrirHorario(incidencia.idEmpleado, incidencia.idHorario, incidencia.nombre)">{{ incidencia.horario }}</button>
                </td>

                <!-- se debe poner hasta el máximo de movimientos de horario en el dia de todos los empleados -->
                <ng-container *ngFor="let incidenciaHorario of incidencia.incidenciaHorario">
                  <!-- entrada -->
                  <td>
                    <div class="d-flex justify-content-center mt-2">
                      <ng-container *ngIf="incidenciaHorario.tipoIncidenciaEntrada != TipoIncidencia.SinRegistro && incidenciaHorario.tipoIncidenciaEntrada != TipoIncidencia.SinHorario && (incidenciaHorario.tipoIncidenciaEntrada != TipoIncidencia.Descanso || incidenciaHorario.trabajoEnDescanso) && incidenciaHorario.tipoIncidenciaEntrada != TipoIncidencia.Pendiente">
                        <!-- Función para mostrar botones de entrada -->
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.ATiempo || incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.Tolerancia">
                          <mat-icon class="text-success fs-5">check</mat-icon>
                        </button>
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.Retardo || incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.RetardoSinRegistro">
                          <mat-icon class="text-danger fs-5">start</mat-icon>
                        </button>
                        <!-- <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.SinRegistro">
                          <mat-icon class="text-danger fs-5">cancel</mat-icon>
                        </button> -->
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.TiempoAFavor">
                          <mat-icon class="text-success fs-5">arrow_back</mat-icon>
                        </button>
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.TiempoExtra">
                          <mat-icon class="text-success fs-5">update</mat-icon>
                        </button>
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.Compensando">
                          <mat-icon class="text-warning fs-5">login</mat-icon>
                        </button>
                        <div>{{ getFormatoHoras(incidenciaHorario.horaEntrada) }} <sup *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.RetardoSinRegistro" class="text-danger" matTooltip="Sin registrar. Registrado por política de incidencia">**</sup></div>
                      </ng-container>
                      <ng-container *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.SinRegistro || incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.SinHorario || (incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.Descanso && !incidenciaHorario.trabajoEnDescanso)">
                        <button class="p-0 btn d-flex justify-content-center align-items-center" matTooltip="El empleado no tiene horario registrado" *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.SinHorario">
                          <mat-icon class="text-warning fs-5">info</mat-icon>
                        </button>
                        <div matTooltip="No tiene registros este dia" *ngIf="incidenciaHorario.tipoIncidenciaEntrada != TipoIncidencia.SinHorario">-</div>
                      </ng-container>
                      <ng-container *ngIf="incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.Pendiente" >
                        <div matTooltip="Aún no se registró el ingreso">-</div>
                      </ng-container>
                    </div>
                  </td>

                  <!-- salida -->
                  <td>
                    <div class="d-flex justify-content-center mt-2">
                      <ng-container *ngIf="incidenciaHorario.tipoIncidenciaSalida != TipoIncidencia.SinRegistro && (incidenciaHorario.horasTrabajadas + incidenciaHorario.horasExtras + incidenciaHorario.trabajoEnDescanso > 0) && incidenciaHorario.tipoIncidenciaSalida != TipoIncidencia.Pendiente">
                        <!-- Función para mostrar botones de salida -->
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.ATiempo || incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.Tolerancia">
                          <mat-icon class="text-success fs-5">check</mat-icon>
                        </button>
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.TiempoAFavor">
                          <mat-icon class="text-success fs-5">start</mat-icon>
                        </button>
                        <!-- <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.SinRegistro">
                          <mat-icon class="text-danger fs-5">cancel</mat-icon>
                        </button> -->
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.Retardo || incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.RetardoSinRegistro">
                          <mat-icon class="text-warning fs-5">arrow_back</mat-icon>
                        </button>
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.TiempoExtra">
                          <mat-icon class="text-warning fs-5">update</mat-icon>
                        </button>
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.Compensando">
                          <mat-icon class="text-warning fs-5">logout</mat-icon>
                        </button>
                        <button class="p-0 btn d-flex justify-content-center align-items-center" *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.SinHorario">
                          <mat-icon class="text-warning fs-5">info</mat-icon>
                        </button>
                        <div>{{ getFormatoHoras(incidenciaHorario.horaSalida) }} <sup *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.RetardoSinRegistro" class="text-danger" matTooltip="Sin registrar. Registrado por política de incidencia">**</sup></div>
                      </ng-container>
                      <ng-container *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.SinRegistro || (incidenciaHorario.horasTrabajadas + incidenciaHorario.horasExtras + incidenciaHorario.trabajoEnDescanso <= 0)">
                        <div matTooltip="No tiene registros este dia">-</div>
                      </ng-container>
                      <ng-container *ngIf="incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.Pendiente">
                        <div matTooltip="Aún no se registró la salida">-</div>
                      </ng-container>
                    </div>
                  </td>
                </ng-container>


                <td>
                  <p class="d-flex justify-content-center mt-2 mb-0">{{ incidencia.horasTrabajadasTxt }}</p>
                </td>
                <td [ngClass]="{'text-danger': incidencia.retardos > 0.15}">
                  <p class="d-flex justify-content-center mt-2 mb-0">{{ incidencia.retardosTxt }}</p>
                </td>
                <td>
                  <p class="d-flex justify-content-center mt-2 mb-0">{{ incidencia.horasExtrasTxt }}</p>
                </td>
                <td [ngClass]="{ 'bg-danger-subtle text-danger': incidencia.faltas > 0 }">
                  <p class="d-flex justify-content-center mt-2 mb-0">{{ incidencia.faltasTxt }}</p>
                </td>
                <td>
                  <p class="d-flex justify-content-center mt-2 mb-0">{{ incidencia.especialTxt }}</p>
                </td>
                <td class="p-0">
                  <div class="d-flex justify-content-center">
                    <button mat-icon-button (click)="openModal(incidencia)" matTooltip="Detalles de la incidencia">
                      <mat-icon class="text-info">info</mat-icon>
                    </button>
                    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="icon-button with a menu" (click)="sumarRetardo = false; sumarHorasExtras = false;">
                      <mat-icon>arrow_drop_down</mat-icon>
                    </button>
                    <!-- Menú de opciones -->
                    <mat-menu #menu="matMenu">
                      <!-- ============================================= modificar la incidencia del dia ============================================= -->
                      <ng-container>
                        <button mat-menu-item (click)="reEstablecerIncidencias(incidencia)" [disabled]="incidencia.idIncidenciaAjuste == 0">
                          <mat-icon>history</mat-icon>
                          <span>Re-establecer incidencias</span>
                        </button>
                        <button mat-menu-item (click)="eliminarIncidencias(incidencia)" [disabled]="incidencia.idIncidenciaAjuste == 0 && incidencia.incidenciaHorario[0].tipoIncidenciaEntrada == TipoIncidencia.SinRegistro  && incidencia.incidenciaHorario[0].tipoIncidenciaSalida == TipoIncidencia.SinRegistro">
                          <mat-icon>delete</mat-icon>
                          <span>Eliminar incidencias del día</span>
                        </button>
                        <button mat-menu-item (click)="marcarFalta(incidencia)" *ngIf="incidencia.faltas == 0">
                          <mat-icon>location_off</mat-icon>
                          <span>Marcar como falta</span>
                        </button>
                        <button mat-menu-item (click)="marcarAsistencia(incidencia)" *ngIf="incidencia.faltas > 0">
                          <mat-icon>location_on</mat-icon>
                          <span>Marcar como asistencia</span>
                        </button>
                      </ng-container>

                      <!-- ============================================= editar retardos ============================================= -->
                      <ng-container *ngIf="incidencia.faltas == 0">
                        <button mat-menu-item class="text-danger" (click)="quitarRetardo(incidencia)" [disabled]="incidencia.retardos <= 0">
                          <mat-icon class="text-danger">snooze</mat-icon>
                          <span class="text-danger">Quitar retardo</span>
                        </button>
                        <button mat-menu-item (click)="cargarDatosRetardo(incidencia); $event.stopPropagation();">
                          <mat-icon>snooze</mat-icon>
                          <span>Editar retardo</span>
                        </button>
                        <div class="d-flex align-items-center" *ngIf="sumarRetardo">
                          <div class="my-2 px-2 col-4" (click)="$event.stopPropagation()">
                            <label for="horasRetardo" class="form-label mb-0">Horas</label>
                            <input type="number" class="form-control" id="horasRetardo" [(ngModel)]="horasRetardos" placeholder="Horas" min="0" max="12">
                          </div>
                          <div class="my-2 px-2 col-4" (click)="$event.stopPropagation()">
                            <label for="minutosRetardo" class="form-label mb-0">Minutos</label>
                            <input type="number" class="form-control" id="minutosRetardo" [(ngModel)]="minutosRetardos" placeholder="Minutos" min="0" max="59">
                          </div>
                          <div class="px-2 col-2 pt-4">
                            <button mat-raised-button class="text-primary mx-1" (click)="agregarRetardo(incidencia)">Editar</button>
                          </div>
                        </div>
                        <!-- <button mat-menu-item>
                          <mat-icon>snooze</mat-icon>
                          <span>Editar retardo</span>
                        </button>
                        <div class="input-group mb-1 px-2" (click)="$event.stopPropagation()">
                          <select class="form-select" [(ngModel)]="seleccionEditarRetardo" (change)="editarRetardo($event, incidencia)">
                            <option *ngFor="let opcion of opcionesEditarRetardo | keyvalue" [value]="opcion.key">{{ opcion.value }}</option>
                          </select>
                        </div> -->
                      </ng-container>

                      <!-- ============================================= editar horas extras ============================================= -->
                      <ng-container *ngIf="incidencia.faltas == 0">
                        <button mat-menu-item (click)="quitarHorasExtra(incidencia)" [disabled]="incidencia.horasExtras <= 0">
                          <mat-icon class="text-danger">history_toggle_off</mat-icon>
                          <span class="text-danger">Quitar horas extras</span>
                        </button>
                        <button mat-menu-item (click)="cargarDatosHorasExtra(incidencia); $event.stopPropagation();">
                          <mat-icon>history_toggle_off</mat-icon>
                          <span>Editar horas extras</span>
                        </button>
                        <div class="d-flex align-items-center" *ngIf="sumarHorasExtras">
                          <div class="my-2 px-2 col-4" (click)="$event.stopPropagation()">
                            <label for="horas" class="form-label mb-0">Horas</label>
                            <input type="number" class="form-control" id="horas" [(ngModel)]="horasExtrasAdicionales" placeholder="Horas" min="0" max="59">
                          </div>
                          <div class="my-2 px-2 col-4" (click)="$event.stopPropagation()">
                            <label for="minutos" class="form-label mb-0">Minutos</label>
                            <input type="number" class="form-control" id="minutos" [(ngModel)]="minutosExtrasAdicionales" placeholder="Minutos" min="0" max="59">
                          </div>
                          <div class="px-2 col-2 pt-4">
                            <button mat-raised-button class="text-primary mx-1" (click)="agregarHorasExtras(incidencia)">Editar</button>
                          </div>
                        </div>

                        <!-- <button mat-menu-item>
                          <mat-icon>history_toggle_off</mat-icon>
                          <span>Editar horas extras</span>
                        </button>
                        <div class="input-group mb-1 px-2" (click)="$event.stopPropagation()">
                          <select class="form-select" [(ngModel)]="seleccionEditarHorasExtras" (change)="editarHorasExtras($event, incidencia)">
                            <option *ngFor="let opcion of opcionesHorasExtras | keyvalue" [value]="opcion.key">{{ opcion.value }}</option>
                          </select>
                        </div> -->
                      </ng-container>

                      <!-- ============================================= editar horas antes de ingreso ============================================= -->
                      <!-- <ng-container *ngIf="incidencia.faltas == 0">
                        <button mat-menu-item>
                          <mat-icon>snooze</mat-icon>
                          <span>Editar salida antes de tiempo</span>
                        </button>

                        <div class="input-group mb-1 px-2" (click)="$event.stopPropagation()">
                          <select class="form-select" [(ngModel)]="seleccionAgregarHorasIngreso" (change)="editarHorasAntesIngreso($event, incidencia)">
                            <option *ngFor="let opcion of opcionesHorasAntesIngreso | keyvalue" [value]="opcion.key">{{ opcion.value }}</option>
                          </select>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="my-2 px-2 col-4" (click)="$event.stopPropagation()" *ngIf="agregarHorasAntesIngreso">
                            <label for="horasIngreso" class="form-label mb-0">Horas</label>
                            <input type="number" class="form-control" id="horasIngreso" [(ngModel)]="horasAntesIngreso" placeholder="Horas" min="0" max="12">
                          </div>
                          <div class="my-2 px-2 col-4" (click)="$event.stopPropagation()" *ngIf="agregarHorasAntesIngreso">
                            <label for="minutosIngreso" class="form-label mb-0">Minutos</label>
                            <input type="number" class="form-control" id="minutosIngreso" [(ngModel)]="minutosAntesIngreso" placeholder="Minutos" min="0" max="59">
                          </div>
                          <div class="px-2 col-2 pt-4" *ngIf="agregarHorasAntesIngreso">
                            <button mat-raised-button class="text-primary mx-1" (click)="agregarRetardo(incidencia)">Agregar</button>
                          </div>
                        </div>
                      </ng-container>     -->

                      <!-- ============================================= editar horarios ============================================= -->
                      <button mat-menu-item (click)="cambiarHorarioDialog(incidencia, false)" *ngIf="esDebugger">
                        <mat-icon fontIcon="event_repeat"></mat-icon>
                        <span>Cubrir turno</span>
                      </button>
                      <button mat-menu-item (click)="cambiarHorarioDialog(incidencia, true)" *ngIf="esDebugger">
                        <mat-icon fontIcon="more_time"></mat-icon>
                        <span>Asignar nuevo horario</span>
                      </button>
                    </mat-menu>
                  </div>
                </td>

            </ng-container>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>

  <!-- ================================== tabla resumen ejecutivo ================================== -->
  <div class="my-3 scroll" *ngIf="reporteSeleccionado === 'ejecutivo'" style="max-height: 600px; overflow-y: auto;">
    <table class="table table-hover table-striped centro">
      <thead class="bg-body-secondary" style="position: sticky; top: 0; z-index: 100;">
        <tr>
          <th class="border border-2 border-light" scope="col">Empleado</th>
          <th class="border border-2 border-light" scope="col">ID</th>
          <th class="border border-2 border-light" scope="col" *ngIf="verDetalles">Entrada</th>
          <th class="border border-2 border-light" scope="col" *ngIf="verDetalles">Salida</th>
          <th class="border border-2 border-light" scope="col">Horas trabajadas</th>
          <th class="border border-2 border-light" scope="col">Retardos</th>
          <th class="border border-2 border-light" scope="col">Horas Extras</th>
          <th class="border border-2 border-light" scope="col">Faltas</th>
        </tr>
        <tr class="bg-body-secondary" style="position: sticky; top: 35px; z-index: 99;">
          <th scope="col" class="border border-2 border-light text-start">
            {{ formatoFecha( inicioPeriodo) }} - {{ formatoFecha(finPeriodo) }}
          </th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th *ngIf="verDetalles" class="border border-2 border-light"></th>
          <th *ngIf="verDetalles" class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
          <th class="border border-2 border-light"></th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let incidenciaGrupo of incidenciasReporte | keyvalue: valueIncidenciasAscOrder">
          <tr>
            <td class="text-start">{{ incidenciaTotales[incidenciaGrupo.key].nombre }}</td>
            <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key].idEmpleado }}</td>
            <td class="text-center" *ngIf="verDetalles">{{ getFormatoHoras(incidenciaTotales[incidenciaGrupo.key].incidenciaHorario[0]?.horaEntrada) }}</td>
            <td class="text-center" *ngIf="verDetalles">{{ getFormatoHoras(incidenciaTotales[incidenciaGrupo.key].incidenciaHorario[0]?.horaSalida) }}</td>
            <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key]?.horasTrabajadasTxt || 0 }}</td>
            <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key]?.retardosTxt || 0 }}</td>
            <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key]?.horasExtrasTxt || 0 }}</td>
            <td class="text-center" [ngClass]="{ 'bg-danger-subtle text-danger': incidenciaTotales[incidenciaGrupo.key].faltas > 0 }">
              {{ incidenciaTotales[incidenciaGrupo.key]?.faltasTxt || 0 }}
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>

    <!-- ================================== tabla resumen de incidencias ================================== -->
    <div class="my-3 scroll" *ngIf="reporteSeleccionado === 'incidencias'" style="max-height: 600px; overflow-y: auto;">
      <table class="table table-hover table-striped centro">
        <thead class="bg-body-secondary" style="position: sticky; top: 0; z-index: 100;">
          <tr>
            <th class="border border-2 border-light" scope="col">Empleado</th>
            <th class="border border-2 border-light" scope="col">ID</th>
            <th class="border border-2 border-light" scope="col">Días trabajados</th>
            <th class="border border-2 border-light" scope="col">Descansos</th>
            <th class="border border-2 border-light" scope="col">Incidencias</th>
            <th class="border border-2 border-light" scope="col">Retardos</th>
            <th class="border border-2 border-light" scope="col">Horas extras</th>
          </tr>
          <tr class="bg-body-secondary" style="position: sticky; top: 35px; z-index: 99;">
            <th scope="col" class="border border-2 border-light text-start">
              {{ formatoFecha( inicioPeriodo) }} - {{ formatoFecha(finPeriodo) }}
            </th>
            <th class="border border-2 border-light"></th>
            <th class="border border-2 border-light"></th>
            <th class="border border-2 border-light"></th>
            <th class="border border-2 border-light"></th>
            <th class="border border-2 border-light"></th>
            <th class="border border-2 border-light"></th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let incidenciaGrupo of incidenciasReporte | keyvalue: valueIncidenciasAscOrder">
            <tr>
              <td class="text-start">{{ incidenciaTotales[incidenciaGrupo.key].nombre }}</td>
              <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key].idEmpleado }}</td>
              <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key]?.diasTrabajados || '-' }}</td>
              <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key]?.descanso || '-' }}</td>
              <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key]?.incidencias || '-' }}</td>
              <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key]?.retardosTxt}}</td>
              <td class="text-center">{{ incidenciaTotales[incidenciaGrupo.key]?.horasExtrasTxt }}</td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </div>

  <!-- ================================== registro de asistencia ================================== -->
  <ng-container *ngIf="reporteSeleccionado === 'registroFoto'">
    <app-empleados-registro-incidencias (cerrarRegistroIncidencias)="handleCerrarRegistroIncidencias()" [empleados]="empleados"></app-empleados-registro-incidencias>
  </ng-container>

  <div class="text-start col-4 py-3" *ngIf="esDebugger && !cargando">
    <label class="form-label mb-0">Timer de actualización</label>
    <select class="form-select form-select-sm" aria-label="Default select example" [(ngModel)]="timerActualizacion">
      <option *ngFor="let opcion of timers | keyvalue" [value]="opcion.key">
        {{ opcion.value }}
      </option>
    </select>
  </div>
</div>

<!-- ================================== modal detalles de la incidencia ================================== -->
<ng-template #detalles let-modal>
  <div class="d-flex justify-content-between align-items-center px-3 border-bottom border-light shadow-sm">
    <h2 class="modal-title mb-0 ms-2 fw-bold">Detalles de Incidencia</h2>
    <button mat-icon-button (click)="modalRef?.hide()">
      <mat-icon fontIcon="close"></mat-icon>
    </button>
  </div>
  <div class="modal-body row">
    <div class="col-6">
      <div class="table-responsive">
        <table class="table table-striped">
          <tbody>
            <tr *ngIf="esDebugger">
              <td>ID:</td>
              <td>{{ incidencia.id }}</td>
            </tr>
            <tr *ngIf="esDebugger">
              <td>Clave del empleado:</td>
              <td>{{ incidencia.idEmpleado }}</td>
            </tr>
            <tr>
              <td>Nombre:</td>
              <td>{{ incidencia.nombre }}</td>
            </tr>
            <tr>
              <td>Horario:</td>
              <td>{{ incidencia.horario }}</td>
            </tr>
            <tr *ngIf="esDebugger">
              <td>Departamento:</td>
              <td>{{ incidencia.idDepartamento }}</td>
            </tr>
            <tr>
              <td>Fecha:</td>
              <td>{{ formatoFecha(incidencia.fecha) }}</td>
            </tr>
            <tr>
              <td>Hora de entrada:</td>
              <td>{{ getFormatoHorasCompleto(incidencia.incidenciaHorario[0].horaEntrada) }}{{ esDebugger ? ' (' + incidencia.incidenciaHorario[0].tipoIncidenciaEntrada + ') ' : '' }}</td>
            </tr>
            <tr>
              <td>Hora de entrada del horario:</td>
              <td>{{ getFormatoHoras(incidencia.incidenciaHorario[0].horaEntradaHorario) }}</td>
            </tr>
            <tr>
              <td>Hora de salida:</td>
              <td>{{ getFormatoHorasCompleto(incidencia.incidenciaHorario[0].horaSalida) }}<small class="text-danger">{{ incidencia.incidenciaHorario[0].salidaDiaSiguiente ? ' * dia siguiente' : '' }}</small>{{ esDebugger ? ' (' + incidencia.incidenciaHorario[0].tipoIncidenciaSalida + ') ' : '' }}</td>
            </tr>
            <tr>
              <td>Hora de salida del horario:</td>
              <td>{{ getFormatoHoras(incidencia.incidenciaHorario[0].horaSalidaHorario) }}</td>
            </tr>
            <tr>
              <td>Horas trabajadas:</td>
              <td>{{ incidencia.horasTrabajadasTxt }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-6">
      <div class="table-responsive">
        <table class="table table-striped">
          <tbody>
            <tr>
              <td>Horas extras:</td>
              <td>{{ incidencia.horasExtrasTxt }}</td>
            </tr>
            <tr>
              <td>Retardos:</td>
              <td>{{ incidencia.retardosTxt }}</td>
            </tr>
            <tr>
              <td>Faltas:</td>
              <td>{{ incidencia.faltasTxt }}</td>
            </tr>
            <tr>
              <td>Descanso:</td>
              <td>{{ incidencia.descanso }}</td>
            </tr>
            <tr>
              <td>Trabajo en descanso:</td>
              <td>{{ incidencia.esTrabajoDescanso ? 'Si' : 'No' }}</td>
            </tr>
            <tr *ngIf="esDebugger">
              <td>Tiempo a Favor:</td>
              <td>{{ incidencia.incidenciaHorario[0].tiempoAFavor }}</td>
            </tr>
            <tr>
              <td>Vacaciones:</td>
              <td>{{ incidencia.vacaciones }}</td>
            </tr>
            <tr>
              <td>Incapacidad:</td>
              <td>{{ incidencia.incapacidad }}</td>
            </tr>
            <tr>
              <td>Especial:</td>
              <td>{{ incidencia.especialTxt }}</td>
            </tr>
            <tr>
              <td>Incidencia ajuste:</td>
              <td>{{ incidencia.idIncidenciaAjuste }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-striped">
        <tbody>
          <tr>
            <td>Observaciones:</td>
            <td class="d-flex justify-content-between" *ngFor="let obs of incidencia.incidenciaHorario[0].observaciones">
              <span>{{ obs }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</ng-template>
