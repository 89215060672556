<div *ngIf="cargando">
  <div class="text-center py-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-5">
    <span>Cargando datos...</span>
  </div>
</div>

<div class="mx-auto row mt-2 mb-4 align-items-center" *ngIf="!cargando">
  <div class="d-flex justify-content-end mb-2" *ngIf="!mostrarElementos">
    <button mat-raised-button class="mx-1 text-primary-emphasis" (click)="validarIncidenciaDialog()" *ngIf="!isModificando">
      Validación de incidencias
    </button>
    <button mat-raised-button matTooltip="Editar datos" class="mx-1 text-primary" (click)="modificarDatos()" *ngIf="!isModificando">
      <mat-icon class="me-0">edit</mat-icon>
    </button>
    <button mat-raised-button matTooltip="Dar de baja empleado" class="mx-1 text-danger" (click)="eliminarEmpleado()" *ngIf="!isModificando">
      <mat-icon class="me-0">person_remove</mat-icon>
    </button>
    <div *ngIf="isModificando">
      <button mat-raised-button matTooltip="Guardar cambios" class="ms-2 text-success" (click)="guardarDatos()">
        <mat-icon class="me-0">save</mat-icon>
      </button>
      <button mat-raised-button matTooltip="Deshacer" class="ms-2 text-danger" (click)="deshacerDatos()">
        <mat-icon class="me-0">reply</mat-icon>
      </button>
    </div>
  </div>
  <div class="col-md-6">
    <div class="row g-3 text-start">
      <div *ngIf="esDebugger" [class.col-2]="esDebugger">
        <label for="id" class="form-label mb-0">ID</label>
        <input type="text" class="form-control bg-light" id="rfc" [value]="empleado.id" onClick="this.select();" readonly>
      </div>
      <div class="col-2" [class.col-3]="!esDebugger">
        <label for="rfc" class="form-label mb-0">Clave</label>
        <input type="text" class="form-control bg-light" id="rfc" [(ngModel)]="empleado.numero" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-8" [class.col-9]="!esDebugger" *ngIf="!isModificando">
        <label for="nombre" class="form-label mb-0">Nombre</label>
        <input type="text" class="form-control bg-light" id="nombre" [value]="nombreCompleto()" onClick="this.select();" readonly>
      </div>
      <div class="col-8" [class.col-9]="!esDebugger" *ngIf="isModificando">
        <label for="nombre" class="form-label mb-0">Nombre(s)</label>
        <input type="text" class="form-control bg-light" id="nombre" [(ngModel)]="empleado.nombre" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6" *ngIf="isModificando">
        <label for="apellidoPaterno" class="form-label mb-0">Apellido Paterno</label>
        <input type="text" class="form-control bg-light" id="apellidoPaterno" [(ngModel)]="empleado.paterno" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6" *ngIf="isModificando">
        <label for="apellidoMaterno" class="form-label mb-0">Apellido Materno</label>
        <input type="text" class="form-control bg-light" id="apellidoMaterno" [(ngModel)]="empleado.materno" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6">
        <label for="rfc" class="form-label mb-0">R.F.C.</label>
        <input type="text" class="form-control bg-light" id="rfc" [(ngModel)]="empleado.rfc" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6">
        <label for="curp" class="form-label mb-0">CURP</label>
        <input type="text" class="form-control bg-light" id="curp" [(ngModel)]="empleado.curp" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6">
        <label for="imss" class="form-label mb-0">IMSS</label>
        <input type="text" class="form-control bg-light" id="imss" [(ngModel)]="empleado.imss" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6">
        <label for="correo" class="form-label mb-0">Correo</label>
        <input type="text" class="form-control bg-light" id="correo" [(ngModel)]="empleado.correo" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6">
        <label for="area" class="form-label mb-0">Area</label>
        <input type="text" class="form-control bg-light" id="area" [(ngModel)]="empleado.area" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6">
        <label for="puesto" class="form-label mb-0">Puesto</label>
        <input type="text" class="form-control bg-light" id="puesto" [(ngModel)]="empleado.puesto" onClick="this.select();" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
      </div>
      <div class="col-md-6" *ngIf="!isModificando || !esNuevoEmpleado">
        <label for="alta" class="form-label mb-0">Fecha de alta</label>
        <input type="text" class="form-control bg-light" id="alta" [value]="formatoFecha(empleado.alta)" onClick="this.select();" readonly>
      </div>

      <div class="col-md-6" *ngIf="isModificando && esNuevoEmpleado">
        <div class="example-form">
          <label for="filtroEmpleado" class="form-label mb-0">Fecha de alta</label>
          <div class="d-flex align-items-center" (click)="picker.open()">
            <!-- ========== input oculto ============ -->
            <input type="text" matInput [matDatepicker]="picker" [(ngModel)]="empleado.alta" class="form-control d-none">
            <!-- ========== input visible ============ -->
            <input type="text" [value]="empleado.alta | formatoFecha" class="form-control">
            <mat-datepicker-toggle matIconSuffix [for]="picker" style="height: 42px;"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <label for="alta" class="form-label mb-0">Fecha de baja</label>
        <input type="text" class="form-control bg-light" id="alta" [value]="formatoFecha(empleado.baja)" onClick="this.select();" readonly>
      </div>
      <div class="col-md-6" *ngIf="!esNuevoEmpleado">
        <label class="form-label mb-0">Tipo de empleado</label>
        <select class="form-select bg-light" aria-label="Default select example" [(ngModel)]="empleado.tipoEmpleado" [disabled]="!isModificando" [ngClass]="{ 'bg-white': isModificando }">
          <option *ngFor="let tEmpleado of tiposEmpleado | keyvalue" [value]="tEmpleado.key">
            {{ tEmpleado.value }}
          </option>
        </select>
      </div>
      <div class="col-md-6" *ngIf="!esNuevoEmpleado">
        <label class="form-label mb-0">Manejo de empleado</label>
        <select class="form-select bg-light" aria-label="Default select example" [(ngModel)]="empleado.manejoAccesoExpress" [disabled]="!isModificando" [ngClass]="{ 'bg-white': isModificando }">
          <option *ngFor="let mEmpleado of manejoEmpelado | keyvalue" [value]="mEmpleado.key">
            {{ mEmpleado.value }}
          </option>
        </select>
      </div>
      <div class="col-md-6">
        <label class="form-label mb-0">{{etiquetaAgrupar}}</label>
        <div class="input-group">
          <input type="text" class="form-select bg-light" id="grupoEmpleados" [(ngModel)]="empleado.grupo" [matAutocomplete]="auto" [readOnly]="!isModificando || estaGuardando" [ngClass]="{ 'bg-white': isModificando }">
          <button *ngIf="empleado.grupo && isModificando" mat-raised-button type="button" class="text-primary" (click)="empleado.grupo = '';">
            <mat-icon fontIcon="cancel" class="me-0"></mat-icon>
          </button>
        </div>
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let grupo of grupoEmpleados" [value]="grupo">
            {{grupo}}
          </mat-option>
        </mat-autocomplete>
      </div>
      <div class="col-md-6">
        <label for="alta" class="form-label mb-0">ID Referencia</label>
        <input type="text" class="form-control bg-light" id="alta" [(ngModel)]="empleado.idReferencia" onClick="this.select();" [disabled]="!isModificando" [ngClass]="{ 'bg-white': isModificando }">
        <small>Este campo es para el ID de su programa de nómina. <small class="text-danger">Si usted usa NomiExpress Web, no es necesario llenarlo.</small></small>
      </div>
      <div class="col-md-6" *ngIf="esDebugger">
        <label for="ultimaCargaCF" class="form-label mb-0">Ultima Carga CF</label>
        <input type="text" class="form-control bg-light" id="ultimaCargaCF" [value]="formatoFecha(empleado.ultimaCargaCF)" onClick="this.select();" readonly>
      </div>
    </div>
  </div>
  <div class="col-md-5 mx-auto">
    <div class="text-center" *ngIf="!empleado.tieneDatosRF" (click)="emitirMostrarReconocimientoFacial()">
      <img src="assets/images/face.png" alt="..." class="opacity-50" style="width: 18rem;">
    </div>
    <div class="text-center" *ngIf="empleado.tieneDatosRF" (click)="emitirMostrarReconocimientoFacial()">
      <img src="{{'data:image/jpg;base64,' + empleado.datosRF.datos}}" style="width: 18rem;"/>
    </div>
    <div class="mt-3 d-flex justify-content-center">
      <button (click)="emitirMostrarReconocimientoFacial()" mat-raised-button class="ms-3 text-primary-emphasis" matTooltip="Subir foto" [disabled]="isModificando">
        <mat-icon class="me-0">face</mat-icon>
      </button>
      <button (click)="emitirMostrarCodigo()" mat-raised-button class="ms-3 text-primary" matTooltip="Codigo QR" [disabled]="isModificando">
        <mat-icon class="me-0">qr_code_scanner</mat-icon>
      </button>
      <button (click)="emitirMostrarRegistroIncidencias()" mat-raised-button class="ms-3 text-primary" matTooltip="Mostrar registro de incidencias" [disabled]="isModificando">
        <mat-icon class="me-0">perm_media</mat-icon>
      </button>
    </div>
  </div>
</div>
