import { KeyValue } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Observable } from 'rxjs';
import { fFecha } from 'src/app/core/Funciones/fFecha';
import { fechaT } from 'src/app/core/Funciones/fTexto';
import { mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleados, nombreEmpleado, Vacaciones, VacacionesEmpleado } from 'src/app/models/empleados';
import { IEmpresa } from 'src/app/models/empresa';
import { IAccesoIncidenciasUsuario, IAccesoIncidenciasUsuarioCargar } from 'src/app/models/incidencias';

@Component({
  selector: 'app-empleados-control-vacaciones',
  templateUrl: './empleados-control-vacaciones.component.html',
  styleUrls: ['./empleados-control-vacaciones.component.scss']
})
export class EmpleadosControlVacacionesComponent implements OnInit {
  public estaCargando: boolean = true;
  public esDebugger: boolean = false;
  public empleados: IEmpleados[] = [];
  public empleadosTotales: IEmpleados[] = [];
  public mostrarBajas: boolean = false;
  public inicioPeriodo: Date = new Date(1900, 0, 1);
  public finPeriodo: Date = new Date(2500, 0, 1);
  public empresa!: IEmpresa;
  public incidenciasUsuarioOriginal: IAccesoIncidenciasUsuario[] = [];
  public incidenciasUsuario: IAccesoIncidenciasUsuario[] = [];
  public vacacionesPorEmpleado: VacacionesEmpleado[] = [];
  public empleadoSeleccionado: IEmpleados | undefined = undefined;
  public modificando: boolean = false;
  public manejoDiasAdicionales: boolean = false;

  public vacacionesEmpleadoObs!: Observable<any>;
  public dataSource: MatTableDataSource<VacacionesEmpleado> = new MatTableDataSource<VacacionesEmpleado>(this.vacacionesPorEmpleado);

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private accesoDatosService: AccesoDatosService,
  ) {
    this.cargarDatos();
  }

  public cargarDatos() {
    this.estaCargando = true;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.empleadosTotales = empleados;
        this.aplicarFiltrosEmpleados();
        this.empleados.forEach(empleado => {
          (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
        });
        this.generarTablaVacaciones();
        this.dataSource.data = this.vacacionesPorEmpleado;
        this.estaCargando = false;
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
        this.empleadosTotales = [];
      }
    );

    this.accesoDatosService.incidenciasUsuarioCargar({inicio: this.inicioPeriodo, inicioTxt: fechaT(this.inicioPeriodo), fin: this.finPeriodo, finTxt: fechaT(this.finPeriodo)}).subscribe(
      (respuesta: IAccesoIncidenciasUsuarioCargar) => {
        if (respuesta.code == 100) {
          this.empresa = respuesta.empresa;
          this.incidenciasUsuarioOriginal = respuesta.incidencias;
          this.aplicarFiltrosIncidencias();
          return;
        }
        mostrarSwalError('Validación de Incidencias', `Error al cargar las incidencias, respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`)
      },
      (error) => {
        let err: string = JSON.stringify(error);
        this.accesoDatosService.logAgrega2(err);
        mostrarSwalError('Validación de Incidencias', `Error al cargar las incidencias, ${err}`);
      }
    );
  }

  public aplicarFiltrosEmpleados() {
    if (this.mostrarBajas) {
      this.empleados = this.empleadosTotales;
    }  else {
      this.empleados = this.empleadosTotales.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
        (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
      );
    }

    this.empleados = this.empleados;
  }

  public aplicarFiltrosIncidencias() {
    this.incidenciasUsuario = this.incidenciasUsuarioOriginal.sort(
      (a, b) => b.fin.getTime() - a.fin.getTime() || a.inicio.getTime() - b.inicio.getTime() || a.nombreEmpleado.toLowerCase().localeCompare(b.nombreEmpleado.toLowerCase())
    );
  }

  public actualizarVacaciones(index: number) {
    const empleado = this.vacacionesPorEmpleado[index];
    const vacaciones = empleado.vacaciones[0];

    vacaciones.saldoTotal = vacaciones.diasLey + vacaciones.diasAdicionales + vacaciones.diasConGoceSueldo;
    vacaciones.saldoRestante = vacaciones.saldoTotal - vacaciones.diasTomados;
  }

  public generarTablaVacaciones() {
    const empleadoSeleccionado = this.empleados.find(e => e.id === this.empleadoSeleccionado?.id);
    const empleadosAConsiderar = empleadoSeleccionado ? [empleadoSeleccionado] : this.empleados;

    this.vacacionesPorEmpleado = empleadosAConsiderar.map((empleado) => {
      if (!empleado) return { empleado: null, vacaciones: [] };

      const fechaAlta = new Date(empleado.alta);
      const aniosTrabajo = new Date().getFullYear() - fechaAlta.getFullYear();
      const vacaciones: Vacaciones[] = [];

      for (let i = 0; i <= aniosTrabajo; i++) {
        const inicioPeriodo = new Date(fechaAlta.getFullYear() + i, fechaAlta.getMonth(), fechaAlta.getDate());
        const finPeriodo = new Date(fechaAlta.getFullYear() + i + 1, fechaAlta.getMonth(), fechaAlta.getDate() - 1);
        const anio = fechaAlta.getFullYear() + i;
        const diasTomados = this.obtenerDiasTomados(+empleado.id, anio);
        const diasLey = this.calcularDiasPorLey(fechaAlta, i);

        vacaciones.push(this.generarVacacionesEmpleado(inicioPeriodo, finPeriodo, anio, aniosTrabajo, diasTomados, diasLey));
      }

      return { empleado, vacaciones };
    }).filter((item): item is { empleado: IEmpleados; vacaciones: Vacaciones[] } => item.empleado !== null);

    this.dataSource.data = this.vacacionesPorEmpleado;
  }

  private generarVacacionesEmpleado(inicioPeriodo: Date, finPeriodo: Date, anio: number, aniosTrabajo: number, diasTomados: number, diasLey: number): Vacaciones {
    const diasAdicionales = 0;
    const diasConGoceSueldo = 0;
    const aniosTrabajados = aniosTrabajo;
    const saldoTotal = diasLey + diasAdicionales + diasConGoceSueldo;
    const saldoRestante = saldoTotal - diasTomados;

    return {
      aniosTrabajados,
      diasLey,
      diasTomados,
      saldoTotal,
      saldoRestante,
      anio,
      inicioPeriodo,
      finPeriodo,
      diasAdicionales: diasAdicionales,
      diasConGoceSueldo: diasConGoceSueldo,
      manejoDiasConGoce: this.manejoDiasAdicionales,
    };
  }

  public obtenerDiasTomados(idEmpleado: number, anio: number): number {
    const incidenciasEmpleado = this.incidenciasUsuarioOriginal.filter(inc => inc.idEmpleado === idEmpleado && inc.tipoManejoIncidencia === 4 && new Date(inc.inicio).getFullYear() === anio);
    return this.calcularDiasTomados(incidenciasEmpleado);
  }

  public calcularDiasTomados(incidencias: IAccesoIncidenciasUsuario[]): number {
    let totalDias = 0;

    for (const incidencia of incidencias) {
      const inicio = new Date(incidencia.inicio);
      const fin = new Date(incidencia.fin);
      const diferencia = fin.getTime() - inicio.getTime();
      const dias = Math.ceil(diferencia / (1000 * 3600 * 24)) + 1;
      totalDias += dias;
    }

    return totalDias;
  }

  public calcularDiasPorLey(fechaAlta: Date, anioTrabajado: number): number {
    const fechaActual = new Date();
    let aniosTrabajo = anioTrabajado;

    const mesActual = fechaActual.getMonth();
    const mesAlta = fechaAlta.getMonth();
    const diaActual = fechaActual.getDate();
    const diaAlta = fechaAlta.getDate();

    if (mesActual < mesAlta || (mesActual === mesAlta && diaActual < diaAlta)) {
      aniosTrabajo--;
    }

    if (aniosTrabajo < 1) return 0;

    if (aniosTrabajo === 1) return 12;
    if (aniosTrabajo === 2) return 14;
    if (aniosTrabajo === 3) return 16;
    if (aniosTrabajo === 4) return 18;
    if (aniosTrabajo === 5) return 20;
    if (aniosTrabajo >= 6 && aniosTrabajo <= 10) return 20 + (aniosTrabajo - 5) * 2;
    return 30 + Math.floor((aniosTrabajo - 10) / 5) * 2;
  }

  public formatoFecha(fecha: Date | undefined) {
    if (!fecha) return '-';
    return fFecha(fecha, "FSL")
  }

  public nombreCompleto(empleado: IEmpleados) {
    return nombreEmpleado(empleado);
  }

  public modificarDias() {
    this.modificando = true;
  }

  public guardarDias() {
    this.modificando = false;
  }

  public buscarEmpleado(value: IEmpleados | string) {
    // let filterValue: string = '';

    // if (typeof value === 'string') {
    //   filterValue = value.toLowerCase().trim();
    // } else if (value && value.nombre) {
    //   filterValue = value.nombre.toLowerCase().trim();
    // }

    // if (filterValue) {
    //   this.empleados = this.empleadosTotales.filter(empleado =>
    //     empleado.nombre.toLowerCase().includes(filterValue)
    //   );
    //   this.dataSource.data = this.empleados;

    //   this.empleadoNoEncontrado = this.empleados.length === 0;
    // } else {
    //   this.aplicarFiltros();
    //   this.empleadoNoEncontrado = false;
    // }
  }

  public limpiarFiltro() {
    this.empleadoSeleccionado = undefined;
  }

  ngOnInit(): void {
    this.changeDetectorRef.detectChanges();
    this.dataSource.paginator = this.paginator;
    this.vacacionesEmpleadoObs = this.dataSource.connect();
  }
}
