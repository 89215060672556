import { KeyValue } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { ArchivoExcel, ColumnaExcel, RenglonesExcel, TítulosExcel, creaArchivoExcel } from 'src/app/core/Funciones/excel';
import { soloDia, soloHoy, fFecha, CVFechaT, addHours, dateDiff, TipoDateDiff, addDays, inicioSemanaActual, soloHora, soloHoraNum, inicioQuincenaActual, inicioQuincena, inicioMes, inicioMesActual, calculaDiasFestivos, getDateKey, getFechaT, fechaDeTxt } from 'src/app/core/Funciones/fFecha';
import { fNumero, fechaT } from 'src/app/core/Funciones/fTexto';
import { mostrarSwalConfirm, mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { PDFService } from 'src/app/core/services/pdfmake.service';
import { CambiarHorarioComponent } from 'src/app/empleados/cambiar-horario/cambiar-horario.component';
import { HorarioDialogComponent } from 'src/app/horarios/horario-dialog/horario-dialog.component';
import { ICheckReporte, TipoIngreso } from 'src/app/models/checks';
import { EmpleadosManejoAccesoExpress, IEmpleados, nombreEmpleado, } from 'src/app/models/empleados';
import { IEmpresa } from 'src/app/models/empresa';
import { $timerActulizacion, $tipoReporte, $verPor, } from 'src/app/models/filtrosIncidencias';
import { IHorarios, IHorariosEmpleado, RangoHora, IHorariosIncidencias, nuevoPeriodo, IHorariosTotal, crearHorarioDesdeIncidencia } from 'src/app/models/horarios';
import { $tipoEmpleadosManejoAccesoExpress, $tipoIncapacidad, $tipoManejoIncidencia, $tipoValorIncidencia, DatosCrearIncidencia, DatosIncidencias, IAccesoIncidenciasAjuste, IAccesoPoliticas, IDetalleHOrarioDia, IncidenciaHorario, MinifiedDetalleHOrarioDia, TipoIncapacidad, TipoIncidencia, TipoManejoIncidencia, TipoValorIncidencia, convertirHorarioDiaADetalle, minifiedDetalleHorarioDia, nuevaIncidenciaAjuste, nuevaIncidenciaHorario, nuevoDatosIncidencia } from 'src/app/models/incidencias';
import { $tipoPeriodoReporte, $tipoPeriodoReporteOrden } from 'src/app/models/periodos';
import { $programas } from 'src/app/models/programaExportar';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { PuntosDeAccesoEstadoComponent } from 'src/app/puntosDeAcceso/puntos-de-acceso-estado/puntos-de-acceso-estado.component';
import Swal from 'sweetalert2';
const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake/build/vfs_fonts.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-incidencias',
  templateUrl: './incidencias.component.html',
  styleUrls: ['./incidencias.component.scss'],
})
export class IncidenciasComponent implements OnInit {
  public agregarFirma: boolean = false;
  public empresa!: IEmpresa;
  public estaVigente: boolean = true;
  public checks: ICheckReporte[] = [];
  public checksOrigen: ICheckReporte[] = [];
  public horariosEmpleado: IHorariosEmpleado[] = [];
  public horariosIncidencias: IHorariosIncidencias[] = [];
  public incidencias: DatosIncidencias[] = [];
  public incidenciasAjuste: IAccesoIncidenciasAjuste[] = [];
  public incidenciasReporte: { [key: string]: DatosIncidencias[]  } = { };
  public incidenciaTotales: { [key: string]: DatosIncidencias} = {};
  public politicaIncidencias!: IAccesoPoliticas;
  public nombreEmpleados: { [key: string]: {nombre: string, idDepartamento: number} } = {};
  public semanaDias: string[] = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado',];
  public diasFestivos: { [date: string]: boolean } = { };
  public iReporte: number = 0;

  public TipoIngreso = TipoIngreso;
  public TipoIncidencia = TipoIncidencia;
  public grupoEmpleados: string[] = [];


  // filtros
  public reporteSeleccionado: string = 'fecha';
  public tipoReporte = $tipoReporte;
  public periodo: string = 'semana0';

  public verPorSeleccionado: string = 'todo';
  public verPor = $verPor;

  // Filtro empleados
  public empleados: IEmpleados[] = [];
  public empleadosFiltrados: IEmpleados[] = [];
  public empleadoSeleccionado: IEmpleados | undefined = undefined;
  public fechaBajaMax: Date = new Date();
  public agrupadorSeleccionado: string = '';


  public programaSeleccionado: string = 'NomiExpressWeb';
  public programas = $programas;

  public tipoPeriodoReporte = $tipoPeriodoReporte;
  public tipoPeriodoReporteOrden = $tipoPeriodoReporteOrden;

  // banderas
  public cargando: boolean = true;
  public cargandoHorarios: boolean = true;
  public cargandoRegistros: boolean = true;
  public cargandoIncidenciasAjustes: boolean = true;
  public verDetalles: boolean = false;
  public formato12Horas: boolean = false;
  public esDebugger: boolean = false;
  public fullDebugger: boolean = false;
  public tipoManejoIncidencia = $tipoManejoIncidencia;
  public tipoValorIncidencia = $tipoValorIncidencia;
  public tipoMenajoEmpleadoAcceso = $tipoEmpleadosManejoAccesoExpress;
  public tipoIncapacidad = $tipoIncapacidad;
  public etiquetaAgrupar: string = 'Agrupador';

  public modalRef?: BsModalRef;

  @ViewChild('detalles') detalles!: TemplateRef<any>;
  public incidencia!: DatosIncidencias;

  @Input()
  public recargarEvent = new EventEmitter<void>();
  // public recargarEvent: EventEmitter<void> = new EventEmitter<void>();


  // public dataSource: MatTableDataSource<IEmpleados>;
  public displayedColumns: string[] = [];

  @Output()
  public cambioEmpleadoEvent = new EventEmitter<string | undefined>();

  // var filtros
  public departamento: boolean = false;


  // tabla incidencias

  public fallaSincronizar: boolean = false;
  public sumarRetardo: boolean = false;
  public sumarHorasExtras: boolean = false;
  public agregarHorasAntesIngreso: boolean = false;
  public mostrarDialog: boolean = false;


  // filtros de incidencias
  public opcionesEditarRetardo = {
    'default': 'Selecciona una opción',
    'quitar': 'Quitar retardo',
    'sumar': 'Agregar retardo',
  }
  public opcionesHorasExtras = {
    'default': 'Selecciona una opción',
    'quitar': 'Quitar horas extras',
    'sumar': 'Agregar horas extras',
  }
  public opcionesHorasAntesIngreso = {
    'default': 'Selecciona una opción',
    'quitar': 'Quitar horas',
    'sumar': 'Agregar horas',
  }






  // ngModels
  public horasExtrasAdicionales: number = 0;
  public minutosExtrasAdicionales: number = 0;
  public horasRetardos: number = 0;
  public minutosRetardos: number = 0;

  // periodo de incidencias
  public inicioPeriodo: Date = inicioSemanaActual();
  public finPeriodo: Date = soloHoy();
  public rangoPersonalizado = new FormGroup({
    desde: new FormControl<Date | null>(null),
    hasta: new FormControl<Date | null>(null),
  });
  public inicioPersonalizado: Date = new Date();
  public finPersonalizado: Date = new Date();


  public timers = $timerActulizacion;
  public timerActualizacion: string = '';

  constructor(
    public dialog: MatDialog,
    private accesoDatosService: AccesoDatosService,
    private modalService: BsModalService,
    private pdfService: PDFService,
  ) {
    this.cargando = true;
    this.cargarDatosInicial();
  }


  public cargarDatosInicial() {
    this.cargando = true;
    this.cargandoHorarios = true;
    this.cargandoRegistros = true;
    this.cargandoIncidenciasAjustes = true;
    let cargandoPoliticaIncidencias: boolean = true;
    let cargandoEmpleados: boolean = true;

    this.accesoDatosService.incidenciasPolitica().subscribe(
      (politica: IAccesoPoliticas) => {
        this.politicaIncidencias = politica;
        if (!this.politicaIncidencias.horaMaxCambioJornada) this.politicaIncidencias.horaMaxCambioJornada = 5;
        cargandoPoliticaIncidencias = false;
        if (!cargandoEmpleados) this.cargarDatos();
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Cargar política de incidencias.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
      }
    );

    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        let fecha: Date = addDays(new Date(), -10);
        if (this.inicioPeriodo.getTime() < fecha.getTime()) {
          fecha = addDays(this.inicioPeriodo, -10);
        }
        this.fechaBajaMax = fecha;
        this.empleadosFiltrados = empleados.filter(x => !x.baja  || x.baja.getTime() <= new Date(1900, 1, 1).getTime() || x.baja.getTime() >= this.fechaBajaMax.getTime())
        .sort((a, b) => (a.nombre + a.paterno).toLowerCase().localeCompare((b.nombre + b.paterno).toLowerCase()));

        this.empleados = empleados.filter(x => !x.baja  || x.baja.getTime() <= new Date(1900, 1, 1).getTime() || x.baja.getTime() >= this.fechaBajaMax.getTime())
          .sort((a, b) => (a.nombre + a.paterno).toLowerCase().localeCompare((b.nombre + b.paterno).toLowerCase()));
        console.log(`Incidencias ==> cargarDatosInicial. Empleados: ${empleados.length} ==> ${this.empleados.length}`);
        this.nombreEmpleados = {};
        empleados.forEach(
          x => {
            let nombre: string = this.nombreCompleto(x);
            if (!!x.baja && x.baja.getTime() > new Date(2000, 1, 1).getTime()) nombre = `${this.nombreCompleto(x)} (baja: ${fFecha(x.baja, 'fc')})`;
            if (!this.nombreEmpleados.hasOwnProperty(x.id)) {
              this.nombreEmpleados[x.id] = { nombre: nombre, idDepartamento: x.idDepartamento };
            }
          }
        );
        this.empleados.forEach(empleado => {
          (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
        });
        // console.log(`=========>>>> Empleados: ${empleados.length}`, this.nombreEmpleados);

        cargandoEmpleados = false;
        if (!cargandoPoliticaIncidencias) this.cargarDatos();
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Cargar empleados.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
      }
    );

    this.accesoDatosService.horariosEmpleadosConIncidencias().subscribe(
      (horariosTotales: IHorariosTotal) => {
        this.horariosEmpleado = horariosTotales.horariosEmpleados;
        this.horariosIncidencias = horariosTotales.horariosIncidencias;
        this.cargandoHorarios = false;
        if (this.fullDebugger) console.log(`cargarIncidencias ${this.cargandoRegistros}, ${this.cargandoIncidenciasAjustes}, ${this.cargandoHorarios}`);
        if (!this.cargandoRegistros && !this.cargandoIncidenciasAjustes && !this.cargandoHorarios) {
          this.crearIncidencias();
        }
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Cargar incidencias.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
      }
    )

    this.accesoDatosService.empresaObtener().subscribe(
      (empresa: IEmpresa) => {
        this.empresa = empresa;
        this.empresa.vigenteHastaFecha = getFechaT(this.empresa.vigenteHasta);
        if (this.empresa.vigenteHastaFecha < new Date(2024, 8, 31)) {
          this.empresa.vigenteHastaFecha = new Date(2024, 11, 31);
        }
        this.estaVigente = new Date().getTime() < addDays(this.empresa.vigenteHastaFecha, -7).getTime();
        this.etiquetaAgrupar = !empresa.etiquetaGrupo ? 'Agrupador' : empresa.etiquetaGrupo;
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Cargar empresa.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
      }
    );

    this.accesoDatosService.empleadosGrupos().subscribe(
      (grupos: string[]) => {
        this.grupoEmpleados = grupos;
      },
      (error) => {
        let errorTxt: string = error.error;
        this.ponerTxtEnConsola(`Error al cargar los datos de los grupos de empleados. ${errorTxt}`);
        console.error(error);
      }
    );
  }

  public cargarDatos() {
    this.cargando = true;
    this.cargandoRegistros = true;
    this.cargandoIncidenciasAjustes = true;

    this.esDebugger = this.accesoDatosService.getModoDebug();

    this.diasFestivos = {};
    for (let index = this.inicioPeriodo.getFullYear(); index <= this.finPeriodo.getFullYear(); index++) {
      let diasFestivosEjercicio = calculaDiasFestivos(index);
      for (const key in diasFestivosEjercicio) {
        if (key in diasFestivosEjercicio) {
          if (!diasFestivosEjercicio[key]) continue;
            this.diasFestivos[key] = true;
        }
      }
      if (!!this.politicaIncidencias.diasFestivosEmpresa) {
        let diasFestivosEmpresa: string[] = this.politicaIncidencias.diasFestivosEmpresa.split('|');
        diasFestivosEmpresa.forEach(key => {
          let fecha: string[] = key.split('-');
          if (fecha.length < 3) return;
          fecha[0] = index.toString();
          let keyEjercicio: string = fecha.join('-');
          this.diasFestivos[keyEjercicio] = true;
        })
      }
    }

    if (!this.politicaIncidencias || !this.politicaIncidencias.horaMaxCambioJornada) this.politicaIncidencias.horaMaxCambioJornada = 5;
    let horaMaxCambioJornada: number = this.politicaIncidencias.horaMaxCambioJornada;
    let fechaInicialPrimerJornada: Date = addHours(this.inicioPeriodo, horaMaxCambioJornada);
    this.accesoDatosService.registros(nuevoPeriodo(this.inicioPeriodo, addDays(this.finPeriodo, 1)), this.nombreEmpleados).subscribe(
      (checks: ICheckReporte[]) => {
        if (this.fullDebugger) console.log(`===========>>> checks origen`, checks);

        this.checks = checks.filter(x => x.fecha <= this.empresa.vigenteHastaFecha);
        this.checksOrigen = checks;

        let checksUsados: number = 0;
        this.checks.forEach(check => {
          if (check.fecha < fechaInicialPrimerJornada) {
            check.idIncidencia = 999999999;
            checksUsados++;
          }
        });

        if (this.esDebugger || this.fullDebugger) console.log(`se cargaron: ${this.checks.length} checks, usadas: ${checksUsados}`);
        if (checksUsados > 0) {
          if (this.esDebugger || this.fullDebugger) console.log('checksUsadas', null);
          let checksUsadas: ICheckReporte[] = this.checks.filter(x => x.idIncidencia > 0);
          if (this.esDebugger || this.fullDebugger) console.log('checksUsadas', checksUsadas);
        }
        this.cargandoRegistros = false;
        if (this.fullDebugger) console.log(`cargarIncidencias ${this.cargandoRegistros}, ${this.cargandoIncidenciasAjustes}, ${this.cargandoHorarios}`);
        if (!this.cargandoRegistros && !this.cargandoIncidenciasAjustes && !this.cargandoHorarios) {
          this.crearIncidencias();
        }
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Cargar registros.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
      }
    );

    this.accesoDatosService.incidenciasAjusteConEspeciales(nuevoPeriodo(this.inicioPeriodo, this.finPeriodo)).subscribe(
      (incidencias: IAccesoIncidenciasAjuste[]) => {
        if (!this.empresa || !this.empresa.vigenteHastaFecha || this.empresa.vigenteHastaFecha < new Date(2024, 8, 31)) {
          this.empresa.vigenteHastaFecha = new Date(2024, 11, 31);
        }
        this.incidenciasAjuste = incidencias.filter(x => x.fecha <= this.empresa.vigenteHastaFecha);
        if (this.esDebugger || this.fullDebugger) console.log(`incidenciasAjuste: ${this.incidenciasAjuste.length}`, this.incidenciasAjuste);
        this.cargandoIncidenciasAjustes = false;
        if (this.fullDebugger) console.log(`cargarIncidencias ${this.cargandoRegistros}, ${this.cargandoIncidenciasAjustes}, ${this.cargandoHorarios}`);
        if (!this.cargandoRegistros && !this.cargandoIncidenciasAjustes && !this.cargandoHorarios) {
          this.crearIncidencias();
        }
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Cargar incidencias.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
      }
    );
  }

  // ======================================================================================================================== >>>>>>>>>>>>>>> Incidencias
  private crearIncidencias() {
    if (this.fullDebugger) console.log(`cargarIncidencias ${this.cargandoRegistros}, ${this.cargandoIncidenciasAjustes}, ${this.cargandoHorarios}`);
    if (this.cargandoRegistros || this.cargandoIncidenciasAjustes || this.cargandoHorarios) {
      return;
    }
    this.incidencias = [];
    if (!this.inicioPeriodo) this.inicioPeriodo = new Date();
    if (!this.finPeriodo) this.finPeriodo = new Date();
    let empeladosFiltrados: IEmpleados[] = [];
    if (this.agrupadorSeleccionado) {
      empeladosFiltrados = this.empleados.filter(empleado => empleado.grupo == this.agrupadorSeleccionado);
    } else {
      empeladosFiltrados = this.empleados;
    }

    let id: number = 1;
    let dia1: Date = this.inicioPeriodo;
    let dias: number = dateDiff(this.inicioPeriodo, this.finPeriodo, TipoDateDiff.días) + 1;
    if (this.fullDebugger) console.log(`cargarIncidencias inicializar variables`);
    for (const empleado of empeladosFiltrados) {

      let nombre: string = this.nombreEmpleados[empleado.id].nombre;
      if (!nombre) nombre = nombreEmpleado(empleado);
      let idDepartamento: number = this.nombreEmpleados[empleado.id].idDepartamento;
      let checksEmpleado: ICheckReporte[] = this.checks.filter(x => x.idEmpleado == +empleado.id);

      dia1 = this.inicioPeriodo;
      let incidencia: DatosIncidencias = nuevoDatosIncidencia(+empleado.id, nombre, id, idDepartamento, dia1, empleado.idReferencia);
      let incidenciasHorario: IncidenciaHorario[] = [];

      // if (this.fullDebugger) console.log(`cargarIncidencias incidenciasAjusteEmpleado`);
      let incidenciasAjusteEmpleado: IAccesoIncidenciasAjuste[] = this.incidenciasAjuste.filter(x => x.idEmpleado == +empleado.id);
      // if (this.fullDebugger) console.log(`cargarIncidencias incidenciasAjusteEmpleado 2`);
      let tieneIncidenciaAjusteEmpleado: boolean = !!incidenciasAjusteEmpleado && incidenciasAjusteEmpleado.length > 0;

      if (empleado.manejoAccesoExpress == undefined) empleado.manejoAccesoExpress = EmpleadosManejoAccesoExpress.Normal;
      if (!tieneIncidenciaAjusteEmpleado && (!checksEmpleado || checksEmpleado.length <= 0) && empleado.manejoAccesoExpress == EmpleadosManejoAccesoExpress.Normal && false) {
        // if (this.fullDebugger) console.log(`cargarIncidencias incidenciasAjusteEmpleado tieneIncidenciaAjusteEmpleado`);
        // if (this.esDebugger || this.fullDebugger) console.log(`Periodo: ${fFecha(dia1, 'fm')} + ${dias + 1} días, empleado: ${empleado.id} - ${nombre} no tiene checks ni incidencias en el período`);

        // en caso de no tener movimientos, y si el empleado no se le asignan ajustes y no es un empelado normal (a quienes se le ponen faltas)
        console.log(`empleado saltado: ${empleado.id}, ${empleado.nombre}`);
        for (let index = 1; index <= dias; index++){
          if (this.fullDebugger) console.log(`cargarIncidencias incidenciasAjusteEmpleado tieneIncidenciaAjusteEmpleado ${index}`);
          dia1 = soloDia(addDays(this.inicioPeriodo, dias - index));
          incidencia = nuevoDatosIncidencia(+empleado.id, nombre, id, idDepartamento, dia1, empleado.idReferencia);

          incidenciasHorario = [];
          incidenciasHorario.push(nuevaIncidenciaHorario());
          incidencia.incidenciaHorario = incidenciasHorario;

          this.incidencias.push(this.cargarTotalesIncidenciaDia(incidencia));
          id++;
          incidencia.id = id;

        }
        continue;
      }

      dia1 = soloDia(addDays(this.inicioPeriodo, dias - 1));
      let horario: IHorarios | undefined = this.obtenerHorarioEmpleado(+empleado.id, dia1);
      for (let index = 1; index <= dias; index++) {
        dia1 = soloDia(addDays(this.inicioPeriodo, index - 1));
        let dia2: Date = soloDia(addDays(dia1, 1));
        let dia3: Date = soloDia(addDays(dia1, 2));

        if (+empleado.id == 112629) {
          if (dia1.getTime() == new Date(2024, 9, 23).getTime()) {
            console.log(`empleado: ${empleado.id}, ${empleado.nombre}, dia: ${this.formatoFecha(dia1)}`);
          }
        }

        incidenciasAjusteEmpleado = this.incidenciasAjuste.filter(x => x.idEmpleado == +empleado.id && x.fecha.getTime() == dia1.getTime() && x.tipoManejoIncidencia != TipoManejoIncidencia.Incidencia);
        incidencia = nuevoDatosIncidencia(+empleado.id, nombre, id, idDepartamento, dia1, empleado.idReferencia);

        // buscar incidencias cargadas por los usuarios
        for (let i = 0; i < incidenciasAjusteEmpleado.length; i++) {
          const incidenciaAjuste = incidenciasAjusteEmpleado[i];
          incidencia.idIncidenciaAjuste = incidenciaAjuste.id;
          incidencia.tipoManejoIncidencia = incidenciaAjuste.tipoManejoIncidencia;
          incidencia.especialTxt = this.tipoManejoIncidencia[incidenciaAjuste.tipoManejoIncidencia];
          if (incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.Ausentismo || incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.PermisoSinPago  || incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.ComisionSinPago) {
            incidencia.faltas = 1;
            break;
          }
          if (incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.Incapacidad) {
            incidencia.incapacidad = 1;
            break;
          }
          if (incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.Vacaciones) {
            incidencia.vacaciones = 1;
            break;
          }
          if (incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.ReEstablecer || incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.Incidencia || incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.Ninguno) {
            incidencia.especialTxt = ``;
          }

          if (incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.Comision || incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.Permiso || incidenciaAjuste.tipoManejoIncidencia == TipoManejoIncidencia.Viaje) {
            if (this.esDebugger || this.fullDebugger) {
              console.log(`Incidencia ajuste ${this.tipoManejoIncidencia[incidenciaAjuste.tipoManejoIncidencia]}, ${empleado.id} ${nombre}, ${fFecha(dia1, 'fm')}`);
            }
          }

          // switch (incidenciasAjusteEmpleado[i].tipoManejoIncidencia) {
          //       case TipoManejoIncidencia.Permiso:
          //       case TipoManejoIncidencia.Asistencia:
          //       case TipoManejoIncidencia.Eliminar:                ===>
          // }
        }

        // en caso de tener incidencias que alteren el dia del empleado, las cargamos e ignoramos los demás movimientos del día
        if (incidencia.idIncidenciaAjuste > 0 && incidencia.faltas + incidencia.incapacidad + incidencia.vacaciones > 0 && incidencia.tipoManejoIncidencia != TipoManejoIncidencia.Eliminar) {
          if (this.esDebugger || this.fullDebugger) console.log(`Incidencia ajuste ${this.tipoManejoIncidencia[incidencia.tipoManejoIncidencia]}, empleado: ${empleado.id} ${nombre}, el ${fFecha(dia1, 'fc')} <==`);
          incidenciasHorario = [];
          incidenciasHorario.push(nuevaIncidenciaHorario());
          incidenciasHorario[0].observaciones.push(`Incidencia: ${this.tipoManejoIncidencia[incidencia.tipoManejoIncidencia]}`);
          if (incidencia.faltas > 0) {
            incidenciasHorario[0].falta = 1;
            incidencia.faltas = 1;
            incidencia.faltasTxt = '1';
          } else {
            incidencia.faltas = 0;
          }
          incidencia.incidenciaHorario = incidenciasHorario;

          this.incidencias.push(incidencia);
          id++;
          incidencia.id = id;
          continue;
        } else if (incidencia.faltas + incidencia.incapacidad + incidencia.vacaciones > 0) {
          incidencia.faltas = 0;
          incidencia.incapacidad = 0;
          incidencia.vacaciones = 0;
        }

        // revisamos que el movimiento tenga horario
        if (this.fullDebugger) console.log(`cargarIncidencias ${fFecha(dia1, 'fc')} ${empleado.id} - ${nombre}`);
        if (!!this.horariosIncidencias && this.horariosIncidencias.length > 0) {
          let horarioIncidencia: IHorariosIncidencias | undefined = this.horariosIncidencias.find(x => x.idEmpleado == +empleado.id && x.inicio <= dia1 && x.fin >= dia1);
          if (!!horarioIncidencia) {
            horario = {
              id: -1,
              idEmpleado: +empleado.id,
              idHorario: undefined,
              nombreHorario: 'Incidencia de horario',
              inicio: horarioIncidencia.inicio,
              inicioTxt: horarioIncidencia.inicioTxt,
              fin: horarioIncidencia.fin,
              finTxt: horarioIncidencia.finTxt,
              esModificable: false,
              nocturno: horarioIncidencia.esNocturno,
              detalles: convertirHorarioDiaADetalle(horarioIncidencia, dia1)
            }
          }
        }
        if (!horario || horario.inicio > dia1 || horario.fin < dia1){
          horario = this.obtenerHorarioEmpleado(+empleado.id, dia1);
          if (!horario) {
            if (this.fullDebugger) console.log(`No existe horario para el día ${fFecha(dia1, 'fc')}, para el empleado: ${empleado.id} - ${nombre}`);
          }
        }

        // cargamos las incidencias del dia actual + 1 día para las salidas en la madrugada
        let checksDia: ICheckReporte[]  = [];
        let checksDiaSiguienteDia: ICheckReporte[] = [];
        if (incidencia.tipoManejoIncidencia != TipoManejoIncidencia.Eliminar) {
          checksDia = checksEmpleado.filter(x => x.fecha >= dia1 && x.fecha < dia2 && x.idEmpleado == +empleado.id && x.idIncidencia == 0).sort((a, b) => a.fecha.getTime() - b.fecha.getTime());
          checksDiaSiguienteDia = checksEmpleado.filter(x => x.fecha >= dia2 && x.fecha < dia3 && x.idEmpleado == +empleado.id && x.idIncidencia == 0).sort((a, b) => a.fecha.getTime() - b.fecha.getTime());
        } else if (this.esDebugger || this.fullDebugger) {
          // si el empleado tiene una incidencia de eliminar los movimientos del día no la asignamos
          let checksDia1 = checksEmpleado.filter(x => x.fecha >= dia1 && x.fecha < dia2 && x.idEmpleado == +empleado.id && x.idIncidencia == 0).sort((a, b) => a.fecha.getTime() - b.fecha.getTime());
          console.log(`Se eliminaron: ${checksDia1.length} checks`);
        }

        // cargamos el horario del dia para el empleado
        incidencia.detalleHorarioDia.horarioDia = [];
        incidencia.detalleHorarioDia.esDescanso = false;
        incidencia.detalleHorarioDia.esNocturno = false;
        let txt: string = getDateKey(dia1);
        if (txt in this.diasFestivos) {
          incidencia.detalleHorarioDia.esDescanso = true;
          incidencia.especialTxt = 'Descanso Ley';
        }
        txt = `Fecha: ${fFecha(dia1, 'fm')}, empleado: ${empleado.id} - ${nombre}`;
        if (!!horario) {
          txt += `, horario.inicio: ${fFecha(horario.inicio, 'fc')}`;
          incidencia.idHorario = horario.idHorario || '';
          incidencia.horario = horario.nombreHorario || '';
          let semanasHorario: number = horario.detalles.length;
          if (semanasHorario < 1) semanasHorario = 1;
          let semanas: number = Math.trunc(dateDiff(horario.inicio, dia1, TipoDateDiff.días) / 7);
          let semanaDetalle: number = Math.ceil(semanas) % semanasHorario;

          if (semanaDetalle > semanasHorario) {
            txt += `, semanaDetalle MAYOR A ${semanasHorario}: ${semanaDetalle}`;
            semanaDetalle = 0;
          } else {
            txt += `, semanaDetalle: ${semanaDetalle}`;
          }

          let key: string = this.semanaDias[dia1.getDay()];
          txt += `, semanas: ${semanas}, semanas horario: ${semanasHorario}, key: ${key}`;
          incidencia.detalleHorarioDia.horarioDia = horario.detalles[semanaDetalle].horariosSemana[key];
          let descansos: string[] = horario.detalles[semanaDetalle].diasDescanso;
          incidencia.detalleHorarioDia.esDescanso = incidencia.detalleHorarioDia.esDescanso || descansos.includes(key);
          txt += `, semanas: ${semanas}, semanas horario: ${semanasHorario}, key: ${key}, esDescanso: ${incidencia.detalleHorarioDia.esDescanso}`;
          if (!!incidencia.detalleHorarioDia.horarioDia && horario.nocturno) {
            txt += `, Es Nocturno`;
          }
          incidencia.detalleHorarioDia.esNocturno = horario.nocturno;
        }

        if (+empleado.id == 112629) {
          if (dia1.getTime() == new Date(2024, 9, 23).getTime()) {
            console.log(`empleado: ${empleado.id}, ${empleado.nombre}, dia: ${this.formatoFecha(dia1)}`);
          }
        }

        incidenciasHorario = [];
        // No es descanso y no tiene movimientos
        if (!incidencia.detalleHorarioDia.esDescanso && (!checksDia || checksDia.length <= 0) && (!checksDiaSiguienteDia || checksDiaSiguienteDia.length <= 0)) {
          let incidenciaHorario: IncidenciaHorario = nuevaIncidenciaHorario();
          if (!horario) {
            incidenciaHorario.tipoIncidenciaEntrada = TipoIncidencia.SinHorario;
          }
          incidenciaHorario.falta = empleado.manejoAccesoExpress != EmpleadosManejoAccesoExpress.ConfianzaSinRegistro ? 1 : 0;
          incidenciasHorario.push(incidenciaHorario);
          txt = `Fecha: ${fFecha(dia1, 'fm')}, empleado: ${empleado.id} - ${nombre}, sin checksDia`;
          txt += `, incidenciasHorario: ${incidenciasHorario.length}`;
        } else {
          let datos: DatosCrearIncidencia = new DatosCrearIncidencia(+empleado.id, nombre, id, dia1, incidencia.detalleHorarioDia.esDescanso, dia1.getTime() >= soloHoy().getTime());
          incidenciasHorario = this.crearIncidenciaDia(checksDia, checksDiaSiguienteDia, incidencia.detalleHorarioDia.horarioDia, datos);
          // let checksUsadas: ICheckReporte[] = checksEmpleado.filter(x => x.fecha >= addDays(dia1, -1) && x.fecha < dia3 && x.idEmpleado == +empleado.id && x.idIncidencia > 0).sort((a, b) => a.fecha.getTime() - b.fecha.getTime());
          // if (this.esDebugger || this.fullDebugger) console.log(`checksUsadas`, checksUsadas);
          txt += `, checksDia: ${checksDia.length + 1}, `;
          txt += `, incidenciasHorario: ${incidenciasHorario.length}`;
        }

        let incidenciasAjusteHorario: IAccesoIncidenciasAjuste[] = this.incidenciasAjuste.filter(x => x.idEmpleado == +empleado.id && x.fecha.getTime() == dia1.getTime() && x.tipoManejoIncidencia == TipoManejoIncidencia.Incidencia);
        if (this.fullDebugger || this.esDebugger && (incidenciasAjusteHorario.length > 0 || incidencia.tipoManejoIncidencia != TipoManejoIncidencia.Ninguno)) {
          console.log(txt + `, Incidencia: ${this.tipoManejoIncidencia[incidencia.tipoManejoIncidencia]}`);
        }

        for (let i = 0; i < incidenciasHorario.length; i++) {
          txt = '';
          if (incidencia.tipoManejoIncidencia == TipoManejoIncidencia.Eliminar) {
            txt = 'Las incidencias han sido eliminadas';
            if (this.fullDebugger || this.esDebugger) console.log(`Fecha: ${fFecha(dia1, 'fm')}, empleado: ${empleado.id} - ${nombre}, ${txt}`);
            incidenciasHorario[i].observaciones.push(txt);
          }
          if (this.fullDebugger) console.log(`incidenciasHorario: ${i} ->  id: ${id}`, incidenciasHorario[i]);
          if (incidencia.tipoManejoIncidencia == TipoManejoIncidencia.Asistencia || incidencia.tipoManejoIncidencia == TipoManejoIncidencia.Permiso || incidencia.tipoManejoIncidencia == TipoManejoIncidencia.Comision || incidencia.tipoManejoIncidencia == TipoManejoIncidencia.Viaje){
            // console.log(`incidenciasHorario: ${i} ->  id: ${id}`, incidenciasHorario[i], incidenciaCarga);
            // console.log(`incidenciaCarga: ${i} ->  id: ${id}, ${empleado.id} - ${nombre} ==> incidenciaCarga.tipoManejoIncidencia: ${this.tipoManejoIncidencia[incidencia.tipoManejoIncidencia]}`, incidencia, incidenciasHorario[i]);
            if (incidencia.faltas > 0 || incidenciasHorario[i].falta > 0) {
              incidencia.faltas = 0;
              incidencia.faltasTxt = '-';
              incidenciasHorario[i].falta = 0;
            }
            txt = `Incidencia ${this.tipoManejoIncidencia[incidencia.tipoManejoIncidencia]}`;
            if (this.fullDebugger || this.esDebugger) console.log(`Fecha: ${fFecha(dia1, 'fm')}, empleado: ${empleado.id} - ${nombre}, ${txt}`);
            incidenciasHorario[i].observaciones.push(txt);
          }

          if (empleado.manejoAccesoExpress != EmpleadosManejoAccesoExpress.Normal) {
            if (incidencia.faltas > 0 || incidenciasHorario[i].falta > 0 || incidencia.retardos > 0 || incidenciasHorario[i].retardo > 0 || incidencia.horasExtras > 0 || incidenciasHorario[i].horasExtras > 0) {
              incidencia.faltas = 0;
              incidencia.retardos = 0;
              incidencia.horasExtras = 0;
              incidencia.faltasTxt = '-';
              incidenciasHorario[i].falta = 0;
              incidenciasHorario[i].retardo = 0;
              incidenciasHorario[i].horasExtras = 0;
              txt = `Incidencia ${this.tipoMenajoEmpleadoAcceso[empleado.manejoAccesoExpress]}`;
              if (this.fullDebugger || this.esDebugger) console.log(`Fecha: ${fFecha(dia1, 'fm')}, empleado: ${empleado.id} - ${nombre}, ${txt}`);
              incidenciasHorario[i].observaciones.push(txt);
            }
          }

          if (!!incidenciasAjusteHorario && incidenciasAjusteHorario.length > 0) {
            for (let j = 0; j < incidenciasAjusteHorario.length; j++) {
              const incidenciaAjusteHorario = incidenciasAjusteHorario[j];
              txt = `Incidencia horario: ${this.tipoValorIncidencia[incidenciaAjusteHorario.tipoIncidencia]}`;
              if (incidenciaAjusteHorario.tipoIncidencia == TipoValorIncidencia.IncidenciaEspecial || incidenciaAjusteHorario.tipoIncidencia == TipoValorIncidencia.AsistenciaEspecial || incidenciaAjusteHorario.tipoIncidencia == TipoValorIncidencia.FaltaJustificada) {
                txt = `Incidencia horario: ${this.tipoManejoIncidencia[incidenciaAjusteHorario.tipoManejoIncidencia]}`;
              }
              if (incidenciaAjusteHorario.tipoIncidencia == TipoValorIncidencia.HoraExtra || incidenciaAjusteHorario.tipoIncidencia == TipoValorIncidencia.Retardo) {
                txt += ` ${fNumero(incidenciaAjusteHorario.valor, 'hm')}`;
              }
              if (this.fullDebugger || this.esDebugger) console.log(txt);
              incidenciasHorario[i].observaciones.push(txt);
              switch(incidenciaAjusteHorario.tipoIncidencia) {
                case TipoValorIncidencia.Asistencia:
                  incidencia.faltas = 0;
                  incidenciasHorario[i].falta = 0;
                  break;
                case TipoValorIncidencia.AsistenciaEspecial:
                  console.log(`tipoIncidencia: ${incidenciaAjusteHorario.tipoIncidencia}, tipoManejoIncidencia: ${incidenciaAjusteHorario.tipoManejoIncidencia}`);
                  incidencia.faltas = 0;
                  incidenciasHorario[i].falta = 0;
                  break;
                case TipoValorIncidencia.Falta:
                case TipoValorIncidencia.FaltaJustificada:
                  incidencia.faltas = i == 0 ? 1 : 0;
                  incidenciasHorario[i].falta = i == 0 ? 1 : 0;
                  incidencia.descanso = 0;
                  incidenciasHorario[i].descanso = 0;
                  incidencia.retardos = 0;
                  incidenciasHorario[i].retardo = 0;
                  incidencia.horasExtras = 0;
                  incidenciasHorario[i].horasExtras = 0;
                  break;
                case TipoValorIncidencia.Retardo:
                  incidencia.retardos = i == 0 ? incidenciaAjusteHorario.valor : 0;
                  incidenciasHorario[i].retardo = i == 0 ? incidenciaAjusteHorario.valor : 0;
                  break;
                case TipoValorIncidencia.QuitarRetardo:
                  incidencia.retardos = 0;
                  incidenciasHorario[i].retardo = 0;
                  break;
                case TipoValorIncidencia.HoraExtra:
                  incidencia.horasExtras = i == 0 ? incidenciaAjusteHorario.valor : 0;
                  incidenciasHorario[i].horasExtras = i == 0 ? incidenciaAjusteHorario.valor : 0;
                  break;
                case TipoValorIncidencia.QuitarHorasExtra:
                  incidencia.horasExtras = 0;
                  incidenciasHorario[i].horasExtras = 0;
                  break;
                case TipoValorIncidencia.IncidenciaEspecial:
                  if (incidenciaAjusteHorario.tipoManejoIncidencia == TipoManejoIncidencia.Permiso || incidenciaAjusteHorario.tipoManejoIncidencia == TipoManejoIncidencia.Comision ||incidenciaAjusteHorario.tipoManejoIncidencia == TipoManejoIncidencia.Viaje) {
                    incidencia.faltas = 0;
                    incidenciasHorario[i].falta = 0;
                  } else {
                    console.log(`Tipo de incidencia especial no reconocida: ${incidenciaAjusteHorario.tipoManejoIncidencia}`);
                  }
                  break;
              }
              if (incidencia.idIncidenciaAjuste == 0) incidencia.idIncidenciaAjuste = incidenciaAjusteHorario.id;
              if (incidenciaAjusteHorario.tipoIncidencia == TipoValorIncidencia.Falta) break;
            }
          }

          let incidenciaCarga: DatosIncidencias = { ...incidencia };
          incidenciaCarga.incidenciaHorario = [ incidenciasHorario[i]  ];
          let incidenciaConTotales: DatosIncidencias = this.cargarTotalesIncidenciaDia(incidenciaCarga);
          if (this.esDebugger && this.fullDebugger && (incidenciaCarga.tipoManejoIncidencia == TipoManejoIncidencia.Asistencia || incidenciaCarga.tipoManejoIncidencia == TipoManejoIncidencia.Permiso)) {
            console.log(`incidenciaConTotales: ${i} ->  id: ${id}, ${empleado.id} - ${nombre} ==> incidenciaCarga.tipoManejoIncidencia: ${this.tipoManejoIncidencia[incidenciaCarga.tipoManejoIncidencia]}`, incidenciaCarga, incidenciaConTotales);
          }
          this.incidencias.push(incidenciaConTotales);
          id++;
          incidencia.id = id;
        }
      }
    }

    if (this.fullDebugger) console.log('Incidencias:', this.incidencias);

    this.cargarIncidencias();

    this.filtrarDatos(false);
    this.cargando = false;
  }

  private crearIncidenciaDia(checksDia: ICheckReporte[], checksDia2: ICheckReporte[], horarioDia: RangoHora[], datos: DatosCrearIncidencia): IncidenciaHorario[] {
    let cantChecks: number = !checksDia ? 0 : checksDia.length;
    let cantChecksDia2: number = !checksDia2 ? 0 : checksDia2.length;
    let cantMovHorarioDia: number = !horarioDia ? 0 : horarioDia.length;
    let incidencias: IncidenciaHorario[] = [];
    let incidenciaHorario: IncidenciaHorario = nuevaIncidenciaHorario();
// idIncidencia: number, esDescanso: boolean, esHoyOMayor: boolean, nombreEmpleado: string
    if ((cantChecks < 1) && (datos.esDescanso || cantChecksDia2 < 1 || cantMovHorarioDia < 1)) {
      if (datos.esDescanso) {
        // console.log('crearIncidenciaDia -> sin checks dia, es Descanso');
        incidenciaHorario.tipoIncidenciaEntrada = TipoIncidencia.Descanso;
        incidenciaHorario.descanso = 1;
      } else {
        // console.log('crearIncidenciaDia -> sin checks dia');
        incidenciaHorario.tipoIncidenciaEntrada = TipoIncidencia.SinRegistro;
      }
      incidenciaHorario.tipoIncidenciaSalida = incidenciaHorario.tipoIncidenciaEntrada;
      incidencias.push(incidenciaHorario);
      return incidencias;
    }

    if (cantChecks >= 1) {
      // console.log(`checksDia[0]: ${fFecha(checksDia[0].fecha, 'fmh')}, checksDia[cantChecks - 1 = ${cantChecks - 1}] : ${fFecha(checksDia[cantChecks - 1].fecha, 'fmh')}`);
      incidenciaHorario.horaEntrada = soloHora(checksDia[0].fecha);
      if (cantChecks > 1) incidenciaHorario.horaSalida = soloHora(checksDia[cantChecks - 1].fecha);
    }

    if (this.politicaIncidencias.horaMaxCambioJornada < 0) this.politicaIncidencias.horaMaxCambioJornada = 0;
    let horaMaxCambioJornada: number = this.politicaIncidencias.horaMaxCambioJornada;
    if (horaMaxCambioJornada > 12) horaMaxCambioJornada = 12;
    if (datos.esDescanso) {
      incidenciaHorario.descanso = 1;
      incidenciaHorario.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
      if (cantChecks > 1) {
        incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
        incidenciaHorario.trabajoEnDescanso = dateDiff(incidenciaHorario.horaSalida, incidenciaHorario.horaEntrada, TipoDateDiff.horas);
      } else {
        incidenciaHorario.trabajoEnDescanso = 0.0001;
        incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.SinRegistro;
        // if (this.fullDebugger) console.log(`crearIncidenciaDia -> trabajo en descanso sin salida, trabajoEnDescanso: ${incidenciaHorario.trabajoEnDescanso}, tipoIncidenciaSalida: ${incidenciaHorario.tipoIncidenciaSalida}`);
      }

      if (cantChecksDia2 > 0) {
        for (let index = 0; index < checksDia2.length; index++) {
          const check = checksDia2[index];
          if (soloHora(check.fecha).getTime() >= soloHoraNum(horaMaxCambioJornada).getTime()) {
            break;
          }
          check.idIncidencia = datos.idIncidencia;
          incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
          incidenciaHorario.horaSalida = soloHora(check.fecha);
          incidenciaHorario.trabajoEnDescanso = dateDiff(addDays(incidenciaHorario.horaSalida, 1), incidenciaHorario.horaEntrada, TipoDateDiff.horas);
        }
      }

      checksDia.forEach(x => x.idIncidencia = datos.idIncidencia);

      incidencias.push(incidenciaHorario);
      return incidencias;
    }

    if (cantMovHorarioDia < 1) {
      // if (this.fullDebugger) console.log('crearIncidenciaDia -> sin horario dia');
      incidenciaHorario.tipoIncidenciaEntrada = TipoIncidencia.SinHorario;
      incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.SinHorario;
      incidencias.push(incidenciaHorario);

      checksDia.forEach(x => x.idIncidencia = datos.idIncidencia);
      return incidencias;
    }

    let depurarMovimiento: boolean = (datos.idEmpleado == 112629 && datos.dia.getTime() >= new Date(2024, 9, 25).getTime());
    if (depurarMovimiento) {
      console.log(`horarioDia`, horarioDia);
    }

    let nocturno: boolean = horarioDia[cantMovHorarioDia - 1].nocturno;
    if (nocturno) {
      horaMaxCambioJornada = horarioDia[cantMovHorarioDia - 1].horaSalida - 23 + this.politicaIncidencias.horaMaxCambioJornada;
    }
    if (horaMaxCambioJornada > 12) horaMaxCambioJornada = 12;

    let horaCheck: Date;
    incidenciaHorario = nuevaIncidenciaHorario();
    incidenciaHorario.horaEntradaHorario = soloHoraNum(horarioDia[0].horaEntrada);
    incidenciaHorario.horaSalidaHorario = soloHoraNum(horarioDia[cantMovHorarioDia - 1].horaSalida);
    incidenciaHorario.horasHorario = dateDiff(incidenciaHorario.horaEntradaHorario, incidenciaHorario.horaSalidaHorario, TipoDateDiff.horas);
    if (cantChecks < 1) {
      horaCheck = soloHora(checksDia2[0].fecha);
      if (horaCheck.getTime() >= soloHoraNum(horaMaxCambioJornada).getTime()) {
        // sin movimientos
        incidenciaHorario.observaciones.push('No se registró movimientos en el día. Sin movimientos.');
        incidenciaHorario.falta = 1;
        incidencias.push(incidenciaHorario);
        return incidencias;
      }

      if (this.esDebugger || this.fullDebugger) console.log(`${nombreEmpleado}, Validando checks dia siguiente: ${fFecha(checksDia2[0].fecha, 'fmh')}, horaMaxCambioJornada: ${horaMaxCambioJornada}`);

      for (let index = 0; index < checksDia2.length; index++) {
        const check = checksDia2[index];
        if (soloHora(check.fecha).getTime() >= soloHoraNum(horaMaxCambioJornada).getTime()) {
          break;
        }
        horaCheck = soloHora(check.fecha);
        check.idIncidencia = datos.idIncidencia;
      }

      incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
      incidenciaHorario.salidaDiaSiguiente = true;
      incidenciaHorario.horaSalida = horaCheck;
      incidencias.push(incidenciaHorario);
    } else {
      horaCheck = soloHora(checksDia[0].fecha);
      let difHoras: number = 0;
      let txt: string = `fecha: ${fFecha(checksDia[0].fecha, 'fmh')} cantChecks: ${cantChecks}`;
      let indexHorario: number = 0;

      if (cantChecks <= 2 || cantMovHorarioDia == 1) {
        indexHorario = cantMovHorarioDia - 1;
        if (this.esIngreso(incidenciaHorario, horaCheck))  { // si el registro es antes del horario de entrada lo consideramos como ingreso
          incidenciaHorario.horaEntrada = horaCheck;
          incidenciaHorario.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
        } else {
          incidenciaHorario.horaSalida = horaCheck;
          incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
        }

        if (cantChecks > 1) {
          horaCheck = soloHora(checksDia[cantChecks - 1].fecha);
          if (incidenciaHorario.tipoIncidenciaSalida == TipoIncidencia.SinRegistro) {
            difHoras = dateDiff(incidenciaHorario.horaEntrada, horaCheck, TipoDateDiff.horas);
            if (difHoras > 1 || incidenciaHorario.horasHorario <= 2) {
              txt += ` (2+)s`;
              incidenciaHorario.horaSalida = horaCheck;
              incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
            } else {
              txt += ` (2+)e, difHoras: ${difHoras}, horasHorario: ${incidenciaHorario.horasHorario}`;
            }
          } else {
            txt += ` (2+)ns`;
            incidenciaHorario.horaSalida = horaCheck;
          }
        }
        txt += `, tipoIncidenciaEntrada: ${incidenciaHorario.tipoIncidenciaEntrada}, tipoIncidenciaSalida: ${incidenciaHorario.tipoIncidenciaSalida}`
        txt += `, hora entrada: ${fFecha(incidenciaHorario.horaEntrada, 'hm')}, hora salida: ${fFecha(incidenciaHorario.horaSalida, 'hm')}`;
        // if (this.esDebugger || this.fullDebugger) console.log(txt);
      } else {
        if (depurarMovimiento) {
          console.log(`horarioDia[indexHorario]`, horarioDia[indexHorario]);
        }
        incidenciaHorario.horaEntradaHorario = soloHoraNum(horarioDia[indexHorario].horaEntrada);
        incidenciaHorario.horaSalidaHorario = soloHoraNum(horarioDia[indexHorario].horaSalida);
        incidenciaHorario.horasHorario = dateDiff(incidenciaHorario.horaEntradaHorario, incidenciaHorario.horaSalidaHorario, TipoDateDiff.horas);

        for (let index = 0; index < checksDia.length; index++) {
          const check = checksDia[index];
          horaCheck = soloHora(check.fecha);
          let txt: string = `fecha: ${fFecha(check.fecha, 'fmh')} cantChecks1: ${checksDia.length}`;

          if (this.esIngreso(incidenciaHorario, horaCheck)) { // si el movimiento está mas cerca del ingreso
            if (incidenciaHorario.tipoIncidenciaEntrada == TipoIncidencia.ATiempo) { //  si ya existe una hora registrada
              difHoras = dateDiff(incidenciaHorario.horaEntrada, horaCheck, TipoDateDiff.horas);
              if (difHoras > 1 || incidenciaHorario.horasHorario <= 2) { // si es ingreso y ha pasado mas de una hora o el horario es menor a 2 horas lo consideramos como salida
                // lo consideramos como salida
                incidenciaHorario.horaSalida = horaCheck;
                incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
              }
            } else {
              incidenciaHorario.horaEntrada = horaCheck;
              incidenciaHorario.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
            }
            txt += `, IndexHorario: ${indexHorario}, tipoIncidenciaEntrada: ${incidenciaHorario.tipoIncidenciaEntrada}, tipoIncidenciaSalida: ${incidenciaHorario.tipoIncidenciaSalida}`
            txt += `, hora entrada: ${fFecha(incidenciaHorario.horaEntrada, 'hm')}, hora salida: ${fFecha(incidenciaHorario.horaSalida, 'hm')}`;
            // if (this.esDebugger || this.fullDebugger) console.log(txt);
            continue;
          }

          if ((indexHorario < horarioDia.length - 1) && (horaCheck.getTime() > incidenciaHorario.horaSalidaHorario.getTime())) {
            // si no esta en el ultimo movimiento de horario vemos si es una salida o si pertenece al siguiente movimiento de horario
            // y la hora de salida es posterior al la hora de salida del horario
            let siguienteIngreso: Date = soloHoraNum(horarioDia[indexHorario + 1].horaEntrada);
            let diffSalidaActual: number = Math.abs(dateDiff(incidenciaHorario.horaSalidaHorario, horaCheck, TipoDateDiff.horas));
            let diffEntradaSiguiente: number = Math.abs(dateDiff(siguienteIngreso, horaCheck, TipoDateDiff.horas));

            if (horaCheck.getTime() >= siguienteIngreso.getTime() || diffSalidaActual > diffEntradaSiguiente) {
              incidencias.push(incidenciaHorario);

              incidenciaHorario = nuevaIncidenciaHorario();

              indexHorario++;
              incidenciaHorario.horaEntradaHorario = soloHoraNum(horarioDia[indexHorario].horaEntrada);
              incidenciaHorario.horaSalidaHorario = soloHoraNum(horarioDia[indexHorario].horaSalida);
              incidenciaHorario.horasHorario = dateDiff(incidenciaHorario.horaEntradaHorario, incidenciaHorario.horaSalidaHorario, TipoDateDiff.horas);
              if (this.esIngreso(incidenciaHorario, horaCheck)) {
                incidenciaHorario.horaEntrada = horaCheck;
                incidenciaHorario.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
              } else {
                incidenciaHorario.horaSalida = horaCheck;
                incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
              }

              txt += `, IndexHorario: ${indexHorario}, ++ tipoIncidenciaEntrada: ${incidenciaHorario.tipoIncidenciaEntrada}, tipoIncidenciaSalida: ${incidenciaHorario.tipoIncidenciaSalida}`
              txt += `, hora entrada: ${fFecha(incidenciaHorario.horaEntrada, 'hm')}, hora salida: ${fFecha(incidenciaHorario.horaSalida, 'hm')}`;
              // if (this.esDebugger || this.fullDebugger) console.log(txt);
              continue;
            }
          }

          incidenciaHorario.horaSalida = horaCheck;
          incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
          txt += `, IndexHorario: ${indexHorario}, tipoIncidenciaEntrada: ${incidenciaHorario.tipoIncidenciaEntrada}, tipoIncidenciaSalida: ${incidenciaHorario.tipoIncidenciaSalida}`
          txt += `, hora entrada: ${fFecha(incidenciaHorario.horaEntrada, 'hm')}, hora salida: ${fFecha(incidenciaHorario.horaSalida, 'hm')}`;
          // if (this.esDebugger || this.fullDebugger) console.log(txt);
        }

        txt += `, tipoIncidenciaEntrada: ${incidenciaHorario.tipoIncidenciaEntrada}, tipoIncidenciaSalida: ${incidenciaHorario.tipoIncidenciaSalida}`
        txt += `, hora entrada: ${fFecha(incidenciaHorario.horaEntrada, 'hm')}, hora salida: ${fFecha(incidenciaHorario.horaSalida, 'hm')}`;
        // if (this.esDebugger || this.fullDebugger) console.log(txt);
      }

      checksDia.forEach(x => x.idIncidencia = datos.idIncidencia);

      for (let index = 0; index < checksDia2.length; index++) {
        const check = checksDia2[index];
        if (soloHora(check.fecha).getTime() >= soloHoraNum(horaMaxCambioJornada).getTime()) {
          break;
        }

        if (this.esDebugger || this.fullDebugger) console.log(`${nombreEmpleado}, Validando checks dia siguiente (2+): ${fFecha(checksDia2[index].fecha, 'fmh')}, horaMaxCambioJornada: ${horaMaxCambioJornada}`);

        horaCheck = soloHora(check.fecha);
        check.idIncidencia = datos.idIncidencia;

        if (indexHorario < cantMovHorarioDia - 1) {
          incidencias.push(incidenciaHorario);

          incidenciaHorario = nuevaIncidenciaHorario();
          indexHorario = cantMovHorarioDia - 1;
          incidenciaHorario.horaEntradaHorario = soloHoraNum(horarioDia[indexHorario].horaEntrada);
          incidenciaHorario.horaSalidaHorario = soloHoraNum(horarioDia[indexHorario].horaSalida);
          incidenciaHorario.horasHorario = dateDiff(incidenciaHorario.horaEntradaHorario, incidenciaHorario.horaSalidaHorario, TipoDateDiff.horas);
        }
        incidenciaHorario.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
        incidenciaHorario.salidaDiaSiguiente = true;
        incidenciaHorario.horaSalida = horaCheck;
      }

      incidencias.push(incidenciaHorario);
    }

    if (depurarMovimiento){
      console.log(`incidencias`, incidencias);
    }

    // validar tipo de incidencia (ingreso temprano, retardo, falta, horas extra, etc.)
    let revisarRetardosComida: boolean = !this.politicaIncidencias.ignorarPenalizarNoEntrada || incidencias.length == 1;
    for (let index = 0; index < incidencias.length; index++) {
      let incidencia = incidencias[index];
      incidencia.observaciones = [];
      let txt: string = `tipoIncidenciaEntrada: ${incidencia.tipoIncidenciaEntrada}, tipoIncidenciaSalida: ${incidencia.tipoIncidenciaSalida}`;
      if (incidencia.tipoIncidenciaEntrada != TipoIncidencia.ATiempo || incidencia.tipoIncidenciaSalida != TipoIncidencia.ATiempo) {
        if (incidencia.descanso > 0) {
          // if (this.esDebugger || this.fullDebugger) console.log(`crearIncidenciaDia (index: ${index + 1} SM), horaSalida: ${fFecha(incidencia.horaSalida, 'hs')}, horaEntrada: ${fFecha(incidencia.horaEntrada, 'hs')}, horasTrabajadas: ${incidencia.horasTrabajadas.toFixed(2)}, ${txt}`);
          incidencia.observaciones.push('Trabajo en descanso obligatorio sin salida o entrada');
          continue;
        }

        if (incidencia.tipoIncidenciaEntrada != TipoIncidencia.ATiempo) {
          if ((revisarRetardosComida || index == 0)) {
            if (this.politicaIncidencias.penalizarNoEntrada >= 24) {
              incidencia.observaciones.push('No se registró el regreso de comer, por la política se debe registrar falta');
              incidencia.tipoIncidenciaEntrada = TipoIncidencia.SinRegistro;
              incidencia.horasTrabajadas = 0.001;
              incidencia.falta = 1;
              // if (this.esDebugger || this.fullDebugger) console.log(`crearIncidenciaDia (index: ${index + 1} FALTA), falta: ${incidencia.falta}, horaSalida: ${fFecha(incidencia.horaSalida, 'hs')}, horaEntrada: ${fFecha(incidencia.horaEntrada, 'hs')}, horasTrabajadas: ${incidencia.horasTrabajadas.toFixed(2)}, ${txt}`);
              continue;
            }
            if (datos.esHoyOMayor) {
              incidencia.observaciones.push('Aún no se registró el ingreso');
              incidencia.tipoIncidenciaEntrada = TipoIncidencia.Pendiente;
            } else { // if (this.politicaIncidencias.penalizarNoEntrada > 0) {
              incidencia.observaciones.push(`No se registró el ingreso, por la política se registra el ingreso con penalización de ${this.politicaIncidencias.penalizarNoEntrada} horas`);
              incidencia.tipoIncidenciaEntrada = TipoIncidencia.RetardoSinRegistro;
              incidencia.horaEntrada = addHours(incidencia.horaEntradaHorario, this.politicaIncidencias.penalizarNoEntrada);
            }
          } else {
            incidencia.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
            incidencia.horaEntrada = incidencia.horaEntradaHorario;
            incidencia.observaciones.push('No se registró el ingreso, por la política se registra a tiempo');
          }
        }

        if (incidencia.tipoIncidenciaSalida != TipoIncidencia.ATiempo) {
          if (revisarRetardosComida || index == incidencias.length - 1) {
            if (this.politicaIncidencias.penalizarNoSalida >= 24) {
              incidencia.observaciones.push('No se registró la salida a comer, por la política se debe registrar falta');
              incidencia.tipoIncidenciaSalida = TipoIncidencia.SinRegistro;
              incidencia.horasTrabajadas = 0.001;
              incidencia.falta = 1;
              // if (this.esDebugger || this.fullDebugger) console.log(`crearIncidenciaDia (index: ${index + 1} FALTA), falta: ${incidencia.falta}, horaSalida: ${fFecha(incidencia.horaSalida, 'hs')}, horaEntrada: ${fFecha(incidencia.horaEntrada, 'hs')}, horasTrabajadas: ${incidencia.horasTrabajadas.toFixed(2)}, ${txt}`);
              continue;
            }
            if (datos.esHoyOMayor) {
              incidencia.observaciones.push('Aún no se registró la salida');
              incidencia.tipoIncidenciaSalida = TipoIncidencia.Pendiente;
            } else { // if (this.politicaIncidencias.penalizarNoSalida > 0) {
              incidencia.tipoIncidenciaSalida = TipoIncidencia.RetardoSinRegistro;
              incidencia.horaSalida = addHours(incidencia.horaSalidaHorario, this.politicaIncidencias.penalizarNoSalida * -1);
              incidencia.observaciones.push(`No se registró la salida, por la política se registra el ingreso con penalización de ${this.politicaIncidencias.penalizarNoSalida.toFixed(2)} horas, nueva horaSalida: ${fFecha(incidencia.horaSalida, 'hm')}`);
            }
          } else {
            incidencia.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
            incidencia.horaSalida = incidencia.horaSalidaHorario;
            incidencia.observaciones.push('No se registró la salida, por la política se registra a tiempo');
          }
        }
      }

      if (depurarMovimiento) {
        let aa1 = 1;
        console.log('datos', datos);
        console.log(`incidencia`, incidencia);
      }

      let ignorarTiempoRetardo: boolean = this.politicaIncidencias.ignorarPenalizarNoEntrada && index > 0;
      let horasTrabajadas: number = dateDiff(incidencia.horaEntrada, incidencia.horaSalida, TipoDateDiff.horas) + (incidencia.salidaDiaSiguiente ? 24 : 0);
      let diffEntrada: number = dateDiff(incidencia.horaEntradaHorario, incidencia.horaEntrada, TipoDateDiff.horas);
      if (diffEntrada != 0 && ignorarTiempoRetardo) // es retardo e ignoramos el tiempo del retardo
      {
        if (depurarMovimiento) {
          console.log(`ignorarTiempoRetardo. ignorarPenalizarNoEntrada: ${this.politicaIncidencias.ignorarPenalizarNoEntrada}, index: ${index}, diffEntrada: ${diffEntrada}, tipoIncidenciaEntrada: ${incidencia.tipoIncidenciaEntrada}`);
        }
        diffEntrada = 0;
        incidencia.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
      }

      ignorarTiempoRetardo = this.politicaIncidencias.ignorarPenalizarNoEntrada && index < incidencias.length - 1;
      let diffSalida: number = dateDiff(incidencia.horaSalidaHorario, incidencia.horaSalida, TipoDateDiff.horas);
      if (diffSalida != 0 && ignorarTiempoRetardo) // salida antes de tiempo
      {
        if (depurarMovimiento) {
          console.log(`ignorarTiempoRetardo. ignorarPenalizarNoEntrada: ${this.politicaIncidencias.ignorarPenalizarNoEntrada}, index: ${index}, diffSalida: ${diffSalida}, tipoIncidenciaSalida: ${incidencia.tipoIncidenciaSalida}`);
        }
        diffSalida = 0;
        incidencia.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
      }

      if (incidencia.tipoIncidenciaEntrada == TipoIncidencia.Pendiente) {
        horasTrabajadas = 0.001;
        diffEntrada = 0;
      }

      if (incidencia.tipoIncidenciaSalida == TipoIncidencia.Pendiente) {
        horasTrabajadas = 0.001;
        diffSalida = 0;
      }


      if (incidencia.salidaDiaSiguiente) diffSalida += 24;
      // txt += `, diffEntrada: ${diffEntrada.toFixed(2)}, diffSalida: ${diffSalida.toFixed(2)} ==>> `;
      if (diffEntrada < 0 && diffSalida < 0 && this.politicaIncidencias.cubrirRetardo && !datos.esHoyOMayor) {
        let obs: string = `+++> horaEntradaHorario: ${incidencia.horaEntradaHorario}, horaEntrada: ${incidencia.horaEntrada}, diffEntrada: ${diffEntrada.toFixed(2)}, horaSalidaHorario: ${incidencia.horaSalidaHorario}, horaSalida: ${incidencia.horaSalida}, diffSalida: ${diffSalida.toFixed(2)} ==>> `;
        if (Math.abs(diffEntrada) >= Math.abs(diffSalida)) {
          diffEntrada -= diffSalida;
          obs += `Tiempo en ingreso compensado. ${fNumero(Math.abs(diffSalida), 'd2')}`
          // txt += `cubrir retardo salida: ${diffSalida.toFixed(2)}`;
          diffSalida = 0;
        } else {
          diffSalida -= diffEntrada;
          obs += `Tiempo en ingreso compensado (total). ${fNumero(Math.abs(diffEntrada), 'd2')}`;
          // txt += `cubrir retardo salida: ${diffEntrada.toFixed(2)}`;
          diffEntrada = 0;
        }
        obs += ` ==>> diffEntrada: ${diffEntrada.toFixed(2)}, diffSalida: ${diffSalida.toFixed(2)}`;
        incidencia.observaciones.push(obs);
      } else if (diffEntrada > 0 && diffSalida > 0 && this.politicaIncidencias.cubrirRetardo && !datos.esHoyOMayor) {
        if (Math.abs(diffEntrada) < Math.abs(diffSalida)) {
          diffSalida -= diffEntrada;
          incidencia.observaciones.push(`Tiempo en salida compensado. ${fNumero(Math.abs(diffEntrada), 'd2')}`);
          // txt += `cubrir retardo entrada: ${diffEntrada}`;
          diffEntrada = 0;
        } else {
          diffEntrada -= diffSalida;
          incidencia.observaciones.push(`Tiempo en salida compensado. (total) ${fNumero(Math.abs(diffSalida), 'd2')}`);
          // txt += `cubrir retardo entrada: ${diffSalida.toFixed(2)}`;
          diffSalida = 0;
        }
      }

      if (depurarMovimiento) {
        console.log(`incidencias`, incidencias);
      }

      if (diffEntrada < 0) {
        incidencia.tipoIncidenciaEntrada = TipoIncidencia.TiempoAFavor;
        if (incidencia.tipoIncidenciaSalida != TipoIncidencia.Pendiente) horasTrabajadas += diffEntrada; // no son horas trabajadas, o son tiempo extra o las ignora
        if (this.politicaIncidencias.toleranciaHorasExtra <= Math.abs(diffEntrada)) {
          incidencia.tipoIncidenciaEntrada = TipoIncidencia.TiempoExtra;
          incidencia.horasExtras = Math.abs(diffEntrada);
          if (this.esDebugger) {
            incidencia.observaciones.push(`TiempoExtra ==> ${Math.abs(diffEntrada).toFixed(2)} (${dateDiff(incidencia.horaEntradaHorario, incidencia.horaEntrada, TipoDateDiff.horas)}) < toleranciaHorasExtra: ${this.politicaIncidencias.toleranciaHorasExtra}  ==> horasExtras: ${incidencia.horasExtras.toFixed(2)}`);
          }
        } else {
          incidencia.tiempoAFavor += Math.abs(diffEntrada);
          if (this.esDebugger) {
            incidencia.observaciones.push(`TiempoAFavor ==> ${Math.abs(diffEntrada).toFixed(2)} (${dateDiff(incidencia.horaEntradaHorario, incidencia.horaEntrada, TipoDateDiff.horas)}) < toleranciaHorasExtra: ${this.politicaIncidencias.toleranciaHorasExtra}  ==> tiempoAFavor: ${incidencia.tiempoAFavor.toFixed(2)}`);
          }
        }
      } else if (diffEntrada > 0 && this.politicaIncidencias.toleranciaRetardos <= diffEntrada) { // retardo
        if (incidencia.tipoIncidenciaEntrada != TipoIncidencia.RetardoSinRegistro) incidencia.tipoIncidenciaEntrada = TipoIncidencia.Retardo;
        incidencia.retardo = diffEntrada;
      } else if (diffEntrada > (1/60) && diffEntrada < (5/60)) { // tolerancia
        incidencia.tipoIncidenciaEntrada = TipoIncidencia.Tolerancia;
      } else {
        // incidencia.observaciones.push(`Entrada a tiempo: ${diffEntrada.toFixed(2)}, (retardos: ${this.politicaIncidencias.toleranciaRetardos} --> ${1/60}, ${5/60})`);
      }

      if (diffSalida > 0) {
        incidencia.tipoIncidenciaSalida = TipoIncidencia.TiempoAFavor;
        if (incidencia.tipoIncidenciaEntrada != TipoIncidencia.Pendiente) horasTrabajadas -= diffSalida; // no son horas trabajadas, o son tiempo extra o las ignora
        if (this.politicaIncidencias.toleranciaHorasExtra <= Math.abs(diffSalida)) {
          incidencia.tipoIncidenciaSalida = TipoIncidencia.TiempoExtra;
          incidencia.horasExtras += Math.abs(diffSalida);
          incidencia.observaciones.push(`TiempoExtra ==> ${diffSalida.toFixed(2)} (${dateDiff(incidencia.horaSalidaHorario, incidencia.horaSalida, TipoDateDiff.horas)}) < toleranciaHorasExtra: ${this.politicaIncidencias.toleranciaHorasExtra.toFixed(2)}  ==> horasExtras: ${incidencia.horasExtras.toFixed(2)}`);
        } else {
          incidencia.tiempoAFavor += Math.abs(diffSalida);
          if (this.esDebugger) {
            incidencia.observaciones.push(`TiempoAFavor ==> ${diffSalida.toFixed(2)} (${dateDiff(incidencia.horaSalidaHorario, incidencia.horaSalida, TipoDateDiff.horas)}) < toleranciaHorasExtra: ${this.politicaIncidencias.toleranciaHorasExtra.toFixed(2)}  ==> tiempoAFavor: ${incidencia.tiempoAFavor.toFixed(2)}`);
          }
        }
      } else if (diffSalida < 0 && this.politicaIncidencias.toleranciaRetardos <= Math.abs(diffSalida)) { // retardo
        if (!this.politicaIncidencias.salidasComoRetardos) {
          incidencia.observaciones.push(`Salida temprana no considerada como retardo por la política de la empresa. (${fNumero(Math.abs(diffSalida), 'd2')})`);
        } else {
          if (incidencia.tipoIncidenciaSalida != TipoIncidencia.RetardoSinRegistro) incidencia.tipoIncidenciaSalida = TipoIncidencia.Retardo;
          incidencia.retardo -= diffSalida;
        }
      } else if (diffSalida < 0 && Math.abs(diffSalida) > (1/60) && Math.abs(diffSalida) < (5/60)) { // tolerancia
        incidencia.tipoIncidenciaSalida = TipoIncidencia.Tolerancia;
      } else {
        // incidencia.observaciones.push(`Salida a tiempo: ${diffSalida.toFixed(2)}, (retardos: ${this.politicaIncidencias.toleranciaRetardos} --> ${1/60}, ${5/60})`);
      }

      if (incidencia.retardo > 0 && incidencias.length == 1 && !!this.politicaIncidencias.toleranciaFaltas && this.politicaIncidencias.toleranciaFaltas > 0 && this.politicaIncidencias.toleranciaFaltas < 0.99) {
        let horasHorario: number = dateDiff(incidencia.horaEntradaHorario, incidencia.horaSalidaHorario, TipoDateDiff.horas);
        let horasMaximasRetado: number = horasHorario * this.politicaIncidencias.toleranciaFaltas;
        if (incidencia.retardo >= horasMaximasRetado) {
          incidencia.observaciones.push(`El tiempo de retardo máximo (${fNumero(this.politicaIncidencias.toleranciaFaltas, '%')} - ${horasMaximasRetado.toFixed(2)} hrs) para la jornada ha sido excedido: ${incidencia.retardo.toFixed(2)}`);
          incidencia.retardo = 0;
          incidencia.falta = 1;
          horasTrabajadas = 0.001;
        }
      }

      incidencia.horasTrabajadas = horasTrabajadas;
      if (this.esDebugger || this.fullDebugger) {
        txt = `crearIncidenciaDia (index: ${index + 1}), horaEntrada: ${fFecha(incidencia.horaEntrada, 'hs')}, horaSalida: ${fFecha(incidencia.horaSalida, 'hs')}, horasTrabajadas: ${incidencia.horasTrabajadas.toFixed(2)}`
        txt += `, tipoIncidenciaEntrada: ${incidencia.tipoIncidenciaEntrada}, tipoIncidenciaSalida: ${incidencia.tipoIncidenciaSalida}`;
        txt += `, diffEntrada: ${diffEntrada.toFixed(2)}, diffSalida: ${diffSalida.toFixed(2)}`;
        for (let i = 0; i < incidencia.observaciones.length; i++) {
          txt += `, ${incidencia.observaciones[i]}`;
        }
        // console.log(txt);
      }
    }
    // validar tipo de incidencia (ingreso temprano, retardo, falta, horas extra, etc.)

    if (this.esDebugger || this.fullDebugger) {
      let fechaTxt: string;
      if (cantChecks >= 1) {
        fechaTxt = fFecha(checksDia[0].fecha, 'fmh');
      } else {
        fechaTxt = `Salida nocturna: ${fFecha(checksDia2[0].fecha, 'fmh')}`;
      }
      // console.log(`====>>> Incidencias ${nombreEmpleado} ==> ${fechaTxt}`, incidencias, `, checksDia:`, checksDia, `, checksDia2:`, checksDia2, `, horarioDia:`, horarioDia, `, cantChecks: ${cantChecks}, cantChecksDia2: ${cantChecksDia2}`);
    }

    if (incidencias.length > 0) {
      let faltas: number = 0;
      let horaExtra: number = 0;
      let aFavor: number = 0;
      let retardos: number = 0;
      incidencias.forEach(incidencia => {
        faltas += incidencia.falta;
        if (incidencia.retardo > 0 && faltas < 1  && !!this.politicaIncidencias.toleranciaFaltas && this.politicaIncidencias.toleranciaFaltas > 0 && this.politicaIncidencias.toleranciaFaltas < 0.99) {
          let horasHorario: number = dateDiff(incidencia.horaEntradaHorario, incidencia.horaSalidaHorario, TipoDateDiff.horas);
          let horasMaximasRetado: number = horasHorario * this.politicaIncidencias.toleranciaFaltas;
          if (incidencia.retardo >= horasMaximasRetado) {
            incidencia.observaciones.push(`El tiempo de retardo máximo (${fNumero(this.politicaIncidencias.toleranciaFaltas, '%')} - ${horasMaximasRetado.toFixed(2)} hrs) para la jornada ha sido excedido: ${incidencia.retardo.toFixed(2)}`);
            incidencia.retardo = 0;
            incidencia.falta = 1;
            incidencia.horasTrabajadas = 0.001;
          }
        }

        if (faltas >= 1) {
          if (incidencia.horasExtras > 0) {
            incidencia.observaciones.push(`El tiempo extra registrado (${incidencia.horasExtras.toFixed(2)}) ha sido eliminado por existir una falta`);
            incidencia.horasExtras = 0;
            incidencia.tiempoAFavor = 0;
          }
          incidencia.retardo = 0;
        } else {
          horaExtra += incidencia.horasExtras;
          retardos += incidencia.retardo;
          aFavor += incidencia.tiempoAFavor;
        }
      });

      if (faltas > 1) {
        faltas = 0;
        incidencias.forEach(incidencia => {
          incidencia.falta = faltas < 1 && incidencia.falta > 0 ? 1 : 0;
          faltas += incidencia.falta;
          if (incidencia.horasExtras != 0) {
            incidencia.observaciones.push(`El tiempo extra registrado (${incidencia.horasExtras.toFixed(2)}) ha sido eliminado por existir una falta`);
            incidencia.horasExtras = 0;
            incidencia.tiempoAFavor = 0;
            horaExtra = 0;
            aFavor = 0;
          }
          incidencia.retardo = 0;
          retardos = 0;
        });
      }

      if ((horaExtra + aFavor) > 0 && retardos > 0 && this.politicaIncidencias.cubrirRetardo) {
        if (this.esDebugger) incidencias[0].observaciones.push(`==> Compensar Retardos (${retardos.toFixed(2)}) con Tiempo a favor (${aFavor.toFixed(2)}) y Tiempo extra (${horaExtra.toFixed(2)})`);
        let compensarRetardos: number = (horaExtra + aFavor) > retardos ? retardos : horaExtra + aFavor;
        let retardoCompensado: number = 0;
        let horaExtraCompensado: number = 0;

        if (aFavor > 0) {
          incidencias.forEach(incidencia => {
            if (incidencia.retardo > 0 && compensarRetardos > retardoCompensado ) {
              let compensar: number = compensarRetardos - retardoCompensado;
              if (compensar > incidencia.retardo) compensar = incidencia.retardo;

              incidencia.observaciones.push(`Retardo compensado con Tiempo a favor. ${fNumero(compensar, 'd2')}`);
              retardoCompensado += compensar;
              retardos -= compensar;
              incidencia.retardo -= compensar;
              if (incidencia.retardo < this.politicaIncidencias.toleranciaRetardos) {
                if (incidencia.tipoIncidenciaEntrada == TipoIncidencia.Retardo) incidencia.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
                if (incidencia.tipoIncidenciaSalida == TipoIncidencia.Retardo) incidencia.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
                if (incidencia.retardo > 0) {
                  retardoCompensado += incidencia.retardo;
                  retardos -= incidencia.retardo;
                  incidencia.retardo = 0;
                }
              }
            }

            if (incidencia.tiempoAFavor > 0 && compensarRetardos > horaExtraCompensado ) {
              let compensar: number = compensarRetardos - horaExtraCompensado;
              if (compensar > incidencia.tiempoAFavor) compensar = incidencia.tiempoAFavor;
              incidencia.observaciones.push(`Tiempo a favor compensando a Retardo. ${fNumero(compensar, 'd2')}`);
              horaExtraCompensado += compensar;
              aFavor -= compensar;
              incidencia.tiempoAFavor -= compensar;
            }
          });
        }

        incidencias.forEach(incidencia => {
          if (incidencia.retardo > 0 && compensarRetardos > retardoCompensado ) {
            let compensar: number = compensarRetardos - retardoCompensado;
            if (compensar > incidencia.retardo) compensar = incidencia.retardo;
            incidencia.observaciones.push(`Retardo compensado con Tiempo Extra. ${fNumero(compensar, 'd2')}`);
            retardoCompensado += compensar;
            retardos -= compensar;
            incidencia.retardo -= compensar;
            if (incidencia.retardo < this.politicaIncidencias.toleranciaRetardos) {
              if (incidencia.tipoIncidenciaEntrada == TipoIncidencia.Retardo) incidencia.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
              if (incidencia.tipoIncidenciaSalida == TipoIncidencia.Retardo) incidencia.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
              if (incidencia.retardo > 0) {
                retardoCompensado += incidencia.retardo;
                retardos -= incidencia.retardo;
                incidencia.retardo = 0;
              }
            }
          }

          if (incidencia.horasExtras > 0 && compensarRetardos > horaExtraCompensado ) {
            let compensar: number = compensarRetardos - horaExtraCompensado;
            if (compensar > incidencia.horasExtras) compensar = incidencia.horasExtras;
            incidencia.observaciones.push(`Tiempo Extra compensando a Retardo. ${fNumero(compensar, 'd2')}`);
            horaExtraCompensado += compensar;
            horaExtra -= compensar;
            incidencia.horasExtras -= compensar;
            if (incidencia.horasExtras < this.politicaIncidencias.toleranciaHorasExtra) {
              incidencia.tiempoAFavor += incidencia.horasExtras;
              horaExtra -= incidencia.horasExtras;
              incidencia.horasExtras = 0;
              if (incidencia.tipoIncidenciaEntrada == TipoIncidencia.TiempoExtra) incidencia.tipoIncidenciaEntrada = TipoIncidencia.ATiempo;
              if (incidencia.tipoIncidenciaSalida == TipoIncidencia.TiempoExtra) incidencia.tipoIncidenciaSalida = TipoIncidencia.ATiempo;
            }
          }
        });

      } else if (this.esDebugger && horaExtra + aFavor > 0 && retardos > 0) {
        incidencias[0].observaciones.push(`Sin opción para Compensar Retardos (${retardos.toFixed(2)}) con Tiempo a favor (${(horaExtra + aFavor).toFixed(2)})`);
      }
    }


    return incidencias;
  }

  private esIngreso(incidenciaHorario: IncidenciaHorario, horaCheck: Date): boolean {
    let diffEntrada: number = dateDiff(incidenciaHorario.horaEntradaHorario, horaCheck, TipoDateDiff.horas);
    let diffSalida: number = dateDiff(incidenciaHorario.horaSalidaHorario, horaCheck, TipoDateDiff.horas);
    // diffEntrada: ${diffEntrada.toFixed(2)}, diffSalida: ${diffSalida.toFixed(2)},
    return (diffEntrada < 0 || (Math.abs(diffEntrada) < Math.abs(diffSalida) && diffSalida < 0));   // si el registro es antes del horario de entrada lo consideramos como ingreso
  }

  private cargarTotalesIncidenciaDia(incidencia: DatosIncidencias): DatosIncidencias {
    let incidenciaHorario: IncidenciaHorario = incidencia.incidenciaHorario[0];

    incidencia.descanso = incidenciaHorario.descanso;
    incidencia.trabajoEnDescanso = incidenciaHorario.trabajoEnDescanso;
    if (incidencia.descanso > 0) {
      if (incidencia.trabajoEnDescanso < 0) incidencia.trabajoEnDescanso = 0;
      let descansoTxt: string = incidencia.especialTxt == 'Descanso Ley' ? 'Descanso Ley' : 'Descanso';
      incidencia.especialTxt = incidencia.trabajoEnDescanso > 0.001 ? `${fNumero(incidencia.trabajoEnDescanso, 'D2')}h trabajadas` : incidencia.trabajoEnDescanso > 0.0000001 ? `* Ingreso sin salida` : descansoTxt;
      // console.log(`cargarTotalesIncidenciaDia (3) + ${incidencia.descansoTxt} == trabajoEnDescanso: ${incidencia.trabajoEnDescanso}, trabajoEnDescanso: ${incidenciaHorario.trabajoEnDescanso}`);
    }
    // console.log(`cargarTotalesIncidenciaDia (3) + ${incidencia.descansoTxt}`);

    incidencia.horasTrabajadas = incidenciaHorario.horasTrabajadas;
    incidencia.horasTrabajadasTxt = fNumero(incidencia.horasTrabajadas + incidencia.trabajoEnDescanso, 'hm')
    // console.log(`cargarTotalesIncidenciaDia (4) + ${incidencia.horasTrabajadasTxt}`);

    incidencia.faltas = incidenciaHorario.falta;
    incidencia.faltasTxt = fNumero(incidencia.faltas, 'D2-');
    // console.log(`cargarTotalesIncidenciaDia (5) + ${incidencia.faltasTxt}`);

    incidencia.horasExtras = incidenciaHorario.horasExtras;
    incidencia.horasExtrasTxt = fNumero(incidencia.horasExtras, 'hm');
    // console.log(`cargarTotalesIncidenciaDia (6) + ${incidencia.horasExtrasTxt}`);

    incidencia.retardos = incidenciaHorario.retardo;
    incidencia.retardosTxt = fNumero(incidencia.retardos, 'hm');
    // console.log(`cargarTotalesIncidenciaDia (7) + ${incidencia.retardosTxt}`);

    return incidencia;
  }

  private cargarIncidencias() {
    console.log(`cargarIncidencias`);
    this.incidenciasReporte = { };

    let incidenciasFiltradas: DatosIncidencias[] = this.incidenciasFiltradas();
    // console.log('incidencias después del filtrado:', incidenciasFiltradas);

    incidenciasFiltradas.forEach(incidencia => {
      let key: string = this.reporteSeleccionado == 'fecha' ? fechaT(incidencia.fecha) : incidencia.nombre;
      // console.log(`clave: ${key}`);
      if (!Object.prototype.hasOwnProperty.call(this.incidenciasReporte, key)) {
        this.incidenciasReporte[key] = [];
      }
      this.incidenciasReporte[key].push(incidencia);
    });

    if (this.reporteSeleccionado === 'ejecutivo' || this.reporteSeleccionado === 'incidencias') {
      this.calcularTotalIncidencias();
    }

    console.log('incidenciasReporte:', this.incidenciasReporte);
    // if (this.fullDebugger || this.esDebugger) console.log('incidenciasReporte:', this.incidenciasReporte);
  }

  private incidenciasFiltradas(): DatosIncidencias[] {
    let incidenciasFiltradas: DatosIncidencias[] = [];

    let idEmpleado: number = !this.empleadoSeleccionado || !this.empleadoSeleccionado.id ? 0 : +this.empleadoSeleccionado.id;
    if (this.esDebugger || this.fullDebugger) console.log(`id empleado: ${idEmpleado}`);

    this.incidencias.forEach(incidencia => {
      if(idEmpleado > 0 && incidencia.idEmpleado != idEmpleado) return;

      // si existen mas filtros se debe agregar aquí

      incidenciasFiltradas.push(incidencia);
    });


    // console.log('incidencias filtradas:', incidenciasFiltradas);
    return incidenciasFiltradas;
  }

  public filtrarDatos(cargarIncidencias: boolean) {
    console.log(`Empleado seleccionado: ${this.empleadoSeleccionado?.id} ${this.empleadoSeleccionado?.paterno}`);

    // if (this.agrupadorSeleccionado) {
    //   this.empleados = this.empleadosOriginales.filter(empleado => empleado.grupo === this.agrupadorSeleccionado);
    //     console.log(`agrupador ${this.empleados}`);
    // } else {
    //   this.empleados = this.empleadosOriginales;
    // }

    let idEmpleado: number = !this.empleadoSeleccionado || !this.empleadoSeleccionado.id ? 0 : +this.empleadoSeleccionado.id;

    if(!idEmpleado) {
      this.checks = this.checksOrigen;
    } else {
      this.checks = this.checksOrigen.filter(x => x.idEmpleado == idEmpleado);
    }

    this.accesoDatosService.guardarDatosRegistro(this.inicioPeriodo, this.finPeriodo, this.empleadoSeleccionado);
    if (cargarIncidencias) this.cargarIncidencias();
  }

  private obtenerHorarioEmpleado(idEmpleado: number, dia: Date): IHorarios | undefined {
    let horarioEmpleado: IHorariosEmpleado | undefined = this.horariosEmpleado.find(x => x.idEmpleado == idEmpleado);
    if (!horarioEmpleado) {
      // console.log(`Horarios de TODOS los empleados:`, this.horariosEmpleado);
      return undefined;
    }

    return horarioEmpleado.horarios.find(x => x.inicio <= dia && x.fin >= dia);
  }
  // ======================================================================================================================== >>>>>>>>>>>>>>> Incidencias


  // ======================================================================================================================== >>>>>>>>>>>>>>> Reporte
  public cambiarPeriodo() {
    if (this.periodo != 'personalizado') console.log(`cambiarPeriodo. periodo: ${this.periodo}`);

    let inicio: Date = this.inicioPeriodo;
    let fin: Date = soloHoy();

    switch (this.periodo) {
      case 'hoy':
        inicio = soloHoy();
        break;
      case 'semana0':
        inicio = inicioSemanaActual();
        break;
      case 'semana1a':
        inicio = addDays(inicioSemanaActual(), -7);
        fin = addDays(inicio, 6);
        break;
      case 'quincena0':
        inicio = inicioQuincenaActual();
        break;
      case 'quincena1a':
        fin = addDays(inicioQuincenaActual(), -1);
        inicio = inicioQuincena(fin);
        break;
      case 'mes0':
        inicio = inicioMesActual();
        break;
      case 'mes1a':
        fin = addDays(inicioMesActual(), -1);
        inicio = inicioMes(fin);
        break;
      case 'personalizado':
        inicio = this.rangoPersonalizado.value.desde ? this.rangoPersonalizado.value.desde : this.inicioPeriodo;
        fin = this.rangoPersonalizado.value.hasta ? this.rangoPersonalizado.value.hasta : this.finPeriodo;
        console.log(`cambiarPeriodo. periodo: ${this.periodo}, ${inicio}, ${fin}`);
        break;
      default:

        return;
    }

    if (inicio.getTime() > fin.getTime()) {
      fin = inicio;
    }

    if (this.inicioPeriodo.getTime() != inicio.getTime() || this.finPeriodo.getTime() != fin.getTime()) {
      console.log(`===========>>> Nuevo periodo: ${fFecha(inicio, 'fm')}, ${fFecha(fin, 'fm')} <<<<===========`);
      this.inicioPeriodo = inicio;
      this.finPeriodo = fin;
      this.accesoDatosService.guardarDatosRegistro(this.inicioPeriodo, this.finPeriodo, this.empleadoSeleccionado);
      if (this.inicioPeriodo.getTime() < this.fechaBajaMax.getTime()) {
        this.cargarDatosInicial();
      } else {
        this.cargarDatos();
      }
      return;
    }
  }

  public cambioReporte() {
    if (this.reporteSeleccionado === 'incidencias' && !this.esDebugger) {
      this.reporteSeleccionado = 'fecha';
    }
    if (this.reporteSeleccionado == 'fecha' || this.reporteSeleccionado == 'empleado' || this.reporteSeleccionado == 'ejecutivo' || this.reporteSeleccionado == 'incidencias') {
      this.cargarIncidencias();
    } else if (this.reporteSeleccionado == 'registroFoto') {
      this.accesoDatosService.guardarDatosRegistro(this.inicioPeriodo, this.finPeriodo, this.empleadoSeleccionado);
    }
  }

  public nombreCompleto(empleado: IEmpleados): string {
    return nombreEmpleado(empleado);
  }

  public nombreCompletoConId(idEmpleado: number): string {
    if (!idEmpleado) return '';
    let empleado: IEmpleados | undefined = this.empleados.find(x => x.id == idEmpleado.toString());
    if (!empleado) return '';
    return nombreEmpleado(empleado);
  }

  public formatoFechaKey(fecha: string) {
    if (!fecha) {
      return '-';
    }
    return fFecha(CVFechaT(fecha), 'fcds');
  }

  public formatoFecha(fecha: Date | undefined) {
    if (!fecha) {
      return '-';
    }
    return fFecha(fecha, 'FSL');
  }

  public formatoHora(fecha: Date | undefined) {
    if (!fecha) {
      return '-';
    }
    return fFecha(fecha, 'hm');
  }

  public abrirHorario(idEmpleado: number, idHorario: string, nombreEmpelado: string) {
    let horarioEmpleado: IHorariosEmpleado | undefined = this.horariosEmpleado.find(x => x.idEmpleado == idEmpleado);
    if (!horarioEmpleado) {
      mostrarSwalError('Horarios', 'No se puede localizar el horario del empleado');
      return;
    }
    let horario: IHorarios | undefined = horarioEmpleado.horarios.find(x => x.idHorario == idHorario);
    if (!horario) {
      mostrarSwalError('Horarios', 'No se puede localizar el horario del empleado');
      return;
    }

    const dialogRef = this.dialog.open(HorarioDialogComponent, {
      data: {
        horario: horario,
        nombreEmpleado: nombreEmpelado,
      },
      width: '65rem',
      height: '35rem',
      maxWidth: '100rem'
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  private convertirFormatoHoras(fecha: Date): string {
    let horas = fecha.getHours();
    const minutos = fecha.getMinutes();
    const ampm = horas >= 12 ? 'PM' : 'AM';
    horas = horas % 12 || 12;
    return `${horas}:${minutos < 10 ? '0' + minutos : minutos} ${ampm}`;
  }

  private convertirFormatoHorasCompleto(fecha: Date): string {
    let horas = fecha.getHours();
    const minutos = fecha.getMinutes();
    const segundos = fecha.getSeconds();
    const ampm = horas >= 12 ? 'PM' : 'AM';
    horas = horas % 12 || 12;
    return `${horas}:${minutos < 10 ? '0' + minutos : minutos}${segundos.toString().padStart(2, '0')} ${ampm}`;
  }

  public getFormatoHorasCompleto(fecha: Date | undefined): string {
    if (!fecha) {
      return '-';
    }
    return this.formato12Horas ? this.convertirFormatoHorasCompleto(fecha) : fFecha(fecha, 'hs');
  }

  public handleCerrarRegistroIncidencias() {
    this.reporteSeleccionado = 'fecha';
  }

  public cambiarAgrupador() {
    this.crearIncidencias();
  }
  // ======================================================================================================================== >>>>>>>>>>>>>>> Reporte



  // ======================================================================================== >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> editar incidencias
  public reEstablecerIncidencias(incidencia: DatosIncidencias) {
    Swal.fire({
      title: "Re-establecer Incidencias",
      html: `¿Estás seguro de que deseas re-establecer las incidencias de ${incidencia.nombre} del día ${fFecha(incidencia.fecha, 'fsl')} ?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        let incidenciaAjuste: IAccesoIncidenciasAjuste = nuevaIncidenciaAjuste(this.empresa.id, incidencia.idEmpleado, incidencia.fecha, TipoManejoIncidencia.ReEstablecer, TipoValorIncidencia.ReEstablecer, 1, 0);
        this.accesoDatosService.incidenciasAjusteReEstablecer(incidenciaAjuste).subscribe(
          (respuesta: IRespuestaChecker) => {
            Swal.close();
            if (respuesta.code != 100) {
              Swal.fire({
                title: "Re-establecer Incidencias",
                html: `No ha sido posible re-establecer las incidencias del empleado ${incidencia.nombre} del día ${fFecha(incidencia.fecha, 'fsl')}, Mensaje: ${respuesta.code}, ${respuesta.mensaje}`,
                icon: "error",
                timer: 10000
              });
              return;
            }
            Swal.fire({
              title: "¡Incidencias re-establecer!",
              html: `Las incidencias de ${incidencia.nombre} del día ${fFecha(incidencia.fecha, 'fsl')} han sido re-establecidas`,
              icon: "success",
              timer: 4000
            });
            this.cargarDatos();
          }, (error: HttpErrorResponse) => {
            Swal.close();
            let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
            Swal.fire({
              title: "Re-establecer Incidencias",
              text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
              icon: 'error'
            });
          }
        );
      }
    });
  }

  public eliminarIncidencias(incidencia: DatosIncidencias) {
    Swal.fire({
      title: "Eliminar Incidencias",
      html: `¿Estás seguro de que deseas eliminar las incidencias de ${incidencia.nombre} del día ${fFecha(incidencia.fecha, 'fsl')} ?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        let incidenciaAjuste: IAccesoIncidenciasAjuste = nuevaIncidenciaAjuste(this.empresa.id, incidencia.idEmpleado, incidencia.fecha, TipoManejoIncidencia.Eliminar, TipoValorIncidencia.Eliminar, 1, 0);
        this.accesoDatosService.incidenciasAjusteEliminar(incidenciaAjuste).subscribe(
          (respuesta: IRespuestaChecker) => {
            Swal.close();
            if (respuesta.code != 100) {
              Swal.fire({
                title: "Eliminar Incidencias",
                html: `No ha sido posible eliminar las incidencias del empleado ${incidencia.nombre} del día ${fFecha(incidencia.fecha, 'fsl')}, Mensaje: ${respuesta.code}, ${respuesta.mensaje}`,
                icon: "error",
                timer: 10000
              });
              return;
            }
            Swal.fire({
              title: "¡Incidencias eliminadas!",
              html: `Las incidencias de ${incidencia.nombre} del día ${fFecha(incidencia.fecha, 'fsl')} han sido eliminadas`,
              icon: "success",
              timer: 4000
            });
            this.cargarDatos();
          }, (error: HttpErrorResponse) => {
            Swal.close();
            let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
            Swal.fire({
              title: "Eliminar Incidencias",
              text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
              icon: 'error'
            });
          }
        );
      }
    });
  }

  public marcarFalta(incidencia: DatosIncidencias) {
    if (incidencia.faltas !== 0) {
      Swal.fire({
        html: `El empleado no puede tener mas de una falta en un día`,
        icon: 'info',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-end',
        toast: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
      return;
    }

    Swal.fire({
      title: "Marcar como falta",
      html: `¿Estás seguro de que deseas marcar como falta el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre} ?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        let incidenciaAjuste: IAccesoIncidenciasAjuste = nuevaIncidenciaAjuste(this.empresa.id, incidencia.idEmpleado, incidencia.fecha, TipoManejoIncidencia.Ausentismo, TipoValorIncidencia.Falta, 1, 0);
        this.accesoDatosService.incidenciasAjusteGuardar(incidenciaAjuste).subscribe(
          (respuesta: IRespuestaChecker) => {
            Swal.close();
            if (respuesta.code != 100) {
              Swal.fire({
                title: "Marcar como falta",
                html: `No ha sido posible marcar como falta el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
                icon: "error",
                timer: 10000
              });
              return;
            }
            Swal.fire({
              title: "Marcar como falta",
              html: `Ha sido marcado como falta el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
              icon: "success",
              timer: 4000
            });
            this.cargarDatos();
          }, (error: HttpErrorResponse) => {
            Swal.close();
            let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
            Swal.fire({
              title: "Marcar como falta",
              text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
              icon: 'error'
            });
          }
        );
      }
    });
  }

  public marcarAsistencia(incidencia: DatosIncidencias) {
    if (incidencia.faltas == 0) {
      Swal.fire({
        html: `El empleado ya tiene asignada una asistencia para el día ${fFecha(incidencia.fecha, 'fsl')}`,
        icon: 'info',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-end',
        toast: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
      return;
    }

    Swal.fire({
      title: "Marcar como asistencia",
      html: `¿Estás seguro de que deseas marcar como asistencia el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre} ?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        let incidenciaAjuste: IAccesoIncidenciasAjuste = nuevaIncidenciaAjuste(this.empresa.id, incidencia.idEmpleado, incidencia.fecha, TipoManejoIncidencia.Asistencia, TipoValorIncidencia.Asistencia, 1, 0);
        this.accesoDatosService.incidenciasAjusteGuardar(incidenciaAjuste).subscribe(
          (respuesta: IRespuestaChecker) => {
            Swal.close();
            if (respuesta.code != 100) {
              Swal.fire({
                title: "Marcar como asistencia",
                html: `No ha sido posible marcar como asistencia el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
                icon: "error",
                timer: 10000
              });
              return;
            }
            Swal.fire({
              title: "Marcar como asistencia",
              html: `Ha sido marcado como asistencia el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
              icon: "success",
              timer: 4000
            });
            this.cargarDatos();
          }, (error: HttpErrorResponse) => {
            Swal.close();
            let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
            Swal.fire({
              title: "Marcar como asistencia",
              text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
              icon: 'error'
            });
          }
        );
      }
    });
  }

  public quitarRetardo(incidencia: DatosIncidencias) {
    if (incidencia.retardos == 0) {
      Swal.fire({
        html: `El empleado no tiene retardos para el día ${fFecha(incidencia.fecha, 'fsl')}`,
        icon: 'info',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-end',
        toast: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
      return;
    }

    Swal.fire({
      title: "Quitar retardo",
      html: `¿Estás seguro de que deseas quitar el retardo del día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre} ?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        let incidenciaAjuste: IAccesoIncidenciasAjuste = nuevaIncidenciaAjuste(this.empresa.id, incidencia.idEmpleado, incidencia.fecha, TipoManejoIncidencia.Incidencia, TipoValorIncidencia.QuitarRetardo, 0, 0);
        this.accesoDatosService.incidenciasAjusteGuardar(incidenciaAjuste).subscribe(
          (respuesta: IRespuestaChecker) => {
            Swal.close();
            if (respuesta.code != 100) {
              Swal.fire({
                title: "Quitar retardo",
                html: `No ha sido posible quitar retardo el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
                icon: "error",
                timer: 10000
              });
              return;
            }
            Swal.fire({
              title: "Quitar retardo",
              html: `Se han quitado los retardos el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
              icon: "success",
              timer: 4000
            });
            this.cargarDatos();
          }, (error: HttpErrorResponse) => {
            Swal.close();
            let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
            Swal.fire({
              title: "Quitar retardo",
              text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
              icon: 'error'
            });
          }
        );
      }
    });
  }

  public quitarHorasExtra(incidencia: DatosIncidencias) {
    if (incidencia.horasExtras == 0) {
      Swal.fire({
        html: `El empleado no tiene horas extra para el día ${fFecha(incidencia.fecha, 'fsl')}`,
        icon: 'info',
        showConfirmButton: false,
        timer: 2000,
        timerProgressBar: true,
        position: 'top-end',
        toast: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
        }
      });
      return;
    }

    Swal.fire({
      title: "Quitar horas extra",
      html: `¿Estás seguro de que deseas quitar las horas extra del día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre} ?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        let incidenciaAjuste: IAccesoIncidenciasAjuste = nuevaIncidenciaAjuste(this.empresa.id, incidencia.idEmpleado, incidencia.fecha, TipoManejoIncidencia.Incidencia, TipoValorIncidencia.QuitarHorasExtra, 0, 0);
        this.accesoDatosService.incidenciasAjusteGuardar(incidenciaAjuste).subscribe(
          (respuesta: IRespuestaChecker) => {
            Swal.close();
            if (respuesta.code != 100) {
              Swal.fire({
                title: "Quitar horas extra",
                html: `No ha sido posible quitar las horas extra del día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
                icon: "error",
                timer: 10000
              });
              return;
            }
            Swal.fire({
              title: "Quitar horas extra",
              html: `Se han quitado las horas extra el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
              icon: "success",
              timer: 4000
            });
            this.cargarDatos();
          }, (error: HttpErrorResponse) => {
            Swal.close();
            let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
            Swal.fire({
              title: "Quitar horas extra",
              text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
              icon: 'error'
            });
          }
        );
      }
    });
  }

  public agregarRetardo(incidencia: DatosIncidencias) {
    if (this.horasRetardos > 12 || this.horasRetardos < 0) {
      Swal.fire({
        html: `Las horas de retardo deben estar entre 0 y 12`,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
      });
      return;
    }

    if (this.minutosRetardos > 59 || this.minutosRetardos < 0) {
      Swal.fire({
        html: `Los minutos de retardo deben estar entre 0 y 59`,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
      });
      return;
    }

    const horasRetado = this.horasRetardos + (this.minutosRetardos / 60);
    Swal.fire({
      title: "Editar retardo",
      html: `¿Estás seguro de que deseas cambiar el retardo para el empleado ${incidencia.nombre} del día ${fFecha(incidencia.fecha, 'fsl')} a ${fNumero(horasRetado, 'hm')}  ?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        let incidenciaAjuste: IAccesoIncidenciasAjuste = nuevaIncidenciaAjuste(this.empresa.id, incidencia.idEmpleado, incidencia.fecha, TipoManejoIncidencia.Incidencia, TipoValorIncidencia.Retardo, horasRetado, 0);
        this.accesoDatosService.incidenciasAjusteGuardar(incidenciaAjuste).subscribe(
          (respuesta: IRespuestaChecker) => {
            Swal.close();
            if (respuesta.code != 100) {
              Swal.fire({
                title: "Editar retardo",
                html: `No ha sido posible editar el retardo del día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
                icon: "error",
                timer: 10000
              });
              return;
            }
            Swal.fire({
              title: "Editar retardo",
              html: `Se han modificado el retardo del día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre} a ${fNumero(horasRetado, 'hm')}`,
              icon: "success",
              timer: 4000
            });
            this.cargarDatos();
          }, (error: HttpErrorResponse) => {
            Swal.close();
            let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
            Swal.fire({
              title: "Editar retardo",
              text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
              icon: 'error'
            });
          }
        );
      }
    });
  }

  public agregarHorasExtras(incidencia: DatosIncidencias) {
    if (this.horasExtrasAdicionales > 12 || this.horasExtrasAdicionales < 0) {
      Swal.fire({
        html: `Las horas extras deben estar entre 0 y 12`,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
      });
      return;
    }

    if (this.minutosExtrasAdicionales > 59 || this.minutosExtrasAdicionales < 0) {
      Swal.fire({
        html: `Los minutos extras deben estar entre 0 y 59`,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener('mouseenter', Swal.stopTimer);
          toast.addEventListener('mouseleave', Swal.resumeTimer);
      }
      });
      return;
    }

    const horasExtra = this.horasExtrasAdicionales + (this.minutosExtrasAdicionales / 60);
    Swal.fire({
      title: "Editar horas extra",
      html: `¿Estás seguro de que deseas cambiar las horas extra para el empleado ${incidencia.nombre} del día ${fFecha(incidencia.fecha, 'fsl')} a ${fNumero(horasExtra, 'hm')}  ?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        let incidenciaAjuste: IAccesoIncidenciasAjuste = nuevaIncidenciaAjuste(this.empresa.id, incidencia.idEmpleado, incidencia.fecha, TipoManejoIncidencia.Incidencia, TipoValorIncidencia.HoraExtra, horasExtra, 0);
        this.accesoDatosService.incidenciasAjusteGuardar(incidenciaAjuste).subscribe(
          (respuesta: IRespuestaChecker) => {
            Swal.close();
            if (respuesta.code != 100) {
              Swal.fire({
                title: "Editar horas extra",
                html: `No ha sido posible editar las horas extra del día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre}`,
                icon: "error",
                timer: 10000
              });
              return;
            }
            Swal.fire({
              title: "Editar horas extra",
              html: `Se han modificado las de horas extra el día ${fFecha(incidencia.fecha, 'fsl')} para el empleado ${incidencia.nombre} a ${fNumero(horasExtra, 'hm')}`,
              icon: "success",
              timer: 4000
            });
            this.cargarDatos();
          }, (error: HttpErrorResponse) => {
            Swal.close();
            let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
            Swal.fire({
              title: "Editar horas extra",
              text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
              icon: 'error'
            });
          }
        );
      }
    });
  }


  public cargarDatosRetardo(incidencia: DatosIncidencias) {
    this.sumarRetardo = !this.sumarRetardo;
    this.horasRetardos = Math.floor(incidencia.retardos);
    this.minutosRetardos = Math.floor((incidencia.retardos - this.horasRetardos) * 60);
  }

  public cargarDatosHorasExtra(incidencia: DatosIncidencias) {
    this.sumarHorasExtras = !this.sumarHorasExtras;
    this.horasExtrasAdicionales = Math.floor(incidencia.horasExtras);
    this.minutosExtrasAdicionales = Math.floor((incidencia.horasExtras - this.horasExtrasAdicionales) * 60);
  }

































  // ======================================================================================================================== >>>>>>>>>>>>>>> Exportar
  public exportaArchivo() {

    let empleadosDeConfianzaSinExportar: string[] = [];
    this.empleados.filter(x => x.manejoAccesoExpress == EmpleadosManejoAccesoExpress.ConfianzaSinExportar).forEach(
      x => {
        empleadosDeConfianzaSinExportar.push(x.id);
      }
    );

    switch (this.programaSeleccionado) {
      case 'NomiExpressWeb':
        Swal.fire({
          html: `
          <div class="d-flex justify-content-center mb-4">
            <img src="/assets/images/nomiexpress-block.png" style="width: auto; height: auto;">
          </div>
          <div class="d-flex justify-content-center">
            <p class="mb-0">
              ¿Éstas seguro de reportar el total de incidencias a NomiExpress? Las incidencias pasarán a NomiExpress y se verán reflejadas en la nomina
            </p>
          </div>
          `,
          showCancelButton: true,
          cancelButtonText: 'Cancelar',
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "Si, enviar incidencias a NomiExpress",
        }).then((result) => {
          if (result.isConfirmed) {
            this.sincronizarNomiExpressWeb(empleadosDeConfianzaSinExportar);
          }
        });
        break;
      case 'contpaq':
        this.exportarTxtContpaq(empleadosDeConfianzaSinExportar);
        break;
      case 'NomiExpressEscritorio':
        this.exportarTxtNomiExpressEscritorio(empleadosDeConfianzaSinExportar);
        break;

      default:
        mostrarSwalError('Error', 'Error al generar el reporte al programa seleccionado, favor de comunicarse a soporte al teléfono: 33-3123-1565');
        break;
    }
  }

  public async sincronizarNomiExpressWeb(empleadosDeConfianzaSinExportar: string[]) {
    Swal.fire({
      title: 'Sincronizando...',
      text: 'Por favor espere',
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      didOpen: () => {
        Swal.showLoading();
      }
    });

    try {
      let mensajeExito: string = await this.sincronizarDatos(empleadosDeConfianzaSinExportar);
      Swal.close();
      Swal.fire({
        title: 'Éxito',
        text: mensajeExito,
        icon: 'success'
      });

    } catch (error: any) {
      Swal.close();
      let errorMessage = typeof error === 'string' ? error : error.message || JSON.stringify(error);
      Swal.fire({
        title: 'Error',
        text: `Hubo un problema al sincronizar los datos: ${errorMessage}`,
        icon: 'error'
      });
    }
  }

  private sincronizarDatos(empleadosDeConfianzaSinExportar: string[]): Promise<string> {

    // this.errorUpdating = '';
    // this.respuestaSincronizar = '';
    let incidenciasFiltradas: DatosIncidencias[] = this.incidenciasFiltradas().filter(x => !(empleadosDeConfianzaSinExportar.includes(x.idEmpleado.toString())));

    return new Promise((resolve, reject) => {
      this.accesoDatosService.incidenciasExportar(this.inicioPeriodo, this.finPeriodo, incidenciasFiltradas).subscribe(
        (respuesta: IRespuestaChecker) => {
          if (respuesta.code != 100) {
            // problema en la exportación
            reject(`La respuesta del servidor es: ${respuesta.code}, ${respuesta.mensaje}`);
          }
          resolve(`Datos sincronizados correctamente, ${respuesta.mensaje}`);
        },
        (error: HttpErrorResponse) => {
          this.ponerTxtEnConsola(`Exportar incidencias.`);
          let err: string = JSON.stringify(error);
          this.ponerTxtEnConsola(err);
          reject(err);
        }
      );
    });
  }

  public exportarTxtContpaq(empleadosDeConfianzaSinExportar: string[]) {
    let valorIncidencia: string = '';
    let incidenciasPorEmpleado: { [key: string]: string[] } = {};
    let tipoDePeriodo: string = this.periodo;
    let valorDePeriodo: string = '';
    switch (this.periodo) {
      case 'semana0':
      case 'semana1a':
        tipoDePeriodo = 'Semanal';
        break;
      case 'quincena0':
      case 'quincena1a':
      case 'personalizado':
        tipoDePeriodo = 'Quincenal';
        break;
      case 'mes0':
      case 'mes1a':
        tipoDePeriodo = 'Mensual';
        break;
      default:
        mostrarSwalError('Error', 'Periodo no compatible');
        return;
    }

    let idEmpleado: number = !this.empleadoSeleccionado || !this.empleadoSeleccionado.id ? 0 : +this.empleadoSeleccionado.id;
    let incidenciaConError: DatosIncidencias | undefined = this.incidencias.find(x => !x.idRefEmpleado && (idEmpleado < 1 || x.idEmpleado == idEmpleado));
    if (!!incidenciaConError) {
      mostrarSwalError('Exportar Incidencias a Contpaq', `El empelado: ${incidenciaConError.nombre} no tiene un Id de Referencia asignado para poder realizar la exportación, vaya a las consulta del empleado y cargue el valor especificado de referencia.`);
      return;
    }

    this.incidencias.forEach(incidencia => {
      if (idEmpleado > 0 && incidencia.idEmpleado != idEmpleado) return;
      if (empleadosDeConfianzaSinExportar.includes(incidencia.idEmpleado.toString())) return;
      const fechaIncidencia = incidencia.fecha;
      const diaDeFechaIncidencia = fechaIncidencia.getDate();

      if (diaDeFechaIncidencia <= 15) {
        valorDePeriodo = '1';
      } else {
        valorDePeriodo = '2';
      }

      let tipoIncidencia = '';
      let incidenciaEmpleado: string[] = [];

      if (incidencia.faltas > 0) {
        tipoIncidencia = 'Faltas injustificadas';
        valorIncidencia = `${incidencia.faltas}.00`;
        let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
        incidenciaEmpleado.push(incidenciaTxt);
      }

      if (incidencia.horasExtras > 0) {
        tipoIncidencia = 'Horas Extras';
        let valorIncidencia = `${incidencia.horasExtras.toFixed(0)}.00`;
        let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
        incidenciaEmpleado.push(incidenciaTxt);
      }

      if (incidencia.retardos > 0) {
        tipoIncidencia = 'Retardos';
        let valorIncidencia = incidencia.retardos < 1 ? `0.${(incidencia.retardos * 100).toFixed(0).padStart(2, '0')}` : `${Math.floor(incidencia.retardos)}.00`;
        let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
        incidenciaEmpleado.push(incidenciaTxt);
      }

      if (incidencia.incapacidad > 0) {
        tipoIncidencia = 'Incapacidad';
        valorIncidencia = `${incidencia.incapacidad}.00`;
        let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
        incidenciaEmpleado.push(incidenciaTxt);
      }

      // if (incidencia.trabajoEnDescanso > 0) {
      //   tipoIncidencia = 'Trabajo en descanso';
      //   let valorIncidencia = '1.00';
      //   let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
      //   incidenciaEmpleado.push(incidenciaTxt);
      // }

      // if (incidencia.tipoManejoIncidencia === TipoManejoIncidencia.Permiso) {
      //   tipoIncidencia = 'Permisos con goce de sueldo';
      //   valorIncidencia = `${incidencia.tipoManejoIncidencia}.00`;
      //   let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
      //   incidenciaEmpleado.push(incidenciaTxt);
      // }

      // if (incidencia.tipoManejoIncidencia === TipoManejoIncidencia.PermisoSinPago) {
      //   tipoIncidencia = 'Permisos sin goce de sueldo';
      //   valorIncidencia = `${incidencia.tipoManejoIncidencia}.00`;
      //   let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
      //   incidenciaEmpleado.push(incidenciaTxt);
      // }

      // if (incidencia.tipoManejoIncidencia === TipoManejoIncidencia.Vacaciones) {
      //   tipoIncidencia = 'Vacaciones a pagar';
      //   valorIncidencia = `${incidencia.vacaciones}.00`;
      //   let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
      //   incidenciaEmpleado.push(incidenciaTxt);
      // }

      if (incidencia.tipoManejoIncidencia === TipoManejoIncidencia.Incapacidad && incidencia.incapacidad === TipoIncapacidad.Maternidad) {
        tipoIncidencia = 'Incapacidad por maternidad';
        valorIncidencia = `${incidencia.vacaciones}.00`;
        let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
        incidenciaEmpleado.push(incidenciaTxt);
      }

      if (incidencia.tipoManejoIncidencia === TipoManejoIncidencia.Incapacidad && incidencia.incapacidad === TipoIncapacidad.RiesgoDeTrabajo) {
        tipoIncidencia = 'Accidente de trabajo';
        valorIncidencia = `${incidencia.vacaciones}.00`;
        let incidenciaTxt: string = `D ${tipoDePeriodo.padEnd(25)}${valorDePeriodo} 2                   ${tipoIncidencia.padEnd(45)}${valorIncidencia} ${fFecha(incidencia.fecha, 'fcd')},00:0 ${fFecha(incidencia.fecha, 'anio')}\n`;
        incidenciaEmpleado.push(incidenciaTxt);
      }

      if (!!tipoIncidencia) {
        if (!incidencia.idRefEmpleado) {
          mostrarSwalError('Exportar incidencias', `No existe el id de referencia del empleado: ${incidencia.nombre}`);
          return;
        }

        if (!incidenciasPorEmpleado[incidencia.idRefEmpleado] || !Object.prototype.hasOwnProperty.call(incidenciasPorEmpleado, incidencia.idRefEmpleado)) {
          incidenciasPorEmpleado[incidencia.idRefEmpleado] = [];
        }
        for (const incidenciaDia of incidenciaEmpleado) {          ;
          incidenciasPorEmpleado[incidencia.idRefEmpleado].push(incidenciaDia);
          console.log(`exportarTxtContpaq --> ${incidenciaDia}, ${incidenciasPorEmpleado[incidencia.idRefEmpleado].length} elementos en el array`)
        }
      }
    });

    let texto: string = "";
    for (let idRefEmpleado in incidenciasPorEmpleado) {
      texto +=`E       ${idRefEmpleado}\n`;
      incidenciasPorEmpleado[idRefEmpleado].forEach(linea => {
        texto += linea;
      });
    }


    var element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(texto));
    element.setAttribute('download', `MovimientoDYH.txt`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);

    mostrarSwalConfirm('Tu exportación ha sido realizada con éxito. Además, hemos enviado los datos a NomiExpress Web', 'success');
  }

  public exportarTxtNomiExpressEscritorio(empleadosDeConfianzaSinExportar: string[]) {
    let incidenciasPorEmpleado: { [key: string]: string[] } = {};
    let valorIncidencia: number = 0;
    let observaciones: string = '';

    if (!this.empresa.idReferencia || this.empresa.idReferencia.length < 32) {
      mostrarSwalError('Exportar incidencias', 'No existe el id de referencia de la empresa');
      return;
    }

    let idEmpleado: number = !this.empleadoSeleccionado || !this.empleadoSeleccionado.id ? 0 : +this.empleadoSeleccionado.id;
    let incidenciaConError: DatosIncidencias | undefined = this.incidencias.find(x => !x.idRefEmpleado && (idEmpleado < 1 || x.idEmpleado == idEmpleado));
    if (!!incidenciaConError) {
      mostrarSwalError('Exportar Incidencias a NomiExpress Escritorio', `El empelado: ${incidenciaConError.nombre} no tiene un Id de Referencia asignado para poder realizar la exportación, vaya a las consulta del empleado y cargue el valor especificado de referencia.`);
      return;
    }


    this.incidencias.forEach(incidencia => {
      if (idEmpleado > 0 && incidencia.idEmpleado != idEmpleado) return;
      if (empleadosDeConfianzaSinExportar.includes(incidencia.idEmpleado.toString())) return;

        let incidenciaEmpleado: string[] = [];
        let tipoIncidencia = '';

        if (incidencia.faltas) {
          tipoIncidencia = 'Falta';
          valorIncidencia = incidencia.faltas;
          observaciones = '';
          let incidenciaTxt: string = `${incidencia.idRefEmpleado}||${fechaT(incidencia.fecha)}||${tipoIncidencia}||${valorIncidencia}||${observaciones}`;
          incidenciaEmpleado.push(incidenciaTxt);
        }

        if (incidencia.retardos) {
          tipoIncidencia = 'Retardo';
          let horasHorario: number = 8;
          if (!!incidencia && !!incidencia.incidenciaHorario && incidencia.incidenciaHorario.length >= 0){
            horasHorario = incidencia.incidenciaHorario[0].horasHorario;
            if (horasHorario <= 8) {
              horasHorario = 8;
            }
          }
          valorIncidencia = incidencia.retardos / horasHorario;
          observaciones = '';
          let incidenciaTxt: string = `${incidencia.idRefEmpleado}||${fechaT(incidencia.fecha)}||${tipoIncidencia}||${valorIncidencia}||${observaciones}`;
          incidenciaEmpleado.push(incidenciaTxt);
        }

        if (incidencia.horasExtras) {
          tipoIncidencia = 'HoraExtra';
          valorIncidencia = incidencia.horasExtras;
          observaciones = '';
          let incidenciaTxt: string = `${incidencia.idRefEmpleado}||${fechaT(incidencia.fecha)}||${tipoIncidencia}||${valorIncidencia}||${observaciones}`;
          incidenciaEmpleado.push(incidenciaTxt);
        }

        if (incidencia.trabajoEnDescanso) {
          tipoIncidencia = 'TrabajoEnDescanso';
          valorIncidencia = incidencia.trabajoEnDescanso;
          observaciones = '';
          let incidenciaTxt: string = `${incidencia.idRefEmpleado}||${fechaT(incidencia.fecha)}||${tipoIncidencia}||${valorIncidencia}||${observaciones}`;
          incidenciaEmpleado.push(incidenciaTxt);
        }

        if (incidencia.incapacidad) {
          tipoIncidencia = 'Incapacidad';
          valorIncidencia = incidencia.incapacidad;
          observaciones = '';
          let incidenciaTxt: string = `${incidencia.idRefEmpleado}||${fechaT(incidencia.fecha)}||${tipoIncidencia}||${valorIncidencia}||${observaciones}`;
          incidenciaEmpleado.push(incidenciaTxt);
        }

        if (incidencia.vacaciones) {
          tipoIncidencia = 'Vacaciones';
          valorIncidencia = incidencia.vacaciones;
          observaciones = '';
          let incidenciaTxt: string = `${incidencia.idRefEmpleado}||${fechaT(incidencia.fecha)}||${tipoIncidencia}||${valorIncidencia}||${observaciones}`;
          incidenciaEmpleado.push(incidenciaTxt);
        }

        if (incidencia.descanso) {
          tipoIncidencia = 'Descanso';
          valorIncidencia = incidencia.descanso;
          observaciones = '';
          let incidenciaTxt: string = `${incidencia.idRefEmpleado}||${fechaT(incidencia.fecha)}||${tipoIncidencia}||${valorIncidencia}||${observaciones}`;
          incidenciaEmpleado.push(incidenciaTxt);
        }

        if (!!tipoIncidencia) {
          if (!incidencia.idRefEmpleado) {
            mostrarSwalError('Exportar incidencias', `No existe el id de referencia del empleado: ${incidencia.nombre}`);
            return;
          }

          if (!incidenciasPorEmpleado[incidencia.idRefEmpleado] || !Object.prototype.hasOwnProperty.call(incidenciasPorEmpleado, incidencia.idRefEmpleado)) {
              incidenciasPorEmpleado[incidencia.idRefEmpleado] = [];
          }
          for (const incidenciaDia of incidenciaEmpleado) {
              incidenciasPorEmpleado[incidencia.idRefEmpleado].push(incidenciaDia);
          }
        }
    });

    let texto: string = "";
    for (let idRefEmpleado in incidenciasPorEmpleado) {
        texto += `NExp2024||${this.empresa.idReferencia}||incidencias\n`;
        incidenciasPorEmpleado[idRefEmpleado].forEach(linea => {
            texto += linea + '\n';
        });
    }

    let ref: string = `${fFecha(this.inicioPeriodo, 'fa')}`;
    if (this.iReporte > 0) ref += `_${this.iReporte}`;
    var element = document.createElement(`a`);
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(texto));
    element.setAttribute('download', `Incidencias_${this.empresa.nombre}_${ref}_NExp2024.txt`);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();
    document.body.removeChild(element);
    this.iReporte++;

    mostrarSwalConfirm('Tu exportación ha sido realizada con éxito, busca el archivo generado en descargas de su navegador.', 'success');
  }

  public exportarReporte() {
    if (this.reporteSeleccionado === 'ejecutivo') {
      this.exportarReporteEjecutivo();
    } else {
      this.exportarPDF();
    }
  }

  public async exportarReporteEjecutivo() {
    try {
      const imgDefault = 'assets/images/ACE_Acceso-Express.png';
      const imgDefaultBase64 = await this.pdfService.getBase64ImageFromAssets(imgDefault);
      const rows = [];

      const headerRow = [
        { text: 'Empleado', style: 'tableHeader' },
        { text: 'ID', style: 'tableHeader' },
        { text: 'Horas Trabajadas', style: 'tableHeader' },
        { text: 'Retardos', style: 'tableHeader' },
        { text: 'Horas Extras', style: 'tableHeader' },
        { text: 'Faltas', style: 'tableHeader' },
      ];

      if (this.agregarFirma) {
        headerRow.push({ text: 'Firma del Empleado', style: 'tableHeader' });
      }

      rows.push(headerRow);

      for (const [key, incidenciaGrupo] of Object.entries(this.incidenciasReporte)) {
        const dataRow = [
          { text: this.incidenciaTotales[key]?.nombre, style: 'tableCell' },
          { text: this.incidenciaTotales[key]?.idEmpleado, style: 'tableCell' },
          { text: this.incidenciaTotales[key]?.horasTrabajadasTxt, style: 'tableCell' },
          { text: this.incidenciaTotales[key]?.retardosTxt, style: 'tableCell' },
          { text: this.incidenciaTotales[key]?.horasExtrasTxt, style: 'tableCell' },
          { text: this.incidenciaTotales[key]?.faltasTxt, style: `${this.incidenciaTotales[key]?.faltas > 0 ? 'faltasTabla' : 'tableCell'}` },
        ];

        if (this.agregarFirma) {
          dataRow.push({ text: '', style: 'tableCell' });
        }

        rows.push(dataRow);
      }

      let empresaImageBase64: string;
      try {
        empresaImageBase64 = await this.pdfService.getBase64ImageFromAssets(this.empresa.imageUrl);
      } catch (error) {
        console.error('Error al cargar la imagen de la empresa, usando imagen predeterminada.', error);
        empresaImageBase64 = imgDefaultBase64;
      }

      const docDefinition = {
        pageOrientation: 'landscape',
        pageSize: 'letter',
        footer: (currentPage: number, pageCount: number) => {
          return {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: 'center',
            style: 'footer',
            margin: [0, 10, 0, 0]
          };
        },
        content: [
          {
            columns: [
              {
                image: empresaImageBase64,
                width: 100,
                margin: [0, 0, 20, 0]
              },
              {
                stack: [
                  { text: 'Reporte de Incidencias', style: 'headerTitle', alignment: 'right' },
                  { text: `Fecha de expedición: ${this.formatoFecha(new Date())}`, style: 'headerDate', alignment: 'right' },
                  { text: `Fecha del reporte: ${this.formatoFecha(this.inicioPeriodo)} - ${this.formatoFecha(this.finPeriodo)}`, style: 'headerDate', alignment: 'right' },
                  { text: this.empresa.nombre, style: 'headerDate', alignment: 'right' },
                  { text: this.empresa.rfc, style: 'headerDate', alignment: 'right' },
                ],
                alignment: 'right'
              }
            ],
            margin: [0, 0, 0, 10]
          },
          {
            table: {
              headerRows: 1,
              widths: Array(headerRow.length).fill('*'),
              body: rows,
            },
            layout: {
              fillColor: (rowIndex: number) => (rowIndex === 0) ? '#CCCCCC' : null,
              hLineWidth: () => 0.5,
              vLineWidth: () => 0.5,
              hLineColor: () => '#EAEAEA',
              vLineColor: () => '#EAEAEA',
            },
            margin: [0, 0, 0, 20]
          },
        ],
        styles: {
          headerTitle: { fontSize: 16, bold: true, margin: [0, 0, 0, 10] },
          headerDate: { fontSize: 10, italics: true, margin: [0, 0, 0, 0] },
          footer: { fontSize: 10, italics: true, margin: [0, 0, 0, 10] },
          tableHeader: { bold: true, fontSize: 10, color: '#000000', alignment: 'center', fillColor: '#E3F2FD', margin: [0, 5, 0, 5] },
          tableGroupHeader: { bold: true, fontSize: 10, color: '#000000', fillColor: '#dddddd', alignment: 'left', margin: [0, 5, 0, 5] },
          tableCell: { fontSize: 8, alignment: 'center', margin: [0, 5, 0, 5] },
          faltasTabla: { fontSize: 8, alignment: 'center', margin: [0, 5, 0, 5], color: '#dc3545', fillColor: '#f8d7da' }
        }
      };

      pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      console.error('Error al generar el PDF', error);
    }
  }

  public async exportarPDF() {
    try {
      const imgDefault = 'assets/images/ACE_Acceso-Express.png';
      const imgDefaultBase64 = await this.pdfService.getBase64ImageFromAssets(imgDefault);
      const rows = [];

      const headerRow = [
        { text: 'ID', style: 'tableHeader' },
        { text: 'Empleado', style: 'tableHeader' },
        { text: 'Hora Entrada', style: 'tableHeader' },
        { text: 'Hora Salida', style: 'tableHeader' },
        { text: 'Horas Trabajadas', style: 'tableHeader' },
        { text: 'Horas Extras', style: 'tableHeader' },
        { text: 'Retardo', style: 'tableHeader' },
        { text: 'Falta', style: 'tableHeader' },
      ];

      if (this.agregarFirma) {
        headerRow.push({ text: 'Firma del Empleado', style: 'tableHeader' });
      }
      rows.push(headerRow);

      let clavesOrdenadas = Object.keys(this.incidenciasReporte);
      if (this.reporteSeleccionado === 'fecha') {
        clavesOrdenadas.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
      }

      for (const key of clavesOrdenadas) {
        if (this.incidenciasReporte.hasOwnProperty(key)) {
          const incidenciaGrupo = this.incidenciasReporte[key];
          const dateKey: Date = new Date(key);

          // if (this.reporteSeleccionado !== 'fecha') {
          //   incidenciaGrupo = incidenciaGrupo.sort((a, b) => new Date(b.fecha).getTime() - new Date(a.fecha).getTime());
          // }

          const groupHeaderRow = [
            { text: this.reporteSeleccionado === 'fecha' ? this.formatoFecha(dateKey) : key, colSpan: headerRow.length, style: 'tableGroupHeader' },
            ...new Array(headerRow.length - 1).fill({})
          ];

          rows.push(groupHeaderRow);

          for (const incidencia of incidenciaGrupo) {
            const horaEntradaHorario = incidencia.incidenciaHorario.map(horario => this.getFormatoHoras(horario.horaEntradaHorario)).join(', ');
            const horaEntradaChecada = this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaEntrada) !== '00:00' && this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaEntrada) !== '12:00 AM' ? this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaEntrada) : '-'
            const horaSalidaHorario = incidencia.incidenciaHorario.map(horario => this.getFormatoHoras(horario.horaSalidaHorario)).join(', ');
            const horaSalidaChecada = this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaSalida) !== '00:00' && this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaSalida) !== '12:00 AM' ? this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaSalida) : '-'

            const horaEntradaTexto = this.politicaIncidencias.horasOficialPDF ? horaEntradaHorario : horaEntradaChecada;
            const horaSalidaTexto = this.politicaIncidencias.horasOficialPDF ? horaSalidaHorario : horaSalidaChecada;

            const dataRow = [
              { text: `${this.esDebugger ? incidencia.idEmpleado : incidencia.id}`, style: 'tableCell' },
              { text: this.reporteSeleccionado === 'fecha' ? incidencia.nombre : this.formatoFecha(incidencia.fecha), style: 'tableCell' },
              { text: horaEntradaTexto, style: 'tableCell'},
              { text: horaSalidaTexto, style: 'tableCell'},
              { text: incidencia.horasTrabajadasTxt || '', style: 'tableCell' },
              { text: incidencia.horasExtrasTxt || '', style: 'tableCell' },
              { text: incidencia.retardosTxt || '', style: 'tableCell' },
              { text: incidencia.faltasTxt || '', style: `${incidencia.faltas > 0 ? 'faltasTabla' : 'tableCell'}` },
            ];

            if (this.agregarFirma) {
              dataRow.push({ text: '', style: 'tableCell' });
            }

            rows.push(dataRow);
          }
        }
      }

      let empresaImageBase64: string;
      try {
        empresaImageBase64 = await this.pdfService.getBase64ImageFromAssets(this.empresa.imageUrl);
      } catch (error) {
        console.log('Error al cargar la imagen de la empresa, usando imagen predeterminada.', error);
        empresaImageBase64 = imgDefaultBase64;
      }

      const docDefinition = {
        pageOrientation: 'landscape',
        pageSize: 'A4',
        // pageMargins: [40, 60, 40, 60],
        footer: (currentPage: number, pageCount: number) => {
          return {
            text: `Página ${currentPage} de ${pageCount}`,
            alignment: 'center',
            style: 'footer',
            margin: [0, 10, 0, 0]
          };
        },
        content: [
          {
            columns: [
              {
                image: empresaImageBase64,
                width: 100,
                margin: [0, 0, 20, 0]
              },
              {
                stack: [
                  { text: 'Reporte de Incidencias', style: 'headerTitle', alignment: 'right' },
                  { text: `Fecha de expedición: ${this.formatoFecha(new Date())}`, style: 'headerDate', alignment: 'right' },
                  { text: `Fecha del reporte: ${this.formatoFecha(this.inicioPeriodo)} - ${this.formatoFecha(this.finPeriodo)}`, style: 'headerDate', alignment: 'right' },
                  { text: this.empresa.nombre, style: 'headerDate', alignment: 'right' },
                  { text: this.empresa.rfc, style: 'headerDate', alignment: 'right' },
                ],
                alignment: 'right'
              }
            ],
            margin: [0, 0, 0, 10]
          },
          {
            table: {
              headerRows: 1,
              widths: this.agregarFirma
                ? ['auto', 230, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto']
                : ['auto', 320, 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: rows,
            },
            layout: {
              fillColor: (rowIndex: number) => {
                return (rowIndex === 0) ? '#3498db' : (rowIndex % 2 === 0) ? '#f9f9f9' : '#ffffff';
              },
              hLineWidth: (i: number, node: any) => 0.5,
              vLineWidth: (i: number, node: any) => 0.5,
              hLineColor: (i: number, node: any) => '#bfbfbf',
              vLineColor: (i: number, node: any) => '#bfbfbf',
            },
            margin: [0, 0, 0, 20]
          },
        ],
        styles: {
          headerTitle: { fontSize: 16, bold: true, margin: [0, 0, 0, 10] },
          headerDate: { fontSize: 10, italics: true, margin: [0, 0, 0, 0] },
          footer: { fontSize: 10, italics: true, margin: [0, 0, 0, 10] },
          tableHeader: { bold: true, fontSize: 10, color: '#000000', alignment: 'center', fillColor: '#E3F2FD', margin: [0, 5, 0, 5] },
          tableGroupHeader: { bold: true, fontSize: 10, color: '#000000', fillColor: '#dddddd', alignment: 'left', margin: [0, 5, 0, 5] },
          tableCell: { fontSize: 8, alignment: 'center', margin: [0, 5, 0, 5] },
          faltasTabla: { fontSize: 8, alignment: 'center', margin: [0, 5, 0, 5], color: '#dc3545', fillColor: '#f8d7da' }
        }
      };

      pdfMake.createPdf(docDefinition).open();
    } catch (error) {
      console.error('Error al generar el PDF', error);
    }
  }

  public async exportarExcel() {
    let títulos: TítulosExcel = {
      titulo1: `Incidencias ${this.empresa.nombre}`,
      titulo2: `Fecha de expedición: ${this.formatoFecha(new Date())}`,
      titulo3: `Periodo del reporte: ${this.formatoFecha(this.inicioPeriodo)} - ${this.formatoFecha(this.finPeriodo)}`,
      titulo4: undefined
    };

    let columnas: ColumnaExcel[] = [
      { header: 'ID', key: 'id', width: 33, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: `${this.reporteSeleccionado === 'fecha' ? 'Empleado' : 'Fecha'}`, key: 'empleado', width: 30, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Hora Entrada', key: 'entrada', width: 12, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Hora Salida', key: 'salida', width: 12, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Horas trabajadas', key: 'horasTrabajadas', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Retardos', key: 'horasExtras', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Horas extras', key: 'retardos', width: 20, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Faltas', key: 'faltas', width: 6, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
      { header: 'Descanso', key: 'descanso', width: 10, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } },
    ];

    if (this.agregarFirma) {
      columnas.push({ header: 'Firma del Empleado', key: 'firma', width: 32, alignment: { vertical: 'middle', horizontal: 'left', wrapText: false } });
    }

    let títuloColumnas: string[] = columnas.map(columna => columna.header);

    let renglones: RenglonesExcel[] = [];

    let clavesOrdenadas = Object.keys(this.incidenciasReporte);
    if (this.reporteSeleccionado === 'fecha') {
        clavesOrdenadas.sort((a, b) => new Date(b).getTime() - new Date(a).getTime());
    }

    for (const key of clavesOrdenadas) {
      if (this.incidenciasReporte.hasOwnProperty(key)) {
        const incidenciaGrupo = this.incidenciasReporte[key];
        const dateKey: Date = new Date(key);
        const groupHeaderRow = this.reporteSeleccionado === 'fecha' ? this.formatoFecha(dateKey) : key;

        renglones.push({
          datos: this.agregarFirma ? [groupHeaderRow, '', '', '', '', '', '', '', '', '',] : [groupHeaderRow, '', '', '', '', '', '', '', ''],
          estilo: {
            font: { name: 'Trebuchet MS', bold: true, color: { argb: '000000' } },
            fill: { type: 'pattern', pattern: 'solid', fgColor: { argb: 'DDDDDD' } }
          }
         });

        for (const incidencia of incidenciaGrupo) {
          const datosRenglon = [
            this.esDebugger ? incidencia.idEmpleado : incidencia.id,
            this.reporteSeleccionado === 'fecha' ? incidencia.nombre : this.formatoFecha(incidencia.fecha),
            this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaEntrada) !== '00:00' && this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaEntrada) !== '12:00 AM' ? this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaEntrada) : '-',
            this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaSalida) !== '00:00' && this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaSalida) !== '12:00 AM' ? this.getFormatoHoras(incidencia.incidenciaHorario[0]?.horaSalida) : '-',
            incidencia.horasTrabajadasTxt,
            incidencia.retardosTxt,
            incidencia.horasExtrasTxt,
            incidencia.faltasTxt,
            incidencia.especialTxt
          ];

          if (this.agregarFirma) {
            datosRenglon.push('___________________________');
          }

          renglones.push({ datos: datosRenglon });
        }
      }
    }

    let totales: string[] = [];

    let datos: ArchivoExcel = {
      nombreArchivo: `${títulos.titulo1}.xlsx`,
      títulos: títulos,
      columnas: columnas,
      títuloColumnas: títuloColumnas,
      renglones: renglones,
      totales: totales,
      fuenteTítulo: undefined,
      fuenteRenglones: undefined,
      fuenteTotales: undefined,
    };

    creaArchivoExcel(datos, 'xxx');
  }

  public limpiarFiltro(): void {
    const cargarIncidencias = true;
    this.empleadoSeleccionado = undefined;
    this.filtrarDatos(cargarIncidencias);
  }

  public limpiarFiltroAgrupador() {
    this.agrupadorSeleccionado = '';
    this.crearIncidencias();
  }

  // public limpiarFecha(picker: MatDateRangePicker<Date>){
  //   this.rangoPersonalizado.reset({ desde: this.inicioPeriodo, hasta: this.finPeriodo });
  //   picker.close();
  // }

  // public aplicarFechas() {
  //   const desde = this.rangoPersonalizado.get('desde')?.value;
  //   const hasta = this.rangoPersonalizado.get('hasta')?.value;

  //   if (desde && hasta) {
  //     console.log('Fecha desde seleccionada:', desde);
  //     console.log('Fecha hasta seleccionada:', hasta);
  //     this.manipularRangeChange(desde, hasta);
  //   } else {
  //     console.error('Las fechas ingresadas no son válidas.');
  //   }
  // }

  // public manipularRangeChange(desde: Date | null, hasta: Date | null) {
  //   this.rangoPersonalizado.reset({ desde: null, hasta: null });

  //   if (!desde || !hasta) {
  //     console.error('Las fechas ingresadas no son válidas.');
  //     return;
  //   }

  //   console.log('Fecha desde seleccionada:', desde);
  //   console.log('Fecha hasta seleccionada:', hasta);

  //   if (desde.getTime() > hasta.getTime()) {
  //     console.warn('La fecha de inicio es mayor que la fecha de fin.');
  //     return;
  //   }

  //   if (this.inicioPeriodo.getTime() !== desde.getTime() || this.finPeriodo.getTime() !== hasta.getTime()) {
  //     this.inicioPeriodo = desde;
  //     this.finPeriodo = hasta;
  //     this.accesoDatosService.guardarDatosRegistro(this.inicioPeriodo, this.finPeriodo, this.empleadoSeleccionado);

  //     if (this.inicioPeriodo.getTime() < this.fechaBajaMax.getTime()) {
  //       this.cargarDatosInicial();
  //     } else {
  //       this.cargarDatos();
  //     }
  //   }
  // }

  public dateRangeChange(dateRangeStart: HTMLInputElement, dateRangeEnd: HTMLInputElement) {
    this.periodo = 'personalizado';
    let inicio = new Date(dateRangeStart.value);
    let fin = new Date(dateRangeEnd.value);

    console.log('Fecha desde seleccionada:', inicio);
    console.log('Fecha hasta seleccionada:', fin);

    if (inicio.getTime() > fin.getTime()) {
      fin = inicio;
    }

    if (this.inicioPeriodo.getTime() !== inicio.getTime() || this.finPeriodo.getTime() !== fin.getTime()) {
      console.log(`===========>>> Nuevo periodo: ${fFecha(inicio, 'fm')}, ${fFecha(fin, 'fm')} <<<<===========`);
      this.inicioPeriodo = inicio;
      this.finPeriodo = fin;
      this.accesoDatosService.guardarDatosRegistro(this.inicioPeriodo, this.finPeriodo, this.empleadoSeleccionado);
      if (this.inicioPeriodo.getTime() < this.fechaBajaMax.getTime()) {
        this.cargarDatosInicial();
      } else {
        this.cargarDatos();
      }
    }
  }

  public getFormatoHoras(fecha: Date | undefined): string {
    if (!fecha) {
      return '-';
    }
    return this.formato12Horas ? this.convertirFormatoHoras(fecha) : this.formatoHora(fecha);
  }

  public openModal(incidencia: DatosIncidencias) {
    this.incidencia = incidencia;
    this.modalRef = this.modalService.show(this.detalles, { initialState: { incidencia: this.incidencia }, class: 'modal-dialog modal-lg modal-dialog-centered' });
  }

  public cambiarHorarioDialog(incidencia: DatosIncidencias, agregarNuevoHorario: boolean) {
    const diaIncidencia = this.obtenerDiaSemana(new Date(incidencia.fecha));
    let incidenciasDelDia = this.incidencias;
    let horarios = crearHorarioDesdeIncidencia(incidenciasDelDia[0].detalleHorarioDia, incidenciasDelDia[0].fecha, incidenciasDelDia[0].horario);

    const cambiarHorario = this.dialog.open(CambiarHorarioComponent, {
      data: {
        incidencia: incidencia,
        incidenciasDelDia: incidenciasDelDia,
        agregarHorario: agregarNuevoHorario,
        horario: horarios,
        diaIncidencia: diaIncidencia,
      },
      width: '70vw',
      height: 'auto',
      maxHeight: '90vh',
    });

    cambiarHorario.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  public obtenerDiaSemana(fecha: Date) {
    const semanaDias = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado',];
    return semanaDias[fecha.getDay()];
  }

  // ======================================================================================================================== >>>>>>>>>>>>>>> Reporte





  // ======================================================================================================================== >>>>>>>>>>>>>>> Otros
  public abrirDialogPuntos() {
    const puntosAcceso = this.dialog.open(PuntosDeAccesoEstadoComponent, {
      data: undefined,
      width: '37rem',
      height: '17rem',
      maxHeight: '25rem',
    });

    puntosAcceso.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.accesoDatosService.logAgrega(txt);
  }

  public valuePeriodoAscOrder = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
    return this.tipoPeriodoReporteOrden[a.key] - this.tipoPeriodoReporteOrden[b.key];
  };

  public valueEmpleadosAscOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
    // return this.tipoPeriodoReporteOrden[a.value.nombre] - this.tipoPeriodoReporteOrden[b.value.nombre];
  };

  public valueIncidenciasAscOrder = (a: KeyValue<string, any>, b: KeyValue<string, any>): number => {
    if (this.reporteSeleccionado != 'fecha') {
      return a.key.toLowerCase().localeCompare(b.key.toLowerCase());
    }
    return b.key.toLowerCase().localeCompare(a.key.toLowerCase());
  };
  // ======================================================================================================================== >>>>>>>>>>>>>>> Otros

  // ======================================================================================================================== >>>>>>>>>>>>>>> Horarios Incidencias
  public cambiarHorariosEmpleados(incidencia: DatosIncidencias, idEmpleadoCambio: number) {
    if (!incidencia.detalleHorarioDia || !incidencia.detalleHorarioDia.horarioDia || incidencia.detalleHorarioDia.horarioDia.length < 1) {
      mostrarSwalError('Cambio de horario', 'No es posible localizar el horario actual del empleado');
      return;
    }

    if (incidencia.idEmpleado == idEmpleadoCambio) {
      mostrarSwalError('Cambio de horario', 'El empleado de actual y el sustituto es el mismo');
      return;
    }

    let incidenciaEmpleadoCambio: DatosIncidencias | undefined = this.incidencias.find(x => x.idEmpleado == idEmpleadoCambio && x.fecha == incidencia.fecha);
    if (!incidenciaEmpleadoCambio) {
      mostrarSwalError('Cambio de horario', 'No es posible localizar el horario actual del empleado con el que se realizará el cambio');
      return;
    }

    let horarioIncidencia1: IHorariosIncidencias = {
      id: -1,
      idEmpresa: this.empresa.id,
      idEmpleado: idEmpleadoCambio,  // es correcto se pasa este horario al otro empleado
      inicio: incidencia.fecha,
      inicioTxt: fechaT(incidencia.fecha),
      fin: incidencia.fecha,
      finTxt: fechaT(incidencia.fecha),
      horario: JSON.stringify(minifiedDetalleHorarioDia(incidencia.detalleHorarioDia)),
      esDescanso: incidencia.detalleHorarioDia.esDescanso,
      esNocturno: incidencia.detalleHorarioDia.esNocturno,
      horarioDia: incidencia.detalleHorarioDia.horarioDia
    }

    let horarioIncidencia2: IHorariosIncidencias = {
      id: -1,
      idEmpresa: this.empresa.id,
      idEmpleado: incidencia.idEmpleado, // es correcto se pasa el horario del otro empleado a este empelado
      inicio: incidenciaEmpleadoCambio.fecha,
      inicioTxt: fechaT(incidenciaEmpleadoCambio.fecha),
      fin: incidenciaEmpleadoCambio.fecha,
      finTxt: fechaT(incidenciaEmpleadoCambio.fecha),
      horario: JSON.stringify(minifiedDetalleHorarioDia(incidenciaEmpleadoCambio.detalleHorarioDia)),
      esDescanso: incidenciaEmpleadoCambio.detalleHorarioDia.esDescanso,
      esNocturno: incidenciaEmpleadoCambio.detalleHorarioDia.esNocturno,
      horarioDia: incidenciaEmpleadoCambio.detalleHorarioDia.horarioDia
    }

    if (horarioIncidencia1.esDescanso == horarioIncidencia2.esDescanso && horarioIncidencia1.esNocturno == horarioIncidencia2.esNocturno && horarioIncidencia1.horario == horarioIncidencia2.horario) {
      mostrarSwalError('Cambio de horario', 'El horario actual y el sustituto es el mismo');
      return;
    }

    this.accesoDatosService.horarioIncidenciaCambio(horarioIncidencia1, horarioIncidencia2).subscribe(
      (respuesta: IRespuestaChecker) => {
        if (respuesta.code != 100) {
          mostrarSwalError('Cambio de horario', `Respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`);
          return;
        }
        mostrarSwalToast('Cambios realizador con éxito', 'success');
        this.cargarDatosInicial();
      }, (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Exportar incidencias.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
        mostrarSwalError('Cambio de horario', `Error al generar el cambio de horario, error: ${err}`);
      }
    );
  }

  public horarioIncidenciaModificar(incidencia: DatosIncidencias, horarioIncidenciaCambio: IHorariosIncidencias ){
    if (!horarioIncidenciaCambio) {
      mostrarSwalError('Cambio de horario', 'No es posible localizar el horario actual del empleado con el que se realizará el cambio');
      return;
    }

    if (!incidencia.detalleHorarioDia || !incidencia.detalleHorarioDia.horarioDia || incidencia.detalleHorarioDia.horarioDia.length < 1) {
      mostrarSwalError('Cambio de horario', 'No es posible localizar el horario actual del empleado');
      return;
    }

    if (incidencia.idEmpleado != horarioIncidenciaCambio.idEmpleado) {
      mostrarSwalError('Cambio de horario', 'El empleado de actual y el sustituto no es el mismo');
      return;
    }

    let horarioJson: string = JSON.stringify(minifiedDetalleHorarioDia(incidencia.detalleHorarioDia))
    if (horarioIncidenciaCambio.esDescanso == incidencia.detalleHorarioDia.esDescanso && horarioIncidenciaCambio.esNocturno == incidencia.detalleHorarioDia.esNocturno && horarioIncidenciaCambio.horario == horarioJson) {
      mostrarSwalError('Cambio de horario', 'El horario actual y el sustituto es el mismo');
      return;
    }

    this.accesoDatosService.horarioIncidenciaModificar(horarioIncidenciaCambio).subscribe(
      (respuesta: IRespuestaChecker) => {
        if (respuesta.code != 100) {
          mostrarSwalError('Cambio de horario', `Respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`);
          return;
        }
        mostrarSwalToast('Cambios realizador con éxito', 'success');
        this.cargarDatosInicial();
      }, (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Exportar incidencias.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
        mostrarSwalError('Cambio de horario', `Error al generar el cambio de horario, error: ${err}`);
      }
    );

  }

  // ======================================================================================================================== >>>>>>>>>>>>>>> Horarios Incidencias






  // ======================================================================================================================== >>>>>>>>>>>>>>> Posibles ya no utilizadas
  public calcularHorasExtras(horaEntrada: Date, horaSalida: Date, horaEntradaRegistrada: Date, horaSalidaRegistrada: Date): string {
    let horasExtrasTrabajadas = 0;
    let minutosExtrasTrabajados = 0;
    this.calcularRetardos(horaEntrada, horaEntradaRegistrada);

    if (!!horaSalida && horaSalida < horaSalidaRegistrada) {
      // this.checoAntesSalida = true;
    }

    if (!!horaEntrada && horaEntrada < horaEntradaRegistrada) {
      // this.checoAntesEntrada = true;
      const diferencia = horaEntradaRegistrada.getTime() - horaEntrada.getTime();
      horasExtrasTrabajadas += Math.max(Math.floor(diferencia / (1000 * 60 * 60)), 0);
      minutosExtrasTrabajados += Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
    }

    if (!!horaSalida && horaSalida > horaSalidaRegistrada) {
      // this.checoDespuesSalida = true;
      const diferencia = horaSalida.getTime() - horaSalidaRegistrada.getTime();
      horasExtrasTrabajadas += Math.max(Math.floor(diferencia / (1000 * 60 * 60)), 0);
      minutosExtrasTrabajados += Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));
    }

    const minutosFormateados = minutosExtrasTrabajados < 10 ? `0${minutosExtrasTrabajados}` : `${minutosExtrasTrabajados}`;

    return `${horasExtrasTrabajadas > 0 ? horasExtrasTrabajadas + ' hora' + (horasExtrasTrabajadas !== 1 ? 's' : '') : '-'} ${minutosFormateados > '1' ? minutosFormateados + ' minuto' + (minutosFormateados !== '1' ? 's' : '') : ''}`;
  }

  public calcularRetardos(horaEntrada: Date, horaEntradaRegistrada: Date): string {
    if (this.sumarRetardo) {
      // horaEntrada = new Date(horaEntrada.getTime() + this.minutosAsumar * 60000);
    }

    if (!!horaEntrada && horaEntrada > horaEntradaRegistrada) {
      let horasRetardo = 0;
      let minutosRetardo = 0;

      const calcularRetardo = horaEntrada.getTime() - horaEntradaRegistrada.getTime();
      horasRetardo = Math.floor(calcularRetardo / (1000 * 60 * 60));
      minutosRetardo = Math.floor((calcularRetardo % (1000 * 60 * 60)) / (1000 * 60));

      // this.checoTardeEntrada = true;

      return `${horasRetardo > 0 ? horasRetardo + ' hora' + (horasRetardo !== 1 ? 's' : '') : ''} ${minutosRetardo > 1 ? minutosRetardo + ' minuto' + (minutosRetardo !== 1 ? 's' : '') : ''}`;
    } else {
      // this.checoTardeEntrada = false;
      return "0"
    }
  }

  public calcularHorasTrabajadas(horaEntrada: Date | undefined, horaSalida: Date | undefined, retardo: number, horasExtras: number): string {
    if (horaEntrada && horaSalida) {
      let horasTrabajadas = 0;
      let minutosTrabajados = 0;
      const diferencia = horaSalida.getTime() - horaEntrada.getTime();

      horasTrabajadas = Math.floor(diferencia / (1000 * 60 * 60));
      minutosTrabajados = Math.floor((diferencia % (1000 * 60 * 60)) / (1000 * 60));

      if (typeof retardo === 'number') {
        minutosTrabajados -= retardo;
      }

      if (typeof horasExtras === 'number') {
        minutosTrabajados += horasExtras;
      }

      if (minutosTrabajados < 0) {
        horasTrabajadas -= 1;
        minutosTrabajados += 60;
      }

      if (minutosTrabajados >= 60) {
        horasTrabajadas += Math.floor(minutosTrabajados / 60);
        minutosTrabajados = minutosTrabajados % 60;
      }

      const minutosFormateados = minutosTrabajados < 10 ? `0${minutosTrabajados}` : `${minutosTrabajados}`;
      const horasTexto = horasTrabajadas > 0 ? `${horasTrabajadas} hora${horasTrabajadas !== 1 ? 's' : ''}` : '';
      const minutosTexto = minutosTrabajados > 0 ? `${minutosFormateados} minuto${minutosTrabajados !== 1 ? 's' : ''}` : '';

      return `${horasTexto} ${minutosTexto}`.trim();
    } else {
      Swal.fire({
        html: `Hora de entrada o hora de salida no definidas`,
        icon: 'error',
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
      });
      return "0 horas 0 minutos";
    }
  }

  private horasMinutos(date: Date): string {
    const horas = date.getHours().toString().padStart(2, '0');
    const minutos = date.getMinutes().toString().padStart(2, '0');
    return `${horas}:${minutos}`;
  }

  public calcularTotalIncidencias() {
    this.incidenciaTotales = {};
    const entradasPorDia: { [idEmpleado: number]: string[] } = {};

    this.incidencias.forEach(incidencia => {
      const empleado = incidencia.nombre;

      if (!this.incidenciaTotales[empleado]) {
        this.incidenciaTotales[empleado] = {
          id: 0,
          idEmpleado: 0,
          idRefEmpleado: '',
          nombre: '',
          idHorario: '',
          idDepartamento: 0,
          horario: '',
          fecha: new Date(),
          incidenciaHorario: [],
          horasTrabajadas: 0,
          horasTrabajadasTxt: '-',
          horasExtras: 0,
          horasExtrasTxt: '-',
          retardos: 0,
          retardosTxt: '-',
          faltas: 0,
          faltasTxt: '-',
          descanso: 0,
          trabajoEnDescanso: 0,
          vacaciones: 0,
          incapacidad: 0,
          especialTxt: '-',
          esTrabajoDescanso: false,
          idIncidenciaAjuste: 0,
          tipoManejoIncidencia: TipoManejoIncidencia.Ninguno,
          diasTrabajados: 0,
          detalleHorarioDia: new IDetalleHOrarioDia,
          incidencias: 0,
        } as DatosIncidencias;
      }

      if (incidencia.incidenciaHorario.length > 0) {
        const horaEntradaMenor = incidencia.incidenciaHorario.reduce((minHoraEntrada, horarioActual) => {
          return horarioActual.horaEntrada < minHoraEntrada ? horarioActual.horaEntrada : minHoraEntrada;
        }, incidencia.incidenciaHorario[0].horaEntrada);

        const horaSalidaMaxima = incidencia.incidenciaHorario.reduce((maxHoraSalida, horarioActual) => {
          return horarioActual.horaSalida > maxHoraSalida ? horarioActual.horaSalida : maxHoraSalida;
        }, incidencia.incidenciaHorario[0].horaSalida);

        this.incidenciaTotales[empleado].incidenciaHorario[0] = {
          ...this.incidenciaTotales[empleado].incidenciaHorario[0],
          horaEntrada: horaEntradaMenor,
          horaSalida: horaSalidaMaxima,
        };
      }

      let diasEnPeriodo: number = 0;
      let diasIncapacidad: number = 0;
      let diasVacaciones: number = 0;
      let diasDescanso: number = 0;
      let diasFaltas: number = 0;
      if (!(incidencia.idEmpleado in entradasPorDia)) {
        entradasPorDia[incidencia.idEmpleado] = [];
      }

      let fechaTexto: string = fechaT(incidencia.fecha);
      if (!entradasPorDia[incidencia.idEmpleado].includes(fechaTexto)) {
        entradasPorDia[incidencia.idEmpleado].push(fechaTexto);
        if (incidencia.vacaciones) {
          diasVacaciones++;
        } else if (incidencia.incapacidad) {
          diasIncapacidad++;
        } else if (incidencia.descanso) {
          diasDescanso++;
        } else if (incidencia.faltas) {
          diasFaltas++;
        } else {
          diasEnPeriodo++;
        }
      }

      this.incidenciaTotales[empleado].horasTrabajadas += incidencia.horasTrabajadas + incidencia.trabajoEnDescanso;
      this.incidenciaTotales[empleado].horasExtras += incidencia.horasExtras;
      this.incidenciaTotales[empleado].retardos += incidencia.retardos;
      this.incidenciaTotales[empleado].faltas += incidencia.faltas;
      this.incidenciaTotales[empleado].nombre = incidencia.nombre;
      this.incidenciaTotales[empleado].idEmpleado = incidencia.idEmpleado;
      this.incidenciaTotales[empleado].descanso += diasDescanso;
      this.incidenciaTotales[empleado].diasTrabajados += diasEnPeriodo;
      this.incidenciaTotales[empleado].incidencias += diasIncapacidad + diasVacaciones + diasFaltas;

      this.incidenciaTotales[empleado].horasTrabajadasTxt = fNumero(this.incidenciaTotales[empleado].horasTrabajadas, 'hm');
      this.incidenciaTotales[empleado].retardosTxt = fNumero(this.incidenciaTotales[empleado].retardos, 'hm');
      this.incidenciaTotales[empleado].horasExtrasTxt = fNumero(this.incidenciaTotales[empleado].horasExtras, 'hm');
      this.incidenciaTotales[empleado].faltasTxt = fNumero(this.incidenciaTotales[empleado].faltas, 'D2-');
    });
  }

  public validarEntradaSalida(incidencia: any): boolean {
    const entradas = incidencia.incidenciaHorario.filter((registro: any) => registro.horaEntrada);
    const salidas = incidencia.incidenciaHorario.filter((registro: any) => registro.horaSalida);
    return entradas.length >= 2 && salidas.length > 1;
  }


  private getHoraEnFormato24(fecha: Date | undefined): string {
    if (!fecha) {
      return '--';
    }
    const horas = fecha.getHours().toString().padStart(2, '0');
    const minutos = fecha.getMinutes().toString().padStart(2, '0');
    return `${horas}:${minutos}`;
  }

  // ======================================================================================================================== >>>>>>>>>>>>>>> Posibles ya no utilizadas



  ngOnInit(): void {
    this.empleadoSeleccionado = undefined;
    this.recargarEvent.subscribe(
      () => {
        this.accesoDatosService.logAgrega2('===>>>>  recargarEvent  >>>');
        this.cargarDatos();
      }
    );

    this.rangoPersonalizado.valueChanges.subscribe(values => {
      if (values.desde && values.hasta) {
        this.cambiarPeriodo();
      }
    });
  }
}
