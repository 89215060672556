import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { addDays, fFecha, inicioMesActual } from 'src/app/core/Funciones/fFecha';
import { fechaT } from 'src/app/core/Funciones/fTexto';
import { mostrarSwalError } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { IEmpleados, nombreEmpleado, Vacaciones, VacacionesEmpleado } from 'src/app/models/empleados';
import { IEmpresa } from 'src/app/models/empresa';
import { IAccesoIncidenciasUsuario, IAccesoIncidenciasUsuarioCargar, TipoManejoIncidencia } from 'src/app/models/incidencias';

@Component({
  selector: 'app-empleados-control-vacaciones',
  templateUrl: './empleados-control-vacaciones.component.html',
  styleUrls: ['./empleados-control-vacaciones.component.scss']
})
export class EmpleadosControlVacacionesComponent implements OnInit {
  public estaCargando: boolean = true;
  public esDebugger: boolean = false;
  public empleados: IEmpleados[] = [];
  public empleadosTotales: IEmpleados[] = [];
  public mostrarBajas: boolean = false;
  public inicioPeriodo: Date;
  public finPeriodo: Date;
  public empresa!: IEmpresa;
  public incidenciasUsuarioOriginal: IAccesoIncidenciasUsuario[] = [];
  public incidenciasUsuario: IAccesoIncidenciasUsuario[] = [];
  public vacacionesPorEmpleado: VacacionesEmpleado[] = [];
  public empleadoSeleccionado: IEmpleados | undefined = undefined;

  public dataSource: MatTableDataSource<VacacionesEmpleado> = new MatTableDataSource<VacacionesEmpleado>(this.vacacionesPorEmpleado);
  public displayedColumns: string[] = ['nombre', 'aniosTrabajados', 'diasLey', 'diasExtra', 'diasTomados', 'saldoTotal', 'saldoRestante'];

  constructor(
    private accesoDatosService: AccesoDatosService,
  ) {
    this.inicioPeriodo = inicioMesActual();
    this.finPeriodo = addDays(new Date(this.inicioPeriodo.getFullYear(), this.inicioPeriodo.getMonth() + 1, 1), -1);
    this.cargarDatos();
  }

  public cargarDatos() {
    this.estaCargando = true;
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empleadosListado().subscribe(
      (empleados: IEmpleados[]) => {
        this.empleados = empleados;
        this.empleadosTotales = empleados;
        this.aplicarFiltrosEmpleados();
        this.empleados.forEach(empleado => {
          (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
        });
        this.generarTablaVacaciones();
        this.dataSource.data = this.vacacionesPorEmpleado;
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        this.empleados = [];
        this.empleadosTotales = [];
      }
    );

    this.accesoDatosService.incidenciasUsuarioCargar({inicio: this.inicioPeriodo, inicioTxt: fechaT(this.inicioPeriodo), fin: this.finPeriodo, finTxt: fechaT(this.finPeriodo)}).subscribe(
      (respuesta: IAccesoIncidenciasUsuarioCargar) => {
        if (respuesta.code == 100) {
          this.empresa = respuesta.empresa;
          this.incidenciasUsuarioOriginal = respuesta.incidencias;
          this.aplicarFiltrosIncidencias();
          this.estaCargando = false;
          return;
        }
        mostrarSwalError('Validación de Incidencias', `Error al cargar las incidencias, respuesta del servidor: ${respuesta.code}, ${respuesta.mensaje}`)
      },
      (error) => {
        let err: string = JSON.stringify(error);
        this.accesoDatosService.logAgrega2(err);
        mostrarSwalError('Validación de Incidencias', `Error al cargar las incidencias, ${err}`);
      }
    );
  }

  public aplicarFiltrosEmpleados() {
    if (this.mostrarBajas) {
      this.empleados = this.empleadosTotales;
    }  else {
      this.empleados = this.empleadosTotales.filter(x => !x.baja || x.baja.getTime() <= new Date(1900, 1, 1).getTime()).sort(
        (a, b) => +a.numero - +b.numero || a.nombre?.toLowerCase().localeCompare(b.nombre?.toLowerCase())
      );
    }

    this.empleados.forEach(empleado => {
      (empleado as any)['nombreCompleto'] = `${empleado.nombre} ${empleado.paterno} ${empleado.materno}`;
    });

    this.empleados = this.empleados;
  }

  public aplicarFiltrosIncidencias() {
    this.incidenciasUsuario = this.incidenciasUsuarioOriginal.sort(
      (a, b) => b.fin.getTime() - a.fin.getTime() || a.inicio.getTime() - b.inicio.getTime() || a.nombreEmpleado.toLowerCase().localeCompare(b.nombreEmpleado.toLowerCase())
    );
  }

  public calcularDiasPorLey(fechaAlta: Date): number {
    const fechaActual = new Date();
    let aniosTrabajo = fechaActual.getFullYear() - fechaAlta.getFullYear();

    const mesActual = fechaActual.getMonth();
    const mesAlta = fechaAlta.getMonth();
    const diaActual = fechaActual.getDate();
    const diaAlta = fechaAlta.getDate();

    if (mesActual < mesAlta || (mesActual === mesAlta && diaActual < diaAlta)) {
      aniosTrabajo--;
    }

    if (aniosTrabajo < 1) return 0;

    if (aniosTrabajo === 1) return 12;
    if (aniosTrabajo === 2) return 14;
    if (aniosTrabajo === 3) return 16;
    if (aniosTrabajo === 4) return 18;
    if (aniosTrabajo === 5) return 20;
    if (aniosTrabajo >= 6 && aniosTrabajo <= 10) return 20 + (aniosTrabajo - 5) * 2;
    return 30 + Math.floor((aniosTrabajo - 10) / 5) * 2;
  }

  public generarTablaVacaciones() {
    this.vacacionesPorEmpleado = this.empleados.map((empleado) => {
      const fechaAlta = new Date(empleado.alta);
      const aniosTrabajo = new Date().getFullYear() - fechaAlta.getFullYear();
      const diasTomados = this.obtenerDiasTomados(+empleado.id);
      const diasLey = this.calcularDiasPorLey(fechaAlta);

      const vacaciones: Vacaciones[] = this.generarVacacionesEmpleado(aniosTrabajo, diasTomados, diasLey);
      return { empleado, vacaciones };
    });
  }

  private generarVacacionesEmpleado(aniosTrabajo: number, diasTomados: number, diasLey: number): Vacaciones[] {
    const vacaciones: Vacaciones[] = [];

    for (let i = 0; i < aniosTrabajo; i++) {
      const aniosTrabajados = aniosTrabajo;
      const diasTomadosTotal = diasTomados;
      const diasExtra = 0;
      const saldoTotal = diasLey + diasExtra;
      const saldoRestante = saldoTotal - diasTomadosTotal;

      vacaciones.push({ aniosTrabajados, diasLey, diasExtra, diasTomados: diasTomadosTotal, saldoTotal, saldoRestante });
    }

    return vacaciones;
  }

  public obtenerDiasTomados(idEmpleado: number): number {
    let diasTomados: number = 0;
    const incidenciasEmpleado = this.incidenciasUsuarioOriginal.filter(inc => inc.idEmpleado === idEmpleado && inc.tipoManejoIncidencia === 4);

    if (incidenciasEmpleado.length > 0) {
      incidenciasEmpleado.forEach(() => {
        diasTomados++;
      });
    }

    return diasTomados;
  }

  public actualizarSaldo(vacacionEmpleado: VacacionesEmpleado) {
    vacacionEmpleado.vacaciones.forEach((vacacion) => {
      vacacion.saldoTotal = vacacion.diasLey + vacacion.diasExtra;
      vacacion.saldoRestante = vacacion.saldoTotal - vacacion.diasTomados;
    });
  }

  public formatoFecha(fecha: Date | undefined){
    if (!fecha) return '-';
    return fFecha(fecha, "FSL")
  }

  public nombreCompleto(empleado: IEmpleados){
    return nombreEmpleado(empleado);
  }

  public buscarEmpleado(value: IEmpleados | string) {
    // let filterValue: string = '';

    // if (typeof value === 'string') {
    //   filterValue = value.toLowerCase().trim();
    // } else if (value && value.nombre) {
    //   filterValue = value.nombre.toLowerCase().trim();
    // }

    // if (filterValue) {
    //   this.empleados = this.empleadosTotales.filter(empleado =>
    //     empleado.nombre.toLowerCase().includes(filterValue)
    //   );
    //   this.dataSource.data = this.empleados;

    //   this.empleadoNoEncontrado = this.empleados.length === 0;
    // } else {
    //   this.aplicarFiltros();
    //   this.empleadoNoEncontrado = false;
    // }
  }

  public limpiarFiltro() {
    // this.empleadoNoEncontrado = false;
    // this.empleadoSeleccionado = undefined;
  }

  ngOnInit(): void {}
}
