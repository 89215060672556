import { Font, Workbook } from "exceljs";
import * as fs from 'file-saver';

export function creaArchivoExcel(datos: ArchivoExcel, nombreReporte: string) {
    let workbook = new Workbook();
    let worksheet = workbook.addWorksheet(datos.nombreArchivo);

    if (datos.títulos) {
        let titulo1 = worksheet.addRow([ datos.títulos.titulo1 ]);
        titulo1.font = { name: 'Trebuchet MS', size: 20, bold: true, color: { argb: 'FF303030' } };
        if (datos.títulos.titulo2) {
            let addressRow = worksheet.addRow([ datos.títulos.titulo2 ]);
            addressRow.font = { name: 'Trebuchet MS', size: 12, color: { argb: 'FF858585' } };
        }
        if (datos.títulos.titulo3) {
            let addressRow = worksheet.addRow([ datos.títulos.titulo3 ]);
            addressRow.font = { name: 'Trebuchet MS', size: 12, color: { argb: 'FF858585' } };
        }
        if (datos.títulos.titulo4) {
            let addressRow = worksheet.addRow([ datos.títulos.titulo4 ]);
            addressRow.font = { name: 'Trebuchet MS', size: 12, color: { argb: 'FF858585' } };
        }
        worksheet.addRow([]);
    }

    let font: Partial<Font> =  datos.fuenteTítulo || { color: { argb: 'FF005CB2' }, size: 12, name: 'Trebuchet MS', bold: true };
    let wsRow = worksheet.addRow(datos.títuloColumnas);
    wsRow.height = 40;
    let col = 0;
    wsRow.eachCell(cell => {
        font = font;
        cell.border = { bottom: { style: 'thick', color: { argb: 'FF005CB2' } } };
        cell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'FFE3F2FD' } };
        cell.alignment = { vertical: "middle", horizontal: "center", wrapText: true };
        let w = 25;
        if (datos.columnas && datos.columnas[col]) w = datos.columnas[col].width;
        worksheet.columns[col].width = w;
        col++;
    });

    font =  datos.fuenteRenglones || { color: { argb: 'FF303030' }, size: 12, name: 'Trebuchet MS', bold: false };
    datos.renglones.forEach(renglón => {
            wsRow = worksheet.addRow(renglón.datos);
            wsRow.font = font;
            col = 0;
            wsRow.eachCell(cell => {
                if (datos.columnas[col]?.alignment) {
                    const { vertical, horizontal, wrapText } = datos.columnas[col].alignment;
                    cell.alignment = { vertical, horizontal, wrapText };
                }

                if (renglón.estilo) {
                  if (renglón.estilo.font) {
                      cell.font = renglón.estilo.font;
                  }
                  if (renglón.estilo.fill) {
                      cell.fill = renglón.estilo.fill;
                  }
                  if (renglón.estilo.alignment) {
                      cell.alignment = renglón.estilo.alignment;
                  }
              }
                col++;
            })
        }
    );

    font =  datos.fuenteTotales || { color: { argb: 'FF303030' }, size: 12, name: 'Trebuchet MS', bold: true };
    wsRow = worksheet.addRow(datos.totales);
    col = 0;
    wsRow.eachCell(cell => {
        if (datos.columnas[col]?.alignment) {
            const { vertical, horizontal, wrapText } = datos.columnas[col].alignment;
            cell.alignment = { vertical, horizontal, wrapText };
        }
        col++;
    });

    workbook.xlsx.writeBuffer().then((data) => {
        let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
        fs.saveAs(blob, datos.nombreArchivo);
    });
}

export interface ArchivoExcel {
    nombreArchivo: string;
    títulos: TítulosExcel | undefined;
    columnas: ColumnaExcel[];
    títuloColumnas: string[];
    renglones: RenglonesExcel[];
    totales: string[];
    fuenteTítulo: { color: { argb: string }, size: number, name: string, bold: boolean } | undefined;
    fuenteRenglones: { color: { argb: string }, size: number, name: string, bold: boolean } | undefined;
    fuenteTotales: { color: { argb: string }, size: number, name: string, bold: boolean } | undefined;
}

export interface TítulosExcel {
    titulo1: string;
    titulo2: string | undefined;
    titulo3: string | undefined;
    titulo4: string | undefined;
}

export interface ColumnaExcel {
    header: string;
    key: string;
    width: number;
    alignment: {
        vertical: "top" | "bottom" | "middle" | "distributed" | "justify" | undefined,
        horizontal: "left" | "right" | "distributed" | "justify" | "center" | "fill" | "centerContinuous" | undefined,
        wrapText: boolean
    };
}

export interface RenglonesExcel {
    datos: any[];
    estilo?: {
      font?: Partial<Font>;
      fill?: {
          type: 'pattern';
          pattern: 'solid';
          fgColor: { argb: string };
      };
      alignment?: {
          vertical?: "top" | "bottom" | "middle" | "distributed" | "justify" | undefined;
          horizontal?: "left" | "right" | "distributed" | "justify" | "center" | "fill" | "centerContinuous" | undefined;
          wrapText?: boolean;
      };
  };
}
