<div class="d-flex justify-content-between align-items-center px-3 border-bottom border-light shadow-sm mb-3 modal-header-fixed">
  <h2 class="mb-0 ms-2 fw-bold">Control de vacaciones</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon fontIcon="close"></mat-icon>
  </button>
</div>

<section class="row g-3 mx-4 mb-3">
  <div class="col-6">
    <label class="mb-0 text-start">Filtrar por empleado</label>
    <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" (ngModelChange)="buscarEmpleado($event)" (clear)="limpiarFiltro()" notFoundText="Empleado no encontrado" placeholder="Búsqueda por empleado">
      <ng-template ng-option-tmp let-item="item">
        {{ nombreCompleto(item) }}
      </ng-template>
    </ng-select>
  </div>
</section>

<section class="mat-elevation-z8 col-11 mx-auto mb-3 scroll" tabindex="0" style="height: 70vh; overflow: auto;">
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="nombre">
      <th mat-header-cell *matHeaderCellDef>Empleado</th>
      <td mat-cell *matCellDef="let item"> {{nombreCompleto(item.empleado)}} </td>
    </ng-container>

    <ng-container matColumnDef="aniosTrabajados">
      <th mat-header-cell *matHeaderCellDef>Años trabajados</th>
      <td mat-cell *matCellDef="let item" class="text-center"> {{item.vacaciones[0]?.aniosTrabajados}} </td>
    </ng-container>

    <ng-container matColumnDef="diasLey">
      <th mat-header-cell *matHeaderCellDef>Días por ley</th>
      <td mat-cell *matCellDef="let item" class="text-center"> {{item.vacaciones[0]?.diasLey}} </td>
    </ng-container>

    <ng-container matColumnDef="diasExtra">
      <th mat-header-cell *matHeaderCellDef>Días extra</th>
      <td mat-cell *matCellDef="let item" class="text-center">
        <!-- <input type="number" [(ngModel)]="item.vacaciones[0].diasExtra" (input)="actualizarSaldo(item)" /> -->
      </td>
    </ng-container>

    <ng-container matColumnDef="diasTomados">
      <th mat-header-cell *matHeaderCellDef>Días tomados</th>
      <td mat-cell *matCellDef="let item" class="text-center"> {{item.vacaciones[0]?.diasTomados}} </td>
    </ng-container>

    <ng-container matColumnDef="saldoTotal">
      <th mat-header-cell *matHeaderCellDef>Saldo total</th>
      <td mat-cell *matCellDef="let item" class="text-center"> {{item.vacaciones[0]?.saldoTotal}} </td>
    </ng-container>

    <ng-container matColumnDef="saldoRestante">
      <th mat-header-cell *matHeaderCellDef>Saldo restante</th>
      <td mat-cell *matCellDef="let item" class="text-center"> {{item.vacaciones[0]?.saldoRestante}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</section>
