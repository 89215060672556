<div *ngIf="!data.agregarHorario">
  <div class="d-flex justify-content-between align-items-center px-3 border-bottom border-light shadow-sm">
    <h2 class="modal-title mb-0 ms-2 fw-bold">Cubrir turno del dia {{ formatoFecha(data.incidencia.fecha) }}</h2>
    <button mat-icon-button mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="mx-3 mb-3">
    <div>
      <h3 class="mt-3 mb-0 fw-medium">Horario del empleado {{ data.incidencia.nombre }}</h3>
      <app-horario-detalle [soloConsulta]="true" [horario]="data.horario" [horarioEmpleado]="true" [diaIncidencia]="data.diaIncidencia"></app-horario-detalle>
    </div>

    <hr class="border border-secondary">

    <div>
      <div class="mb-2">
        <label class="mb-0 text-start">Selecciona el empleado con el que se cambiara de turno</label>
        <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" notFoundText="Empleado no encontrado" (ngModelChange)="obtenerHorario()">
          <ng-template ng-option-tmp let-item="item">
            {{ nombreCompleto(item) }}
          </ng-template>
        </ng-select>
      </div>
      <div *ngIf="empleadoSeleccionado">
        <app-horario-detalle [soloConsulta]="true" [horario]="horarioSeleccionadoEmpleado" [horarioEmpleado]="true" [diaIncidencia]="data.diaIncidencia"></app-horario-detalle>
      </div>
    </div>

    <div class="d-flex pt-2 justify-content-end">
      <button mat-raised-button class="bg-success text-white" (click)="aplicarCambioTurno()" [disabled]="!empleadoSeleccionado">Cambiar turno</button>
      <button mat-raised-button mat-dialog-close class="bg-danger text-white mx-1">Cancelar</button>
    </div>
  </div>
</div>

<div *ngIf="data.agregarHorario">
  <div class="d-flex justify-content-between align-items-center px-3 border-bottom border-light shadow-sm">
    <h2 class="modal-title mb-0 ms-2 fw-bold">Asignar nuevo horario a {{ data.incidencia.nombre }}</h2>
    <button mat-dialog-close mat-icon-button><mat-icon>close</mat-icon></button>
  </div>

  <div class="mx-4 my-3">
    <app-horario-detalle [horario]="data.horario" [horarioEmpleado]="true" [diaIncidencia]="data.diaIncidencia"></app-horario-detalle>
  </div>

  <div class="d-flex m-2 justify-content-end">
    <button mat-raised-button class="bg-success text-white" (click)="aplicarAsignarHorario()">Asignar horario</button>
    <button mat-raised-button mat-dialog-close class="bg-danger text-white mx-1">Cancelar</button>
  </div>
</div>
