<div class="d-flex justify-content-between align-items-center px-3 border-bottom border-light shadow-sm mb-3 modal-header-fixed">
  <h2 class="mb-0 ms-2 fw-bold">Control de vacaciones</h2>
  <button mat-icon-button mat-dialog-close>
    <mat-icon fontIcon="close"></mat-icon>
  </button>
</div>

<div *ngIf="estaCargando">
  <div class="text-center pt-5 mt-5">
    <mat-spinner class="img-center"></mat-spinner>
  </div>
  <div class="text-center py-3">
    <span>Cargando datos...</span>
  </div>
</div>

<ng-container *ngIf="!estaCargando">
  <div class="row g-3 mx-2 mb-3">
    <div class="col-6 d-flex">
      <div class="col-10 me-1">
        <label class="mb-0 text-start">Filtrar por empleado</label>
        <ng-select [items]="empleados" [(ngModel)]="empleadoSeleccionado" bindLabel="nombreCompleto" (ngModelChange)="generarTablaVacaciones()" (clear)="limpiarFiltro()" notFoundText="Empleado no encontrado" placeholder="Búsqueda por empleado">
          <ng-template ng-option-tmp let-item="item">
            {{ nombreCompleto(item) }}
          </ng-template>
        </ng-select>
      </div>
      <div class="col-2 mt-auto">
        <button class="text-danger-emphasis mx-2" mat-raised-button matTooltip="Exportar" (click)="exportarPDF()">
          <mat-icon class="m-0">picture_as_pdf</mat-icon>
        </button>
      </div>
    </div>
    <div class="col-6 d-flex justify-content-end align-items-end" *ngIf="esDebugger">
      <div class="form-check align-self-end mx-1">
        <input class="form-check-input" type="checkbox" id="check" [(ngModel)]="manejoDiasAdicionales">
        <label class="form-check-label fw-bold ms-1" for="check">Manejo de días con goce de sueldo</label>
      </div>
      <button mat-raised-button (click)="modificarDias()" class="text-primary mx-3" *ngIf="manejoDiasAdicionales && !modificando">
        <mat-icon fontIcon="edit"></mat-icon>Modificar
      </button>
      <button mat-raised-button (click)="guardarDias()" class="text-success mx-1" *ngIf="manejoDiasAdicionales && modificando">
        <mat-icon fontIcon="save"></mat-icon>Guardar
      </button>
    </div>
  </div>

  <div class="mt-3 scroll mx-3" style="height: 59vh; overflow: auto;">
    <table class="table table-borderless table-striped table-hover">
      <thead class="bg-secondary-subtle" style="position: sticky; top: 0; z-index: 100;">
        <tr>
          <th scope="col" *ngIf="!empleadoSeleccionado">Nombre</th>
          <th scope="col" *ngIf="empleadoSeleccionado">Inicio</th>
          <th scope="col" *ngIf="empleadoSeleccionado">Terminación</th>
          <th scope="col" *ngIf="empleadoSeleccionado">Año</th>
          <th scope="col" *ngIf="!empleadoSeleccionado">Años trabajando</th>
          <th scope="col">Días por ley</th>
          <th scope="col">Días tomados</th>
          <th scope="col" style="width: 125px;" *ngIf="manejoDiasAdicionales">Días adicionales</th>
          <th scope="col" style="width: 125px;" *ngIf="manejoDiasAdicionales">Con goce de sueldo</th>
          <th scope="col">Saldo total</th>
          <th scope="col">Saldo restante</th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngIf="!empleadoSeleccionado; else empleadoSeleccionadoContainer">
          <ng-container *ngFor="let empleado of vacacionesEmpleadoObs | async; let empleadoIndex = index">
            <tr>
              <td class="text-start align-middle">{{ nombreCompleto(empleado.empleado) }}</td>
              <td class="text-center align-middle">{{ empleado.vacaciones[0]?.aniosTrabajados }}</td>
              <td class="text-center align-middle">{{ empleado.vacaciones[0]?.diasLey }}</td>
              <td class="text-center align-middle">{{ empleado.vacaciones[0]?.diasTomados }}</td>
              <td class="text-center" style="width: 125px;" *ngIf="manejoDiasAdicionales">
                <input type="number" class="form-control bg-light" [class.bg-white]="modificando" [(ngModel)]="empleado.vacaciones[0].diasAdicionales" (ngModelChange)="actualizarVacaciones(empleadoIndex, 0)" [readOnly]="!modificando" style="border: none; padding: 3px; border-bottom: 1px solid navy; border-radius: inherit;">
              </td>
              <td class="text-center" style="width: 125px;" *ngIf="manejoDiasAdicionales">
                <input type="number" class="form-control bg-light" [class.bg-white]="modificando" [(ngModel)]="empleado.vacaciones[0].diasConGoceSueldo" (ngModelChange)="actualizarVacaciones(empleadoIndex, 0)" [readOnly]="!modificando" style="border: none; padding: 3px; border-bottom: 1px solid navy; border-radius: inherit;">
              </td>
              <td class="text-center align-middle">{{ empleado.vacaciones[0]?.saldoTotal }}</td>
              <td class="text-center align-middle">{{ empleado.vacaciones[0]?.saldoRestante }}</td>
            </tr>
          </ng-container>
        </ng-container>

        <ng-template #empleadoSeleccionadoContainer>
          <ng-container *ngFor="let empleado of vacacionesEmpleadoObs | async; let empleadoIndex = index">
            <ng-container *ngIf="empleadoSeleccionado && empleado.empleado.id === empleadoSeleccionado.id">
              <tr *ngFor="let vacacion of empleado.vacaciones; let vacacionIndex = index">
                <td class="text-center align-middle">{{ formatoFecha(vacacion.inicioPeriodo) }}</td>
                <td class="text-center align-middle">{{ formatoFecha(vacacion.finPeriodo) }}</td>
                <td class="text-center align-middle">{{ vacacion.anio }}</td>
                <td class="text-center align-middle">{{ vacacion.diasLey }}</td>
                <td class="text-center align-middle">{{ vacacion.diasTomados }}</td>
                <td class="text-center" style="width: 125px;" *ngIf="manejoDiasAdicionales">
                  <input type="number" class="form-control bg-light" [class.bg-white]="modificando" [(ngModel)]="empleado.vacaciones[vacacionIndex].diasAdicionales" (ngModelChange)="actualizarVacaciones(empleadoIndex, vacacionIndex)" [readOnly]="!modificando" style="border: none; padding: 3px; border-bottom: 1px solid navy; border-radius: inherit;">
                </td>
                <td class="text-center" style="width: 125px;" *ngIf="manejoDiasAdicionales">
                  <input type="number" class="form-control bg-light" [class.bg-white]="modificando" [(ngModel)]="empleado.vacaciones[vacacionIndex].diasConGoceSueldo" (ngModelChange)="actualizarVacaciones(empleadoIndex, vacacionIndex)" [readOnly]="!modificando" style="border: none; padding: 3px; border-bottom: 1px solid navy; border-radius: inherit;">
                </td>
                <td class="text-center align-middle">{{ vacacion.saldoTotal }}</td>
                <td class="text-center align-middle">{{ vacacion.saldoRestante }}</td>
              </tr>
            </ng-container>
          </ng-container>
        </ng-template>
      </tbody>
    </table>
  </div>
</ng-container>

<div [hidden]="estaCargando">
  <div class="d-flex justify-content-between mx-3">
    <div class="form-check align-self-end">
      <input class="form-check-input" type="checkbox" id="mostrarBajasIncidenciasCheck" [(ngModel)]="mostrarBajas" (change)="aplicarFiltrosEmpleados()">
      <label class="form-check-label fw-bold ms-1" for="mostrarBajasIncidenciasCheck">Mostrar empleados dados de baja</label>
    </div>

    <mat-paginator
      [showFirstLastButtons]="true"
      [length]="dataSource.data.length"
      [pageSizeOptions]="[20, 50, 80, 100, dataSource.data.length]">
    </mat-paginator>
  </div>
</div>
