import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { fFecha } from 'src/app/core/Funciones/fFecha';
import { mostrarMensajeSnack, mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { HorariosSemana, IHorarioDetalle, IHorarios, RangoHora, nuevoDetalle, nuevoHorario, nuevoHorarioPorDia, nuevoRangoHora } from 'src/app/models/horarios';
import { IRespuestaChecker } from 'src/app/models/resultadoActualiza';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-horario-detalle',
  templateUrl: './horario-detalle.component.html',
  styleUrls: ['./horario-detalle.component.scss']
})
export class HorarioDetalleComponent implements OnInit {
  @Input()
  public horario: IHorarios = nuevoHorario();

  @Input()
  public soloConsulta: boolean = false;

  @Input()
  public horarioEmpleado: boolean = false;

  @Output()
  public recargarDatosEvent = new EventEmitter<boolean>();

  @Output()
  public isModificandoEvent = new EventEmitter<string>();

  @Input()
  public diaIncidencia: string = '';

  public semanaDias: string[] = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado',];

  public editandoHorario: boolean = false;
  public aplicarTodos: boolean = false;
  public cargando: boolean = false;
  public guardando: boolean = false;
  public horarioNocturno: boolean = false;
  public valorCero: number = 0;
  public esDebugger: boolean = false;

  constructor(
    private accesoDatosService: AccesoDatosService,
    private readonly _router: Router,
    private _snackBar: MatSnackBar
  ) {  }

  public cargarHorario() {
    this.cargando = true;
    if (!this.horario.idHorario) {
      this.horario = nuevoHorario();
      setInterval(() => { this.cargando = false; }, 300);
      return;
    }
    this.accesoDatosService.horarioEmpleado(this.horario.id).subscribe(
      (horarioEmpleado: IHorarios) => {
        this.editandoHorario = false;
        this.horario = horarioEmpleado;
        setInterval(() => { this.cargando = false; }, 300);
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
        console.error(error);
        setInterval(() => { this.cargando = false; }, 300);
      }
    );
    this.isModificandoEvent.emit('-');
  }

  public deshacerCambios() {
    if (this.horarioEmpleado) {
      this.cargarHorario();
      return;
    }
    this.recargarDatosEvent.emit(true);
  }

  public formatoFecha(fecha: Date | undefined) {
    if (!fecha) {
      return '-';
    }
    return fFecha(fecha, 'FSL');
  }

  public formatearHora(value: number | undefined): string {
    if (!value || value == 24) return '12:00 AM';
    let horas = Math.floor(value);
    if (horas > 24) horas = horas - 24;
    const minutos = (value % 1) * 60;
    const amPm = horas >= 12 ? 'PM' : 'AM';
    const horas12 = horas === 0 ? 12 : horas > 12 ? horas - 12 : horas;
    const minutosRedondeados = Math.round(minutos / 15) * 15;

    return `${horas12.toString().padStart(2, '0')}:${minutosRedondeados.toString().padStart(2, '0')} ${amPm}`; //  ${value > 24 ? ' * Horario nocturno' : ''}
  }

  public eliminarHorario() {
    this.cargando = true;
    Swal.fire({
      html: `¿Estás seguro de que deseas eliminar el horario?`,
      icon: "warning",
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Aceptar"
    }).then((result) => {
      if (result.isConfirmed) {
        if (this.horario.id == 0 && this.horario.idEmpleado == 0) {
          this.accesoDatosService.horarioEliminar(this.horario.idHorario || '-').subscribe(
            (respuesta: IRespuestaChecker) => {
              if (respuesta.code === 100) {
                mostrarSwalToast('Modificaciones realizas con éxito.', 'info');
                if (!this.horario.idHorario) {
                  this.recargarDatosEvent.emit(true);
                }
                this.cargarHorario;
                return;
              }
              mostrarSwalError('Error', `Respuesta ${respuesta.code}, mensaje: ${respuesta.mensaje}`);
            },
            (error) => {
              this.accesoDatosService.logAgrega2("Error al eliminar el horario");
              console.error(error);
            }
          );
          return;
        }
        this.accesoDatosService.horarioEliminarAsignacion(this.horario.id).subscribe(
          (respuesta: IRespuestaChecker) => {
            if (respuesta.code === 100) {
              mostrarSwalToast('Modificaciones realizas con éxito.', 'info');
              this.recargarDatosEvent.emit(true);
              this.cargarHorario;
              this.cargando = false;
              this.editandoHorario = false;
              // this._router.navigate(['inicio-empresa']);
              return;
            }
            this.cargando = false;
            mostrarSwalError('Error', `Respuesta ${respuesta.code}, mensaje: ${respuesta.mensaje}`);
          },
          (error) => {
            this.cargando = false;
            this.accesoDatosService.logAgrega2("Error al cargar los datos de los empleados");
            console.error(error);
            mostrarSwalError('Error al eliminar el horario', `Respuesta ${error.mensaje}`);
          }
        );
      }
    });

  }

  public guardarHorario() {
    if (!this.horario.nombreHorario) {
      mostrarSwalToast('El horario necesita tener un nombre para poder guardarlo', 'warning');
      return;
    }

    this.cargando = true;

    for (let index = 0; index < this.horario.detalles.length; index++) {
      const detalle = this.horario.detalles[index];
      for (let i = 0; i < this.semanaDias.length; i++) {
        const dia = this.semanaDias[i];
        let horaSalidaAnterior: number = 0;
        for (let j = 0; j < detalle.horariosSemana[dia].length; j++) {
          const horaEntrada = detalle.horariosSemana[dia][j].horaEntrada;
          const horaSalida = detalle.horariosSemana[dia][j].horaSalida;

          if (!horaEntrada) continue;
          if (!horaSalida) {
            mostrarSwalToast(`No existe hora de salida para el horario del dia ${dia}`, 'warning');
            this.cargando = false;
          }

          // console.log(dia, horaEntrada);


          if (horaEntrada > 24) {
            mostrarSwalToast(`Deberás activar el horario nocturno si deseas usar este horario`, 'warning');
            this.cargando = false;
            return;
          }
          if (horaSalidaAnterior > horaEntrada) {
            mostrarSwalToast(`Existe una inconsistencia en el horario del día: ${dia}, en el valor ${j + 1}, la hora de salida anterior es mayor a la hora de ingreso`, 'warning');
            this.cargando = false;
            return;
          }
          if (horaEntrada > horaSalida) {
            mostrarSwalToast(`Existe una inconsistencia en el horario del día: ${dia}, en el valor ${j + 1}, la hora de entrada es mayor que la hora de salida`, 'warning');
            this.cargando = false;
            return;
          }
          if (i == 0 && horaEntrada >= 24) {
            mostrarSwalToast(`No se puede asignar como hora de entrada la media noche o posterior`, 'warning');
            this.cargando = false;
            return;
          }
          horaSalidaAnterior = horaSalida;
        }
      }
    }

    this.cargando = true;
    this.accesoDatosService.horarioGuardar(this.horario).subscribe(
      (respuesta: IRespuestaChecker) => {
        if (respuesta.code == 100) {
          mostrarSwalToast(`Horario guardado correctamente`, 'success');
          if (!this.horario.idHorario || !this.horarioEmpleado) {
            this.recargarDatosEvent.emit(true);
          } else {
            this.cargarHorario();
          }
          return;
        }
        mostrarSwalError('Error', `Respuesta: ${respuesta.code}, mensaje: ${respuesta.mensaje}`);
      },
      (error) => {
        this.accesoDatosService.logAgrega2("Error al guardar el horario");
        console.error(error);
        mostrarSwalError('Error', `Error al guardar el horario`);
      }
    );
  }

  public editarHorario() {
    if (!this.horario.esModificable || this.soloConsulta) {
      mostrarSwalError('Error', `No es posible modificar este horario, es posible que ya se encuentre en uso en un reporte de incidencias`);
      return;
    }
    this.isModificandoEvent.emit(this.horario.idHorario);
    this.editandoHorario = true;
  }

  public isDiaDescanso(detalle: IHorarioDetalle, dia: string): boolean {
    if (detalle.diasDescanso.includes(dia)) {
      detalle.horariosSemana[dia] = [];
      return true;
    }

    if (!detalle.horariosSemana[dia] || detalle.horariosSemana[dia].length == 0) {
      detalle.horariosSemana[dia].push(nuevoRangoHora());
    }

    return false;
  }

  public agregarRangoHora(detalle: IHorarioDetalle, indexDia: string, indexSemana: number) {
    if (this.isDiaDescanso(detalle, indexDia)) {
      mostrarSwalToast('No se pueden agregar rangos de horario a los días de descanso.', 'info');
      return;
    }

    const horarioDia = detalle.horariosSemana[indexDia];
    if (!horarioDia || horarioDia.length == 0) {
      horarioDia.push({ horaEntrada: 9, horaSalida: 16, nocturno: false });
      return;
    }

    if (horarioDia.length >= 4) {
      mostrarSwalToast('No puedes agregar más de 4 rangos.', 'info');
      return;
    }

    let ultimoHorarioDia: RangoHora = horarioDia[horarioDia.length - 1];
    if (ultimoHorarioDia.nocturno) {
      mostrarSwalToast('No puedes agregar rangos de horario cuando existe un horario nocturno.', 'warning');
      return;
    }

    if (ultimoHorarioDia.horaSalida >= 22) {
      mostrarSwalToast('No puedes agregar rangos de horario debido a que el ultimo rango es después de las 10:00pm.', 'warning');
      return;
    }

    // this.cargando = true;
    horarioDia.push({ horaEntrada: ultimoHorarioDia.horaSalida + 1, horaSalida: ultimoHorarioDia.horaSalida + 2, nocturno: false });

    this.actualizarTodosRangos(indexDia, indexSemana);
    // setInterval(() => { this.cargando = false; }, 300);
  }

  public removerRangoHora(detalle: IHorarioDetalle, indexDia: string, indexSemana: number) {
    if (this.isDiaDescanso(detalle, indexDia)) {
      mostrarSwalToast('No se pueden remover rangos de horario a los días de descanso.', 'warning');
      return;
    }

    if (!detalle.horariosSemana[indexDia] || detalle.horariosSemana[indexDia].length <= 1) {
      mostrarSwalToast('No puedes eliminar más rangos', 'warning');
      return;
    }

    this.cargando = true;
    const horarioDia = detalle.horariosSemana[indexDia];
    let ultimoHorarioDia: RangoHora = horarioDia[horarioDia.length - 1];
    if (ultimoHorarioDia.nocturno) {
      let ultimoHorarioDia: RangoHora = horarioDia[horarioDia.length - 2];
      ultimoHorarioDia.nocturno = false;
    }

    horarioDia.pop();
    this.actualizarTodosRangos(indexDia, indexSemana);
    setInterval(() => { this.cargando = false; }, 300);
  }

  private actualizarTodosRangos(indexDia: string, indexSemana: number) {
    if (!this.aplicarTodos) return;
    for (let dia of this.semanaDias) {
      if (dia === indexDia || this.horario.detalles[indexSemana].diasDescanso.includes(dia)) continue;

      this.horario.detalles[indexSemana].horariosSemana[dia] = [...this.horario.detalles[indexSemana].horariosSemana[indexDia]];

      // while (this.horario.detalles[indexSemana].horariosSemana[dia].length > this.horario.detalles[indexSemana].horariosSemana[indexDia].length) {
      //   this.horario.detalles[indexSemana].horariosSemana[dia].pop();
      // }

      // let rangos: number = this.horario.detalles[indexSemana].horariosSemana[indexDia].length;
      // while (this.horario.detalles[indexSemana].horariosSemana[dia].length < this.horario.detalles[indexSemana].horariosSemana[indexDia].length) {
      //   rangos = this.horario.detalles[indexSemana].horariosSemana[dia].length;
      //   this.horario.detalles[indexSemana].horariosSemana[dia].push(this.horario.detalles[indexSemana].horariosSemana[indexDia][rangos]);
      // }

      // rangos = this.horario.detalles[indexSemana].horariosSemana[indexDia].length;
      // for (let indexRango = 0; indexRango < rangos; indexRango++) {
      //   const horaSalida = this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].horaSalida;
      //   const horaEntrada = this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].horaEntrada;
      //   const nocturno = this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].nocturno;
      //   this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].horaEntrada = horaEntrada;
      //   this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].horaSalida = horaSalida;
      //   this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].nocturno = nocturno;
      // }
    }
  }

  public onInputChangeValorCero(event: Event) {
    console.log(`onInputChangeValorCero ${event.target}`);
    this.valorCero = 0;
  }

  public onInputChangeHoraEntrada(event: any) {
    console.log(`onInputChangeHoraEntrada ${event.value}`);

  }

  public esHorarioNocturno() {
    // console.log(`Inicial horario nocturno: ${this.horario.nocturno}, ${this.horarioNocturno}`);
    if (!this.horario.nocturno) {
      this.horario.nocturno = true;
      this.horarioNocturno = true;
      // console.log(`Final (1) horario nocturno: ${this.horario.nocturno}, ${this.horarioNocturno}`);
      return;
    }

    for (let index = 0; index < this.horario.detalles.length; index++) {
      const horarioDetalle = this.horario.detalles[index];
      // console.log(`horarioDetalle. Index: ${index}`);
      for (const key in horarioDetalle.horariosSemana) {
        // console.log(`horariosSemana. Key: ${key}`);
        if (horarioDetalle.horariosSemana.hasOwnProperty(key)) {
          const horarioDia = horarioDetalle.horariosSemana[key];
          // console.log(`horariosDia. Existe: ${!!horarioDia}`);
          if (!horarioDia || horarioDia.length <= 0) continue;

          console.log(`horariosDia. Cant: ${horarioDia.length}`);
          if (horarioDia[horarioDia.length - 1].nocturno) {
            this.horarioNocturno = true;
            mostrarSwalToast('Existen horarios con trabajo nocturno, primero debe eliminarlos para desactivar el horario nocturno', 'warning');
            // console.log(`Final (error) horario nocturno: ${this.horario.nocturno}, ${this.horarioNocturno}`);
            return;
          }
        }
      }
    }

    this.horario.nocturno = false;
    this.horarioNocturno = false;
    console.log(`Final (2) horario nocturno: ${this.horario.nocturno}, ${this.horarioNocturno}`);
  }

  public cambiarHorarioNocturno(detalle: IHorarioDetalle, indexDia: string, indexSemana: number) {
    let horarioDia: RangoHora[] = detalle.horariosSemana[indexDia];

    if (!horarioDia || horarioDia.length == 0) {
      mostrarSwalToast('No es posible asignar horario nocturno a un dia de descanso', 'warning');
      return;
    }

    if (horarioDia.length >= 4) {
      mostrarSwalToast(`No es posible agregar más movimientos al día ${indexDia}`, 'warning');
      return;
    }

    this.cargando = true;
    let nuevoHorarioDia: RangoHora = { nocturno: true, horaEntrada: 0, horaSalida: 6 };
    if (horarioDia.length < 2) { // si solo hay un movimiento no puede tener horario nocturno
      horarioDia[0].nocturno = true;
      horarioDia[0].horaSalida = 24;
      horarioDia.push(nuevoHorarioDia);
      this.actualizarTodosRangos(indexDia, indexSemana);
      setInterval(() => { this.cargando = false; }, 300);
      return;
    }
    let horarioInicioNocturno: RangoHora = horarioDia[horarioDia.length - 2];
    if (horarioInicioNocturno.nocturno) {
      horarioInicioNocturno.nocturno = false;
      horarioInicioNocturno = horarioDia[horarioDia.length - 1];
      horarioDia.pop();
      this.actualizarTodosRangos(indexDia, indexSemana);
      setInterval(() => { this.cargando = false; }, 300);
      return;
    }

    horarioDia[horarioDia.length - 1].nocturno = true;
    horarioDia[horarioDia.length - 1].horaSalida = 24;
    horarioDia.push(nuevoHorarioDia);
    this.actualizarTodosRangos(indexDia, indexSemana);
    setInterval(() => { this.cargando = false; }, 300);
  }

  public agregarSemana() {
    if (this.horario.detalles.length > 1) {
      mostrarSwalToast('Solo es posible tener horarios de 2 semanas', 'warning');
      return;
    }

    this.cargando = true;
    this.horario.detalles.push(nuevoDetalle());
    setInterval(() => { this.cargando = false; }, 300);
  }

  public eliminarSemana() {
    if (this.horario.detalles.length <= 1) {
      mostrarSwalToast('No es posible eliminar el horarios de la semana 1', 'error');
      return;
    }

    Swal.fire({
      html: `¿ Desea eliminar el horario de la segunda semana ?`,
      icon: 'question',
      showCancelButton: true,
      cancelButtonText: "Cancelar",
      confirmButtonText: "Eliminar",
      cancelButtonColor: "#0861e5",
      confirmButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        this.cargando = true;
        this.horario.detalles.pop();
        setInterval(() => { this.cargando = false; }, 300);
      }
    });
  }

  public validarFechaHorario(fecha: Date): boolean {
    const fechaPorDefecto = new Date(1900, 0, 1);
    const fechaMaxima = new Date(2500, 0 ,1);

    if (fecha > fechaPorDefecto && fecha < fechaMaxima) return true;
    // console.log(`fecha ${fecha}, ${fecha > fechaPorDefecto}, ${fecha < fechaMaxima}`);


    return false;
  }

  public actualizarSliders(indexDia: string, indexSemana: number, indexRango: number, aplicarTodosLocal: boolean) {
    if (aplicarTodosLocal) {
      const horaSalida = this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].horaSalida;
      const horaEntrada = this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].horaEntrada;
      const nocturno  = this.horario.detalles[indexSemana].horariosSemana[indexDia][indexRango].nocturno;
      for (let dia of this.semanaDias) {
        if (dia === indexDia || this.horario.detalles[indexSemana].diasDescanso.includes(dia)) continue;
        this.horario.detalles[indexSemana].horariosSemana[dia][indexRango].horaEntrada = horaEntrada;
        this.horario.detalles[indexSemana].horariosSemana[dia][indexRango].horaSalida = horaSalida;
        this.horario.detalles[indexSemana].horariosSemana[dia][indexRango].nocturno = nocturno;
      }
    }
  }

  ngOnInit(): void {
    this.horarioNocturno = this.horario.nocturno;
    this.editandoHorario = (!this.horario.idHorario || this.horario.idHorario == '-') && !this.soloConsulta;
    this.esDebugger = !environment.production;

    if (this.diaIncidencia) {
     this.semanaDias = this.semanaDias.filter(dia => dia === this.diaIncidencia);
    }
  }

}
