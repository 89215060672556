import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { Ahora, CVFechaT, TipoDateDiff, ValidarHora, addDays, addHours, addSeconds, dateDiff, dateDiffAhora, fFecha, obtenerValorFecha } from 'src/app/core/Funciones/fFecha';
import { AccessHubService, IConnectionState, SecretValidation, TimeToken } from 'src/app/core/services/accessHubService';
import { environment } from 'src/environments/environment';
import { MatDialog } from '@angular/material/dialog';
import { PasswordComponent } from 'src/app/core/components/password/password.component';
import { AccesoExpress, OpcionesFecha } from 'src/app/models/accesoExpress';
import { ManejoDescarga, NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { ConfiguraBasicaComponent } from 'src/app/core/components/configura-basica/configura-basica.component';
import { fechaUTC, sha256 } from 'src/app/core/Funciones/fTexto';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, Subject, Subscription, throwError } from 'rxjs';
import { ITokenAccess } from 'src/app/models/tokenAccess';
import { ICodigoQr } from 'src/app/models/codigoQr';
import { IRespuestaChecker, IResultadoActualiza } from 'src/app/models/resultadoActualiza';
import { IEmpleados, TipoEmpleado, nombreEmpleado, nombreEmpleadoCorto, nuevoEmpleado } from 'src/app/models/empleados';
import { IAcceso, ITokenValidation } from 'src/app/models/checks';
import { getToken, getTokenCodeTimeComponents, getUuid } from 'src/app/core/Funciones/funciones';
import { ActivatedRoute, Router } from '@angular/router';
import { FaceApiService } from 'src/app/core/services/FaceApiService';
import { Transition } from 'src/app/models/transition';
import * as faceApi from 'face-api.js'
import { FaceScan } from 'src/app/models/reconocimientoFacial';
import { ConfiguraReconocimientoComponent } from 'src/app/core/components/configura-reconocimiento/configura-reconocimiento.component';
import { DatosEmpleadosAcceso, TipoOrigen } from 'src/app/models/datosEmpleadosAcceso';
import { genBitmapImage } from 'src/app/core/Funciones/imagen';
import { WebcamImage } from 'ngx-webcam';
import { ConfigAvanzadaComponent } from 'src/app/core/components/config-avanzada/config-avanzada.component';
import { ReconocimientoCFService } from 'src/app/core/services/reconocimiento-cf.service';
import { AccesoCF_RespuestaRecognize_Respuesta, AccesoCF_RespuestaRecognize_Subjects } from 'src/app/models/accesoCF';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';

@Component({
  selector: 'app-acceso-bi',
  templateUrl: './acceso-bi.component.html',
  styleUrls: ['./acceso-bi.component.scss']
})
export class AccesoBiComponent implements OnInit, AfterViewInit, OnDestroy {
  public accesoExpress: AccesoExpress;
  public $offset: number = 0;
  public idConexionPuntoAcceso: string = '';
  public estaEscuchando: boolean = false;
  public buscandoConexion: boolean = false;
  public terminalTime: string = '';
  public connectionState: IConnectionState;
  public subscriptions = new Subscription();
  private _codeLoop: any = undefined;
  public mensaje: string = '';
  public error: string = '';
  public renovacion: string = '';
  public opcionSeleccionada: string | undefined;
  public datosActualizados: Date = new Date(1900, 0, 0);

  // Datos de la empresa
  public empleados: IEmpleados[] = []
  public codigosQr: ICodigoQr[] = []

  // Datos de la cámara para reconocimiento facial
  public camaraAncho: number = 400;
  public camaraAlto: number = 400;
  public videoElement: HTMLVideoElement | undefined;
  public faceBox: Transition<faceApi.Box<any>> = new Transition<faceApi.Box<any>>(new faceApi.Box<any>( { x: 0, y: 0, width: 0, height: 0 }), new faceApi.Box<any>({ x: 0, y: 0, width: 0, height: 0 }), 0, 0);
  public drawingTime = this.Now().getTime();
  private _drawingLoop: any = undefined;
  private trigger: Subject<any> = new Subject();
  public webcamImage!: WebcamImage;
  private IdEmpleadoFoto: string | undefined = undefined;
  private tipoChecada: string = '-';
  public infoAdd: string | undefined = undefined;

  public drawingLoop: number;
  public drawingLoopInicial: number;
  public dps: number = 0;
  public drawCada: number = 0;
  private _ultimoDraw: Date = new Date();
  public tiempoDesdeUltimoCheck: number = 0;
  private lastCheck: Date = this.Now();
  private previousValidScan: FaceScan | undefined = undefined;
  private lastScanResults: (FaceScan | undefined)[] = [];
  private expression: { expression: string, probability: number, doNotMessage: string } | undefined = undefined;
  private rotation: { rx: number, ry: number, result: string | undefined } | undefined = undefined;
  public inicioReconocimientoFacial: number = 0;
  public reconocimientosFaciales: number = 0;
  public reconocimientosFacialActivo: boolean = false;
  public reconocimientosFacialCargando: boolean = false;
  public reconocimientosFacialProbando: boolean = false;
  public tryingBiometricsEnd: Date = new Date(1900, 0, 1);
  public displaySize: { width: number, height: number } = { width: 0, height: 0 };
  private requiresCloser = false;
  private requiresCenter = false;
  private requiresNeutral = false;
  private marksPrimaryColor: string = '';
  private marksSecondaryColor: string = '';
  public facingMode: string = '-'; // 'user'; // 'environment'
  // switch to next / previous / specific webcam; true/false: forward/backwards, string: deviceId
  private nextWebcam: Subject<boolean|string> = new Subject<boolean|string>();
  public infoCamara: string = 'No se reconocen rostros en los datos';
  private sinVideo: number = -1;
  private sinCaras: number = 0;
  private centrarCara: number = 0;
  private bestMatchDistance: number = 0;
  private bestMatchUndefined: number = 0;
  private detectFaceActivado: boolean = false;
  private ultimaTomaVerificarOnLine: Date = new Date(1900, 0 , 1);

  private isSavingDescriptors: Date | undefined = undefined;
  private descriptors: Float32Array[] = [];
  private idEmpleadoDesconocido: number = -1;
  private datosEmpleados: { [id: string]: DatosEmpleadosAcceso } = { }
  private revisionDesconocidoCada: number = 10; // minutos
  private maxOnLine: number = 3; // máximo de verificaciones online
  public idEmpleadoActivo: string | undefined = undefined;

  // Datos del qr
  public qrAccessLeft: number = 0;
  public qrAncho: number = 300;
  public codigo: string = '-';
  public valorFecha: Date = new Date();

  // Acceso fuera de línea
  public inputCode: string = '';
  public esconderAccesoFueraDeLinea: any;
  public manejoFueraDeLinea: boolean = false;
  public hideAccess: boolean = false;
  public chequeosFueraDeLinea: number = 0;

  public mostrarCodigo: boolean = false;
  public mostrarFaceID: boolean = false;
  public volumenOn: boolean = true;
  public audio = new Audio('assets/images/notificacion.mp3');
  public proceso: string = 'Cargando código QR...';
  private _estaCerrando: boolean = false;
  private _modoFullDebug: boolean = true && !environment.production;
  public esPantallaCompleta: boolean = false;
  public iconoCamara: string = 'no_photography';
  public iconoVolumen: string = 'volume_up';
  public modoAdmin: number = 0;
  public modoFullDebug: boolean = !environment.production;

  constructor(
    private el: ElementRef, private renderer: Renderer2,
    private _accessHubService: AccessHubService,
    private _httpClient: HttpClient,
    private nomiExpressApi: NomiExpressApiService,
    private accesoDatosServicio: AccesoDatosService,
    private reconocimientoCFApi: ReconocimientoCFService,
    public faceApiService: FaceApiService,
    private _activatedRoute: ActivatedRoute,
    private readonly _router: Router,
    private dialog: MatDialog
  ) {
    this.proceso = 'Inicializando aplicación...'
    this.accesoDatosServicio.cerrarServicioDatosEmpresa();
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.accesoExpress.debug = this.accesoExpress.debug || !environment.production;
    if (!this.accesoExpress.opcionesFecha) this.accesoExpress.opcionesFecha = 0;
    this.drawingLoopInicial = this.accesoExpress.drawingLoopInicial;
    this.drawingLoop = this.accesoExpress.drawingLoopInicial;
    this.marksPrimaryColor = this.accesoExpress.camHighlight1;
    this.marksSecondaryColor = this.accesoExpress.camHighlight2;
    this._accessHubService.connect();
    this.connectionState = this._accessHubService.connectionState;    
  }

   public pantallaCompleta() {
     this.esPantallaCompleta = !this.esPantallaCompleta;
     const elem = this.el.nativeElement;

     if (elem.requestFullscreen) {
       elem.requestFullscreen();
     } else if (elem.mozRequestFullScreen) { // Firefox
       elem.mozRequestFullScreen();
     } else if (elem.webkitRequestFullscreen) { // Chrome, Safari and Opera
       elem.webkitRequestFullscreen();
     } else if (elem.msRequestFullscreen) { // Internet Explorer and Edge
       elem.msRequestFullscreen();
     }
   }

  public salirPantallaCompleta() {
    this.esPantallaCompleta = !this.esPantallaCompleta;
    if (document.exitFullscreen) {
      document.exitFullscreen();
    }
  }

  public abrirConfig() {
    const password = this.dialog.open(PasswordComponent, {
      data: 'Acceso a configuración',
      width: '33rem',
      height: 'auto'
    });

    password.afterClosed().subscribe((resultado) => {
      if (!resultado) return;
      this.abrirConfig2(resultado);
    });
  }

  private async abrirConfig2(password: string | undefined) {
    if (!password) return;
    if (password != 'modoAdmin') {
      password = await this.verificaPwd(password);
      if (password != 'verificadaPwd') {
        this.ponerError('Contraseña incorrecta');
        return
      };
    }

    if (password == 'modoAdmin') {      
      if (!this.idEmpleadoActivo) {
        console.log(`modoAdmin --> idEmpleadoActivo nulo`);
        return;
      }
      if (this.modoAdmin != 1) {
        console.log(`modoAdmin --> no está en modo admin ${this.modoAdmin}`);
        return;
      }

      let empleado: IEmpleados | undefined = this.empleados.find(x => x.id == this.idEmpleadoActivo);
      if (!empleado) {
        console.log(`modoAdmin --> Empleado nulo`);
        return;
      }
      if (empleado.tipoEmpleado != TipoEmpleado.Supervisor && empleado.tipoEmpleado != TipoEmpleado.Administrador) {
        console.log(`modoAdmin --> el empleado no es administrador`);
        return;
      }
      this.idEmpleadoActivo = undefined;
      this.modoAdmin = 0;
      password = 'verificadaPwd';
    }
    if (password != 'verificadaPwd') {
      this.error = 'Contraseña incorrecta';
      return
    };
    const config = this.dialog.open(ConfiguraBasicaComponent, {
      data: undefined,
      width: '45rem',
      height: '21rem',
    });

    config.afterClosed().subscribe((resultado) => {
      this.accesoExpress = this.nomiExpressApi.accesoExpress();
      switch (resultado) {
        case 'inicio-empresa':
          if (environment.production) return;
          this._estaCerrando = true;
          this.desactivarReconocimientoFacial();
          this._estaCerrando = true;
          let empleado: IEmpleados = nuevoEmpleado();
          empleado.id = '999';
          empleado.tipoEmpleado = TipoEmpleado.Sistemas;
          this.accesoDatosServicio.setEmpleadoActual(empleado);
          break;
        case 'avanzada': 
          this.abrirConfigAvanzada();
          break;
        case 'ConfiguraReconocimientoFacial':
          this.abrirConfiguraReconocimientoFacial();
          break;
        case 'ConfigAvanzada':
          this.abrirConfigAvanzada();
          break;
      }
    });
  }

  private async verificaPwd(password: string): Promise<string | undefined> {
    var hash = await sha256(password);
    // var hashTotal = await sha256(hash + this.accesoExpress.deviceId.toLocaleLowerCase());    
    if ((!hash || hash.toLocaleLowerCase() != this.accesoExpress.localSecret.toLocaleLowerCase()) ) {
      const hoy: string = fFecha(Ahora(this.accesoExpress.opcionesFecha), "amd");
      let contraseña: string = `asesor0${hoy.substring(3, 4)}${hoy.substring(4, 5) }${hoy.substring(6, 7) }${hoy.substring(7, 8) }${hoy.substring(5, 6)}`;
      if (password != contraseña) {
        // console.log(`${password} ==> ${await sha256(password)} ==> hash1: ${hashTotal} hash: ${hash} ==> ${this.accesoExpress.localSecret}`);        
        this.ponerError('Contraseña incorrecta');
        return undefined;
      }      
    }
    return 'verificadaPwd';
  }

  private abrirConfigAvanzada() {
    const config = this.dialog.open(ConfigAvanzadaComponent, {
      data: undefined,
      width: '50rem',
      height: '22rem',
    });

    config.afterClosed().subscribe((resultado) => {
      if (resultado && resultado == 'Guardado') {
        this.accesoExpress = this.nomiExpressApi.accesoExpress();
        window.location.reload();
      }
      if (resultado && resultado == 'log') {
        this.ponerTxtEnConsola('Abriendo Log');
        this._router.navigate(['log']);
      }      
    });
  }

  private abrirConfiguraReconocimientoFacial() {    
    const config = this.dialog.open(ConfiguraReconocimientoComponent, {
      data: undefined,
      width: 'auto',
      height: 'auto',
    });

    config.afterClosed().subscribe((resultado) => {
      if (resultado && resultado == 'Guardado') {
        this.accesoExpress = this.nomiExpressApi.accesoExpress();
        window.location.reload();
      }
      if (resultado && resultado == 'log') {
        this.ponerTxtEnConsola('Abriendo Log');
        this._router.navigate(['log']);
      }
    });
  }

  public cambiarManejoFueraDeLinea() {
    this.manejoFueraDeLinea = !this.manejoFueraDeLinea;
    if (this.manejoFueraDeLinea) {
      clearTimeout(this.esconderAccesoFueraDeLinea);
      this.esconderAccesoFueraDeLinea = setTimeout(() => {
        this.manejoFueraDeLinea = false;
      }, 60000);
    }
  }

  public quitarRenovacion() {
    const password = this.dialog.open(PasswordComponent, {
      data: '¿Desea quitar el aviso de renovación por el día?',
      width: '33rem',
      height: 'auto'
    });

    password.afterClosed().subscribe(async (resultado) => {
      if (!resultado) return;
      resultado = await this.verificaPwd(resultado);
      if  (resultado != '') return;
      this.renovacion = '';
    });
  }

  public accesoFueraDeLinea() {}

  // @HostListener('window:resize', ['$event'])
  public onResize(event?: Event) {
    const win = !!event ? (event.target as Window) : window;
    const esVertical: boolean = win.innerHeight > win.innerWidth;

    if (this.mostrarFaceID) {
      var codigoQrDiv = document.getElementById('codigoQrDiv') as HTMLElement;
      var rectCodigoQrDiv = codigoQrDiv.getBoundingClientRect();
      if (rectCodigoQrDiv) {
        let codigoQrDivIzq = rectCodigoQrDiv.left;
        let codigoQrDivAlto = rectCodigoQrDiv.height;

        var cámaraDiv = document.getElementById('codigoQrDiv') as HTMLElement;
        var rectCámaraDiv = cámaraDiv.getBoundingClientRect();
        let cámaraDivAncho = rectCámaraDiv.width || 0;
        let cámaraDivAlto = rectCámaraDiv.height || 0;

        // console.log(`codigoQrDivIzq: ${codigoQrDivIzq}, codigoQrDivAlto: ${codigoQrDivAlto}, cámaraDivAncho: ${cámaraDivAncho}, cámaraDivAlto: ${cámaraDivAlto}, win.innerWidth: ${win.innerWidth}, win.innerHeight: ${win.innerHeight}`);
        if (!this.accesoExpress.webCamScale || this.accesoExpress.webCamScale == null || this.accesoExpress.webCamScale < 0 || this.accesoExpress.webCamScale > 1) this.accesoExpress.webCamScale = 0.85;
        let maxAncho: number = cámaraDivAncho > codigoQrDivAlto ? cámaraDivAncho : codigoQrDivAlto;
        maxAncho = maxAncho > (codigoQrDivIzq * this.accesoExpress.webCamScale) ? maxAncho : (codigoQrDivIzq * this.accesoExpress.webCamScale);
        if (esVertical) maxAncho *= 0.9;

        this.camaraAncho = maxAncho; // win.innerWidth -
        this.camaraAlto = maxAncho; // win.innerHeight > codigoQrDivAlto ? codigoQrDivAlto : win.innerHeight;
        this.qrAncho = this.camaraAncho * 0.4;
        // console.log(`camaraAncho: ${this.camaraAncho}, camaraAlto: ${this.camaraAlto}, esVertical: ${esVertical}, qrAncho: ${this.qrAncho}`);
      }

      // reinicia la posición del canvas
      this.inicioReconocimientoFacial = this.Now().getTime();
      this.reconocimientosFaciales = 0;
  }

    var qrAccessElement = document.getElementById('qr-access') as HTMLElement;
    this.qrAccessLeft = qrAccessElement.getBoundingClientRect().left;
  }

  public mostrarFaceIdClick() {
    if (!this.accesoExpress.isActive || !this.accesoExpress.faceDetectionEnabled) {
      this.mostrarFaceID = false;
    } else {
      this.mostrarFaceID = !this.mostrarFaceID;
      this.accesoExpress.faceDetectionActive = this.mostrarFaceID;
      this.nomiExpressApi.guardarAccesoExpress(this.accesoExpress);
    }

    this.qrAncho = this.mostrarFaceID ? 200 : 300;
    if (this.mostrarFaceID) {
      this.iconoCamara = 'photo_camera';
      this.ponerTxtEnConsola('Reinicializando la cámara');
      this.infoCamara = 'Reinicializando la cámara';
      this.onResize();
      this.inicializarReconocimientoFacial();
      this.ponerTxtEnConsola('Reinicializando video elemento');
      window.location.reload();
      return;
    }
    this.iconoCamara = 'no_photography';
    this.infoCamara = 'Desactivando la cámara';
    this.desactivarReconocimientoFacial();
    window.location.reload();
  }

  public enviarTecla(input: string) {
    if (input == 'bs') {
      this.inputCode = this.inputCode.substring(0, this.inputCode.length - 1);
      return;
    }
    this.inputCode += input;
  }

  private async processScanToken(checadaServidor: TimeToken) {
    if (!checadaServidor.token) return;

    let now: Date = this.Now();
    this.lastCheck = now;
    this.verificaDrawingLoop();
    let tokenId = checadaServidor.token.substring(0, checadaServidor.token.length - 3);
    var shortId = parseInt(tokenId, 16);
    if (this.accesoExpress.debug) this.ponerTxtEnConsola(`processScanToken ==> shortId (${tokenId}) ${shortId} + ${this.$offset}}`);

    if (this.codigosQr.length == 0 || this.codigosQr.length < 1) {
      this.ponerTxtEnConsola('Parece que no ha sido posible descargar la información de los empleados desde el servidor.');
      return;
    }

    let codigoQr: ICodigoQr | undefined = this.codigosQr.find(x => x.idCorto == shortId);
    if (!codigoQr || !codigoQr.id) {
      // algo anda mal, se repitieron los códigos, o el empleado no está registrado...
      let error =  'Parece que tenemos un problema con el teléfono registrado, por favor intenta de nuevo, si ' +
        'el problema persiste, pide un nuevo código de acceso en la empresa a la que estas accediendo.';
      this.nomiExpressApi.logAgrega(error);
      this.ponerError(error);
      return;
    }

    let empleado: IEmpleados | undefined = this.empleados.find(x => x.id == codigoQr?.id);
    if (!empleado) {
      this.ponerTxtEnConsola(`processScanToken ==> getMany ==> _api.employees.getOne (${codigoQr.id}) - error: No fue posible localizar el empleado seleccionado`);
      this.ponerError(`No fue posible localizar el empleado seleccionado`);
      return;
    }

    this.IdEmpleadoFoto = empleado.id;
    this.tipoChecada = 'qr';
    this.tomarFoto();

    if (this.$offset === 0) {
      let tokenValidation = this.validarChecadaEmpleado(empleado, codigoQr, checadaServidor);
      if (tokenValidation.type == 'error') {
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`processScanToken ==> getMany ==> _api.employees.getOne (${codigoQr.id}) ==> validateEmployeeTimeToken - error`);
        if (!checadaServidor.replyTo)
          this.ponerError(tokenValidation.message);
        else
          this._httpClient.post(`${environment.hubUrl}/access/reply`, { error: tokenValidation.message }).subscribe();
        return;
      }
    }

    // validamos que el qr esté autorizado por el usuario.
    if (checadaServidor.replyTo) {
      let c = checadaServidor.connectionId.split('{remote}');
      let k = await sha256(checadaServidor.replyTo.toLocaleLowerCase() + this.accesoExpress.localSecret.toLocaleLowerCase());
      let u = c[1];
      if (k != u) {
        // firma incorrecta
        if (this.accesoExpress.debug) {
          this.ponerTxtEnConsola(`processScanToken ==> getMany ==> _api.employees.getOne (${codigoQr.id}) ==> connectionId + remote`);
          // console.log(txt, k, u);
        }
        return;
      }
    } else {
      let c = checadaServidor.connectionId.split('{local}');
      let k = await sha256(this.connectionState.id.toLocaleLowerCase() + this.accesoExpress.localSecret.toLocaleLowerCase());
      let u = c[1];
      if (k != u) {
        // firma incorrecta
        if (this.accesoExpress.debug) {
          this.ponerTxtEnConsola(`processScanToken ==> getMany ==> _api.employees.getOne (${codigoQr.id}) ==> connectionId + local`);
          // console.log(txt, k, u);
        }
        return;
      }
    }

    this.checadaEmpleado(empleado, now, !!checadaServidor.replyTo, TipoOrigen.codigoQr);

    var replay = {
      name: nombreEmpleadoCorto(empleado),
      date: now,
      replyTo: checadaServidor.replyTo
    };
    if (this.accesoExpress.debug) {
      console.dir(`serverCheck.replyTo`, replay);
    }

    if (checadaServidor.replyTo) {
      this._httpClient.post(`${environment.hubUrl}/access/reply`, replay).subscribe();
    }
  }

  private validarChecadaEmpleado(empleado: IEmpleados, codigoQr: ICodigoQr, checadaServidor: TimeToken): ITokenValidation {
    this.ponerTxtEnConsola(`ValidarChecadaEmpleado en Acceso.Component`);
    let now = this.Now();
    let serverTime: Date = ValidarHora(new Date(checadaServidor.serverTime), this.accesoExpress.opcionesFecha);
    const token = getToken(checadaServidor.code, codigoQr.idCorto || 0, codigoQr.llave || 0);

    let secondsDif = Math.abs(serverTime.getTime() - now.getTime()) / 1000;
    if (secondsDif >  (this.accesoExpress.debug ? 120 : 60) && this.accesoExpress.checkServerTime && this.accesoExpress.offSetFromServer == 0 && Math.abs(secondsDif) < 200) {
      this.accesoExpress.offSetFromServer = secondsDif;
      now = this.Now();
      secondsDif = Math.abs(serverTime.getTime() - now.getTime()) / 1000;
    }
    if (secondsDif >  (this.accesoExpress.debug ? 120 : 60)) {
      // el envío del servidor al cliente duró más de 30 segundos???
      // es posible que la hora de la computadora local esté mal
      // rechazar y volver a intentar

      if (this.accesoExpress.debug) {
        this.ponerTxtEnConsola(`validateEmployeeTimeToken ==> Parece que la hora de la terminal es incorrecta, por favor verifica que la hora en la terminal sea correcta e intenta de nuevo. ${secondsDif}`);
      }

      return {
        message:
          'Parece que la hora de la terminal es incorrecta, por favor verifica que la hora en la terminal sea correcta e intenta de nuevo.',
        type: 'error'
      };
    }

    const codeComponents = getTokenCodeTimeComponents(checadaServidor.code);
    if (!codeComponents.isValid) {
      // código no válido
      if (this.accesoExpress.debug) {
        this.ponerTxtEnConsola(`validateEmployeeTimeToken ==> Código inválido o caducado, intenta de nuevo`);
        // console.log(txt, codeComponents);
      }
      return {
        message: 'Código inválido o caducado, intenta de nuevo.',
        type: 'error'
      };
    }

    var nowInMinutes = now.getHours() * 60 + now.getMinutes() + now.getSeconds() / 60;
    var codeInMinutes = codeComponents.h * 60 + codeComponents.m + codeComponents.s / 60;
    if (Math.abs(nowInMinutes - codeInMinutes) > (checadaServidor.replyTo ? 1 : 0.5) ) {
      // el código qr ya caducó, fue generado hace mas de medio minuto (30 secs)
      if (this.accesoExpress.debug) {
        this.ponerTxtEnConsola(`validateEmployeeTimeToken ==> Código inválido o caducado, intenta de nuevo (2), ${nowInMinutes}, ${codeInMinutes}`);
      }
      return {
        message: 'Código inválido o caducado, intenta de nuevo.',
        type: 'error'
      };
    }

    if (token.toUpperCase() != checadaServidor.token.toUpperCase()) {
      // no pasó la validación por seguridad.
      if (this.accesoExpress.debug) this.ponerTxtEnConsola(`validateEmployeeTimeToken ==> Código inválido o caducado, intenta de nuevo (3), ${token}, ${checadaServidor.token}`);
      return {
        message: 'Código inválido o caducado, intenta de nuevo.',
        type: 'error'
      };
    }

    return { type: "checkIn", message: 'ok' };
  }

  private checadaEmpleado(empleado: IEmpleados, now: Date, ocultar: boolean, origen: TipoOrigen) {

    let acceso: IAcceso = {
      localId: getUuid(),
      companyId: this.accesoExpress.companyId,
      employeeId: empleado.id,
      fecha: fechaUTC(now),
      checkerAccessId: this.accesoExpress.clientId,
      origen: origen,
      similitud: 0
    };

    if (this.estaProbandoReconocimientoFacial()) {
      if (!ocultar) this.ponerMensaje(`[PRUEBA] Hola ${nombreEmpleadoCorto(empleado)}`);
      return;
    }

    this.datosActualizados = new Date(1900, 0, 0);
    setTimeout(() => {
      // this.nomiExpressApi.logAgrega2(`Verificando fecha de actualización updateCheck ${fFecha(this.datosActualizados, 'fmhs')}`);
      if (dateDiff(this.datosActualizados, new Date(), TipoDateDiff.días) >= 1) {
        this.nomiExpressApi.logAgrega2(`Reiniciando (a)`);
        window.location.reload();
      }
    }, 10000);
    this.nomiExpressApi.updateCheck(acceso).subscribe(
      (respuesta: IRespuestaChecker) =>  {
        if (respuesta.code >= 100) {
          if (!ocultar) this.ponerMensaje(`Hola ${nombreEmpleadoCorto(empleado)}`);
        } else {
          this.ponerError(respuesta.mensaje);
        }
        this.idEmpleadoActivo = empleado.id;        
        this.datosActualizados = new Date();
        if (this.modoAdmin == 1 || this.modoAdmin == 2) {
          console.log(`Validando modo admin qr => id: ${this.idEmpleadoActivo}, ma: ${this.modoAdmin}, te: ${empleado.tipoEmpleado}`); // ${JSON.stringify(empleado)}
          if (empleado.tipoEmpleado == TipoEmpleado.Supervisor || empleado.tipoEmpleado == TipoEmpleado.Administrador) {
            if (this.modoAdmin == 1) {
              this.abrirConfig2('modoAdmin');              
            }
            if (this.modoAdmin == 2) {
              this.modoAdmin = 0;
              this.accesoDatosServicio.setEmpleadoActual(empleado);
              this.desactivarReconocimientoFacial();
              this._router.navigate(['inicio-empresa']);
            }
          }
        }        
      }, (err: any) => {
        let error = 'Ha ocurrido un error inesperado, por favor intenta de nuevo, si el problema persiste contacta a soporte técnico.';
        this.ponerError(error);
        this.ponerTxtEnConsola(`Acceso Empleado ==> ${error}, ${err.message}`);
    });
  }

  private estaProbandoReconocimientoFacial(): boolean {
    if (this.$offset !== 0) return false;
    return this.tryingBiometricsEnd.getTime() > this.Now().getTime();
  }

  public fFecha(fecha: string): string {
    return fFecha(CVFechaT(fecha), 'fmh');
  }

  private Now(): Date {
    // $ offset es una variable global para mover la hora del reloj checador.
    // this.ponerTxtEnConsola(`Now en Acceso.Component`);
    let opcionesFecha: OpcionesFecha = OpcionesFecha.Normal;
    if (!this.accesoExpress || !this.accesoExpress.opcionesFecha) {
      // this.ponerTxtEnConsola(`Now en Acceso.Component. sin opcionesFecha`);
      this.accesoExpress = this.nomiExpressApi.accesoExpress();
    }
    if (!!this.accesoExpress) {
      // this.ponerTxtEnConsola(`Now en Acceso.Component. sin opcionesFecha (2)`);
      opcionesFecha = this.accesoExpress.opcionesFecha;
    }
    // this.ponerTxtEnConsola(`Now en Acceso.Component. Op Hora: ${opcionesFecha}`);
    let now: Date = Ahora(opcionesFecha);
    if (this.accesoExpress && this.accesoExpress.offSetFromServer && this.accesoExpress.offSetFromServer != 0) {
      if (this.accesoExpress.offSetFromServer >= -200 && this.accesoExpress.offSetFromServer <= 200)
        now = addSeconds(Ahora(this.accesoExpress.opcionesFecha), this.accesoExpress.offSetFromServer);
    }
    // this.ponerTxtEnConsola(`Now en Acceso.Component (2)`);
    return addHours(now, this.$offset || 0);
  }

  private verificaDrawingLoop() {
    let prueba: boolean = this._modoFullDebug && !environment.production;
    this.tiempoDesdeUltimoCheck = dateDiff(this.lastCheck, this.Now(), TipoDateDiff.minutos);
    // let tiempoDesdeUltimoCheck: number = dateDiff(this.lastCheck, this.Now(), TipoDateDiff.minutos);
    // let tiempoDesdeUltimoCheck2: number = dateDiff(this.Now(), this.lastCheck, TipoDateDiff.minutos);

    // this.ponerTxtEnConsola(`drawingLoop: ${this.drawingLoop}, tiempoDesdeUltimoCheck: ${tiempoDesdeUltimoCheck.toFixed(2)}, tiempoDesdeUltimoCheck2: ${tiempoDesdeUltimoCheck2.toFixed(2)}`);
    // this.ponerTxtEnConsola(`now: ${this.Now()}, lastCheck: ${this.lastCheck}`);
    if (this.tiempoDesdeUltimoCheck > (prueba ? 5 : 200)) {
      this.nomiExpressApi.accesoVerificar(this.tiempoDesdeUltimoCheck).subscribe(
        (resultado: IRespuestaChecker) => {
          this.ponerTxtEnConsola(`Conectando con el servidor. Checker verificar. Recargar Checker. ${resultado.mensaje}`);
          this.ngOnDestroy();
          clearInterval(this._codeLoop);
          clearInterval(this._drawingLoop);
          this.lastCheck = this.Now();
          // this._router.navigate(['recargar']);
          clearInterval(this._codeLoop);
          clearInterval(this._drawingLoop);
          window.location.reload();
        },
        (error: HttpErrorResponse) => {
          this.ponerTxtEnConsola(`Conectando con el servidor. Checker verificar. No conectado.`);
          let err: string = JSON.stringify(error);
          this.ponerTxtEnConsola(err);
        }, () => {
          this.ponerTxtEnConsola(`Conectando con el servidor. Checker verificar. THEN.`);
        }
      );
      if (this.drawingLoop != 2) {
        clearInterval(this._drawingLoop);
        this.drawingLoop = 2;
        clearInterval(this._drawingLoop);
      }
      this.ponerTxtEnConsola(`drawingLoop: ${this.drawingLoop} ${this.tiempoDesdeUltimoCheck} ${prueba}`);
      return;
    }

    if (this.tiempoDesdeUltimoCheck > (prueba ? 10 : 30)) {
      if (this.drawingLoop != this.drawingLoopInicial / 6 ) {
        clearInterval(this._drawingLoop);
        this.drawingLoop = this.drawingLoopInicial / 6;
        clearInterval(this._drawingLoop);
        this.ponerTxtEnConsola(`drawingLoop: ${this.drawingLoop} ${this.tiempoDesdeUltimoCheck} ${prueba}`);
      }
      return;
    }

    if (this.tiempoDesdeUltimoCheck > (prueba ? 8 : 15)) {
      if (this.drawingLoop != (this.drawingLoopInicial / 4)) {
        clearInterval(this._drawingLoop);
        this.drawingLoop = this.drawingLoopInicial / 4;
        clearInterval(this._drawingLoop);
        this.ponerTxtEnConsola(`drawingLoop: ${this.drawingLoop} ${this.tiempoDesdeUltimoCheck} ${prueba}`);
      }
      return;
    }

    if (this.tiempoDesdeUltimoCheck > (prueba ? 2 : 5)) {
      if (this.drawingLoop != (this.drawingLoopInicial / 3)) {
        clearInterval(this._drawingLoop);
        this.drawingLoop = this.drawingLoopInicial / 3;
        clearInterval(this._drawingLoop);
        this.ponerTxtEnConsola(`drawingLoop: ${this.drawingLoop} ${this.tiempoDesdeUltimoCheck} ${prueba}`);
      }
      return;
    }

    if (this.tiempoDesdeUltimoCheck > (prueba ? 1 : 3)) {
      if (this.drawingLoop != (this.drawingLoopInicial / 1.5)) {
        clearInterval(this._drawingLoop);
        this.drawingLoop = this.drawingLoopInicial / 1.5;
        clearInterval(this._drawingLoop);
        this.ponerTxtEnConsola(`drawingLoop: ${this.drawingLoop} ${this.tiempoDesdeUltimoCheck} ${prueba}`);
      }
      return;
    }

    if (this.drawingLoop != this.drawingLoopInicial) {
      clearInterval(this._drawingLoop);
      this.drawingLoop = 30;
      clearInterval(this._drawingLoop);
      this.ponerTxtEnConsola(`drawingLoop: ${this.drawingLoop} ${this.tiempoDesdeUltimoCheck} ${prueba}`);
    }
  }

  private async creaCodigo() {
    // currentTimeStamp
    this.verificaDrawingLoop();
    this.valorFecha = this.Now();
    if (!this.connectionState.id || this.connectionState.id == ':)') {
      this.codigo = '-';
      return;
    }
    this.codigo = `${this.connectionState.id}{local}${await sha256(this.connectionState.id.toLocaleLowerCase()
        + this.accesoExpress.localSecret.toLocaleLowerCase())}.`
        + `${this.accesoExpress.companyId}.${obtenerValorFecha(this.valorFecha)}`;
  }

  private siguienteCodigo = () => {
    this.creaCodigo();
    let nextCodeCount = 4000 + Math.random() * 6000;
    setTimeout(this.siguienteCodigo, nextCodeCount);
  };

  private _inicializarReconocimientoFacial = () => {
    this.ponerTxtEnConsola(`Esperando la carga del reconocimiento facial.`);
    if (this.faceApiService.isFaceDetectionLoaded) {
      this.ponerTxtEnConsola(`Terminando la carga del reconocimiento facial.`);
      this.inicializarReconocimientoFacial2();
      if (this.detectFaceActivado || !this.videoElement) return;
      setTimeout(() => { this.onPlay(); }, 1000);
    }
    setTimeout(this._inicializarReconocimientoFacial, 1300);
  }

  private ponerError(error: string) {
    this.error = error;
    this.mensaje = '';
    setTimeout(() => {
      this.error = '';
    }, 10000);
  }

  private ponerAdvertencia(advertencia: string) {
    this.error = advertencia;
    this.mensaje = '';
  }

  public cambiarSonido() {
    this.volumenOn = !this.volumenOn;
    if (this.volumenOn) {
      this.iconoVolumen = 'volume_up';
    } else {
      this.iconoVolumen = 'volume_off';
    }
  }

  public ponerMensaje(mensaje: string) {
    this.mensaje = mensaje;
    this.error = '';
    if (this.volumenOn) {
      this.audio.play();
    } else {
      this.audio.pause();
    }
    setTimeout(() => {
      this.mensaje = '';
    }, 5000);
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.nomiExpressApi.logAgrega(txt);
  }

  private async detectFace() {
    this.infoAdd = '';
    if (this.accesoExpress.debug && this.sinVideo == -1) {
      this.ponerTxtEnConsola(`detectFace. reconocimientosFaciales: ${this.reconocimientosFaciales}. canvasFps: ${this.accesoExpress.canvasFps}`);
    }
    if (!this.videoElement) {
      if (this.sinVideo < 3) this.ponerTxtEnConsola('Sin video');
      this.infoAdd = 'Sin video';
      this.sinVideo++;
      return;
    }

    // si está cargando el modelo de AI o el video está cargando, entonces ignoramos.
    var s = this.videoElement.readyState;
    if (!this.faceApiService.isFaceDetectionLoaded || s < 4) {
      this.ponerTxtEnConsola(`Esperando carga de video (${s}) - ${this.faceApiService.isFaceDetectionLoaded}`);
      this.infoCamara = 'Esperando carga de video';
      this.infoAdd = 'Esperando carga de video';
      return;
    }

    let canvas = document.getElementById('face-marks') as HTMLCanvasElement;
    if (!canvas) {
      if (this.sinVideo < 3) this.ponerTxtEnConsola('canvas: null');
      this.sinVideo++;
      this.infoAdd = 'canvas: null';
      return;
    }

    var now = this.Now();
    this.sinVideo = 0;
    this.infoAdd = `Video: ${this.displaySize.width}, ${this.displaySize.width}, fb:`;
    if (this.reconocimientosFaciales == 0) {
      if (this.accesoExpress.debug) this.ponerTxtEnConsola('detectFace. Primer reconocimiento facial');
      this.displaySize = { width: this.videoElement.width, height: this.videoElement.height };
      this.infoAdd = `Primer reconocimiento facial. video: ${this.displaySize.width}, ${this.displaySize.width}, f: ${fFecha(now, 'fmh')}, fb:`;
      faceApi.matchDimensions(canvas, this.displaySize);
      // face api no pone el left de la canvas??
      canvas.style.left = this.videoElement.getBoundingClientRect().left + 'px';
      this.inicioReconocimientoFacial = this.Now().getTime();
    }

    this.reconocimientosFaciales++;
    this.infoAdd += ` ${Math.round(this.faceBox.currentValue.left)}, ${Math.round(this.faceBox.currentValue.left)}, r: ${this.reconocimientosFaciales}, model: ${this.accesoExpress.faceApiModel}`;

    const detection= await this.faceApiService.detectSingleFace(this.videoElement);
    this.infoAdd += `, fd: ok`;

    // si no hay caras
    if (!detection) {
      if (this.infoCamara != 'No ha sido posible detectar el rostro') {
        if (this.accesoExpress.debug && this.sinCaras < 3) this.ponerTxtEnConsola(`detectFace. No ha sido posible detectar el rostro`);
        this.infoCamara = 'No ha sido posible detectar el rostro';
        this.infoAdd += `, sin rostro`;
      }      
      this.addScanResult(undefined);
      this.closeFaceBox();
      this.hideAccess = false;
      this.sinCaras++;
      return;
    }

    this.sinCaras = 0;

    const resizedDetection = faceApi.resizeResults(detection, this.displaySize);
    if (!resizedDetection) {
      if (this.accesoExpress.debug) this.ponerTxtEnConsola(`detectFace. falló el resized detection`);
      this.infoAdd += `, fallo el resized Detection`;
      this.infoCamara = 'No ha sido posible realizar el cambiar el tamaño para la detección del rostro';
      this.addScanResult(undefined);
      this.closeFaceBox();
      this.hideAccess = false;
      return;
    }
    

    if (this.accesoExpress.validarDirectoOnline) {
      if (dateDiffAhora(this.ultimaTomaVerificarOnLine, TipoDateDiff.segundos) < 3) return;
      // if (dateDiffAhora(this.ultimaTomaVerificarOnLine, TipoDateDiff.segundos) > 60) 
      //    this.ponerTxtEnConsola(`=========================================  Verificación OnLine: ${this.accesoExpress.validarDirectoOnline}, ${fFecha(this.ultimaTomaVerificarOnLine, "fmhs")}`);
      this.ultimaTomaVerificarOnLine = new Date();

      let bestMatch = await this.faceApiService.findBestMatch(resizedDetection.descriptor);
      let seguridad: number = 0;
      if (!bestMatch || bestMatch.distance > 0.5) { // no reconoció al empleado
        bestMatch = undefined;
        let fullFaceDescriptions = await this.faceApiService.detectSingleFace(this.videoElement);
        if (!fullFaceDescriptions) {
          if (this.accesoExpress.debug) this.ponerTxtEnConsola(`************  Sin fullFaceDescriptions.`);
          return;
        }
        this.IdEmpleadoFoto = `${this.verificaNuevoDesconocido(fullFaceDescriptions.descriptor)}`;
        if (this.IdEmpleadoFoto == '0') {
          if (this.accesoExpress.debug) this.ponerTxtEnConsola(`************  Sin id.`);
          return;
        }
      } else {
        this.IdEmpleadoFoto = bestMatch.label;
        seguridad = bestMatch.distance;
      }

      this.tipoChecada = 'rfo';
      if (this.accesoExpress.debug) this.ponerTxtEnConsola(`************ bestMatch: ${this.IdEmpleadoFoto} ${seguridad}`);
      const validScan = new FaceScan(this.IdEmpleadoFoto, now);
      let datosEmpleado: DatosEmpleadosAcceso = {
        fotos: [],
        ultimaVerificacion: new Date(1900, 1, 1),
        esDesconocido: 0,
        idEmpleado: undefined,
        origen: TipoOrigen.NoEspecificado
      };
      if (this.datosEmpleados.hasOwnProperty(validScan.label)) {
        datosEmpleado = this.datosEmpleados[validScan.label];
      }
      this.tomarFoto();
      return;
    }

    if (this.accesoExpress.usarReconocimientoCompleto) {
      // if (dateDiffAhora(this.ultimaTomaVerificarOnLine, TipoDateDiff.segundos) > 60) 
      //  this.ponerTxtEnConsola(`=========================================  Verificación OnLine: ${this.accesoExpress.validarDirectoOnline}, ${fFecha(this.ultimaTomaVerificarOnLine, "fmhs")}`);
      // this.ultimaTomaVerificarOnLine = new Date();

      if (!resizedDetection.detection.box) {
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`detectFace. falló el resized detection, no existe la detection box`);
        this.infoAdd += `, fallo el resized Detection, no existe la detection box`;
        this.infoCamara = 'No ha sido posible realizar el cambiar el tamaño para la detección del rostro, no existe la detection box';
        this.addScanResult(undefined);
        this.closeFaceBox();
        this.hideAccess = false;
        return;
      }

      this.infoAdd += `, detection.box ${resizedDetection.detection.box.left}, ${resizedDetection.detection.box.top}, ${resizedDetection.detection.box.width}, ${resizedDetection.detection.box.height}`;
      this.faceBox.move(resizedDetection.detection.box, this.drawingTime);

      let cl = canvas.getBoundingClientRect().left + resizedDetection.detection.box.x + resizedDetection.detection.box.width;
      this.hideAccess = cl > this.qrAccessLeft - (this.manejoFueraDeLinea ? 300 : 0);
      if (this.hideAccess) this.infoAdd += `, hide access`;

      // si el area del recuadro de la cara toma menos del area tolerancia (this.accesoExpress.faceAndCamAreaTolerance)
      // entonces ignoramos la lectura
      let faceToCameraRatio: number = this.getFaceToCameraRatio(resizedDetection.detection.box, false);
      if (faceToCameraRatio < this.accesoExpress.faceAndCamAreaTolerance) {
        if (this.accesoExpress.debug || this.accesoExpress.mostrarInfoAdd) {
          // let txt: string = `detectFace. si el area del recuadro de la cara toma menos del area tolerancia (this.accesoExpress.faceAndCamAreaTolerance) entonces ignoramos la lectura`;
          // txt = `detectFace. requiresCloser. faceAndCamAreaTolerance: ${this.accesoExpress.faceAndCamAreaTolerance}`;
          // txt += `, FaceToCameraRatio: ${faceToCameraRatio}`;
          // this.ponerTxtEnConsola(txt);
          this.getFaceToCameraRatio(resizedDetection.detection.box, true);
        }
        this.infoCamara = 'El area del recuadro de la cara toma menos del area tolerancia';
        this.addScanResult(undefined);
        this.requiresCloser = true;
        if (this.hideAccess) this.infoAdd += `, menor a la tolerancia`;
        return;
      }
      this.requiresCloser = false;

      // si la cara está cerca de un borde, pedimos que la centre para evitar posibles errores.
      let bounds = this.videoElement.getBoundingClientRect();
      let faceBox = resizedDetection.detection.box;

      let tolerance = this.accesoExpress.faceBoxTolerance; // 8% del borde
      let nearTop: boolean = faceBox.y < bounds.height * tolerance;
      let nearBot: boolean = faceBox.y + faceBox.height > bounds.height * (1 - tolerance);
      let nearLeft: boolean = faceBox.x < bounds.width * tolerance;
      let nearRight: boolean = faceBox.x + faceBox.width > bounds.width * (1 - tolerance);

      if (nearLeft || nearRight || nearBot || nearTop) {
        let txt: string = `Está muy cerca del borde necesita centrarse.`; // ${nearLeft}, ${nearBot}, ${nearRight}, ${nearTop}`;
        //txt += `, x: ${Math.round(faceBox.x)}, y: ${Math.round(faceBox.y)}, w: ${Math.round(bounds.width)}, h: ${Math.round(bounds.height)}, t: ${tolerance}`;
        if ((this.accesoExpress.debug || this.accesoExpress.mostrarInfoAdd) && this.centrarCara < 3) {
          // this.ponerTxtEnConsola(`detectFace. ${txt}`);
        }
        this.infoAdd += `, ${txt}`;
        this.infoCamara = 'Está muy cerca del borde necesita centrarse';
        this.addScanResult(undefined);
        this.requiresCenter = true;
        this.centrarCara++;
        return;
      }
      this.requiresCenter = false;
      this.centrarCara = 0;

      this.expression = this.getFaceExpression(resizedDetection.expressions);
      // sin expresión en el rostro.
      if (this.expression && this.expression.expression !== 'neutral') {
        // if (this.accesoExpress.debug) this.ponerTxtEnConsola(`detectFace. no tiene la cara neutral`);
        this.infoCamara = 'No tiene la cara neutral';
        this.infoAdd += `, No tiene la cara neutral`;
        this.addScanResult(undefined);
        this.requiresNeutral = true;
        return;
      }
      this.requiresNeutral = false;

      //this.rotation = this.getFaceRotation(resizedDetection.landmarks, resizedDetection.detection);
    }


    // si estamos guardando una nueva cara...
    // if (this.setBiometricsFor) {
    //   if (this.isSavingDescriptors) return;

    //   let fullFaceDescriptions = await this.faceApiService.detectSingleFace(this.videoElement);
    //   if (!fullFaceDescriptions) return;

    //   this.descriptors.push(fullFaceDescriptions.descriptor);

    //   if (this.descriptors.length >= 5) { // guardamos 5 caras en modelo
    //     this.isSavingDescriptors = true;
    //     let labeledDescriptor = new faceApi.LabeledFaceDescriptors(this.setBiometricsFor.id, this.descriptors);

    //     this.faceApiService.addAndSaveLabeledDescriptor(labeledDescriptor)
    //       .subscribe(success => {
    //         let toast = this.toastr.success(
    //           'Modo prueba activado por 60 segs, puedes probar el reconocimiento facial, el sistema no marcará ' +
    //           'asistencia mientras este dialogo está en pantalla, cerrar este dialogo terminará el modo prueba',
    //           `Registrado Correctamente`, {
    //           positionClass: 'toast-bottom-center',
    //           timeOut: 60000,
    //           progressBar: true,
    //           closeButton: true
    //         });

    //         this.tryingBiometricsEnd = addSeconds(now, 65);
    //         toast.onHidden.subscribe(() => {
    //           var remaining = Math.round((this.tryingBiometricsEnd.getTime() - this.Now().getTime()) / 1000);
    //           if (remaining < 5) return;
    //           this.tryingBiometricsEnd = addSeconds(now, 5);
    //         });

    //         this.setBiometricsFor = undefined;
    //         this.descriptors = [];
    //         this.isSavingDescriptors = false;
    //       }, error => {
    //         this.promptError('Ha ocurrido un error inesperado, asegúrate que tu conexión a internet es estable e intenta de nuevo.');
    //         this.descriptors = [];
    //         this.isSavingDescriptors = false;
    //       });
    //   }

    //   return;
    // }

    // if (this.accesoExpress.debug) this.ponerTxtEnConsola(`detectFace. findBestMatch`, resizedDetection);
    const bestMatch = await this.faceApiService.findBestMatch(resizedDetection.descriptor);
    if (!bestMatch) { // no reconoció al empleado
      let fullFaceDescriptions = await this.faceApiService.detectSingleFace(this.videoElement);
      if (!!fullFaceDescriptions) this.verificaNuevoDesconocido(fullFaceDescriptions.descriptor);

      if (this.accesoExpress.debug) {
        let txt: string = JSON.stringify(resizedDetection);
        this.ponerTxtEnConsola(`detectFace. No se puede obtener datos de reconocimiento facial. resizedDetection: ${txt}`);
      }
      this.bestMatchUndefined++;
      this.infoCamara = 'No se puede obtener datos de reconocimiento facial';
      this.infoAdd += `, No se puede obtener datos de reconocimiento facial`;
      this.addScanResult(undefined);
      return;
    }

    this.bestMatchUndefined = 0;
    this.lastCheck = now;
    this.verificaDrawingLoop();

    // si la distancia al modelo más cercano es mayor a 0.4... entonces ignoramos la lectura
    if (bestMatch.distance > 0.5){
      let fullFaceDescriptions = await this.faceApiService.detectSingleFace(this.videoElement);
      if (!!fullFaceDescriptions) this.verificaNuevoDesconocido(fullFaceDescriptions.descriptor);    
      if (this.accesoExpress.debug) {
        // let txt: string = JSON.stringify(bestMatch);
        // let txt2: string = JSON.stringify(resizedDetection);
        this.ponerTxtEnConsola(`detectFace. No se reconoce el rostro. ${bestMatch?.distance}`); //, bestMatch: ${txt}, resizedDetection: ${txt2}`);
      }
      this.bestMatchDistance++;
      this.infoCamara = 'No se reconoce el rostro';
      this.infoAdd += `, No se reconoce el rostro`;
      return;
    }
    this.isSavingDescriptors = undefined;
    this.bestMatchDistance = 0;

    // agregamos el scan.
    if (this.accesoExpress.debug) this.ponerTxtEnConsola(`detectFace. addScanResult. bestMatch.label: ${bestMatch.label}. bestMatch.distance: ${bestMatch.distance}, ha verificado: ${this.lastScanResults.length}`);
    this.addScanResult(new FaceScan(bestMatch.label, now));

    // validamos que existan al menos 3* resultados previos del mismo empleado que sean válidos
    // *: ese número es configurable (this.accesoExpress.scanCountToCheck)
    var validScan: FaceScan | undefined = this.getValidScan();

    if (!validScan) {
      if (this.infoCamara != 'No ha sido validado el escaneo') {
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`detectFace. No ha sido validado el escaneo`);
        this.infoCamara = 'No ha sido validado el escaneo';
        this.infoAdd += `, No ha sido validado el escaneo`;
      }
      return;
    }

    // if (this.accesoExpress.debug) this.ponerTxtEnConsola(`detectFace. Validando datos el empleado`);
    this.infoCamara = 'Validando datos el empleado';

    let datosEmpleado: DatosEmpleadosAcceso = {
      fotos: [],
      ultimaVerificacion: new Date(1900, 1, 1),
      esDesconocido: 0,
      idEmpleado: undefined,
      origen: TipoOrigen.NoEspecificado
    };
    if (this.datosEmpleados.hasOwnProperty(validScan.label)) {
      datosEmpleado = this.datosEmpleados[validScan.label];
    }
    if (validScan.label.startsWith('Desconocido-')) {
      if (!datosEmpleado.esDesconocido && !!datosEmpleado.idEmpleado) {
        // si el empelado desconocido ya fue identificado, entonces tomar el valor que se seleccionó anteriormente (le quita la etiqueta de desconocido)
        validScan.label = datosEmpleado.idEmpleado;
      } else if (datosEmpleado.esDesconocido < this.maxOnLine && dateDiff(datosEmpleado.ultimaVerificacion, new Date(), TipoDateDiff.minutos) >= this.revisionDesconocidoCada) {
        // si no tiene 3 intentos seguidos y no se ha revisado a este "desconocido" en los últimos x minutos, lo revisa de nuevo
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`=========================================  Toma imagen para buscar desconocido.`);
        this.IdEmpleadoFoto = validScan.label;
        this.tipoChecada = 'rf-d';
        this.tomarFoto();
        return; // si esta tomando foto no ponemos el mensaje

        if (false) {
          // let foto: string | undefined = this.tomarFoto1();
          // if (!!foto) {
          //   if (this.revisionDesconocidoCada && datosEmpleado.fotos.length >= 5) {
          //     datosEmpleado.fotos.shift(); // Removes the first element from an array and returns
          //   }
          //   this.ponerTxtEnConsola(`detectFace - desconocido. Toma de foto al empleado: ${validScan.label}, imagen: ${foto}`);
          //   datosEmpleado.fotos.push(foto);
          //   let idEmpleado: string | undefined = this.validarFotoDesconocida(datosEmpleado);
          //   datosEmpleado.ultimaVerificacion = new Date();
          //   datosEmpleado.idEmpleado = idEmpleado;
          //   this.datosEmpleados[validScan.label] = datosEmpleado;
          //   if (!!datosEmpleado.idEmpleado) {
          //     validScan.label = datosEmpleado.idEmpleado;
          //   }
          // }
        }
      }
    }

    if (validScan.label.startsWith('Desconocido-')) {
      if (!datosEmpleado.esDesconocido && !!datosEmpleado.idEmpleado) {
        // si el empelado desconocido ya fue identificado, entonces tomar el valor que se seleccionó anteriormente (le quita la etiqueta de desconocido)
        validScan.label = datosEmpleado.idEmpleado;
        this.ponerTxtEnConsola(`xxxxxxxxxxxxxxxxx  detectFace. El empleado no esta cargado en la base de datos. xxxxxxxxxxxxxxxxx`);
      }
    }

    if (validScan.label.startsWith('Desconocido-')) {
      if (this.accesoExpress.debug || this.accesoExpress.mostrarInfoAdd) {
        let txt: string = '-';
        if (this.accesoExpress.debug) txt = JSON.stringify(validScan);
        this.ponerTxtEnConsola(`detectFace. El empleado no esta cargado en la base de datos. validScan: ${txt}`);
      }
      this.bestMatchUndefined++;
      this.infoCamara = datosEmpleado.esDesconocido > 0 ? 'El empleado no esta cargado en la base de datos' : 'Empleado desconocido';
      this.infoAdd += `, ${this.infoCamara}`;
      this.addScanResult(undefined);

      this.ponerError('Empleado desconocido')
      this.idEmpleadoActivo = undefined;
      return;
    }

    this.idEmpleadoActivo = validScan.label;
    if ((this.modoAdmin == 1 || this.modoAdmin == 2) && !!this.idEmpleadoActivo) {
      console.log(`Validando modo admin => ${this.idEmpleadoActivo}, ${this.modoAdmin}`);
      let empleado: IEmpleados | undefined = this.empleados.find(x => x.id == this.idEmpleadoActivo);
      if (!!empleado && (empleado.tipoEmpleado == TipoEmpleado.Supervisor || empleado.tipoEmpleado == TipoEmpleado.Administrador)) {
        if (this.modoAdmin == 1) {
          this.abrirConfig2('modoAdmin');
        }
        if (this.modoAdmin == 2) {
          this.accesoDatosServicio.setEmpleadoActual(empleado);
          this.desactivarReconocimientoFacial();
          this._router.navigate(['inicio-empresa']);
        }
      }
    }
    this.modoAdmin = 0;
    if (this.previousValidScan) {
      // hay que asegurarse de que el scan previo no tenga más de 1 minuto guardado en memoria
      let secondsElapsedFromPreviousScan = (now.getTime() - this.previousValidScan.time.getTime()) / 1000;
      if (secondsElapsedFromPreviousScan > 60) this.previousValidScan = undefined;

      if (this.previousValidScan && this.previousValidScan.label == validScan.label) {
        // if (this.accesoExpress.debug) this.ponerTxtEnConsola(`detectFace. verificando que no se hayan cargado 2 veces al mismo empleado`);
        // en este punto es probable que la misma persona siga parada enfrente de la camara
        // pero ya fue registrada, quizá no vio el letrero de hola, entonces le avisamos que ya se puede ir.

        if (!this.previousValidScan.alreadyRegisteredWarningTime) this.previousValidScan.alreadyRegisteredWarningTime = now;
        let secondsElapsedFromPreviousWarning = (now.getTime() - this.previousValidScan.alreadyRegisteredWarningTime.getTime()) / 1000;

        // solo mostramos la advertencia cada 10 segundos que siga parado enfrente de la camara.
        if (secondsElapsedFromPreviousWarning > 10 && this.previousValidScan.employee) {
          let txt: string = `${this.previousValidScan.employee.nombre} fuiste registrado hace un momento a las ${fFecha(this.previousValidScan.time, 'fh')}`;
          if (this.accesoExpress.debug) this.ponerTxtEnConsola(txt);
          this.ponerError(txt);
          this.previousValidScan.alreadyRegisteredWarningTime = now;
        }
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(` ++++ Empleado ya registrado`);
        this.infoAdd += `, Empleado ya registrado`;
        return;
      }
    }

    this.tomarFotoIngreso(datosEmpleado, false);    

    if (this.accesoExpress.hasTimeError) {
      let txt: string = `Parece que la hora de la terminal es incorrecta, verifica e intenta de nuevo`;
      this.ponerTxtEnConsola(`detectFace. ++++ Empleado Válido pero ${txt}`);
      this.ponerError(txt);
      return;
    }

    this.previousValidScan = validScan;
    if (datosEmpleado.origen == TipoOrigen.NoEspecificado) datosEmpleado.origen = TipoOrigen.reconocimientoFacialLocal;
    this.checkEmployeeById(validScan, datosEmpleado.origen);
    if (datosEmpleado.origen == TipoOrigen.reconocimientoFacialGoogle || datosEmpleado.origen == TipoOrigen.reconocimientoFacialAmazon || datosEmpleado.origen == TipoOrigen.reconocimientoFacialServidorACE) {
      datosEmpleado.origen = TipoOrigen.reconocimientoFacialLocalHeredado;
      this.datosEmpleados[validScan.label] = datosEmpleado;
    }
    this.clearScannedResults();
  }

  private addScanResult(result: FaceScan | undefined) {
    if (!result) return;
    if (this.accesoExpress.debug) {
      // this.ponerTxtEnConsola(`lastScanResults. ${this.lastScanResults.length}`);
      // console.log(`lastScanResults`, this.lastScanResults);
    }
    this.lastScanResults.unshift(result);

    while (this.lastScanResults.length > this.accesoExpress.scanCountToCheck) {
      this.lastScanResults.pop();
    }

    if (this.accesoExpress.showCamIndicators && result) {
      let empleado: IEmpleados | undefined = this.nomiExpressApi.obtenerEmpleado(result.label);
      if (!empleado) return;
      result.employee = empleado;
    }
  }

  private verificaNuevoDesconocido(fullFaceDescription: Float32Array): number {

    if (this.isSavingDescriptors && dateDiff(this.isSavingDescriptors, new Date(), TipoDateDiff.segundos) > 2) {
      this.descriptors = [];
      this.isSavingDescriptors = undefined;
      return 0;
    }

    if (!fullFaceDescription) return 0;
    // let txt1: string = JSON.stringify(fullFaceDescription);
    // this.ponerTxtEnConsola(`detectFace. No se puede obtener datos de reconocimiento facial. detectSingleFace: ${txt1.substring(0, 30)}`);
    this.descriptors.push(fullFaceDescription);

    if (this.descriptors.length < 3) {
      this.ponerTxtEnConsola(`++++ detectFace. No se puede obtener datos de reconocimiento facial. cargando descripción desconocido. (${this.descriptors.length})`);
      return 0;
    }

    // guardamos 5 caras en modelo
    this.isSavingDescriptors = new Date();
    let labeledDescriptor = new faceApi.LabeledFaceDescriptors(`Desconocido${this.idEmpleadoDesconocido}`, this.descriptors);
    let id: number = this.idEmpleadoDesconocido;

    if (this.faceApiService.agregarLabeledDescriptor(labeledDescriptor))
    {
      this.ponerTxtEnConsola(`===================== Nuevo empleado desconocido. id: Desconocido${this.idEmpleadoDesconocido}}`);
      // donde se va a guardar los datos
      this.descriptors = [];
      this.isSavingDescriptors = undefined;
      this.idEmpleadoDesconocido--;
    }

    return id;

  }

  private tomarFoto() {
    if (!this.videoElement) {
      this.ponerTxtEnConsola(`tomarFoto. trigger. IdEmpleado: ${this.IdEmpleadoFoto} - ${this.tipoChecada}, videoElement: null`);
      return;
    }
    this.ponerTxtEnConsola(`tomarFoto. trigger. IdEmpleado: ${this.IdEmpleadoFoto} - ${this.tipoChecada}`);
    if (!this.IdEmpleadoFoto) return;
    this.trigger.next(void 0);
  }

  private tomarFoto1(): string | undefined {
    let canvas = document.getElementById('face-marks') as HTMLCanvasElement;
    if (this.accesoExpress.debug && !canvas) {
      this.ponerTxtEnConsola(`tomarFoto. canvas: null`);
      return;
    }
    this.drawingTime = this.Now().getTime();

    var ctx = canvas.getContext('2d');
    if (!ctx) {
      this.ponerTxtEnConsola(`tomarFoto. canvas: canvas: sin 2d`);
      return;
    }

    let foto = new Image();

    ctx.drawImage(foto, 0, 0);
    var dataURL = canvas.toDataURL("image/png");
    let datos: ImageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
    let datosTxt: string = genBitmapImage(datos);
    // return datosTxt;
    return dataURL.replace(/^data:image\/(png|jpg);base64,/, "");



    // foto.onload = ()=> {
    //   if (!ctx) return;
    //   this.ponerTxtEnConsola(`tomarFoto. cargando imagen`);
    //   ctx.drawImage(foto, 0, 0, canvas.width, canvas.height);
    //   this.ponerTxtEnConsola(`tomarFoto. regresar imagen`);
    //   return foto;
    // }
    // this.ponerTxtEnConsola(`tomarFoto. regresar imagen`);
    // return foto;
  }

  private validarFotoDesconocida(foto64: string, datosEmpleado: DatosEmpleadosAcceso, idDesconocido: string) {
    let idEmpleadoDesconocido = 0;
    this.infoAdd += `, validando online`;
    this.reconocimientoCFApi.empleadoReconocer(foto64).subscribe(
      (respuesta: AccesoCF_RespuestaRecognize_Respuesta) => {
        idEmpleadoDesconocido = +respuesta.subjects.subject;        
        if (respuesta.subjects.similarity < this.accesoExpress.validarReconocimiento || idEmpleadoDesconocido <= 0) {
          datosEmpleado.ultimaVerificacion = new Date();
          this.validarFotoDesconocidaNoReconocido(datosEmpleado, respuesta.subjects, idDesconocido);
          this.ponerError('Empleado desconocido')
          this.idEmpleadoActivo = undefined;
          return;
        }

        datosEmpleado.idEmpleado = `${idEmpleadoDesconocido}`;
        datosEmpleado.origen = TipoOrigen.reconocimientoFacialCF;
        this.ponerTxtEnConsola(`===================== Validado empleado OnLine. IdEmpleado: ${this.IdEmpleadoFoto} - ${this.tipoChecada}, sujeto: ${respuesta.subjects.subject}, validez: ${respuesta.subjects.similarity}`);
        this.infoAdd += `, validado online`;    
        
        if (dateDiffAhora(datosEmpleado.ultimaVerificacion, TipoDateDiff.minutos) >= 1) {          
          datosEmpleado.ultimaVerificacion = new Date();
          this.datosEmpleados[idDesconocido] = datosEmpleado;
          let validScan: FaceScan = {
            label: datosEmpleado.idEmpleado,
            time: new Date(),
            employee: undefined,
            alreadyRegisteredWarningTime: undefined
          }
          this.checkEmployeeById(validScan, datosEmpleado.origen);
          this.tomarFotoIngreso(datosEmpleado, true);
        }
      }, (error) => {
        let sujeto: AccesoCF_RespuestaRecognize_Subjects = {
          similarity: 0,
          subject: 'err'
        }
        this.validarFotoDesconocidaNoReconocido(datosEmpleado, sujeto, idDesconocido);
      }
    );
  }

  private validarFotoDesconocidaNoReconocido(datosEmpleado: DatosEmpleadosAcceso, sujeto: AccesoCF_RespuestaRecognize_Subjects, idDesconocido: string) {
    datosEmpleado.esDesconocido++;
    this.datosEmpleados[idDesconocido] = datosEmpleado;
    this.infoAdd += `, NO validado online`;
    this.ponerTxtEnConsola(`----->>> No validado empleado OnLine(${datosEmpleado.esDesconocido}). IdEmpleado: ${this.IdEmpleadoFoto}, sujeto: ${sujeto.subject}, validez: ${sujeto.similarity}`);
  }

  private tomarFotoIngreso(datosEmpleado: DatosEmpleadosAcceso, online: boolean) {
    if (dateDiff(datosEmpleado.ultimaVerificacion, new Date(), TipoDateDiff.minutos) >= this.revisionDesconocidoCada) {
      this.infoAdd += `, reg ace`;
      this.IdEmpleadoFoto = datosEmpleado.idEmpleado;
      this.tipoChecada = 'rf';
      if (this.accesoExpress.debug) this.ponerTxtEnConsola(`=========================================  Toma imagen para registrar empleado con RF ${(online ? '(online).' : '' )}`);
      this.tomarFoto();          
    }
  }

  private clearScannedResults() {
    // if (this.accesoExpress.debug) this.ponerTxtEnConsola(`clearScannedResults. limpiando scans. ${this.lastScanResults.length}`);
    this.lastScanResults = [];
  }

  private closeFaceBox() {
    this.faceBox.move(new faceApi.Box<any>({
      x: this.faceBox.currentValue.x + this.faceBox.currentValue.width * .5,
      y: this.faceBox.currentValue.y + this.faceBox.currentValue.height * .5,
      width: 0,
      height: 0
    }), this.drawingTime);
  }

  private getFaceToCameraRatio(currentFaceBox?: faceApi.Box, ponerMensaje?: boolean) {
    var totalArea = this.displaySize.width * this.displaySize.height;
    currentFaceBox = currentFaceBox || this.faceBox.currentValue;
    var faceArea = currentFaceBox.width * currentFaceBox.height;
    if (ponerMensaje) this.ponerTxtEnConsola(`FaceToCameraRatio. faceArea: ${faceArea} / totalArea: ${totalArea}`);
    return faceArea / totalArea;
  }

  private getFaceExpression(expressions: faceApi.FaceExpressions): { expression: string, probability: number, doNotMessage: string } | undefined {
    let a = expressions.asSortedArray();

    let expression = a[0].probability > 0.93 ? a[0].expression : undefined;
    if (!expression) return undefined;

    let doNot = '';

    if (expression == 'happy') doNot = 'Cara neutra, no sonrias';
    if (expression == 'sad') doNot = 'No estés triste, cara neutra';
    if (expression == 'angry') doNot = 'No te enojes, cara neutra';
    if (expression == 'disgusted') doNot = 'Calma, cara neutra';
    if (expression == 'surprised') doNot = 'Calma, cara neutra';
    if (expression == 'fearful') doNot = 'No tengas miedo, cara neutra';

    if (expression && !doNot) doNot = 'Cara neutra por favor';

    return { expression: expression, probability: a[0].probability, doNotMessage: doNot };
  }

  private getValidScan(): FaceScan | undefined {
    if (this.lastScanResults.length < this.accesoExpress.scanCountToCheck) {
      // if (this.accesoExpress.debug) this.ponerTxtEnConsola(`Debe de verificar al empleado ${this.accesoExpress.scanCountToCheck} veces, ha verificado: ${this.lastScanResults.length}`);
      return undefined;
    }

    var first: FaceScan | undefined = undefined;
    var last: FaceScan | undefined = undefined;

    for (let i = 0; i < this.lastScanResults.length; i++) {
      const scannedElement = this.lastScanResults[i];
      // si algún elemento es indefinido entonces no hay resultado.
      if (!scannedElement) {
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`No debe haber elementos nulos en el listado de las últimas checadas`);
        return undefined;
      }

      if (!first) first = scannedElement;
      // si hay dos empleados distintos, entonces no hay resultado válido
      if (!first || first.label != scannedElement.label) {
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`No debe haber empleados diferentes en el listado de las últimas checadas`);
        return undefined;
      }

      last = scannedElement;
    }

    if (!first || !last) {
      if (this.accesoExpress.debug) this.ponerTxtEnConsola(`Solo hay un elemento en el listado de las últimas checadas`);
      return undefined;
    }
    var deltaSecs = (first.time.getTime() - last.time.getTime()) / 1000;

    // si hay una diferencia en segundos mayor a 5 entonces no es un válido
    // ToDo: sería mejor en vez de 5 seg, usar un valor en función de getScansPerSecond()
    // por que no todas las computadoras leen a la misma velocidad.
    if (deltaSecs > 5) {
      if (this.accesoExpress.debug) this.ponerTxtEnConsola(`No debe haber una diferencia de mas de 5 segundos entre los chequeados del empleado. ${deltaSecs}`);
      return undefined;
    }

    // if (this.accesoExpress.debug) this.ponerTxtEnConsola(`=========================================  Empleado válido`);
    return first;
  }

  private setDefaultMarksColors() {
    this.marksPrimaryColor = this.accesoExpress.camHighlight1;
    this.marksSecondaryColor = this.accesoExpress.camHighlight2;
  }

  private setWarningMarksColors() {
    this.marksPrimaryColor = '#ef5350';
    this.marksSecondaryColor = '#fafafa';
  }

  private getFistScanResult(): FaceScan | undefined {
    if (this.lastScanResults.length == 0) return undefined;
    return this.lastScanResults[0];
  }

  private getScansPerSecond() {
    return this.reconocimientosFaciales / ((this.Now().getTime() - this.inicioReconocimientoFacial) / 1000);
  }

  private drawTextBox(text: string, x: number, y: number, ctx: CanvasRenderingContext2D) {
    var padding = 10;

    var nameSize = ctx.measureText(text);
    let actualHeight = nameSize.actualBoundingBoxAscent + nameSize.actualBoundingBoxDescent;

    ctx.beginPath();
    ctx.fillStyle = this.marksPrimaryColor;
    ctx.rect(x - nameSize.width * 0.5, y, nameSize.width + padding, actualHeight + padding);
    ctx.fill();

    ctx.fillStyle = this.marksSecondaryColor;
    ctx.fillText(text, x - nameSize.width * 0.5 + padding * 0.5, y + actualHeight + padding * 0.5);

    return y + actualHeight + padding;
  }

  private drawFaceMarks() {
    this.drawCada = dateDiff(this._ultimoDraw, this.Now(), TipoDateDiff.segundos);
    this._ultimoDraw = this.Now();
    if (!this.mostrarFaceID) {
      this._estaCerrando = true;
      clearInterval(this._drawingLoop);
      return;
    }
    if (this.accesoExpress.debug && false) {
      let now: Date = this.Now();
      this.ponerTxtEnConsola(`drawFaceMarks. now: ${fFecha(now, 'fmhs')}`);
    }
    let easingFunction = (x: number) => Math.sin((x * Math.PI) / 2);

    let canvas = document.getElementById('face-marks') as HTMLCanvasElement;
    if (this.accesoExpress.debug && !canvas) {
      this.ponerTxtEnConsola(`drawFaceMarks. canvas: null, mostrarFaceID: ${this.mostrarFaceID}`);
      return;
    }
    this.drawingTime = this.Now().getTime();

    var ctx = canvas.getContext('2d');
    if (!ctx) {
      this.ponerTxtEnConsola(`drawFaceMarks. canvas: canvas: sin 2d`);
      return;
    }
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    if (!this.faceBox) {
      this.ponerTxtEnConsola(`drawFaceMarks. faceBox: null`);
      return;
    }

    let h = this.faceBox.start + this.faceBox.speed;
    let t = this.drawingTime >= h
      ? 1
      : 1 - ((h - this.drawingTime) / (this.faceBox.speed));

    let p = easingFunction(t);
    var currentFaceBox = this.faceBox.currentValue;

    this.faceBox.currentValue = new faceApi.Box({
      x: currentFaceBox.x + (this.faceBox.targetValue.x - currentFaceBox.x + this.accesoExpress.faceBoxX) * p,
      y: currentFaceBox.y + (this.faceBox.targetValue.y - currentFaceBox.y + this.accesoExpress.faceBoxY) * p,
      width: currentFaceBox.width + (this.faceBox.targetValue.width - currentFaceBox.width + this.accesoExpress.faceBoxW) * p,
      height: currentFaceBox.height + (this.faceBox.targetValue.height - currentFaceBox.height + this.accesoExpress.faceBoxH) * p,
    });

    if (currentFaceBox.width < .01 || currentFaceBox.height < .01) {
      if (this.accesoExpress.debug && (currentFaceBox.width >= 0.01 || currentFaceBox.height >= 0.01)) {
        this.ponerTxtEnConsola(`drawFaceMarks. currentFaceBox: muy pequeño. ${currentFaceBox.width}, ${currentFaceBox.height}`);
      }
      this.infoCamara = 'No se reconocen rostros en los datos';
      return;
    }
    this.infoCamara = '';

    let detectionsBox = new faceApi.draw.DrawBox(currentFaceBox, { boxColor: this.marksPrimaryColor, lineWidth: 4 });
    detectionsBox.draw(canvas);

    this.setDefaultMarksColors();

    let py = 0;
    if (this.accesoExpress.showCamIndicators) {
      ctx.font = "20px Arial";

      let x = currentFaceBox.x + currentFaceBox.width * 0.5
      let y = currentFaceBox.y + currentFaceBox.height;
      let firstScannedResult = this.getFistScanResult();

      // el empleado carga de forma asyncronica
      // entonces si no ha cargado... no escribimos el nombre aún
      // además es posible que no cargue por que no encontró conincidencias con la cara
      if (firstScannedResult && firstScannedResult.employee) {
        let name = nombreEmpleado(firstScannedResult.employee).toLocaleUpperCase();
        y = this.drawTextBox(name, currentFaceBox.x + currentFaceBox.width * 0.5, y + 5, ctx);
      }

      if (this.rotation && this.rotation.result)
        y = this.drawTextBox(
          'Giro de cabeza ' + this.rotation.result + ' , rx: ' + this.rotation.rx + ', ry: ' + this.rotation.ry, x, y + 5, ctx);
      if (this.expression && this.expression.expression)
          y = this.drawTextBox(this.expression.expression + ' ' + Math.round(this.expression.probability*100)/100, x, y + 5, ctx);
      y = this.drawTextBox('Escaneos por segundo: ' + Math.round(this.getScansPerSecond() * 100) / 100, x, y + 5, ctx);
      y = this.drawTextBox('cara/camara: ' + Math.round(this.getFaceToCameraRatio() * 10000) / 100 + ' %', x, y + 5, ctx);

      py = y;
    }

    if (this.requiresCloser || this.requiresCenter || this.requiresNeutral) {
      this.setWarningMarksColors();
      ctx.font = "40px Arial";
      let y = this.faceBox.currentValue.y - 50;
      if (y < 50) {
        y = py == 0 ? currentFaceBox.y + currentFaceBox.height : py;
      }

      this.infoCamara = this.requiresCloser
        ? 'Acércate más'
        : (this.requiresCenter
          ? 'Centra tu cara'
          : (this.requiresNeutral
            ? (this.expression ? this.expression.doNotMessage : '')
            : ''));

      this.drawTextBox(this.infoCamara, this.faceBox.currentValue.x + this.faceBox.currentValue.width * 0.5, y + 5, ctx);
    }

    if (this.estaProbandoReconocimientoFacial()) {
      var remaining = Math.round((this.tryingBiometricsEnd.getTime() - this.Now().getTime()) / 1000);
      ctx.font = "20px Arial";

      var m = remaining > 5
        ? `MODO PRUEBA (${remaining - 5} seg)`
        : 'Aléjate del lector, termino el modo de prueba...';

      this.drawTextBox(
        m,
        this.faceBox.currentValue.x + this.faceBox.currentValue.width * 0.5, this.faceBox.currentValue.y + 5, ctx);
    }
  }

  private checkEmployeeById (match: FaceScan, origen: TipoOrigen) {
    // if (this.accesoExpress.debug) this.ponerTxtEnConsola(`checkEmployeeById. verificando checada del empleado`);
    if (this.accesoExpress.debug) this.ponerTxtEnConsola(`=========================================  Empleado válido --> checkEmployeeById. verificando checada del empleado`);
    let empleado: IEmpleados | undefined = this.nomiExpressApi.obtenerEmpleado(match.label);
    if (!empleado) {
      this.infoAdd += `No se puede localizar al empleado seleccionado. ${match.label}`;
      this.ponerTxtEnConsola(`checkEmployeeById. No se localizó al empleado ${match.label}`);
      this.ponerError('Ha ocurrido un error inesperado, por favor intenta de nuevo, si el problema persiste contacta a soporte técnico.');
      return;
    }
    this.infoAdd += `, Empleado: ${nombreEmpleado(empleado)}`;
    this.checadaEmpleado(empleado, match.time, false, origen);
  }

  public obtenerDatos() {
    if (this.accesoExpress.debug) {
      this.ponerTxtEnConsola('Cargando datos dese el servidor...');
    } else {
      this.nomiExpressApi.logAgrega('Cargando datos dese el servidor...');
    }
    this.proceso = 'Cargando datos dese el servidor...'
    this.nomiExpressApi.logAgrega2(`Cargando datos desde el Servidor`);

    this.datosActualizados = new Date(1900, 0, 0);
    setTimeout(() => {
      // this.nomiExpressApi.logAgrega2(`Verificando fecha de actualización de datos ${fFecha(this.datosActualizados, 'fmhs')}`);
      if (dateDiff(this.datosActualizados, new Date(), TipoDateDiff.días) >= 1) {
        this.nomiExpressApi.logAgrega2(`Reiniciando (a2)`);
        window.location.reload();
      }
    }, 10000);

    this.nomiExpressApi.getUpdate(ManejoDescarga.TodoSinAccesos).subscribe(
      (datosApi: IResultadoActualiza | undefined) => {
        this.datosActualizados = new Date();
        this.nomiExpressApi.logAgrega2(`Procesando datos del Servidor.  ${fFecha(this.datosActualizados, 'fmhs')}`);
        if (!datosApi) {
          this.nomiExpressApi.logAgrega2('Error al cargar datos. Sin información.');
          this.proceso = 'Error al cargar datos. Sin información.'
          this.renovacion = 'Tu suscripción esta por expirar';
          return;
        }
        if (!datosApi.checkServerTime) {
          this._router.navigate(['fecha-no-sincronizada']);
          return;
        }
        if (this.accesoExpress.isActive) {
          this.nomiExpressApi.logAgrega2('Datos cargados.');
          let ahora: Date = Ahora(this.accesoExpress.opcionesFecha);
          // console.log(`acceso.component: expiracion`);
          let expiracion: Date = new Date(ahora.getFullYear() + 1, 11, 30);
          let mostrarMensaje: Date = addDays(expiracion, -31);
          if ((ahora.getTime() - mostrarMensaje.getTime()) >= 0)  {
            this.renovacion = 'Tu suscripción esta por expirar';
          }
        } else {
          this.nomiExpressApi.logAgrega('Tu suscripción esta por expirar');
          this.renovacion = 'Tu suscripción esta por expirar';
        }
        this.proceso = 'Guardando datos localmente...'
        this.empleados = datosApi.empleados;
        this.codigosQr = datosApi.codigosQr;
        this.proceso = 'Guardando datos al servidor...'
        try {
          this.nomiExpressApi.guardarDatosTerminalDelServidor().subscribe(
            (respuesta: IRespuestaChecker) => {
              console.log(`Guardando configuración desde el servidor. Codigo: ${respuesta.code}, Mensaje: ${respuesta.mensaje}`);
              this._router.navigate(['']);
            }, (error) => {
              console.error(error);
            }
          );
        } catch(ex) {
          console.error(ex);
        }
        this.proceso = 'Proceso terminado, guardando datos localmente, en espera de respuesta del servidor...';
      }, (err: Error) => {
        this.proceso = 'Error al cargar datos';
        this.renovacion = 'Tu suscripción esta por expirar';
        this.nomiExpressApi.logAgrega(`Error al cargar datos ${err.message}`);
        console.error(err);
        this.ponerError(err.message);
      }
    );
  }

  private inicializarReconocimientoFacial() {
    this.ponerTxtEnConsola(`>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> inicializarReconocimientoFacial.`);
    try {
      this.faceApiService.loadFaceDetection();
    } catch(ex) {
      console.error(ex);
      this.ponerTxtEnConsola(`Error al cargar los datos del reconocimiento facial.` + JSON.stringify(ex));
      this.ponerError('Error al cargar el reconocimiento facial');
    }
    setTimeout(this._inicializarReconocimientoFacial, 100);
  }

  private inicializarReconocimientoFacial2() {
    this.ponerTxtEnConsola(`>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>> inicializarReconocimientoFacial (2)`);
    if (this.accesoExpress.faceRecognitionDebugMode) {
      this.tryingBiometricsEnd = new Date(2200, 1, 1);
      this.ponerAdvertencia('El registro de incidencias está deshabilitado');
      this.ponerTxtEnConsola('El registro de incidencias está deshabilitado');
    }

    this.recargarFaceBox();

    this._estaCerrando = false;
    if (!!this.videoElement) {
      this.videoElement.addEventListener('play', () => {
        this.onPlay();
      });

      this._drawingLoop = setInterval(() => {
        if (!this.mostrarFaceID) {
          console.log(`_drawingLoop ${this._drawingLoop} ==> desactivando`);
          this.desactivarReconocimientoFacial();
          return;
        }
        this.drawFaceMarks();
        this.dps = this.accesoExpress.canvasFps / this.drawingLoop;
      }, this.accesoExpress.canvasFps * 1000 / this.drawingLoop);
    }
  }

  private recargarFaceBox() {
    this.videoElement = document.getElementsByTagName('video')[0] as HTMLVideoElement;
    if (this.accesoExpress.debug) {
      if (!this.videoElement) {
        this.ponerTxtEnConsola('videoElement: null');
      } 
      // else {
      //   let txt: string = JSON.stringify(this.videoElement);
      //   if (this.accesoExpress.debug) this.ponerTxtEnConsola(`videoElement: ${txt}`);
      // }
    }

    let canvas = document.getElementById('face-marks') as HTMLCanvasElement;
    if (!canvas || canvas == null) {
      if (this.accesoExpress.debug) this.ponerTxtEnConsola('canvas: null');
      return;
    }
    // if (this.accesoExpress.debug) {
    //   let txt: string = JSON.stringify(canvas);
    //   this.ponerTxtEnConsola(`canvas: ${txt}`);
    // }

    let canvasRect = canvas.getBoundingClientRect();
    if (!canvasRect || canvasRect == null) {
      if (this.accesoExpress.debug) this.ponerTxtEnConsola('canvasRect: null');
      return;
    }
    // if (this.accesoExpress.debug) {
    //   let txt: string = JSON.stringify(canvasRect);
    //   this.ponerTxtEnConsola(`canvasRect: ${txt}`);
    // }

    this.faceBox = new Transition<faceApi.Box<any>>(
      new faceApi.Box<any>({ x: canvasRect.left / 2, y: canvasRect.top / 2, width: 0, height: 0 }),
      new faceApi.Box<any>({ x: canvasRect.left / 2, y: canvasRect.top / 2, width: 0, height: 0 }),
      this.drawingTime,
      300);
    if (!this.faceBox || this.faceBox == null) {
      if (this.accesoExpress.debug) this.ponerTxtEnConsola('faceBox: null');
      return;
    }
    // if (this.accesoExpress.debug) {
    //   let txt: string = JSON.stringify(this.faceBox);
    //   this.ponerTxtEnConsola(`faceBox: ${txt}`);
    // }
  }

  private desactivarReconocimientoFacial(){
    this.mostrarFaceID = false;
    this.ponerTxtEnConsola('Desactivando reconocimiento facial');
    clearInterval(this._drawingLoop);
    clearInterval(this._codeLoop);
    this.videoElement = undefined;
    this.faceBox = new Transition<faceApi.Box<any>>(new faceApi.Box<any>( { x: 0, y: 0, width: 0, height: 0 }), new faceApi.Box<any>({ x: 0, y: 0, width: 0, height: 0 }), 0, 0);
    for (let index = 0; index < +this._drawingLoop; index++) {
      clearInterval(index);
    }
    clearInterval(this._codeLoop);
  }

  public urlBase(): string {
    return environment.hubUrl;
  }

  private async onPlay() {
    if (this._estaCerrando) return;
    if (!this.detectFaceActivado) {
      this.ponerTxtEnConsola(`************************************  onPlay --> addEventListener`);
      this.detectFaceActivado = true;
    }
    await this.detectFace();
    setTimeout(() => { this.onPlay(); });
  }

  public captureImg(webcamImage: WebcamImage): void {
    if (!this.IdEmpleadoFoto) return;

    let datosEmpleado: DatosEmpleadosAcceso = {
      fotos: [],
      ultimaVerificacion: new Date(1900, 1, 1),
      esDesconocido: 0,
      idEmpleado: undefined,
      origen: TipoOrigen.NoEspecificado
    };
    if (this.datosEmpleados.hasOwnProperty(this.IdEmpleadoFoto)) {
      datosEmpleado = this.datosEmpleados[this.IdEmpleadoFoto];
    } else if (!this.IdEmpleadoFoto.startsWith('Desconocido-'))  {
      datosEmpleado.esDesconocido = 0;
      datosEmpleado.idEmpleado = this.IdEmpleadoFoto;
    }

    this.infoAdd += `, ft`;
    this.webcamImage = webcamImage;
    let sysImage: string = webcamImage!.imageAsDataUrl;
    let foto: string = webcamImage!.imageAsBase64;
    
    if (!foto) {
      this.ponerTxtEnConsola(`captureImg. foto: null... sysImage: ${sysImage.substring(0, 50)}..., IdEmpleadoFoto: ${this.IdEmpleadoFoto} - ${this.tipoChecada}`);
      this.IdEmpleadoFoto = undefined;
      return;
    }

    if (datosEmpleado.fotos && datosEmpleado.fotos.length >= 5) {
      datosEmpleado.fotos.shift(); // Removes the first element from an array and returns
    }
    this.ponerTxtEnConsola(`captureImg - empleado. Toma de foto al empleado: ${this.IdEmpleadoFoto} - ${this.tipoChecada}, imagen: ${foto.substring(0, 30)}...`);

    datosEmpleado.fotos.push(foto);    

    if (this.IdEmpleadoFoto.startsWith('Desconocido-') || this.tipoChecada == 'rfo') {
      this.datosEmpleados[this.IdEmpleadoFoto] = datosEmpleado;
      this.validarFotoDesconocida(foto, datosEmpleado, this.IdEmpleadoFoto);
      return;
    }

    datosEmpleado.ultimaVerificacion = new Date();
    this.datosEmpleados[this.IdEmpleadoFoto] = datosEmpleado;

    this.nomiExpressApi.enviarDatosEmpleado(+this.IdEmpleadoFoto, this.tipoChecada, foto, 0, undefined).subscribe( (x: IRespuestaChecker) => {
      this.ponerTxtEnConsola(`=========================================  captureImg. enviarDatosEmpleado. Código: ${x.code}, Mensaje: ${x.mensaje}, IdEmpleadoFoto ${this.IdEmpleadoFoto} - ${this.tipoChecada}`);      
      this.IdEmpleadoFoto = undefined;
    }, (err: Error) => {
      let txt: string = err.message;
      this.IdEmpleadoFoto = undefined;
      this.ponerTxtEnConsola(`=========================================  captureImg. enviarDatosEmpleado. Error: ${txt}`);
    });

  }

  public get invokeObservable(): Observable<any> {
    return this.trigger.asObservable();
  }

  public get videoOptions(): MediaTrackConstraints {
    const result: MediaTrackConstraints = {};
    if (this.accesoExpress.facingMode && this.accesoExpress.facingMode !== '') {
        if (this.facingMode != this.accesoExpress.facingMode) {
          this.ponerTxtEnConsola(`>>>>>>>>>>>>>>>>>>>>>>>>> facingMode: ${this.accesoExpress.facingMode}`)
          this.facingMode = this.accesoExpress.facingMode;
        }
        result.facingMode = { ideal: this.accesoExpress.facingMode };
    }
    return result;
  }

  public get nextWebcamObservable(): Observable<boolean|string> {
    return this.nextWebcam.asObservable();
  }

  public mandarPagina() {
    window.open('https://asesorcontable.mx', '_blank');
  }

  ngAfterViewInit(): void {
    this.onResize();
    if (this.mostrarFaceID) {
      if (!this.faceApiService.isFaceDetectionLoaded && !this.faceApiService.isFaceDetectionLoading){
        this.inicializarReconocimientoFacial();
      } else if (this.faceApiService.isFaceDetectionLoaded) {
        this.inicializarReconocimientoFacial2();
      }
    }
  }

  ngOnInit(): void {
    if (environment.production) {
      if (location.protocol === 'http:') {
        this.proceso = 'Redirigiendo a https...';
        this.nomiExpressApi.logAgrega('Redirigiendo a https...');
        window.location.href = location.href.replace('http', 'https');
      }
    }

    this.obtenerDatos();
    this._codeLoop = setInterval(() => {
      const now = this.Now();
      this.terminalTime = `${now.getHours().toString().padStart(2, '0')} : ${now.getMinutes().toString().padStart(2, '0')} : ${now.getSeconds().toString().padStart(2, '0')}`;
    }, 1000);

    if (!this._accessHubService.isListeningToUserAccess) {
      this._accessHubService.onUserAccess.subscribe(token => {
        this.nomiExpressApi.logAgrega('Conectando con el servidor. Acceso de usuario.');
        this.proceso = 'Conectando con el servidor. Acceso de usuario.';
        if (this.accesoExpress.debug) this.ponerTxtEnConsola(`_accessHubService ==> onUserAccess token.code: ${token.code}`);
        this.processScanToken(token);
       });
      this._accessHubService.onTokenValidationRequested.subscribe((secretoValidar: SecretValidation) => {
        this.nomiExpressApi.logAgrega('Conectando con el servidor. Validando');
        this.proceso = 'Conectando con el servidor. Validando';
        secretoValidar.state = secretoValidar.secret.toLocaleLowerCase() == this.accesoExpress.localSecret.toLocaleLowerCase() ? 'ok' : '??';
        this._httpClient.post(`${environment.hubUrl}/access/validateSecret`, secretoValidar).subscribe();
      });
      this._accessHubService.isListeningToUserAccess = true;
    }

    this.subscriptions.add(
      this._accessHubService.connectionStateSubject.subscribe(async state => {
        this.connectionState = state;
        this.nomiExpressApi.logAgrega('Access Hub - Connecting');
        if (this.idConexionPuntoAcceso != this.connectionState.id && (!this.connectionState.id || this.connectionState.id == ':)'))  {
          this.idConexionPuntoAcceso = this.connectionState.id;
        }

        if (!this.accesoExpress.escucharPorMovimientos || this.connectionState.state != 'connected' || this.buscandoConexion) {
          this.nomiExpressApi.logAgrega('Conectando con el servidor. No conectado.');
          this.proceso = 'Conectando con el servidor. No conectado.';
          if (!this.idConexionPuntoAcceso) {
            this.ponerError('No se ha conectado al servidor');
          }
          this.estaEscuchando = false;
          return;
        }
        if (this.error == 'No se ha conectado al servidor') this.error = '';
        this.nomiExpressApi.logAgrega('Conectando con el servidor. Conectado.');
        this.proceso = 'Conectando con el servidor. Conectado.';
        setTimeout(this.siguienteCodigo, 100);

        let listenForScreen = () => {
          this.buscandoConexion = true;
          this.nomiExpressApi.accessHub_EscucharPorMovimiento(this.connectionState.id).subscribe(
            success => {
              // verificar la versión y ver si no hay datos nuevos
              this.nomiExpressApi.logAgrega('Conectando con el servidor. AccessHub_EscucharPorMovimiento.');
              this.estaEscuchando = true;
              this.buscandoConexion = false;
              setTimeout(() => {
                listenForScreen();
              }, 1000 * 60 * 60);
            }, error => {
              this.estaEscuchando = false;
              this.buscandoConexion = false;
              this.nomiExpressApi.logAgrega(`Conectando con el servidor. AccessHub_EscucharPorMovimiento. Error.`);
              this.nomiExpressApi.validarPuntoDeAcceso().subscribe(
                (response: ITokenAccess) => {
                  const acceso = response.accessPoint;
                  this.accesoExpress.isActive = acceso.isActive;
                  setTimeout(() => {
                    listenForScreen();
                  }, 1000 * 10);
                }, (error: any) => {
                  setTimeout(() => {
                    listenForScreen();
                  }, 1000 * 20);
                });
            }
          );
        };

        listenForScreen();
      })
    );

    let empleado = this._activatedRoute.snapshot.params['employee'];
    if (empleado) {
      // this._api.employees.getOne(employee).subscribe(e => this.setBiometricsFor = e);
      // this._appService.sideBarSubject.next(false);
    }

    this.mostrarFaceID = this.accesoExpress.isActive && this.accesoExpress.faceDetectionEnabled && this.accesoExpress.faceDetectionActive;
    if (this.mostrarFaceID) this.inicializarReconocimientoFacial();

    // this._lockTimeout = setTimeout(() => {
    //   this._appService.isLocked = true;
    // }, 5000);
  }

  ngOnDestroy(): void {
    console.log('acceso-bi, ngOnDestroy');
    this._estaCerrando = true;
    this.subscriptions.unsubscribe();
    clearInterval(this._codeLoop);
    clearInterval(this._drawingLoop);
    // clearTimeout(this._lockTimeout);
  }

}

