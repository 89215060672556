import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { fFecha, getFechaT } from 'src/app/core/Funciones/fFecha';
import { mostrarSwalError, mostrarSwalToast } from 'src/app/core/Funciones/funciones';
import { NomiExpressApiService } from 'src/app/core/services/NomiExpress.api.service';
import { AccesoDatosService } from 'src/app/core/services/acceso-datos.service';
import { EmpleadosContratoComponent } from 'src/app/empleados/empleados-contrato/empleados-contrato.component';
import { AccesoExpress } from 'src/app/models/accesoExpress';
import { Estados, IEmpresa, empresaDesdeAcceso } from 'src/app/models/empresa';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-datos-empresa',
  templateUrl: './datos-empresa.component.html',
  styleUrls: ['./datos-empresa.component.scss'],
})
export class DatosEmpresaComponent {
  public accesoExpress: AccesoExpress;
  public empresa: IEmpresa | undefined = undefined;
  public enEdicion: boolean = false;
  public esDebugger: boolean = false;
  public selectedFile: File | undefined = undefined;
  public vigenteHastaTxt: string = '';
  public estados = Estados;
  public inicioApp: Date = new Date();
  public vigenciaValida: boolean = false;

  @Input() mostrarElementosEmpleados: boolean = false;

  @Output()
  public cambiarMenuPuntosAcceso = new EventEmitter<boolean>();

  constructor(
    private nomiExpressApi: NomiExpressApiService,
    private accesoDatosService: AccesoDatosService,
    private readonly _router: Router,
    public dialog: MatDialog
  ) {
    this.accesoExpress = this.nomiExpressApi.accesoExpress();
    this.empresa = empresaDesdeAcceso(this.accesoExpress);
    this.cargarDatos();
    if (!this.accesoDatosService.servicioActivo()) {
      mostrarSwalError('Acceso Express', 'El servicio no está activo');
      this._router.navigate(['']);
    }
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.inicioApp = accesoDatosService.inicioApp;
  }

  private cargarDatos() {
    this.esDebugger = this.accesoDatosService.getModoDebug();
    this.accesoDatosService.empresaObtener().subscribe(
      (empresa: IEmpresa) => {
        // if (this.accesoExpress.debug) {
        //   this.ponerTxtEnConsola(`Empresa obtener. ${JSON.stringify(empresa)}`);
        // }
        this.empresa = empresa;
        if (!this.esDebugger) this.empresa.apiKeyCF = '';
        this.empresa.vigenteHastaFecha = getFechaT(this.empresa.vigenteHasta);
        if (this.empresa.vigenteHastaFecha < new Date(2024, 8, 31)) {
          this.empresa.vigenteHastaFecha = new Date(2024, 11, 31);
        }
        this.vigenteHastaTxt = fFecha(this.empresa.vigenteHastaFecha, "FSL");
        // console.log(`vigenteHasta: ${this.empresa.vigenteHastaFecha} ${this.vigenteHastaTxt}, Logo: ${empresa.imageUrl}`);
        this.vigenciaValida = this.verificarVigencia();
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Empresa obtener. Error.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
      },
      () => {
        this.enEdicion = false;
      }
    );
  }

  private ponerTxtEnConsola(txt: string) {
    console.log(txt);
    this.nomiExpressApi.logAgrega(txt);
  }

  public guardarCambios() {
    if (!this.empresa) return;

    // if (this.empresa.telefono.length < 10) {
    //   mostrarSwalError("Error al guardar los datos", 'El número de teléfono ingresado es demasiado corto. Asegúrese de que tenga al menos 10 dígitos antes de intentar guardar.');
    //   return;
    // }

    const datosFaltantes = [];
    if (!this.empresa.rfc) datosFaltantes.push('RFC');
    if (!this.empresa.nombre) datosFaltantes.push('Nombre de la empresa');
    if (!this.empresa.correo) datosFaltantes.push('Correo');

    if (datosFaltantes.length > 0) {
      const datosFaltantesTxt = datosFaltantes.join(', ');
      mostrarSwalError("Error al guardar los datos", `Faltan los siguientes campos: ${datosFaltantesTxt}.`);
      return;
    }

    if (!this.esDebugger) this.empresa.apiKeyCF = '';
    this.accesoDatosService.empresaModificar(this.empresa).subscribe(
      (empresa: IEmpresa) => {
        this.empresa = empresa;
        this.cargarDatos();
        mostrarSwalToast("Datos guardados correctamente", 'success');
      },
      (error: HttpErrorResponse) => {
        this.ponerTxtEnConsola(`Conectando con el servidor. Empresa modificar. Error.`);
        let err: string = JSON.stringify(error);
        this.ponerTxtEnConsola(err);
        mostrarSwalError("Error al guardar los datos", `${err}`)
      }
    );
  }

  public deshacerCambios() {
    this.cargarDatos();
  }

  public modificarDatos() {
    this.enEdicion = true;
  }

  public formatoFecha(fecha: Date | undefined) {
    if (!fecha) {
      return '-';
    }
    return fFecha(fecha, 'FSL');
  }

  public fFecha(fecha: Date): string {
    return fFecha(fecha, 'fmh');
  }

  public onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.cambiarLogo();
  }

  public cambiarLogo() {
    if (!this.selectedFile || !this.enEdicion) return;

    const reader = new FileReader();
    reader.readAsDataURL(this.selectedFile);
    reader.onload = () => {
      const img = new Image();
      img.src = reader.result as string;
      img.onload = () => {
        const imageHeight = img.naturalHeight;
        const imageWidth = img.naturalWidth;
        if (imageHeight < 10 || imageHeight > 500 || imageWidth < 10 || imageWidth > 500) {
          console.log('Width and Height', imageWidth, imageHeight);
          mostrarSwalError('Acceso Express', `El tamaño de la imagen es incorrecto, debe ser máximo de 500x500 pixeles (${imageHeight}x${imageWidth})`);
          return;
        }
        if (!environment.production) console.log('Width and Height', imageWidth, imageHeight);
        if (!this.selectedFile) return;
        this.accesoDatosService.empresaCambiarLogo(this.selectedFile).subscribe(
          x => {
            mostrarSwalToast('Cambio de logotipo realizado con éxito.', 'success');
            this.enEdicion = false;
            this.cargarDatos();
          }
      );
      };
    };
  }

  public validarCP(event: any) {
    const input = event.target.value;
    if (this.empresa) {
      let convertirCP = input.replace(/\D/g, '').slice(0, 5);

      if (convertirCP.length < 6 && convertirCP.length >= 2) {
        convertirCP = convertirCP.padStart(5, '0');
      }

      this.empresa.cp = convertirCP;
    }
  }

  public modoDebug() {
    this.accesoDatosService.setModoDebug(!this.esDebugger);
    this.esDebugger = this.accesoDatosService.getModoDebug();
    mostrarSwalToast(`Modo debug ${this.esDebugger ? 'activado' : 'desactivado'}`, 'success');
  }

  public visualizarSucursales() {
    this.cambiarMenuPuntosAcceso.emit(true);
  }

  public verificarVigencia(): boolean {
    if (!this.empresa) return false;

    const hoy = new Date();
    const vigenteHasta = this.empresa.vigenteHastaFecha;
    const diferenciaTiempo = vigenteHasta.getTime() - hoy.getTime();
    const diferenciaDias = diferenciaTiempo / (1000 * 3600 * 24);

    return diferenciaDias <= 30;
  }

  ngOnInit(): void {}
}
